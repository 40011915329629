export const fonts = {
    Nunito: { family: 'Nunito', google: true, options: ':300,400,600,700&display=swap' },
    'Open Sans': { family: 'Open Sans', google: true, options: ':300,400,500,700&display=swap' },
    Roboto: { family: 'Roboto', google: true, options: ':300,400,500,700&display=swap' },
    Lato: { family: 'Lato', google: true, options: ':300,400,500,700&display=swap' },
    Ubuntu: { family: 'Ubuntu', google: true, options: ':300,400,500,700&display=swap' },
    Montserrat: { family: 'Montserrat', google: true, options: ':300,400,500,700&display=swap' },
    'Exo 2': { family: 'Exo 2', google: true, options: ':300,400,500,700&display=swap' },
    Oswald: { family: 'Oswald', google: true, options: ':300,400,500,700&display=swap' },
    Dosis: { family: 'Dosis', google: true, options: ':300,400,500,700&display=swap' },
    'EB Garamond': { family: 'EB Garamond', google: true, options: ':300,400,500,700&display=swap' },
    Raleway: { family: 'Raleway', google: true, options: ':300,400,500,700&display=swap' },
    Rubik: { family: 'Rubik', google: true, options: ':300,400,500,700&display=swap' },
    Arial: { family: 'Arial, "Helvetica Neue", Helvetica, sans-serif' },
    Helvetica: { family: '"Helvetica Neue", Helvetica, Arial, sans-serif' },
    TimesNewRoman: { family: 'TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif' },
    Verdana: { family: 'Verdana, Geneva, sans-serif' },
    Trebuchet: { family: '"Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif' },
    Calibri: { family: 'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif' },
    Tahoma: { family: 'Tahoma, Verdana, Segoe, sans-serif' },
    Courier: { family: '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace' },
    'Century Gothic': { family: '"Century Gothic", CenturyGothic, AppleGothic, sans-serif' },
    Optima: { family: 'Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif' },
};

export const defaultTheme = {

    font: fonts.Roboto,
    colors: {
        font: '#34495e',
        background: '#f0f3f4',
        paragraph: '',
        useDefaultChartColors: true,
        link: { text: '#34495e', active: '#ef4f24' },
        footer: { background: '#fff' },
        sidebar: { background: '#fff', text: '#7a7e87' },
        header: { background: '#22313F', text: '#DADFE1' },
        table: { header: { background: '#fff', text: '#34495e' } },
        chart: [
            '#8cd1e8', '#7dbc5d', '#ec6262', '#f9c73c', '#515151', '#f78343', '#758082', '#975ca1', '#0099bc', '#d89ebb',
            '#bce2ed', '#a7cf8b', '#ea8f8f', '#f2ca68', '#7c7c7c', '#efac7b', '#9d9f9f', '#c295c4', '#6cb1bc', '#e7c8d9',
            '#27aace', '#529e33', '#a81b1b', '#dc9902', '#333333', '#d75d13', '#4e4f4f', '#6a2479', '#005866', '#94426b',
        ],
    },
};

export const SOURCE_BUTTON_STYLE = {
    dcm: 'google',
    dbm: 'google',
    googleAnalytics4: 'google',
    linkedin: 'linkedin',
    linkedinads: 'linkedin',
    google_analytics: 'google',
    googlebigquery: 'google',
    googlesheets: 'google',
    adwords: 'google',
    searchads360: 'google',
    googleads: 'google',
    gmail_cdata: 'google',
    facebookads: 'facebook',
    facebook: 'facebook',
    facebook_ad_insights: 'facebook',
    facebook_page_and_post_insights: 'facebook',
    slack: 'slack',
    instagram: 'facebook',
    googleadsmanager: 'google',
};

export const ROLES = {
    ROLE_USER: {
        id: 4,
        title: 'Basic User',
        name: 'ROLE_USER',
        key: 'basic',
        assignable: true,
    },
    ROLE_ADMIN: {
        id: 3,
        title: 'Admin',
        name: 'ROLE_ADMIN',
        key: 'admin',
        assignable: true,
    },
    ROLE_USER_VIEW: {
        id: 5,
        title: 'Read-only',
        name: 'ROLE_USER_VIEW',
        key: 'read',
        assignable: true,
    },
    ROLE_EXTERNAL_USER: {
        id: 6,
        title: 'External user',
        name: 'ROLE_EXTERNAL_USER',
        assignable: false,
    },
};

export const LABELS = {
    defaultError: 'An error has occured. Please try again.',

    jobCreateSuccess: 'Job created',
    jobUpdateSuccess: 'Job updated',
    jobDeleteSuccess: 'Job deleted',
    jobExecutionSuccess: 'Job execution started',
    jobExecutionError: 'There was an error executing the job',

    syncCreateSuccess: 'Sync created',
    syncUpdateSuccess: 'Sync updated',
    syncDeleteSuccess: 'Sync deleted',

    sourceCreateSuccess: 'Source created',
    sourceUpdateSuccess: 'Source updated',
    sourceDeleteSuccess: 'Source deleted',

    destinationCreateSuccess: 'Destination created',
    destinationUpdateSuccess: 'Destination updated',
    destinationDeleteSuccess: 'Destination deleted',

    integrationCreateSuccess: 'Integration created',
    integrationUpdateSuccess: 'Integration updated',
    integrationDeleteSuccess: 'Integration deleted',

    policyCreateSuccess: 'Policy created',
    policyUpdateSuccess: 'Policy updated',
    policyDeleteSuccess: 'Policy deleted',
};

export const URLS = {
    documentation: {
        home: 'https://docs.gravitydata.co',
        sources: 'https://docs.gravitydata.co/sources',
        destinations: 'https://docs.gravitydata.co/destinations',
        integrations: 'https://docs.gravitydata.co/integrations',
    },
    roadmap: 'https://roadmap.gravitydata.co',
};
