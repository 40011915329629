<script>
import { debounce } from 'lodash';

export default {
    props: {
        tag: {
            type: String,
            default: 'input',
        },
        delay: {
            type: Number,
            default: 500,
        },
        value: {},
    },
    data() {
        return {
            model: this.value,
            unwatch: undefined,
        };
    },
    watch: {
        delay() {
            this.watch();
        },
        value(value) {
            this.model = value;
        },
    },
    mounted() {
        this.watch();
    },
    methods: {
        watch() {
            if (this.unwatch) this.unwatch();

            this.unwatch = this.$watch(
                'model',
                debounce(v => this.$emit('input', v), this.delay),
            );
        },
    },
    render(h) {
        const self = this;
        return h(this.tag, {
            domProps: {
                value: self.model,
            },
            on: {
                input(event) {
                    self.model = event.target.value;
                },
            },
        });
    },
};
</script>
