<template>
    <multi-select :name="$props.name" :value="value" :options="options" multiple @input="val => $emit('input', val)" />

</template>

<script>
export default {
    name: 'DropdownField',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        options() {
            return _.filter((this.attrs.accepted_values || '').split(','), val => val.length);
        },
    },
};
</script>
