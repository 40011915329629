/* eslint-disable class-methods-use-this */

import moment from 'moment';
import Config from '../../utils/config';
import Model from './model';

export default class Organisation extends Model {
    useStartDayOfWeek = true;

    get isSpecialOrganisation() {
        return _.get(this.attributes, 'accountStripeDetails.special', false);
    }

    get slug() {
        return _.snakeCase(this.name);
    }

    get startDayOfWeek() {
        if (this.isRedshift) return 7;

        return this.attributes.startDayOfWeek || (this.sunWeek ? 7 : 1);
    }

    get isRedshift() {
        return this.attributes.sourceType === 'REDSHIFT';
    }

    set startDayOfWeek(value) {
        if (this.isRedshift) {
            this.attributes.startDayOfWeek = 7;
        } else {
            this.attributes.startDayOfWeek = value;
        }
    }

    set logo(logo) {
        this.attributes.logo = null;
    }

    get logo() {
        return null;
    }

    hasLogo() {
        return false;
    }

    set icon(icon) {
        this.attributes.icon = null;
    }

    get icon() {
        return null;
    }

    hasIcon() {
        return false;
    }

    set numberOfDecimals(val) {
        this.attributes.numberOfDecimals = _.isNumber(val) ? val : 2;
    }

    get numberOfDecimals() {
        return this.attributes.numberOfDecimals || 2;
    }

    get dateFormat() {
        return this.attributes.dateFormat || Config.dateFormats.regular;
    }

    set dateFormat(value) {
        this.attributes.dateFormat = value;
    }

    get usingExternalIdp() {
        return this.attributes.usingExternalIdp || false;
    }

    get showWeekEndDate() {
        return this.attributes.showWeekEndDate || false;
    }

    set showWeekEndDate(value) {
        this.attributes.showWeekEndDate = !!value;
    }

    get useRounding() {
        return this.attributes.useRounding || false;
    }

    get decimalCount() {
        return this.attributes.useRounding ? 0 : 2;
    }

    get startDayName() {
        return moment().isoWeekday(this.startDayOfWeek).format('dddd');
    }

    get fiscalYearStarting() {
        return `1st of ${moment().month(this.fiscalYearStart).format('MMM')}`;
    }

    get isWhiteLabel() {
        return !window.location.host.match(/avora.com$/);
    }

    get ownerId() {
        return _.get(this.attributes, 'accountStripeDetails.accountOwnerId');
    }

    getDateFormat() {
        return this.dateFormat || Config.dateFormats.regular;
    }

    getDateTimeFormat() {
        return `${this.getDateFormat()} HH:mm:ss`;
    }

    toJSON() {
        const data = {
            id: this.id,
            name: this.name,
            domain: this.domain,
            sunWeek: this.sunWeek,
            location: this.location,
            logo: this.logo,
            icon: this.icon,
            showWeekEndDate: this.showWeekEndDate,
            useRounding: this.useRounding,
            showTrailingZeroes: this.showTrailingZeroes,
            numberOfDecimals: this.numberOfDecimals,
            startDayOfWeek: this.startDayOfWeek,
            useStartDayOfWeek: this.useStartDayOfWeek,
            usingExternalIdp: this.usingExternalIdp,
            defaultCardSize: this.defaultCardSize,
            fiscalYearStart: this.fiscalYearStart,
            themeSettings: this.themeSettings,
            salesforceId: this.salesforceId,
            dateFormat: this.dateFormat,
            sourceType: this.sourceType,
        };

        return data;
    }
}
