import _ from 'lodash';
import { RULE_ENGINE_OPERATORS } from '@/utils/operators';
import { Engine } from 'json-rules-engine';
import DynamicParameter from './dynamicParameter.model';

const extraConfigurationDefaults = {
    bulk_flow: false,
    code: '',
    job_display_level: 'job',
    parameters: {},
};

export default class ExtraConfiguration {
    #data;

    #configuration;

    #updateCallback

    constructor(data = {}, configuration = {}, fetchParameterData = () => {}, updateCallback = () => {}) {
        const values = _.assignIn({}, _.cloneDeep(extraConfigurationDefaults), _.cloneDeep(data));
        this.#data = values;
        this.#configuration = _.cloneDeep(configuration);
        this.#updateCallback = updateCallback;
        _.each(this.#data, (value, key) => {
            _.set(this, key, value);
        });

        this.bulk_flow = this.#configuration.bulk_flow;
        this.code = this.#configuration.code;
        this.job_display_level = this.#configuration.job_display_level;

        this.parameters = _.reduce(values.parameters, (res, value, parameter) => {
            return {
                ...res,
                [parameter]: {
                    value,
                    parameter,
                },
            };
        }, {});

        const ruleEngine = new Engine([], { allowUndefinedFacts: true });
        _.each(RULE_ENGINE_OPERATORS, (ruleFunction, ruleName) => {
            ruleEngine.addOperator(ruleName, ruleFunction);
        });
        this.ruleEngine = ruleEngine;
        this.facts = {};

        _.each(this.#configuration.parameters, (paramConfig) => {
            const paramValue = _.get(values.parameters, paramConfig.parameter, null);
            const paramName = paramConfig.parameter;
            const parameter = new DynamicParameter(paramValue, paramConfig, (parameterName, prerequisiteValues, invalidateCache = false) => {
                return fetchParameterData({
                    invalidateCache,
                    query: {
                        parameter: parameterName,
                        prerequisite_values: prerequisiteValues,
                    },
                });
            });

            _.set(this.parameters, paramName, parameter);
        });

        _.each(this.parameters, (parameter) => {
            _.set(this.facts, parameter.parameter, parameter.value);
            if (parameter instanceof DynamicParameter) {
                if (parameter._config.condition_for_requirement) {
                    this.ruleEngine.addRule(parameter.conditionForRequirementRule);
                }

                parameter.observeDependencies(this.parameters);
                parameter.addObserver(this);
            }
        });

        this.ruleEngine.run(this.facts).then(() => {
            console.log('First run');
            this.#updateCallback();
        });

        console.log('ExtraConfiguration:', this);
    }

    update({ param, value }) {
        this.facts[param] = value;
        this.ruleEngine.run(this.facts).then(() => {
            console.log(`Runed because ${param} changed`);
            this.#updateCallback();
        });
    }

    get isValid() {
        return _(this.parameters)
            .filter((param) => {
                return param.visible && (param instanceof DynamicParameter);
            })
            .every({ isValid: true, loading: false });
    }

    get visibleParameters() {
        return _(this.parameters)
            .filter('visible')
            .sortBy('order_of_display')
            .value();
    }

    get bulkFlow() {
        return this.bulk_flow
            || _.some(this.visibleParameters, { bulk_create: true });
    }

    get payload() {
        const parameters = _.reduce(this.visibleParameters, (result, dynamicParameter) => {
            const { parameter, value } = dynamicParameter;

            if (dynamicParameter.bulk_create && dynamicParameter.control === 'multi-select') {
                return {
                    ...result,
                    [parameter]: _.map(value, (val) => {
                        return _.find(dynamicParameter.accepted_values, { id: val });
                    }),
                };
            }

            if (dynamicParameter.bulk_create && dynamicParameter.control === 'one-select') {
                return {
                    ...result,
                    [parameter]: _.find(dynamicParameter.accepted_values, { id: value }),
                };
            }

            return { ...result, [parameter]: value };
        }, {});

        return {
            code: this.code,
            name: this.name,
            parameters,
        };
    }
}
