<template>
    <side-bar v-if="loggedIn">
        <template v-slot:header="settings">
            <div class="logo">
                <img src="/logos/logo-low-res.png" width="133" height="32">
            </div>
        </template>

        <template v-slot:main="settings">
            <div class="d-flex flex-column justify-content-start">
                <router-link
                    v-b-tooltip="settings.tooltip" title="Syncs" data-id="syncs"
                    class="sidebar-nav-link"
                    :to="{ name: 'syncs' }">
                    <i class="svg-icon icon-syncs" />
                    <span class="sidebar-text">Syncs</span>
                </router-link>

                <router-link
                    v-b-tooltip="settings.tooltip" title="Connections" data-id="connections"
                    class="sidebar-nav-link exact-link"
                    :to="{ name: 'connections' }">
                    <i class="svg-icon icon-connections" />
                    <span class="sidebar-text">Connections</span>
                </router-link>

                <router-link
                    v-b-tooltip="settings.tooltip" title="Run Log" data-id="logs"
                    class="sidebar-nav-link"
                    :to="{ name: 'logs' }">
                    <i class="svg-icon icon-logs" />
                    <span class="sidebar-text">Logs</span>
                </router-link>
            </div>
        </template>

        <template v-slot:footer="settings">
            <div v-if="!isDismissed" class="getting-started mb-5 mt-auto">
                <div class="getting-started__image">
                    <img src="/icons/sidebar-img.svg">
                </div>
                <div class="getting-started__text font-weight-bold text-dark">
                    Just getting started?
                </div>
                <div class="getting-started__text text-muted">
                    Let us help you get setup and started in notime.
                </div>
                <div class="getting-started__buttons d-flex justify-content-center mt-4">
                    <div class="p-2" @click="onDismissClick">
                        Dismiss
                    </div>
                    <a class="font-weight-bold ml-2 p-2" :href="URLS.documentation.home" target="_blank">Get Started</a>
                </div>
            </div>
            <b-dropdown id="dropdown-dropup" dropup>
                <template #button-content>
                    <div class="user-dropdown d-flex flex-row justify-content-start">
                        <img :src="user.photo" class="rounded-circle border" width="40" height="40">
                        <div class="user-text d-flex flex-column justify-content-center">
                            <div class="user-text__name px-3 font-weight-bold">
                                {{ user.fullName }}
                            </div>
                            <div class="user-text__role px-3 text-muted mt-1">
                                {{ userRole }}
                            </div>
                        </div>
                    </div>
                </template>
                <b-dropdown-text class="text-center">
                    <span class="user-text__name font-weight-bold px-2 m-auto">{{ user.fullName }}</span>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-group id="dropdown-group-1">
                    <b-dropdown-item>
                        <router-link
                            v-b-tooltip="settings.tooltip" title="Profile" data-id="profile"
                            class="exact-link dropdown-nav-link"
                            to="/profile">
                            <i class="fal fa-fw fa-user-edit" />
                            <span class="ml-1">Profile</span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <router-link
                            v-b-tooltip="settings.tooltip" title="Billing" data-id="billing"
                            class="dropdown-nav-link exact-link"
                            to="/billing">
                            <i class="fal fa-fw fa-wallet" />
                            <span class="ml-1">Billing</span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <router-link
                            v-b-tooltip="settings.tooltip" title="Team" data-id="team"
                            class="dropdown-nav-link exact-link"
                            to="/team">
                            <i class="fal fa-fw fa-user-friends" />
                            <span class="ml-1">Team</span>
                        </router-link>
                    </b-dropdown-item>
                </b-dropdown-group>
                <b-dropdown-divider />
                <b-dropdown-item>
                    <router-link
                        v-b-tooltip="settings.tooltip" title="Logout" data-id="logout"
                        class="dropdown-nav-link exact-link"
                        to="/logout">
                        <i class="fal fa-fw fa-power-off" />
                        <span class="ml-1">Sign Out</span>
                    </router-link>

                </b-dropdown-item>
            </b-dropdown>
        </template>

        <template v-slot:divider>
            <div class="my-3 text-center">
                <i class="fal fa-lg fa-fw svg-icon dots" />
            </div>
        </template>
    </side-bar>
</template>

<script>
import SideBar from '@/components/layout/sidebar';
import { mapState, mapGetters } from 'vuex';
import { URLS } from '@/constants';

export default {
    name: 'SideMenu',
    components: {
        SideBar,
    },
    data() {
        return {
            URLS,
            isDismissed: true,
        };
    },
    computed: {
        ...mapState({
            loggedIn: store => store.profile.loggedIn,
        }),
        ...mapGetters({
            user: 'profile/user',
            organisation: 'profile/organisation',
        }),
        userRole() {
            return _.get(this.user.role, 'title', 'Read-Only');
        },
    },
    watch: {
        'organisation.startDayOfWeek': {
            handler(start) {
                this.$moment.updateLocale('en', {
                    week: { dow: start },
                });
            },
        },
    },
    created() {
        this.$localStorage.getPermanent('dismissGettingStarted', false)
            .then((val) => {
                this.$set(this, 'isDismissed', val);
            });
    },
    methods: {
        onDismissClick() {
            this.isDismissed = true;
            this.$localStorage.setPermanent('dismissGettingStarted', true);
        },
    },
};
</script>
