<template>
    <div>
        <div class="d-flex flex-row justify-content-between">
            <div>
                <h3 class="text-header-md">
                    Add new sync
                </h3>
                <p class="text-header-info">
                    You’re about to sync a source and a destination. Creating a sync is easy.
                    It’s just a matter of selecting the source you want to pull data from and the destination you want to send it to.
                    The exact data you are pulling will be configured at jobs level.
                </p>
            </div>
        </div>

        <div class="mt-4">
            <form-field key="connectionName"
                        ref="formControl"
                        v-model="syncName.value"
                        class="custom-form-field"
                        :config="syncName"
            />

            <div class="d-flex flex-md-row flex-sm-column justify-content-start mt-4">
                <div class="custom-form-field">
                    <label class="label">1. Select Source</label>
                    <multiselect v-model="syncSource" :options="filteredSources" class="w-100 border-0" :internal-search="false" :option-height="50" label="name" searchable :show-labels="false" placeholder="" @search-change="onSearchChange">
                        <template slot="singleLabel" slot-scope="{option}">
                            <div class="d-flex flex-row justify-content-start" style="height: 50px;">
                                <div style="min-width: 0;" class="option__image">
                                    <img :src="option.icon" height="30" width="30" class="m-2" style="object-fit: scale-down;">
                                </div>
                                <div class="option__desc ml-2 my-auto" style="min-width: 0;">
                                    <span>
                                        {{ option.name }}
                                    </span>
                                </div>
                            </div>
                        </template>

                        <template slot="option" slot-scope="{option}">
                            <div class="d-flex flex-row justify-content-start" style="height: 50px;">
                                <div style="min-width: 0;" class="option__image">
                                    <img :src="option.icon" height="30" width="30" class="m-2" style="object-fit: scale-down;">
                                </div>
                                <div class="option__desc ml-2 my-auto" style="min-width: 0;">
                                    <span>
                                        {{ option.name }}
                                    </span>
                                </div>
                            </div>
                        </template>

                        <template slot="placeholder">
                            <div v-if="!sources.length" class="d-flex flex-row justify-content-between w-100" style="height: 50px;" @mousedown.prevent.stop>
                                <div class="m-auto text-center" style="max-width: 50%;">
                                    You have no sources yet, let’s add one first.
                                </div>
                                <div class="m-auto">
                                    <button class="btn btn-sm btn-primary" @click.prevent.stop="onNewSource">
                                        + Add Source
                                    </button>
                                </div>
                            </div>
                            <div v-else class="input__text" style="height: 50px; line-height: 50px;">Select sync source</div>
                        </template>
                    </multiselect>
                </div>

                <div class="my-auto mx-5" style="padding-top: 40px;">
                    <i class="svg-icon icon-arrow d-sm-none d-md-block" style="width: 100px; height: 50px;" />
                </div>
                
                <div class="custom-form-field">
                    <label class="label">2. Select Destination</label>
                    <multiselect v-model="syncDestination" :options="filteredDestinations" :internal-search="false" class="w-100 border-0" :option-height="50" label="name" searchable :show-labels="false" @search-change="onSearchChange">
                        <template slot="singleLabel" slot-scope="{option}">
                            <div class="d-flex flex-row justify-content-start" style="height: 50px;">
                                <div style="min-width: 0;" class="option__image">
                                    <img :src="option.icon" height="30" width="30" class="m-2" style="object-fit: scale-down;">
                                </div>
                                <div class="option__desc ml-2 my-auto" style="min-width: 0;">
                                    <span>
                                        {{ option.name }}
                                    </span>
                                </div>
                            </div>
                        </template>

                        <template slot="option" slot-scope="{option}">
                            <div class="d-flex flex-row justify-content-start" style="height: 50px;">
                                <div style="min-width: 0;" class="option__image">
                                    <img :src="option.icon" height="30" width="30" class="m-2" style="object-fit: scale-down;">
                                </div>
                                <div class="option__desc ml-2 my-auto" style="min-width: 0;">
                                    <span>
                                        {{ option.name }}
                                    </span>
                                </div>
                            </div>
                        </template>

                        <template slot="placeholder">
                            <div v-if="!destinations.length" class="d-flex flex-row justify-content-between w-100" style="height: 50px;" @mousedown.prevent.stop>
                                <div class="m-auto text-center" style="max-width: 50%;">
                                    You have no destinations yet, let’s add one first.
                                </div>
                                <div class="m-auto">
                                    <button class="btn btn-sm btn-primary" @click.prevent.stop="onNewDestination">
                                        + Add Destination
                                    </button>
                                </div>
                            </div>
                            <div v-else class="input__text" style="height: 50px; line-height: 50px;">Select sync destination</div>
                        </template>
                    </multiselect>
                </div>
            </div>

            <form-field key="syncSchedule"
                        ref="formControl"
                        v-model="syncSchedule.value"
                        class="custom-form-field mt-4"
                        :config="syncSchedule"
            />

            <loading-btn class="btn btn-lg btn-primary mt-4" :disabled="disableSave" :listen="['syncs/saveSync']" @click="onSaveSync">
                Save Sync
            </loading-btn>
        </div>
    </div>
</template>

<script>
import FormField from '@/components/formField';
import Multiselect from 'vue-multiselect';

import DynamicParameter from '@/store/models/dynamicParameter.model';
import { mapActions, mapGetters } from 'vuex';
import { LABELS } from '@/constants';

export default {
    components: { FormField, Multiselect },
    data() {
        return {
            syncName: new DynamicParameter('', { control: 'text', required: true, display_name: 'Name', placeholder: 'A name that identifies this sync' }),
            syncSchedule: new DynamicParameter('0 0 0 */1 * ?', { control: 'cron', required: true, display_name: 'Job run frequency' }),
            syncSource: null,
            syncDestination: null,
            searchTerm: '',
        };
    },
    computed: {
        ...mapGetters({
            mySources: 'dataSources/myDataSources',
            myDestinations: 'dataTargets/myDataTargets',
            availableSources: 'dataSources/availableDataSources',
            availableDestinations: 'dataTargets/availableDataTargets',
        }),
        sources() {
            return _.map(this.mySources, (source) => {
                return {
                    ...source,
                    typename: _.get(_.find(this.availableSources, { code: source.code }), 'name'),
                };
            });
        },
        filteredSources() {
            return _(this.sources)
                .filter(({ name, typename }) => _.includes(_.toLower(`${name} - ${typename}`), _.toLower(this.searchTerm)))
                .sortBy(({ code }) => _.toLower(code))
                .value();
        },
        destinations() {
            return _.map(this.myDestinations, (target) => {
                return {
                    ...target,
                    typename: _.get(_.find(this.availableDestinations, { code: target.code }), 'name'),
                };
            });
        },
        filteredDestinations() {
            return _(this.destinations)
                .filter(({ name, typename }) => _.includes(_.toLower(`${name} - ${typename}`), _.toLower(this.searchTerm)))
                .sortBy(({ code }) => _.toLower(code))
                .value();
        },
        disableSave() {
            return !this.syncSource
                || !this.syncDestination
                || !this.syncName.isValid
                || !this.syncSchedule.isValid;
        },
    },
    methods: {
        ...mapActions({
            saveSync: 'syncs/saveSync',
        }),
        onNewSource() {
            this.$eventHub.$emit('showModal', {
                name: 'new-connection',
                tab: 'sources',
            });
        },
        onNewDestination() {
            this.$eventHub.$emit('showModal', {
                name: 'new-connection',
                tab: 'destinations',
            });
        },
        onSaveSync() {
            this.saveSync({
                name: this.syncName.value,
                sourcecode: this.syncSource.code,
                sourcename: this.syncSource.name,
                targetcode: this.syncDestination.code,
                targetname: this.syncDestination.name,
                run_frequency: this.syncSchedule.value,
                schedule_status: 'enabled',
            }).then(({ id }) => {
                this.$notify({
                    type: 'success',
                    text: LABELS.syncCreateSuccess,
                });
                this.$router.push({ name: 'sync-overview', params: { syncId: id } });
            }).catch((e) => {
                this.$notify({
                    type: 'error',
                    text: e.message || LABELS.defaultError,
                });
            });
        },
        onSearchChange(value) {
            this.$set(this, 'searchTerm', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-form-field {
    max-width: 500px;
    min-width: 500px;

    /deep/ label.label {
        font-family: $font-family-primary;
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        color: $primary-700;
        margin-bottom: 16px;
        opacity: 1;
    }
}
</style>
