import moment from 'moment';
import Model from './model';

export default class Notification extends Model {
    constructor(attributes, state, getters) {
        super(attributes, state, getters);

        const userProfile = getters['profile/user'];
        const organisationProfile = getters['profile/organisation'];
        this.format = userProfile.dateFormat || organisationProfile.dateFormat;
    }

    get createdDateFormatted() {
        const date = moment(this.createdDate);
        return date.calendar(null, {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: `ddd, ${this.format}`,
        });
    }
}
