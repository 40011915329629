<template>
    <div :id="controlId" class="d-flex flex-column justify-content-start table-field">
        <div class="label">
            <label v-if="attrs.display_name" class="m-auto">{{ attrs.display_name }}</label>
            <span v-if="attrs.description" v-b-tooltip="tooltipSettings" class="m-auto">
                <i class="ml-1 fal fa-info-circle" />
            </span>
        </div>
        <div class="card">
            <div class="card-header bg-white pt-4 border-0 pb-0">
                <div class="search-bar px-2">
                    <input v-model="searchTerm" type="text" class="m-auto pl-3 w-100 form-control input__text" placeholder="Search">
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center">
                    <button class="btn btn-link btn-sm" :disabled="allSelected || loading" @click="toggleAll(true)">Select all</button>
                    <button class="btn btn-link btn-sm" :disabled="noneSelected || loading" @click="toggleAll(false)">Deselect all</button>
                    <button class="btn btn-link btn-sm ml-auto" :disabled="loading" @click="refreshDynamicValues(true)">Refresh</button>
                </div>
            </div>
            <loader style="overflow: auto; height: 150px;" :listen="[]" :force-loading="loading" loading-class="m-auto" class="card-body p-0">
                <div v-for="(option, $index) in filteredOptions" :key="$index" class="clickable group-item d-flex flex-row no-wrap ml-2 px-4 py-1" :disabled="option.required" @click.prevent.stop="toggleOption(option)">
                    <div class="custom-control custom-checkbox custom-checkbox-sm my-auto">
                        <input :id="controlId + '-input-' + $index" ref="inputField" v-model="option.selected" type="checkbox" class="custom-control-input" :disabled="option.required" :required="option.required" @change="onChange">
                        <label class="custom-control-label text-center m-auto" :for="controlId + '-input-' + $index" />
                    </div>
                    <div class="align-middle text-left user-select-none" :class="{ 'text-muted': option.required }">
                        <div class="mb-0" style="overflow: hidden; white-space: nowrap;">
                            {{ option.label }}
                        </div>
                        <div v-if="attrs.details" class="text-muted">
                            {{ option.id }}
                        </div>
                    </div>
                </div>
            </loader>
        </div>
    </div>
</template>

<script>
import * as uuid from 'uuid';

export default {
    name: 'FieldTable',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();

        return {
            tableColumns: ['selected', { key: 'label', label: this.attrs.display_name }],
            controlValue: this.value,
            options: [],
            searchTerm: '',
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    computed: {
        allSelected() {
            return !_.some(this.options, { selected: false });
        },
        someSelected() {
            return !this.allSelected && !this.noneSelected;
        },
        noneSelected() {
            return !_.some(this.options, { selected: true });
        },
        accepted_values() {
            return _.orderBy(this.attrs.accepted_values, v => !v.required);
        },
        loading() {
            return this.attrs.loading;
        },
        filteredOptions() {
            return _.filter(this.options, ({ label, id }) => {
                return _.includes(_.toLower(label), _.toLower(this.searchTerm))
                    || _.includes(_.toLower(id), _.toLower(this.searchTerm));
            });
        },
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (!_.isEqual(newVal, this.controlValue)) {
                    this.controlValue = newVal;
                    this.formatOptions();
                }
            },
        },
        'attrs.accepted_values': {
            deep: true,
            handler() {
                if (!this.loading) this.formatOptions();
            },
        },
        'attrs.loading': {
            handler(newVal) {
                if (!newVal) this.formatOptions();
            },
        },
    },
    async created() {
        if (this.attrs.accepted_values) {
            this.controlValue = this.value || _.get(this.attrs, 'default_value', []);
        }
        this.formatOptions();
    },
    methods: {
        toggleAll(state) {
            const newOptions = _.map(this.accepted_values, ({ required, ...args }) => {
                return { ...args, required, selected: required || state };
            });

            this.$set(this, 'options', newOptions);
            this.onChange();
        },
        toggleOption(option) {
            if (!option.required) {
                option.selected = !option.selected;
                this.onChange();
            }
        },
        onChange() {
            this.controlValue = _.map(_.filter(this.options, 'selected'), 'id');
            this.$emit('input', this.controlValue);
        },
        formatOptions() {
            const options = _.map(this.accepted_values, ({ required, id, ...args }) => {
                return { ...args, id, required, selected: required || _.includes(this.controlValue, id) };
            });
            this.$set(this, 'options', options);
        },
        async refreshDynamicValues(refreshValues) {
            if (this.loading) return Promise.resolve();
            return this.attrs.refreshAcceptedValues(refreshValues).then(this.formatOptions);
        },
    },
};
</script>
