// eslint-disable-next-line
String.prototype.replaceAll = function(find, replace) {
    const str = this;
    if (!Array.isArray(find)) {
        find = [find];
    }
    
    if (!find.length) return str;
    
    const replaceCharacters = (value, itemsToFind, replaceWith) => {
        itemsToFind.forEach((char) => {
            // eslint-disable-next-line
            value = value.replace(new RegExp(char.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'), 'g'), replaceWith);
        });
        
        return value;
    };
    
    return str ? replaceCharacters(str, find, replace) : str;
};

// eslint-disable-next-line
String.prototype.trim = function(char) {
    const str = this;
    
    if (!char) return str;
    
    const trimCharacter = (value, character) => {
        const first = [...value].findIndex(c => c !== character);
        const last = [...value].reverse().findIndex(c => c !== character);
        return value.substring(first, value.length - last);
    };
    
    return str ? trimCharacter(str, char) : str;
};

// eslint-disable-next-line
String.prototype.capitalizeFirstLetter = function() {
    const str = this;
    
    return str ? str.charAt(0).toUpperCase() + this.slice(1).toLowerCase() : str;
};

// eslint-disable-next-line
String.prototype.includesWord = function(find, separators = [' ']) {
    const str = this;
    let includesWord = false;
    
    if (str === find) return true;
    
    separators.forEach((separator) => {
        if (str.startsWith(find + separator)) includesWord = true;
        if (str.endsWith(separator + find)) includesWord = true;
        if (str.includes(separator + find + separator)) includesWord = true;
    });
    
    return includesWord;
};
