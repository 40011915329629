<template>
    <div class="router-view h-100 logs-container">
        <portal to="header">
            <div class="d-flex flex-column justify-content-start flex-wrap header-content logs-page-header">
                <h1 class="text-header-md">
                    Logs
                </h1>
                <div class="d-flex flex-row justify-content-between no-wrap mt-3">
                    <p class="header-info">
                        We are logging all of your job runs ({{ runHistoryCount }} so far) so if you happen to look for something,
                        we’ve got you covered. Use our filters on the right to help you navigate and view the list.
                    </p>
                    <div class="filters-section d-flex flex-column no-wrap">
                        <h5 class="text-sub-header">
                            Filter the list
                        </h5>
                        <div class="filter-section card border-0">
                            <div class="filter-section__grid justify-content-between">
                                <!-- Dataset Filter -->
                                <multi-select
                                    v-model="filters.datasetname.value"
                                    :loading="filtersLoading"
                                    :options="filters.datasetname.options"
                                    :multiple="false"
                                    :placeholder="filters.datasetname.label"
                                    :close-on-select="true"
                                    searchable
                                    class="clickable m-auto filter-item" />

                                <!-- Source Type Filter -->
                                <multi-select
                                    v-model="filters.sourcecode.value"
                                    :loading="filtersLoading"
                                    :options="filters.sourcecode.options"
                                    :multiple="false"
                                    :placeholder="filters.sourcecode.label"
                                    :close-on-select="true"
                                    searchable
                                    class="clickable m-auto filter-item" />

                                <!-- Target Type Filter -->
                                <multi-select
                                    v-model="filters.targetcode.value"
                                    :loading="filtersLoading"
                                    :options="filters.targetcode.options"
                                    :multiple="false"
                                    :placeholder="filters.targetcode.label"
                                    :close-on-select="true"
                                    searchable
                                    class="clickable m-auto filter-item" />

                                <!-- Notification Type Filter -->
                                <multi-select
                                    v-model="filters.status.value"
                                    :options="filters.status.options"
                                    :placeholder="filters.status.label"
                                    :multiple="false"
                                    :loading="filtersLoading"
                                    :close-on-select="true"
                                    searchable
                                    class="clickable m-auto filter-item" />

                                <!-- Source Connection Filter -->
                                <multi-select
                                    v-model="filters.sourceconnectionname.value"
                                    :loading="filtersLoading"
                                    :options="filters.sourceconnectionname.options"
                                    :multiple="false"
                                    :placeholder="filters.sourceconnectionname.label"
                                    :close-on-select="true"
                                    searchable
                                    class="clickable m-auto filter-item" />

                                <!-- Target Connection Filter -->
                                <multi-select
                                    v-model="filters.targetconnectionname.value"
                                    :loading="filtersLoading"
                                    :options="filters.targetconnectionname.options"
                                    :multiple="false"
                                    :placeholder="filters.targetconnectionname.label"
                                    :close-on-select="true"
                                    searchable
                                    class="clickable m-auto filter-item" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </portal>

        <!-- Container -->
        <loader :listen="['runHistory/fetchDatasetsRunHistory']" class="h-100">
            <history-list :items="fullRunHistory" />
            <b-pagination
                v-if="runHistoryCount && runHistoryCount > filters.limit.value"
                v-model="filters.offset.page"
                :total-rows="runHistoryCount"
                :per-page="filters.limit.value"
                align="center"
                size="md"
                class="mt-4"
                @input="fetchNextPage" />
        </loader>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LABELS } from '@/constants';
import HistoryList from './history-list';

export default {
    name: 'RunHistory',
    components: {
        HistoryList,
    },
    data() {
        return {
            filtersLoading: false,
            filters: {
                status: {
                    value: null,
                    options: ['FINISHED_OK', 'ERROR'],
                    label: 'Run Status',
                },
                sourcecode: {
                    value: null,
                    options: [],
                    label: 'Source Type',
                },
                targetcode: {
                    value: null,
                    options: [],
                    label: 'Destination Type',
                },
                sourceconnectionname: {
                    value: null,
                    options: [],
                    label: 'Source Connection',
                },
                targetconnectionname: {
                    value: null,
                    options: [],
                    label: 'Destination Connection',
                },
                datasetname: {
                    value: null,
                    options: [],
                    label: 'Job',
                },
                offset: {
                    value: 0,
                    page: 1,
                },
                limit: {
                    value: 10,
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            fullRunHistory: 'runHistory/fullRunHistory',
            myDataSources: 'dataSources/myDataSources',
            myDataTargets: 'dataTargets/myDataTargets',
            myDataSets: 'dataSets/myDataSets',
            runHistoryCount: 'runHistory/runHistoryCount',
        }),
        anyFilterActive() {
            return _.reduce(this.filters, (result, { value }, key) => {
                return result
                    || (key !== 'offset' && key !== 'limit' && value);
            }, false);
        },
    },
    watch: {
        'filters.status.value': {
            handler() {
                this.applyFilters();
            },
        },
        'filters.sourcecode.value': {
            handler() {
                this.applyFilters();
            },
        },
        'filters.targetcode.value': {
            handler() {
                this.applyFilters();
            },
        },
        'filters.sourceconnectionname.value': {
            handler() {
                this.applyFilters();
            },
        },
        'filters.targetconnectionname.value': {
            handler() {
                this.applyFilters();
            },
        },
        'filters.datasetname.value': {
            handler() {
                this.applyFilters();
            },
        },
    },
    async created() {
        this.$eventHub.$emit('clearSearch');
        const autoSelectJobName = this.$route.query.name;

        this.filters.datasetname.options = _(this.myDataSets).map('name').uniq().value();
        this.filters.sourcecode.options = _(this.myDataSources).map('code').uniq().value();
        this.filters.sourceconnectionname.options = _(this.myDataSources).map('name').uniq().value();
        this.filters.targetcode.options = _(this.myDataTargets).map('code').uniq().value();
        this.filters.targetconnectionname.options = _(this.myDataTargets).map('name').uniq().value();

        if (autoSelectJobName) {
            this.filters.datasetname.value = this.filters.datasetname.options.find(op => op === autoSelectJobName);
        }

        this.$track('view-logs-page');
    },
    destroyed() {
        if (this.anyFilterActive) {
            this.resetFilters();
        }
    },
    methods: {
        ...mapActions({
            fetchDatasetsRunHistory: 'runHistory/fetchDatasetsRunHistory',
            fetchMyDataSources: 'dataSources/fetchMyDataSources',
            fetchMyDataSets: 'dataSets/fetchMyDataSets',
        }),
        async fetchHistoryEntries() {
            const filters = _.reduce(this.filters, (result, { value }, key) => {
                return {
                    ...result,
                    [key]: value || undefined,
                };
            }, {});

            try {
                await this.fetchDatasetsRunHistory(filters);
            } catch (e) {
                this.$notify({
                    type: 'error',
                    text: e.message || LABELS.defaultError,
                });
            }
        },
        fetchNextPage() {
            this.filters.offset.value = (this.filters.offset.page - 1) * this.filters.limit.value;
            this.fetchHistoryEntries();
        },
        applyFilters() {
            this.filters.offset.page = 1;
            this.filters.offset.value = 0;
            this.fetchHistoryEntries();
        },
        async resetFilters() {
            this.filters.status.value = null;
            this.filters.sourcecode.value = null;
            this.filters.targetcode.value = null;
            this.filters.sourceconnectionname.value = null;
            this.filters.targetconnectionname.value = null;
            this.filters.datasetname.value = null;
            this.filters.offset.value = 0;
            this.filters.offset.page = 1;
            await this.fetchHistoryEntries();
        },
    },
};
</script>
