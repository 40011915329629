<style scoped lang="scss">
.text-color {
    color: #424242;

    &:hover {
        color: var(--theme-link-text-color);
    }
}

/deep/ .dropdown-toggle {
    background: transparent;
    border: 0;
    display: flex;
    align-content: flex-start;
    padding-left: 0;

    &[aria-expanded="true"] {
        background: transparent;
        border: 0;
    }

    &:active {
        background: transparent !important;
        border: 0 !important;
    }

    &:after {
        padding-top: 15px !important;
        color: gray;

        &:hover {
            color: lightgrey;
        }
    }
}

.router-link {
    color: var(--theme-sidebar-text-color) !important;

    &.router-link-exact-active.exact-link,
    &:not(.exact-link).router-link-active,
    &:hover {
        color: var(--theme-link-text-color) !important;
    }
}
</style>

<template>
    <loader :listen="waitFor" :force-loading="waitingForInit" loading-class="m-auto" class="overflow-none h-100">
        <div v-if="!error && !isAuthorization" class="d-flex flex-row justify-content-start no-wrap">
            <side-menu v-if="(loggedIn && !$route.meta.showOnlyPage)" style="flex: 0 1 auto;" />

            <div id="main-wrapper" style="flex: 1 1 auto;">
                <div id="main-container">
                    <div v-if="(loggedIn && !$route.meta.showOnlyPage)" id="header-wrapper">
                        <div id="header-container">
                            <gravity-header />
                        </div>
                    </div>
                    <div id="content-wrapper">
                        <div :id="$route.meta.showOnlyPage ? 'full-page-content-container' : 'content-container'">
                            <router-view />
                        </div>
                    </div>
                </div>
            </div>

            <portal-target name="modals" multiple transition="modal" />
            <portal-target name="extra-modals" multiple transition="fade" />
            <portal-target name="side-modals" multiple transition="side-modal" />

            <portal to="modals" name="modal">
                <div v-if="modalSettings && modalSettings.name == 'new-connection'" key="new-connection" class="modals-route">
                    <button type="button" class="close-modal-btn btn-light" @click="onModalClosed">
                        <i class="svg-icon icon-close" />
                    </button>
                    <div class="modal-container">
                        <new-connection :tab="modalSettings.tab" @close="onModalClosed" />
                    </div>
                </div>
                <div v-if="modalSettings && modalSettings.name == 'edit-connection'" key="edit-connection" class="modals-route">
                    <button type="button" class="close-modal-btn btn-light" @click="onModalClosed">
                        <i class="svg-icon icon-close" />
                    </button>
                    <div class="modal-container">
                        <edit-connection :settings="modalSettings.settings" @close="onModalClosed" />
                    </div>
                </div>
            </portal>

            <portal to="extra-modals">
                <div v-if="modalSettings && modalSettings.name == 'extra-details'" key="extra-details" class="extra-modals-route">
                    <div class="modal-container" style="max-width: 500px;">
                        <div class="card border-0 shadow-none bg-transparent">
                            <div class="card-header border-0 bg-transparent p-0 m-0">
                                <h3 class="text-header-md">
                                    Tell us about yourself
                                </h3>
                                <p class="text-md text-muted my-3">
                                    If you don't mind, a few quick questions before we get started
                                </p>
                            </div>
                            <div class="card-body p-0">
                                <div>
                                    <h4 class="text-lg">
                                        What's your name?
                                    </h4>
                                    <input v-model="extraDetails.firstName" type="text" class="form-control input__text my-2" placeholder="First name">
                                    <input v-model="extraDetails.lastName" type="text" class="form-control input__text my-2" placeholder="Last name">
                                </div>
                                <div class="my-4">
                                    <h4 class="text-lg">
                                        What's your role?
                                    </h4>
                                    <input v-model="extraDetails.userRole" type="text" class="form-control input__text my-2" placeholder="Role">
                                </div>
                                <div>
                                    <h4 class="text-lg">
                                        What's your company?
                                    </h4>
                                    <input v-model="extraDetails.organisationName" type="text" class="form-control input__text my-2" placeholder="Company name">
                                </div>
                                <div class="d-flex mt-5">
                                    <loading-btn class="btn btn-lg btn-primary m-auto text-center" :disabled="disableExtraDetailsButton" style="min-width: 250px;" :listen="'profile/saveExtraDetails'" @click="onExtraDetails">
                                        Let's Go!
                                    </loading-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </portal>

            <portal to="side-modals">
                <div v-if="modalSettings && modalSettings.name == 'help-modal'" key="help-modal" class="side-modals-route">
                    <button type="button" class="close-modal-btn btn-light" @click="onModalClosed">
                        <i class="svg-icon icon-close" />
                    </button>
                    <div class="modal-container">
                        <div class="d-flex flex-column justify-content-between h-100">
                            <div>
                                <h2 class="text-sm-header">
                                    Help center
                                </h2>
                                <div class="my-4">
                                    <p>
                                        Sources like facebook, instagram, tiktok all gather data. By connecting these sources to the system,
                                        you can start pulling that data into a Destination using the Gravity platform.
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex flex-column">
                                <a class="my-1" :href="URLS.documentation.home" target="_blank">View Documentation</a>
                                <a id="open-intercom-bubble" class="my-1" href="">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </portal>
        </div>

        <div v-else-if="error" class="vh-100 d-flex w-100 justify-content-center align-items-center bg-light">
            <div class="preview-area">
                <i class="fal fa-exclamation-triangle text-danger fa-4x py-2 animate__animated animate__bounceIn" />
                <h5 class="mb-0" v-html="error" />
                <a href="" class="btn btn-sm btn-link">
                    <i class="far fa-fw fa-sync" /> Refresh
                </a>
            </div>
        </div>

        <div v-else id="main-wrapper">
            <div id="content-wrapper" class="d-flex">
                <router-view class="m-auto" />
            </div>
        </div>
    </loader>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import GravityHeader from '@/components/layout/gravity-header';
import NewConnection from '@/modules/connections/views/newConnection';
import EditConnection from '@/modules/connections/views/editConnection';

import SideMenu from '@/components/layout/sidemenu';
import update from '@/mixins/update';
import { LABELS, URLS } from '@/constants';

import Cookies from 'js-cookie';
import themeManager from './services/theme-manager';
import Config from './utils/config';

import {
    PusherService,
    StripeService,
    IntercomService,
    HeapService,
    PosthogService,
    ReditusService,
    SentryService,
} from './utils/services';

export default {
    components: {
        GravityHeader,
        SideMenu,
        NewConnection,
        EditConnection,
    },
    mixins: [update],
    data() {
        return {
            isLoading: false,
            totalNotifications: 0,
            error: '',
            interval: null,
            isDevOrLocal: Config.isDev || Config.isLocal,
            waitFor: [
                'profile/getUser',
                'profile/getOrganisation',
            ],
            modalSettings: null,
            extraDetails: {
                firstName: '',
                lastName: '',
                userRole: '',
                organisationName: '',
            },
            URLS,
        };
    },
    computed: {
        ...mapState({
            styles: store => store.app.config.styles,
            loggedIn: store => store.profile.loggedIn,
        }),
        ...mapGetters({
            user: 'profile/user',
            organisation: 'profile/organisation',
            // Wait until we load user and organisation details and only after that we initialize view components
            profileIsLoaded: 'profile/profileIsLoaded',
        }),
        isSignupPage() {
            return window.location.href.includes('signup');
        },
        allotedRuns() {
            return _.get(this.organisation.accountStripeDetails, 'monthlyAlottedDatasetRuns', 0);
        },
        currentRuns() {
            return _.get(this.organisation.accountStripeDetails, 'monthlyDatasetRunCount', 0);
        },
        waitingForInit() {
            if (this.$route.name === 'login') return false;
            if (this.$route.name === 'logout') return false;
            if (this.$route.name === 'callback') return false;
            if (this.$route.name === 'signup') return false;
            // if (this.$route.meta.externalAccess && !!localStorage.getItem('external_access')) return false;
            return this.isLoading;
        },
        isAuthorization() {
            return this.$route.name === 'authorization';
        },
        disableExtraDetailsButton() {
            return _.isEmpty(_.trim(this.extraDetails.lastName))
                || _.isEmpty(_.trim(this.extraDetails.firstName))
                || _.isEmpty(_.trim(this.extraDetails.userRole))
                || _.isEmpty(_.trim(this.extraDetails.organisationName));
        },
    },
    watch: {
        'organisation.startDayOfWeek': {
            handler(start) {
                this.$moment.updateLocale('en', {
                    week: { dow: start },
                });
            },
        },
    },
    beforeDestroy() {
        if (this.interval) window.clearInterval(this.interval);
        this.$eventHub.$off('showModal', this.showModal);
    },
    async created() {
        if (this.$route.meta.externalAccess && this.$route.query.external_access_code) {
            sessionStorage.setItem('external_access_token', this.$route.query.external_access_code);
            localStorage.setItem('external_access', true);
            sessionStorage.setItem('external_redirect_to', this.$route.query.redirect_to);
        }

        try {
            if (!window.location.href.includes('signup') && !localStorage.getItem('external_access')) {
                this.$eventHub.$on('showModal', this.showModal);

                this.isLoading = true;
                let token = localStorage.getItem('access_token');
                // Valid Token?
                if (this.loggedIn || (token && token.length > 0)) {
                    this.initApp().then(() => {
                        this.isLoading = false;
                    });
                } else {
                    this.interval = window.setInterval(() => {
                        token = localStorage.getItem('access_token');
                        // Valid Token?
                        if (this.loggedIn || (token && token.length > 0)) {
                            clearInterval(this.interval);
                            this.initApp().then(() => {
                                this.isLoading = false;
                            });
                        }
                    }, 500);
                }
            }

            if (this.$route.meta.externalAccess && !!localStorage.getItem('external_access') && this.$route.query.external_access_code) {
                this.isLoading = true;
                await this.loadData(true, true, this.$route.query.external_access_code);
                this.isLoading = false;
            }
        } catch (e) {
            console.error(e.message);
        }
    },
    methods: {
        ...mapActions({
            getProfile: 'profile/getUser',
            getOrganisation: 'profile/getOrganisation',
            getTotalUnreadNotifications: 'notifications/getTotalUnreadNotificationsByUserId',
            getAllPermissions: 'permissions/getCollection',
            fetchMyDataSets: 'dataSets/fetchMyDataSets',
            fetchMyDataSources: 'dataSources/fetchMyDataSources',
            fetchMyDataTargets: 'dataTargets/fetchMyDataTargets',
            fetchAvailableDataSources: 'dataSources/fetchAvailableDataSources',
            fetchAvailableDataTargets: 'dataTargets/fetchAvailableDataTargets',
            fetchAvailableIntegrations: 'integrations/fetchIntegrationTypes',
            fetchMyIntegrations: 'integrations/fetchMyIntegrations',
            fetchDatasetsRunHistory: 'runHistory/fetchDatasetsRunHistory',
            fetchGroupedDatasetRunLogs: 'runHistory/fetchGroupedDatasetRunLogs',
            fetchMyPolicies: 'integrations/fetchMyPolicies',
            fetchSyncs: 'syncs/fetchSyncs',
            saveExtraDetails: 'profile/saveExtraDetails',
        }),
        async initApp() {
            try {
                await Promise.allSettled([
                    this.getProfile(),
                    this.getOrganisation(),
                    this.getAllPermissions(true),
                    this.isAuthorization ? Promise.resolve() : this.loadData(true),
                ]).then(() => {
                    this.extraDetails.firstName = _.get(this.user, 'firstName');
                    this.extraDetails.lastName = _.get(this.user, 'lastName');
                    this.extraDetails.userRole = _.get(this.user, 'userRole');
                    this.extraDetails.organisationName = _.get(this.organisation, 'name');
                    if (this.user.showSignupModal) {
                        this.showModal({
                            name: 'extra-details',
                        });
                    }
                });
            } catch (e) {
                this.error = 'Profile not loaded. <br><p class="small mt-1">Please refresh the page.</p>';
            }
            // this.getTotalUnreadNotifications(this.user.id);
            this.setFavicon();
            await this.initServices();
            this.bindToPusher();
        },
        async loadData(refresh = true, external = false) {
            if (external) {
                await this.getProfile(external);
                await this.getOrganisation(external);

                if (!this.isAuthorization) {
                    return Promise.allSettled([
                        this.fetchAvailableDataSources({ refresh, external }),
                        this.fetchAvailableDataTargets({ refresh, external }),
                        this.fetchAvailableIntegrations({ refresh, external }),
                        this.fetchMyDataTargets({ refresh, external }),
                        this.fetchSyncs({ refresh, external }),

                        PusherService.init(this.user).then((r) => { console.log(`Pusher Service: ${r}`); }),
                        SentryService.init(this.user).then((r) => { console.log(`Sentry Service: ${r}`); }),
                        ReditusService.init(this.user).then((r) => { console.log(`Reditus Service: ${r}`); }),
                    ]);
                }

                this.bindToPusher();
                this.setFavicon();

                return Promise.allSettled([Promise.resolve()]);
            }

            return Promise.allSettled([
                this.fetchMyDataSources(refresh),
                this.fetchMyDataTargets({ refresh, external }),
                this.fetchMyDataSets(refresh),
                this.fetchDatasetsRunHistory(refresh),
                this.fetchGroupedDatasetRunLogs(refresh),
                this.fetchAvailableDataSources({ refresh, external }),
                this.fetchAvailableDataTargets({ refresh, external }),
                this.fetchAvailableIntegrations({ refresh, external }),
                this.fetchMyIntegrations(refresh),
                this.fetchMyPolicies(refresh),
                this.fetchSyncs({ refresh, external }),
            ]);
        },
        setTheme() {
            themeManager.setTheme(this.$store.state.profile.organisation.themeSettings);
            this.$store.watch(s => s.profile.organisation.themeSettings, (theme) => {
                if (theme) themeManager.setTheme(theme);
            });
        },
        exportURL(data) {
            const url = `export/download?exportRequestId=${data.key}`;
            return this.download(url, data);
        },
        bindToPusher() {
            PusherService.organisation.listenFor('feed.update', (data) => {
                this.$store.commit('runHistory/addLogEntry', data);
                this.$store.commit('runHistory/addLogEntryToGroupedHistory', data);
            });

            PusherService.organisation.listenFor('log.update', (data) => {
                this.$store.commit('runHistory/addLogRunUpdate', data);
            });

            PusherService.organisation.listenFor('accountStripeDetails.update', (data) => {
                this.$store.commit('profile/updateSubscription', data);
            });
        },
        download(url, data) {
            const headers = {
                Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
            };

            if (Cookies.get('canary_backend')) headers['x-canary-be'] = 'always';

            fetch(`${Config.baseURL}/${url}`, {
                headers,
            })
                .then((resp) => {
                    // If download fails server is going to return 505 error
                    if (resp.status !== 200) return null;
                    return resp.blob();
                })
                .then((blob) => {
                    if (!blob || blob.type === 'application/json') return;

                    const href = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = href;
                    a.download = _.last(_.get(data, 'value.fileName', '').split('/'));
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => {
                    const exportType = _.get(data.value, 'exportType');

                    return this.$notify({
                        type: 'error',
                        title: data.name,
                        text: `There was an error trying to download ${exportType} <strong>${data.name}</strong> ${data.type} export`,
                        closeButton: true,
                        timeOut: 0,
                        extendedTimeOut: 0,
                        tapToDismiss: false,
                    });
                })
                .finally(() => {
                    this.removeExportId(data.key);
                });
        },
        validateExportId(key) {
            return _.get(sessionStorage, key, false);
        },
        removeExportId(key) {
            delete sessionStorage[key];
        },
        setFavicon() {
            const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = '/favicon.ico';
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        async initServices() {
            await Promise.allSettled([
                PosthogService.init(this.user).then((r) => { console.log(`Posthog Service: ${r}`); }),
                StripeService.init(this.user).then((r) => { console.log(`Stripe Service: ${r}`); }),
                PusherService.init(this.user).then((r) => { console.log(`Pusher Service: ${r}`); }),
                IntercomService.init(this.user).then((r) => { console.log(`Intercom Service: ${r}`); }),
                HeapService.init(this.user).then((r) => { console.log(`Heap Service: ${r}`); }),
                ReditusService.init(this.user).then((r) => { console.log(`Reditus Service: ${r}`); }),
                SentryService.init(this.user).then((r) => { console.log(`Sentry Service: ${r}`); }),
            ]);
        },
        showModal(modalSettings) {
            this.modalSettings = modalSettings;
        },
        onModalClosed(event) {
            if (this.modalSettings && this.modalSettings.callback) {
                const callback = this.modalSettings.callback;
                this.modalSettings = null;
                callback(event);
            } else {
                this.modalSettings = null;
            }
        },
        async onExtraDetails() {
            this.saveExtraDetails(this.extraDetails).then(() => {
                this.modalSettings = null;
            }).catch((e) => {
                this.$notify({
                    type: 'error',
                    text: e.message || LABELS.defaultError,
                });
            });
        },
    },
};
</script>
