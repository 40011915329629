import Vue from 'vue';
import Config from './config';

/**
 * You can include wildcard character in the url * but for now it has to be at the end of the url
 * So if the url is /dashboard/1234 you can setup a wildcard like /dashbaord/*
 */
const linkMap = {
};

const switcher = {
    get current() {
        return window.location.pathname;
    },
    findMatchedKey() {
        return _.keys(linkMap).find((link) => {
            if (link.includes('*')) {
                const withoutWildcard = link.replace('*', '');

                if (this.current.includes(withoutWildcard)) {
                    return this.current.match(link);
                }
            }

            return link === this.current ? this.current : undefined;
        });
    },
    hasOldVersion() {
        return this.findMatchedKey();
    },
    isLatest() {
        const key = this.findMatchedKey();

        return _.get(linkMap, `${key}.latest`, false);
    },
    getPath() {
        const key = this.findMatchedKey();

        if (key) {
            if (!key.includes('*')) return linkMap[key].to;

            const matches = this.current.match(key.replace('*', '(.*)'));
            if (matches) { //  Ensure matches has value
                return linkMap[key].to.replace('*', matches[1]);
            }
            return this.current;
        }
        return this.current;
    },
    setPreferredVersion() {
        if (this.current.includes('feeds')) localStorage.setItem('fav-datafeeds-version', 'v1');
        if (this.current.includes('self-serve')) localStorage.setItem('fav-datafeeds-version', 'v2');
        if (this.current.includes('dataset')) localStorage.setItem('fav-dataset-version', 'v2');
    },
};

Vue.switcher = switcher;
Vue.prototype.$switcher = switcher;
