import Vue from 'vue';
import VeeValidate from 'vee-validate';

const dictionary = {
    en: {
        attributes: {
            email: 'Email Address',
            firstName: 'First Name',
            lastName: 'Last Name',
            confirmPassword: 'repeat password',
        },
        messages: {
            requiredWith: 'requiredWith',
        },
        custom: {},
    },
};

VeeValidate.Validator.extend('requiredWith', (value, [otherValue]) => {
    const valid = !otherValue || !!value;
    return {
        valid,
        data: { required: valid },
    };
}, { computesRequired: true, hasTarget: true });

VeeValidate.Validator.extend('contains-lowercase', {
    getMessage: () => 'The password should contain lower case characters (a-z)',
    validate: (value) => {
        const lettersRegex = new RegExp('^(?=.*[a-z])');
        return lettersRegex.test(value);
    },
});

VeeValidate.Validator.extend('contains-uppercase', {
    getMessage: () => 'The password should contain upper case characters (A-Z)',
    validate: (value) => {
        const lettersRegex = new RegExp('^(?=.*[A-Z])');
        return lettersRegex.test(value);
    },
});

VeeValidate.Validator.extend('contains-numbers', {
    getMessage: () => 'The password should contain numbers(i.e 0-9)',
    validate: (value) => {
        const numbersRegex = new RegExp('^(?=.*[0-9])');
        return numbersRegex.test(value);
    },
});

VeeValidate.Validator.extend('contains-special-or-number', {
    getMessage: () => 'The password should contain numbers or special characters (e.g !@#$%^&*)',
    validate: (value) => {
        const specialRegex = new RegExp('^(?=.*[!@#$%^&*])');
        const numbersRegex = new RegExp('^(?=.*[0-9])');
        return specialRegex.test(value) || numbersRegex.test(value);
    },
});

VeeValidate.Validator.extend('no-name-or-username', {
    getMessage: () => 'The password should not contain your first name, last name, your email or username',
    validate: (input, variables) => {
        const variablesWithoutEmpty = variables.filter(x => x);
        return !variablesWithoutEmpty.some((verifyItem) => {
            const itemSpacesRemoved = verifyItem.trim();
            return itemSpacesRemoved && itemSpacesRemoved.length > 0 && input.toLowerCase().includes(itemSpacesRemoved.toLowerCase());
        });
    },
});

VeeValidate.Validator.extend('plus-with-number', {
    getMessage: () => 'Phone number invalid. Please add your country code (with +) and only numbers after it',
    validate: (value) => {
        const basicPhoneNumberRegex = new RegExp('^\\+\\.?(?=.*[0-9])');
        return basicPhoneNumberRegex.test(value);
    },
});

VeeValidate.Validator.extend('agree-terms', {
    getMessage: () => 'You must agree with the terms and conditions to be able to register',
    validate: (value) => {
        return value;
    },
});

Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        invalid: 'is-invalid',
    },
    dictionary,
    errorBagName: '$errors',
    fieldsBagName: 'veeFields',
    fastExit: false,
});
