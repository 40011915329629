import Vue from 'vue';
import Router from 'vue-router';
import Cache from '@/utils/cache/legacy';

import profile from '@/modules/profile/routes';
import runHistory from '@/modules/datasetHistory/routes';
import authentication from '@/modules/auth/routes';
import connections from '@/modules/connections/routes';
import syncs from '@/modules/syncs/routes';
import jobs from '@/modules/jobs/routes';

import Error404 from '@/views/errors/404';
import User from '@/store/models/user.model';

import { PosthogService } from '@/utils/services';

const ROLES = {
    ROLE_VIEW: 5,
    ROLE_USER: 4,
    ROLE_ADMIN: 3,
    ROLE_EXTERNAL_USER: 6,
};
const levels = {
    view: [
        ROLES.ROLE_VIEW,
        ROLES.ROLE_USER,
        ROLES.ROLE_ADMIN,
    ],
    user: [
        ROLES.ROLE_USER,
        ROLES.ROLE_ADMIN,
    ],
    admin: [
        ROLES.ROLE_ADMIN,
    ],
};

const checkLevel = ({ role }, requiredAuth) => {
    if (!requiredAuth) return true;

    const userRole = _.get(role, 'id', ROLES.ROLE_VIEW);
    return _.includes(levels[requiredAuth], userRole);
};

// const checkPermissions = (currentUser, permissionsList = []) => {
//     return checkLevel(currentUser, 'admin') || _.reduce(permissionsList, (isAllowed, permission) => {
//         return (isAllowed && currentUser.hasAnyPermission(permission));
//     }, true);
// };

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...profile,
        ...runHistory,
        ...authentication,
        ...connections,
        ...syncs,
        ...jobs,
        {
            name: 'error.404',
            path: '/404',
            component: Error404,
            meta: { title: '404' },
        },
        {
            path: '*',
            component: Error404,
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    // check if the route to be accessed requires authorizaton
    const isToken = localStorage.getItem('token');

    if (to.matched.some(record => record.meta.auth)
        && (!to.meta.externalAccess || (to.meta.externalAccess && to.meta.worksWithLogin && !localStorage.getItem('external_access')))) {
        // Check if user is Authenticated
        if (!isToken) {
            // user is not authenticated - redirect to login
            // test if redirect is needed
            if (to.path !== '/' && to.path !== '/home') {
                Cache.setRegular('redirectUrl', to.fullPath);
            }
            return next({ path: '/login' });
        }

        const user = new User(Cache.getPermanent('user', {}));
        if (!checkLevel(user, to.meta.auth)) {
            Vue.notify({ type: 'error', text: 'You are not authorized to view that page' });
            if (to.fullPath !== from.fullPath) {
                return next({ path: from.fullPath });
            }
            return next({ name: 'home' });
        }
    }

    try {
        PosthogService.pageView();
    } catch (e) {
        Vue.logger.notify(e, {
            severity: 'info',
            context: 'page view',
        });
    }

    return next();
});

export default router;
