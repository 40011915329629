import client from './client';

export async function deleteMember(email, owner) {
    const response = await client.post('user/delete', { email, owner });
    return client.parseResponse(response);
}

export async function getAvailableUsers() {
    const response = await client.get('user/organisationUsers');
    return client.parseResponse(response);
}
export async function inviteUser(params) {
    const response = await client.post('user/inviteUser', params);
    return client.parseResponse(response);
}

export async function cancelInvite(email) {
    const response = await client.post('user/cancelInvitationByExistingUser', { email });
    return client.parseResponse(response);
}

export async function resendInvite(email) {
    const response = await client.post('user/resendInvite', { email });
    return client.parseResponse(response);
}

export async function activateUser(id) {
    const response = await client.post(`user/activate/${id}`);
    return client.parseResponse(response);
}

export async function deactivateUser(id) {
    const response = await client.post(`user/deactivate/${id}`);
    return client.parseResponse(response);
}

export async function updateOrganisationUserRole(params) {
    const response = await client.post('user/updateOrganisationUserRole', params);
    return client.parseResponse(response);
}
