import lzString from 'lz-string';
import moment from 'moment';
import Vue from 'vue';
import Config from '../config';

const cacheFactory = {

    get(key, defaultValue) {
        const value = localStorage.getItem(key);
        if (value === undefined) {
            return defaultValue;
        }
        return value;
    },

    put(key, value) {
        localStorage.setItem(key, value);
    },

    remove({ url, baseURL, cache }) {
        const path = this.getPath(cache, baseURL, url);
        localStorage.removeItem(path);
        this.removeKey(path);
    },

    getPath(cache, baseURL = '/gravity', url, method) {
        const key = cache.key || baseURL.concat('/', cache.url || url);
        return `${this.prefix(cache.permanent, cache.method || method, key)}`.replace(/(\w)\/\/(\w)/g, '$1/$2');
    },

    extractKey(path, permanent) {
        const prefix = permanent ? 'vue-cache.caches.gravityPermanentCache.data.' : 'vue-cache.caches.gravityCache.data.';
        return path.replace(prefix, '');
    },

    getResponse({ url, baseURL, cache, method }) {
        const path = this.getPath(cache, baseURL, url, method);
        const response = this.getIfFresh(path);
        if (response) {
            if (_.isUndefined(cache.compress) || cache.compress) {
                return JSON.parse(this.decompress(response.value));
            }
            return response.value;
        }
        return null;
    },

    getPermanent(key, defaultValue) {
        const path = this.prefix(true, '', key);
        const value = this.get(path);
        if (!value) return defaultValue;
        try {
            const result = JSON.parse(value);
            if (!result) return defaultValue;
            return JSON.parse(this.decompress(result.value)) || defaultValue;
        } catch (error) {
            if (error instanceof SyntaxError) { // ignored since saving value was wrong
                return defaultValue;
            }
            throw new Error(`Cache: getPermanent unable to parse json for ${key}`);
        }
    },

    setPermanent(key, value) {
        const path = this.prefix(true, '', key);
        this.addKey(key, true);
        value = this.compress(JSON.stringify(value));

        return this.put(path, JSON.stringify({ key, value }));
    },

    getRegular(key, defaultValue) {
        const path = `vue-cache.caches.gravityCache.data.${key}`;
        const value = this.get(path);
        if (!value) return defaultValue;
        try {
            const result = JSON.parse(value);
            if (!result) return defaultValue;
            return JSON.parse(this.decompress(result.value)) || defaultValue;
        } catch (error) {
            if (error instanceof SyntaxError) { // ignored since saving value was wrong
                return defaultValue;
            }
            throw new Error(`Cache: getRegular unable to parse json for ${key}`);
        }
    },

    setRegular(key, value) {
        const path = `vue-cache.caches.gravityCache.data.${key}`;
        this.addKey(key, false);
        value = this.compress(JSON.stringify(value));

        return this.put(path, JSON.stringify({ key, value }));
    },

    removeItem(key, permanent = false) {
        const path = permanent ? this.prefix(permanent, '', key) : this.prefix(permanent, '', key, true);
        localStorage.removeItem(path);
        this.removeKey(path, permanent);
    },

    saveResponse({ url, baseURL, cache, method }, value) {
        const path = this.getPath(cache, baseURL, url, method);
        const key = this.extractKey(path, cache.permanent);
        const current = this.getIfFresh(key) || { key, created: moment().timestamp };

        value = this.transform(path, value);

        if (_.isUndefined(cache.compress) || cache.compress) {
            value = this.compress(value);
        }

        localStorage.setItem(path, JSON.stringify({ ...current, accessed: moment().timestamp, value }));
        this.addKey(key, cache.permanent);
    },

    // todo: invalidate using expires
    getIfFresh(key) {
        return JSON.parse(localStorage.getItem(key));
    },

    getCacheKeysByUrl(url, requestType) {
        const keys = [];

        for (let i = 0, len = localStorage.length; i < len; ++i) {
            if (localStorage.key(i).startsWith(`${this.prefix(requestType.toUpperCase())}${this.getApiUrl(url)}`)) {
                keys.push(localStorage.key(i));
            }
        }

        return keys;
    },

    clearUrlCache(url, requestType) {
        this.getCacheKeysByUrl(url, requestType).forEach((key) => {
            localStorage.removeItem(key);
        });
    },

    compress(value) {
        return lzString.compressToUTF16(value);
    },

    decompress(value) {
        return lzString.decompressFromUTF16(value);
    },

    getApiUrl(url) {
        return `${Config.baseURL}/${url}`;
    },

    prefix(permanent, method, key, regular = false) {
        if (permanent) {
            return `vue-cache.caches.gravityPermanentCache.data.${key}`;
        }
        if (!_.startsWith(key, window.location.protocol) && !regular) {
            key = `${window.location.protocol}//${window.location.host}${key}`;
        }
        return `vue-cache.caches.gravityCache.data.${method.toUpperCase()}${key}`;
    },

    removeAll(withPermanent = true) {
        const keys = Object.keys(localStorage);

        keys.forEach((key) => {
            if (key.includes('vue-cache.caches.gravityCache.data.')) localStorage.removeItem(key);
        });

        localStorage.setItem('vue-cache.caches.gravityCache.keys', JSON.stringify([]));

        if (withPermanent) {
            this.removePermanent();
        }

        if (window.caches) {
            caches.delete('api');
            caches.delete('assets');
            caches.delete('html');
        }
    },

    removePermanent() {
        const keys = Object.keys(localStorage);

        keys.forEach((key) => {
            if (key.includes('vue-cache.caches.gravityPermanentCache.data.')) localStorage.removeItem(key);
        });

        localStorage.setItem('vue-cache.caches.gravityPermanentCache.keys', JSON.stringify([]));
    },

    keys(permanent = false) {
        const path = permanent ? 'vue-cache.caches.gravityPermanentCache.keys' : 'vue-cache.caches.gravityCache.keys';
        return JSON.parse(localStorage.getItem(path)) || [];
    },

    addKey(path, permanent = false) {
        const keys = this.keys(permanent);
        const key = this.extractKey(path, permanent);

        if (!keys.includes(key)) {
            keys.push(key);
            const store = permanent ? 'vue-cache.caches.gravityPermanentCache.keys' : 'vue-cache.caches.gravityCache.keys';
            localStorage.setItem(store, JSON.stringify(keys));
        }
    },

    removeKey(path, permanent = false) {
        const keys = this.keys(permanent);
        const key = this.extractKey(path, permanent);
        const index = keys.indexOf(key);

        if (index > -1) {
            keys.splice(index, 1);
            const store = permanent ? 'vue-cache.caches.gravityPermanentCache.keys' : 'vue-cache.caches.gravityCache.keys';
            localStorage.setItem(store, JSON.stringify(keys));
        }
    },
    transform(path, value) {
        const transformers = {
            'vue-cache.caches.gravityPermanentCache.data.user': (userStr) => {
                const user = _.isString(userStr) ? JSON.parse(userStr) : userStr;
                return JSON.stringify({
                    ...user,
                    email: user.username,
                    idToken: window.localStorage.getItem('token'),
                    accessToken: window.localStorage.getItem('access_token'),
                    expiresAt: window.localStorage.getItem('expires_at'),
                });
            },
        };

        return _.get(transformers, path, () => value)(value);
    },
};

export default cacheFactory;

