<template>
    <div class="d-flex flex-row justify-content-start no-wrap w-100 h-100 pt-3" style="gap: 30px;">
        <div class="card border-0" style="max-width: 42.5%; min-width: 545px;">
            <div class="card-header bg-white border-bottom-0 px-4 pt-4 pb-0">
                <div class="text-sub-header font-weight-bold">{{ selectedIntegration.name }} policies</div>
                <div class="d-flex justify-content-center mt-4 mx-auto">
                    <input
                        v-model="searchTerm"
                        type="text"
                        class="form-control input__text w-100 pl-4"
                        placeholder="Search">
                    <div class="input-group-icon d-flex flex-content-center p-3">
                        <img src="/icons/search.svg" class="mw-100">
                    </div>
                </div>
            </div>
            <div class="card-body scrollable-area overflow-auto pt-4 px-4">
                <button class="btn btn-lg btn-outline-primary w-100" @click="onNewPolicy">
                    + Add new policy
                </button>
                <div v-for="item in filteredItems" :key="item.id" class="app-card my-3" :class="{ 'active': selectedPolicy && selectedPolicy.id == item.id }">
                    <div class="app-card__wrapper" @click="onEditPolicy(item)">
                        <div :id="item.id" class="app-icon text-center">
                            <img class="app-logo" :src="selectedIntegration.icon">
                        </div>
                        <div class="app-content mt-2">
                            <div class="app-title mb-0">
                                <span class="app-title-content" :title="item.name">
                                    {{ item.name }}
                                </span>
                            </div>
                        </div>
                        <confirm-n-delete-btn
                            v-b-tooltip.hover.top type="button"
                            class="btn btn-md text-danger btn-link ml-auto" title="Delete"
                            message="You are about to delete this policy. Once you have deleted it, there is no way to recover it."
                            @delete="onDeletePolicy(item.id)">
                            <i class="svg-icon icon-trash icon-lg" />
                        </confirm-n-delete-btn>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedPolicy" class="card w-100 border-0" style="min-width: 400px;">
            <div class="card-header bg-white border-bottom-0 d-flex justify-content-between pt-4 px-4">
                <span v-if="selectedPolicy && selectedPolicy.id" class="text-sub-header font-weight-bold"> {{ selectedPolicy.name }} details </span>
                <span v-if="selectedPolicy && !selectedPolicy.id" class="text-sub-header font-weight-bold"> New policy </span>
                <loading-btn v-if="selectedPolicy" class="btn btn-primary btn-lg" :disabled="!policyIsValid || !changed" :listen="['integrations/createPolicy', 'integrations/updatePolicy']" @click="onSave">
                    Save
                </loading-btn>
            </div>
            <div class="card-body overflow-auto">
                <loader :listen="['integrations/policyConfigurations']">
                    <div v-if="selectedPolicy" class="pb-4 pt-0">
                        <template v-for="field in visibleParameters">
                            <form-field
                                :key="field.parameter"
                                ref="formControl"
                                v-model="field.value"
                                class="custom-form-field m-2 py-2"
                                :config="field"
                                @input="onChanged"
                            />
                        </template>
                    </div>
                </loader>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormField from '@/components/formField';
import PolicyModel from '@/store/models/policy.model';
import { LABELS } from '@/constants';

export default {
    components: { FormField },
    props: {
        integrationId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            searchTerm: '',
            selectedPolicy: null,
        };
    },
    computed: {
        ...mapGetters({
            integrationPolicies: 'integrations/integrationPolicies',
            policyConfigurations: 'integrations/policyConfigurations',
            myIntegrations: 'integrations/myIntegrations',
        }),
        filteredItems() {
            return _.filter(_.map(this.integrationPolicies(this.integrationId), (policy) => {
                return {
                    name: _.get(policy.parameters, 'name', ''),
                    ...policy,
                };
            }), ({ name }) => {
                return _.includes(_.toLower(name), _.toLower(this.searchTerm));
            });
        },
        policyConfiguration() {
            return _.get(this.policyConfigurations, this.integrationId, null);
        },
        policyIsValid() {
            return this.selectedPolicy && _.get(this.selectedPolicy, 'isValid');
        },
        selectedIntegration() {
            return _.find(this.myIntegrations, { id: this.integrationId });
        },
        visibleParameters() {
            return _(_.get(this.selectedPolicy, 'parameters', {}))
                .filter('visible')
                .sortBy('order_of_display')
                .value();
        },
    },
    created() {
        this.fetchPolicyConfiguration(this.integrationId);
    },
    methods: {
        ...mapActions({
            fetchPolicyConfiguration: 'integrations/fetchPolicyConfiguration',
            fetchDynamicParameterValues: 'integrations/fetchDynamicParameterValues',
            createPolicy: 'integrations/createPolicy',
            updatePolicy: 'integrations/updatePolicy',
            deletePolicy: 'integrations/deletePolicy',
        }),
        onNewPolicy() {
            this.changed = false;
            this.selectedPolicy = new PolicyModel({ integrationId: this.integrationId }, this.policyConfiguration, this.fetchDynamicParameterValues);
        },
        onEditPolicy(policy) {
            this.changed = false;
            this.selectedPolicy = new PolicyModel(policy, this.policyConfiguration, this.fetchDynamicParameterValues);
        },
        onSave() {
            const payload = _.get(this.selectedPolicy, 'payload');

            (this.selectedPolicy.id ? this.updatePolicy(payload) : this.createPolicy(payload))
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: this.selectedPolicy.id ? LABELS.policyUpdateSuccess : LABELS.policyCreateSuccess,
                    });
                    this.selectedPolicy = null;
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
        onChanged() {
            this.changed = true;
        },
        onDeletePolicy(id) {
            this.deletePolicy(id)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: LABELS.policyDeleteSuccess,
                    });
                    if (this.selectedPolicy && this.selectedPolicy.id === id) this.selectedPolicy = null;
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
    },
};
</script>
