<template>
    <div :id="controlId">
        <label class="label">{{ attrs.display_name }}</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
        <div class="card border-0">
            <date-picker
                class="form-field"
                :value="controlValue"
                :name="attrs.display_name"
                @input="onChange" />
        </div>
    </div>
</template>

<script>
import * as uuid from 'uuid';

export default {
    name: 'FieldDatetime',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();
        return {
            controlValue: this.value,
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) this.controlValue = newVal;
            },
        },
    },
    methods: {
        onChange(value) {
            if (this.controlValue !== value) {
                this.controlValue = value;
                this.$emit('input', value);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.is-validated {
    &.is-valid .form-field {
        border: 1px solid $success-100 !important;
    }

    &.is-invalid .form-field {
        border: 1px solid $error-50 !important;
    }
}
</style>
