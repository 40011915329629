import RunHistory from '@/modules/datasetHistory/views';

export default [
    {
        path: '/logs',
        name: 'logs',
        component: RunHistory,
        meta: {
            title: 'Logs',
            auth: 'view',
        },
    },
];
