<template>
    <b-modal
        v-if="showModal || showRoleChangeModal"
        :id="`user-perm-modal_${user.id}`"
        :key="`user-perm-modal_${user.id}`"
        class="user-permissions-modal"
        size="lg"
        header-bg-variant="white"
        header-text-variant="default"
        visible
        @hide="dismiss">
        <div slot="modal-header">
            <h5 class="modal-title">
                <span>Create New User</span>
            </h5>
        </div>
        <b-tabs>
            <b-tab title="Details" class="pt-4">
                <div v-if="!updateExistingUser" class="form-group">
                    <label>Email Addresses:</label>
                    <div class="input-group">
                        <input v-model="$v.email.$model" class="form-control input__text" type="text" name="email" data-vv-delay="800"
                               placeholder="Use a comma to separate emails"
                               @keyup="emailHandler">
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary"
                                    :disabled="!$v.email.validMail || !email.length" @click="addEmails()">Add</button>
                        </div>
                    </div>
                    <div v-show="$v.$dirty" class="mt-3">
                        <p v-show="!$v.email.validMail" class="alert alert-danger">
                            <i class="fal fa-fw fa-exclamation-triangle" /> There is an invalid e-mail.
                        </p>
                    </div>
                </div>
                <div v-if="!updateExistingUser" class="form-group">
                    <span v-for="emailItem in emailsToInvite" :key="emailItem"
                          class="badge p-2 mr-2 mb-2 badge-primary badge-pill">
                        {{ emailItem }}
                        <span class="fal c-pointer fa-fw fa-times clickable" @click="removeEmailFromList(emailItem)" />
                    </span>
                </div>
                <div class="form-group mt-4">
                    <label>Select a role:</label>
                    <multi-select v-model="selectedRole" :options="roles" label-field="title" value-field="id"
                                  :allow-clear="true" />
                </div>
                <div v-if="!updateExistingUser" class="form-group">
                    <p v-show="!selectedRole || !emailsToInvite.length" class="alert alert-info">
                        <i class="far fa-fw fa-info-circle" />
                        You are required to add at least one email and designate a role to it.
                    </p>
                </div>
            </b-tab>

            <b-tab v-if="!updateExistingUser" title="Advanced" class="pt-4">
                <label class="custom-checkbox-wrapper d-inline-block mb-0 mr-3 mt-2" @click="setLimit(false)">
                    <i :class="['far fa-fw', limit.forever ? 'fa-check-square text-primary': 'fa-square', {active: limit.forever}]" />
                    Grant access forever
                </label>
                <label class="custom-checkbox-wrapper d-inline-block mb-0 mt-2" @click="setLimit(true)">
                    <i :class="['far fa-fw', limit.period ? 'fa-check-square text-primary': 'fa-square', {active: limit.period}]" />
                    Grant limited access
                </label>
                <div v-if="!isCurrentUser && limit.forever" class="alert text-center my-3" role="alert"
                     :class="{'alert-warning': user.hasExpired, 'alert-info': !user.hasExpired}">
                    <i class="far fa-info-circle fa-fw" />
                    {{ user.expirationString || 'Has access forever' }}
                </div>
                <div v-if="limit.period" class="mt-3">
                    <div class="row">
                        <div class="col-6">
                            <input v-model="limit.number" type="number" placeholder="Set number" class="form-control input__text mb-3"
                                   required min="1" max="99"
                                   :disabled="!limit.period" :class="{'is-invalid': !limit.number && limit.period}">
                        </div>
                        <div class="col-6">
                            <multi-select v-model="limit.range" :options="ranges" label-field="label" value-field="value"
                                          :disabled="limit.forever" :class="{'is-invalid': !limit.range && limit.period}" />
                        </div>
                    </div>
                </div>
                <hr>

                <label class="d-block font-weight-bold">Communications</label>
                <label class="custom-checkbox-wrapper d-inline-block mb-0 mt-2"
                       @click="ignoreEmailsFromSystem = !ignoreEmailsFromSystem">
                    <i :class="[
                        'far fa-fw', ignoreEmailsFromSystem ? 'fa-check-square text-primary': 'fa-square',
                        {active: ignoreEmailsFromSystem}
                    ]" />
                    Do not send any communications to this user
                </label>
            </b-tab>
        </b-tabs>

        <template slot="modal-footer">
            <div class="d-flex flex-row justify-content-around w-50 mx-auto">
                <button class="btn btn-link" @click="dismiss()">
                    Cancel
                </button>
                <button class="btn btn-primary" :disabled="!canSend" @click="save()">
                    Send
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { email as emailValidator } from 'vuelidate/lib/validators';
import { ROLES } from '../../../../constants';

export default {
    props: {
        user: {
            type: Object,
            default: () => ({
                role: {},
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideCaret: {
            type: Boolean,
            default: true,
        },
        variant: {
            type: String,
            default: 'link',
        },
        btnClass: {
            type: String,
            default: '',
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        updateExistingUser: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            email: '',
            emailsToInvite: [],
            showRoleChangeModal: false,
            selectedRole: _.get(this.user.role, 'id'),
            roles: _.filter(ROLES, 'assignable'),
            limit: {
                forever: true,
                period: false,
                number: undefined,
                range: undefined,
            },
            ranges: [
                { label: 'Hours', value: 1 },
                { label: 'Days', value: 24 },
                { label: 'Weeks', value: 24 * 7 },
                { label: 'Months', value: 24 * 30 },
            ],
            ignoreEmailsFromSystem: false,
        };
    },
    computed: {
        ...mapState({
            loggedIn: s => s.profile.user,
        }),
        canSend() {
            if (this.limit.period && (!this.limit.number || !this.limit.range)) {
                return false;
            }

            return !!this.emailsToInvite.length || (this.updateExistingUser && this.selectedRole);
        },
        isCurrentUser() {
            return this.user.username === this.loggedIn.username;
        },
    },
    watch: {
        'user.ignoreEmailsFromSystem': {
            immediate: true,
            handler() {
                this.ignoreEmailsFromSystem = !!this.user.ignoreEmailsFromSystem;
            },
        },
    },
    validations: {
        email: {
            validMail(emailItems) {
                emailItems = emailItems.replace(/\s/g, '');
                let validation = true;
                if (emailItems) {
                    emailItems.split(',').forEach((mail) => {
                        validation = validation && emailValidator(mail.trim());
                    });
                    return validation;
                }
                return true;
            },
        },
    },
    mounted() {
        if (!this.user.id) {
            this.limit.forever = true;
        }
    },
    methods: {
        ...mapActions({
            inviteUser: 'users/inviteUser',
        }),
        async invite({ forever, limit, role, ignoreEmailsFromSystem }) {
            const authority = _.find(ROLES, { id: role });

            const params = {
                forever,
                limit,
                authority: authority ? authority.name : '',
                ignoreEmailsFromSystem,
            };

            await Promise.allSettled(this.emailsToInvite.map((mail) => {
                return this.inviteUser({ email: mail, ...params }).then(() => this.$notify({
                    type: 'success',
                    title: 'Succes!',
                    text: `${mail} was invited successfully.`,
                })).catch(e => this.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: e.message || `There was an error when inviting ${mail}.`,
                }));
            }));

            this.$bvModal.hide(`user-perm-modal_${this.user.id}`);
            this.showRoleChangeModal = false;
            this.$v.$reset();
            this.emailsToInvite = [];
        },
        dismiss() {
            this.selectedRole = undefined;
            this.foldersSearchTerm = '';
            this.limit.forever = false;
            this.limit.period = false;
            this.ignoreEmailsFromSystem = !!this.user.ignoreEmailsFromSystem;
            this.email = '';
            this.emailsToInvite = [];
            this.$nextTick(() => {
                this.showRoleChangeModal = false;
                this.$bvModal.hide(`user-perm-modal_${this.user.id}`);
                this.$emit('hide-modal', true);
            });
        },
        async save() {
            const params = {
                email: this.user.username,
                role: this.selectedRole,
                forever: this.limit.forever ? true : undefined,
                limit: this.limit.period ? this.limit.number * this.limit.range : undefined,
                ignoreEmailsFromSystem: this.ignoreEmailsFromSystem,
            };
            if (!this.updateExistingUser) {
                await this.invite({ ...params, filters: this.selectedFilters });
                this.dismiss();
            } else {
                // Update existing user - without filters as they will be managed through the separate popup
                try {
                    // this.$emit('input', user);
                    this.$notify({ type: 'success', text: 'Save successful' });
                    this.dismiss();
                } catch (err) {
                    this.$notify({ type: 'error', text: 'Save not successful' });
                    console.log(err);
                }
            }
        },
        setLimit(limited) {
            this.limit.forever = !limited;
            this.limit.period = limited;
        },
        emailHandler($event) {
            this.email = this.email.replace(/\s/g, '');
            if (!this.$v.email.$anyError) {
                if ($event.keyCode === 13 && this.emailsToInvite.indexOf(this.email.trim()) < 0 && this.email.length) {
                    this.addEmails();
                }
            }
        },
        addEmails() {
            this.email.split(',').forEach((mail) => {
                if (this.emailsToInvite.indexOf(mail.trim()) < 0 && mail.length) { // if it doesn't already exists
                    this.emailsToInvite.push(mail.trim());
                }
            });
            this.email = '';
        },
        removeEmailFromList(emailItem) {
            this.emailsToInvite.splice(this.emailsToInvite.indexOf(emailItem), 1);
        },
    },
};
</script>

