import WebFont from 'webfontloader';
import { defaultTheme, fonts } from '../constants';

export default {
    setTheme(theme) {
        if (!theme) {
            theme = defaultTheme;
        }
        const font = fonts[theme.font];
        if (font && font.google) {
            WebFont.load({
                google: {
                    families: [font.family + font.options],
                },
                active() {
                    document.body.style.setProperty('--theme-global-font', _.get(font, 'family'));
                },
            });
        }
        this.setVariables(theme, font);
    },
    setVariables(theme, font) {
        const { style } = document.body;
        style.setProperty('--theme-global-font', _.get(font, 'family'));
        style.setProperty('--theme-text-color', _.get(theme, 'colors.font'));
        style.setProperty('--theme-background-color', _.get(theme, 'colors.background'));
        style.setProperty('--theme-link-text-color', _.get(theme, 'colors.link.text'));
        style.setProperty('--theme-link-active-text-color', _.get(theme, 'colors.link.active'));
        style.setProperty('--theme-table-header-text-color', _.get(theme, 'colors.table.header.text'));
        style.setProperty('--theme-table-header-background-color', _.get(theme, 'colors.table.header.background'));
        style.setProperty('--theme-sidebar-text-color', _.get(theme, 'colors.sidebar.text'));
        style.setProperty('--theme-sidebar-background-color', _.get(theme, 'colors.sidebar.background'));
        style.setProperty('--theme-header-text-color', _.get(theme, 'colors.header.text'));
        style.setProperty('--theme-header-background-color', _.get(theme, 'colors.header.background'));
        style.setProperty('--theme-footer-background-color', _.get(theme, 'colors.footer.background'));
    },
};
