import PortalVue from 'portal-vue';
import Vue from 'vue';
import Fragment from 'vue-fragment';
import VueObserveVisibility from 'vue-observe-visibility';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import '@/styles/main.scss';

import App from './App';
import './components';
import './directives';
import './filters';
import router from './router';
import store from './store';
import './utils/cache/legacy';
import './utils/cache';
import 'bootstrap';

import Config from './utils/config';
import './utils/moment';
import './utils/notify';
import './utils/string';
import './utils/switcher';
import './utils/track';
import './utils/validator';
import './utils/vendors';
import './utils/eventHub';
import './registerServiceWorker';

window.start = performance.now();

// import './components/icons/fa';

Vue.use(Fragment.Plugin);
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(VueObserveVisibility);
Vue.use(BootstrapVue);

Vue.config.productionTip = false;
Vue.config.performance = true;

// eslint-disable-next-line import/first
// import popupWindow from './mixins/popupWindow';

// Vue.mixin(popupWindow);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

console.groupCollapsed(`App Version (${Config.isProd ? process.env.VUE_APP_VERSION : process.env.VUE_APP_REVISION})`);
console.log('Revision number: ', process.env.VUE_APP_REVISION);
// Do not show version on dev servers as it will be invalid. We only tag releases on master branch
if (Config.isProd) console.log('Application version: ', process.env.VUE_APP_VERSION);
console.groupEnd();
