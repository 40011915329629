<template>
    <!--
        above-scrollable: Slot that adds content above the scrollable area
        below-scrollable: Slot that adds content below the scrollable area

        --- Props ---
        title: String - Modal Title
        size: String - Specifies the size of the Modal: 'sm', 'md', 'lg', 'xl'
        hasFooter: Boolean - Shows or hides the Modal Footer
    -->
    <b-modal v-model="showModal"
             scrollable
             :title="title"
             class="radius-3"
             :hide-footer="hideFooter"
             :size="size"
             @hidden="close"
    >
        <slot slot="modal-header" name="modal-header" />
        <slot name="above-scrollable" />
        <div slot="default" class="scrollable-container">
            <div class="scrollable-content">
                <slot />
            </div>
        </div>
        <slot name="below-scrollable" />
        <slot slot="modal-footer" name="modal-footer" />
    </b-modal>
</template>

<script>
export default {
    props: {
        displayModal: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            required: true,
        },
        hideFooter: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
    watch: {
        displayModal(display) {
            this.showModal = display;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
    .modal-dialog-scrollable .modal-body {
        overflow-y: hidden;
    }

    .scrollable-container {
        margin: 0 -1.5rem;
        max-height: 55vh;
        min-height: 55vh;
        padding: 0 1.5rem;
        overflow-y: auto;
    }

    .scrollable-content {
        overflow-x: hidden;
    }

</style>

