<template>
    <div class="m-auto">
        <div class="d-flex flex-row justify-content-between no-wrap">
            <div class="d-flex flex-row justify-content-between no-wrap">
                <h3 class="text-header-md">
                    Configuring jobs ({{ sourceTables.length }})
                </h3>
            </div>
            <loading-btn
                class="btn btn-lg btn-primary"
                :listen="['dataSets/saveJobs']"
                :disabled="disableSave"
                @click="onSave">
                <span>
                    Save jobs ({{ sourceTables.length }})
                </span>
            </loading-btn>
        </div>

        <loader :listen="['dataSets/fetchJobConfiguration']" id="jobs-container">
            <template v-if="jobsSettings.length">
                <template v-for="(settings, $index) in jobsSettings">
                    <div :key="`header-${$index}`" class="card job-card my-4 animate__animated animate__fadeIn animate__fast" :class="{ 'active': settings.showExpanded }">
                        <div class="card-header border-0 bg-transparent py-0 d-flex flex-row justify-content-between" :class="{ 'clickable': settings.jobModel && !settings.jobModel.isLoading }" @click="settings.jobModel && !settings.jobModel.isLoading && onToggleExpanded($index)">
                            <div class="d-flex flex-row my-auto" @click.prevent.stop>
                                <div v-if="!settings.showRename" class="my-auto d-flex flex-row job-name justify-content-start clickable" @click.prevent.stop="onEditName($index)">
                                    <span class="font-weight-bolder">{{ settings.name.value }}</span>
                                    <span v-if="settings.jobModel" class="text-muted ml-2 job-rename">(click to rename)</span>
                                </div>
                                <form-field
                                    v-if="settings.showRename"
                                    :key="$index + settings.name.parameter"
                                    ref="nameField"
                                    v-model="settings.name.value"
                                    class="custom-form-field py-2 name-field d-inline my-auto"
                                    :config="settings.name" />
                                <button v-if="settings.showRename" :disabled="!settings.name.isValid" class="my-auto clickable btn btn-link" @click.prevent.stop="onApplyName($index)">
                                    Apply
                                </button>
                            </div>
                            <div v-if="settings.jobModel && jobIssues(settings.jobModel)" v-b-tooltip:jobs-container.hover="'Setup pending'" class="my-auto ml-auto py-1 px-2 mr-2 issues-text text-sm user-select-none d-inline">
                                {{ jobIssues(settings.jobModel) }} {{ jobIssues(settings.jobModel) > 1 ? 'ISSUES' : 'ISSUE' }}
                            </div>
                            <div class="my-auto text-muted mx-2">
                                <div v-if="!settings.jobModel || settings.jobModel.isLoading" v-b-tooltip:jobs-container.hover="'Setup pending'" class="my-auto mx-3 dot-pulse" />
                                <i v-else class="far fa-fw fa-2x m-auto clickable" :class="settings.showExpanded ? 'fa-angle-up' : 'fa-angle-down'" />
                            </div>
                        </div>
                    </div>
                    <div v-if="settings.showExpanded && settings.jobModel" :key="`content-${$index}`" class="card-body bg-transparent px-0 animate__animated animate__fadeIn animate__fast" style="z-index: 2;">
                        <div v-for="group in settings.jobModel.groupedVisibleParameters" :key="`${$index}_${group.name}`" class="">
                            <div v-if="group.name" class="my-5 text-center card group-card clickable" @click="onToggleGroupExpanded(`${$index}_${group.name}`)">
                                <div class="card-body bg-transparent p-2 d-flex flex-row justify-content-center">
                                    <div class="my-auto p-2">
                                        {{ group.name }}
                                    </div>
                                    <div v-if="groupIssues(group.name, settings.jobModel)" class="my-auto mx-2 py-1 px-2 issues-text text-sm user-select-none clickable d-inline">
                                        {{ groupIssues(group.name, settings.jobModel) }} {{ groupIssues(group.name, settings.jobModel) > 1 ? 'ISSUES' : 'ISSUE' }}
                                    </div>
                                    <div class="my-auto text-muted">
                                        <i class="far fa-fw fa m-auto" :class="groupExpanded(`${$index}_${group.name}`) ? 'fa-angle-up' : 'fa-angle-down'" />
                                    </div>
                                </div>
                            </div>
                            <div v-show="!group.name || groupExpanded(`${$index}_${group.name}`)" class="parameters-container mb-4">
                                <form-field
                                    v-for="field in group.parameters"
                                    :key="`${$index}_${group.name}_${field.parameter}`"
                                    ref="formControl"
                                    v-model="field.value"
                                    validate-on-create
                                    class="custom-form-field"
                                    :config="field" />
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </loader>
    </div>
</template>

<script>
import { PromisePool } from '@supercharge/promise-pool';

import { mapActions, mapGetters } from 'vuex';

import JobModel from '@/store/models/job.model';
import DynamicParameter from '@/store/models/dynamicParameter.model';
import FormField from '@/components/formField';

export default {
    name: 'BulkJobCreation',
    components: { FormField },
    props: {
        syncId: {
            type: [String, Number],
            required: true,
        },
        sourceTables: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            jobNameSettings: {
                control: 'text',
                parameter: 'name',
                required: true,
                validations: {
                    allowedCharacters: {
                        name: 'allowedCharacters',
                        message: 'Invalid characters. Only underscore and aplahumeric characters are allowed',
                        validate(value) {
                            return (/^[a-z0-9_]+$/i).test(value);
                        },
                    },
                },
            },
            jobConfiguration: null,
            jobsSettings: [],
            expandedGroups: [],
        };
    },
    computed: {
        ...mapGetters({
            mySyncs: 'syncs/mySyncs',
        }),
        disableSave() {
            return !_.every(this.jobsSettings, (settings) => {
                return !_.isNull(settings.jobModel)
                    && settings.name.isValid
                    && settings.jobModel.isValid
                    && !settings.jobModel.isLoading;
            });
        },
        sync() {
            return _.get(this.mySyncs, this.syncId, {});
        },
        jobIssues() {
            return (job) => {
                const issues = _.reject(_.get(job, 'visibleParameters'), 'isValid');
                return issues.length || '';
            };
        },
        groupExpanded() {
            return (groupId) => {
                return _.includes(this.expandedGroups, groupId);
            };
        },
        groupIssues() {
            return (name, job) => {
                const issues = _.reject(_.filter(_.get(job, 'visibleParameters'), { section: name }), 'isValid');
                return issues.length || '';
            };
        },
    },
    created() {
        this.fetchJobConfiguration({
            datasource_code: _.get(this.sync, 'sourcecode', undefined),
            datatarget_code: _.get(this.sync, 'targetcode', undefined),
        }).then((configuration) => {
            this.jobConfiguration = configuration;
            this.jobsSettings = _.map(this.sourceTables, (selectedJob) => {
                const jobName = _.replace(_.get(selectedJob, 'label'), /([^a-z_1-9])+/gi, '_');

                return {
                    showExpanded: false,
                    showRename: false,
                    name: new DynamicParameter(jobName, this.jobNameSettings),
                    jobModel: null, // new JobModel(values, this.jobConfiguration, this.fetchDynamicParameterValues),
                };
            });

            PromisePool
                .for(this.jobsSettings)
                .withConcurrency(10)
                .process(async (settings, index, pool) => {
                    await this.generateJobModel(settings, index, pool);
                });
        });
    },
    methods: {
        ...mapActions({
            fetchJobConfiguration: 'dataSets/fetchJobConfiguration',
            fetchDynamicParameterValues: 'dataSets/fetchDynamicParameterValues',
        }),
        onEditName(index) {
            this.$set(this.jobsSettings[index], 'showRename', true);
        },
        onApplyName(index) {
            if (_.get(this.jobsSettings[index], 'jobModel')) {
                this.jobsSettings[index].jobModel.name = this.jobsSettings[index].name.value;
            }
            this.$set(this.jobsSettings[index], 'showRename', false);
        },
        onToggleExpanded(index) {
            const settings = this.jobsSettings[index];
            this.$set(this.jobsSettings[index], 'showExpanded', !settings.showExpanded);
        },
        onSave() {
            const payload = _.map(this.jobsSettings, 'jobModel.payload');
            this.$emit('save', payload);
        },
        onToggleGroupExpanded(groupId) {
            if (this.groupExpanded(groupId)) {
                this.expandedGroups = _.without(this.expandedGroups, groupId);
            } else {
                this.expandedGroups = _.concat(this.expandedGroups, groupId);
            }
        },
        async generateJobModel(jobSettings, index) {
            const values = {
                name: _.get(jobSettings.name, 'value'),
                syncId: _.get(this.sync, 'id', undefined),
                sourcecode: _.get(this.sync, 'sourcecode', undefined),
                sourceconnectionname: _.get(this.sync, 'sourcename', undefined),
                targetcode: _.get(this.sync, 'targetcode', undefined),
                targetconnectionname: _.get(this.sync, 'targetname', undefined),
                parameters: {
                    ..._.get(this.sourceTables[index], 'parameters', {}),
                    SCHEDULE_INTERVAL_ANCHOR: _.get(this.sync, 'run_frequency'),
                },
            };

            const jobModel = new JobModel(values, this.jobConfiguration, this.fetchDynamicParameterValues);
            this.$set(this.jobsSettings[index], 'jobModel', jobModel);

            console.log(values.name, 'start processing', Date.now());

            return jobModel.initialLoading
                .finally(() => {
                    console.log(values.name, 'finished processing', jobModel.requestQueue.length, Date.now());
                });
        },
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

#jobs-container {
    .parameters-container {
        display: grid;
        gap: 50px;

        @include respond-to('small') {
            grid-template-columns: repeat(auto-fill, 500px);
        }

        @include respond-to('medium') {
            grid-template-columns: repeat(auto-fill, 500px);
        }

        @include respond-to('large') {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .custom-form-field {
        min-width: 200px;

        &.name-field {
            min-width: 400px;
            flex-grow: 1;
        }
    }

    .job-card {
        border-color: transparent;
        background-color: $shade-50;
        &.active {
            border-color: $primary-300;
            background-color: $primary-100;
        }
    }

    .card {
        .card-header {
            min-height: 80px;
            border-radius: $border-radius;
        }
    }

    .group-card {
        background: #e6e3e0;
        border-radius: $border-radius;
    }

    .issues-text {
        color:$warning-100;
        border-radius: 5px;
        background-color: $warning-50;
    }

    .job-name:not(:hover) {
        .job-rename {
            visibility: hidden;
        }
    }
}
</style>
