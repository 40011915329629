<template>
    <div :id="controlId" class="field-cron">
        <label class="label">Run Frequency</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
        <div class="card border-0">
            <div class="text-center cron-widget__wrapper m-auto form-field w-100">
                <vue-cron-editor-buefy
                    v-model="controlValue"
                    class="m-auto"
                    cron-syntax="quartz"
                    :preserve-state-on-switch-to-advanced="true"
                    @input="onCronChanged" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import * as uuid from 'uuid';

export default {
    name: 'FieldCron',
    components: { VueCronEditorBuefy },
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();

        return {
            // cronRegex: new RegExp('(?<seconds>[^ ]+) (?<minutes>[^ ]+) (?<hours>[^ ]+) (?<daysOfMonth>[^ ]+) (?<month>[^ ]+) (?<daysOfWeek>[^ ]+)'),
            controlValue: this.value,
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) this.controlValue = newVal;
            },
        },
    },
    methods: {
        onCronChanged(cron) {
            this.controlValue = cron;
            this.$emit('input', this.controlValue);
        },
    },
};
</script>
