/* eslint-disable */
import client from './client';

export async function fetchIntegrationTypes(skip, externalAccess = false) {
    const response = await client.get('courier/integrationTypes', {}, { cache: { skip, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function fetchMyIntegrations(skip) {
    const response = await client.get('courier/listOrganisationIntegrations', {}, { cache: { skip, v2: true } });
    return client.parseResponse(response);
}

export async function fetchIntegrationConfiguration(code) {
    const response = await client.get('courier/integrationConfiguration', { code }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function createIntegration(payload) {
    const response = await client.post('courier/createIntegration', payload, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function updateIntegration(payload) {
    const response = await client.put('courier/updateIntegration', payload, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function deleteIntegration(id) {
    const response = await client.delete('courier/deleteIntegration', { id }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function fetchPolicyConfiguration(id) {
    const response = await client.get('courier/policyConfiguration', { id }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function fetchMyPolicies() {
    const response = await client.get('courier/listOrganisationPolicies', {}, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function createPolicy(payload) {
    const response = await client.post('courier/createPolicy', payload, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function deletePolicy(id) {
    const response = await client.delete('courier/deletePolicy', { id }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function updatePolicy(payload) {
    const response = await client.put('courier/updatePolicy', payload, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function fetchDynamicParameterValues(query) {
    const response = await client.get('courier/dynamicParameterValues', query, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}


