import Vue from 'vue';
import * as api from '@/store/api/connection.api';

import { DataSet } from '@/store/models/dataConnection.model';
import { DataSetConfiguration } from '@/store/models/dataConfiguration.model';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        myDataSets: {},
        dataSetsConfigurations: {},
        jobConfigurations: {},
    },
    getters: {
        myDataSets(state) {
            return state.myDataSets;
        },
        dataSetsConfigurations(state) {
            return state.dataSetsConfigurations;
        },
        jobConfigurations(state) {
            return state.jobConfigurations;
        },
    },
    mutations: {
        setMyDataSets(state, sets) {
            _.each(sets, (set) => {
                Vue.set(state.myDataSets, set.id, set);
            });
        },
        setDataSet(state, set) {
            Vue.set(state.myDataSets, set.id, set);
        },
        deleteAllMyDataSets(state) {
            Vue.set(state, 'myDataSets', {});
        },
        deleteDataSet(state, setId) {
            Vue.delete(state.myDataSets, setId);
        },
        setDataSetConfiguration(state, setConfiguration) {
            Vue.set(state.dataSetsConfigurations, setConfiguration.id, setConfiguration);
        },
        setJobConfiguration(state, configuration) {
            Vue.set(state.jobConfigurations, _.get(configuration, 'code'), configuration);
        },
        setJobStatus(state, job) {
            const jobId = job.id || `${job.sourcecode}_${job.name}_${job.sourceconnectionname || job.targetconnectionname}`;
            if (jobId) {
                Vue.set(state.myDataSets[jobId], 'schedule_status', job.schedule_status);
            }
        },
        setSyncJobsStatus(state, sync) {
            if (sync && sync.syncId) {
                _(state.myDataSets)
                    .filter({ syncId: sync.syncId })
                    .each((job) => {
                        const jobStatus = _.get(job, 'schedule_status', 'enabled');
                        if (jobStatus === 'enabled'
                            || jobStatus === 'disabled') {
                            Vue.set(state.myDataSets[job.id], 'schedule_status', sync.schedule_status);
                        }
                    });
            }
        },
    },
    actions: {
        async fetchMyDataSets({ commit }, refresh = false) {
            commit('activateLoading', 'dataSets/fetchMyDataSets', { root: true });
            try {
                const { data } = await api.fetchMyDataSets({}, refresh);
                const dataSets = _.map(data, (item) => {
                    return new DataSet(item);
                });
                if (refresh) {
                    commit('deleteAllMyDataSets');
                }
                commit('setMyDataSets', dataSets);
                return dataSets;
            } finally {
                commit('deactivateLoading', 'dataSets/fetchMyDataSets', { root: true });
            }
        },
        async fetchDataSetConfiguration({ commit }, { sourcecode, sourceconnectionname, targetcode, targetconnectionname }, refresh = false) {
            commit('activateLoading', 'dataSets/fetchDataSetConfiguration', { root: true });
            try {
                const configuration = await api.fetchDataSetConfiguration({
                    sourcecode,
                    sourceconnectionname,
                    targetcode,
                    targetconnectionname,
                }, refresh);
                const dataSetConfig = new DataSetConfiguration(_.first(configuration));
                commit('setDataSetConfiguration', dataSetConfig);
                return dataSetConfig;
            } finally {
                commit('deactivateLoading', 'dataSets/fetchDataSetConfiguration', { root: true });
            }
        },
        async saveDataSet({ commit }, dataSet) {
            commit('activateLoading', 'dataSets/saveDataSet', { root: true });
            try {
                const { data } = await api.saveDataSet(dataSet);
                const newDataSet = new DataSet(_.first(data));
                commit('setDataSet', newDataSet);
                return newDataSet;
            } finally {
                commit('deactivateLoading', 'dataSets/saveDataSet', { root: true });
            }
        },
        async deleteDataSet({ commit }, dataSet) {
            commit('activateLoading', 'dataSets/deleteDataSet', { root: true });
            try {
                await api.deleteDataSet(dataSet);
                commit('deleteDataSet', dataSet.id);
            } finally {
                commit('deactivateLoading', 'dataSets/deleteDataSet', { root: true });
            }
        },
        async executeDataSet({ commit }, dataSet) {
            commit('activateLoading', `dataSets/executeDataSet/${dataSet.id}`, { root: true });
            try {
                const response = await api.executeDataSet(dataSet);
                return response;
            } finally {
                commit('deactivateLoading', `dataSets/executeDataSet/${dataSet.id}`, { root: true });
            }
        },
        async fetchDynamicParameterValues({ commit }, { query, invalidateCache = false, external = false }) {
            commit('activateLoading', `dataSets/fetchDynamicParameterValues/${query.parameter}`, { root: true });
            try {
                if (invalidateCache) memoizedDynamicParameterValues.cache.delete(hashFunction(query));
                const response = _.get(await memoizedDynamicParameterValues(query, external), 'data.json', []);
                return response;
            } catch (e) {
                memoizedDynamicParameterValues.cache.delete(hashFunction(query));
                return [];
            } finally {
                commit('deactivateLoading', `dataSets/fetchDynamicParameterValues/${query.parameter}`, { root: true });
            }
        },
        async fetchJobConfiguration({ commit }, query) {
            commit('activateLoading', 'dataSets/fetchJobConfiguration', { root: true });
            try {
                const external = query.external;
                delete query.external;

                const data = await api.fetchConfiguration('job', query, true, external);
                if (data && data.length) {
                    commit('setJobConfiguration', _.first(data));
                    return _.first(data);
                }
                return null;
            } finally {
                commit('deactivateLoading', 'dataSets/fetchJobConfiguration', { root: true });
            }
        },
        async fetchAvailableBulkJobs({ commit }, { query, invalidateCache = false, external }) {
            commit('activateLoading', 'dataSets/fetchAvailableBulkJobs', { root: true });
            try {
                if (invalidateCache) memoizedFetchAvailableBulkJobs.cache.delete(hashFunction(query));
                return await memoizedFetchAvailableBulkJobs(query, invalidateCache, external);
            } catch (e) {
                memoizedFetchAvailableBulkJobs.cache.delete(hashFunction(query));
                return [];
            } finally {
                commit('deactivateLoading', 'dataSets/fetchAvailableBulkJobs', { root: true });
            }
        },
        async saveJobs({ commit }, payload) {
            commit('activateLoading', 'dataSets/saveJobs', { root: true });
            try {
                const external = payload.external;
                delete payload.external;

                const { jobs } = await api.saveJobs(payload, external);
                _.each(jobs, (job) => {
                    commit('setDataSet', new DataSet(job));
                });
                return jobs;
            } finally {
                commit('deactivateLoading', 'dataSets/saveJobs', { root: true });
            }
        },
        async updateJobStatus({ commit }, job) {
            commit('activateLoading', `dataSets/updateJobStatus/${job.id}`, { root: true });
            try {
                await api.updateJobsStatus({
                    ...job,
                    id: undefined,
                });
                commit('setJobStatus', job);
            } finally {
                commit('deactivateLoading', `dataSets/updateJobStatus/${job.id}`, { root: true });
            }
        },
    },
};

function hashFunction(args) {
    const json = JSON.stringify({
        ...args,
        prerequisite_values: args.prerequisite_values ? _.reduce(args.prerequisite_values, (res, val, key) => {
            return {
                ...res,
                [key]: _.sortBy(val),
            };
        }, {}) : undefined,
    });
    return json;
}

const memoizedDynamicParameterValues = _.memoize(api.fetchDynamicParameterValues, hashFunction);
const memoizedFetchAvailableBulkJobs = _.memoize(api.fetchAvailableBulkJobs, hashFunction);
