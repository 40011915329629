<template>
    <button :disabled="loading" class="btn btn-md btn-primary" @click="onTriggerFileUpload">
        <input :id="id" ref="fileInput" type="file" style="visibility: hidden; width: 0; height: 0;" accept="image/*" :value="value" @change="fileChanged">
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        listen: {
            type: String,
        },
    },
    data() {
        return {
            value: '',
            id: '',
        };
    },
    computed: {
        loading() {
            return this.$store.state.loading[this.listen];
        },
    },
    created() {
        this.generateId();
    },
    methods: {
        generateId() {
            this.id = new Date().getUTCMilliseconds();
        },
        fileChanged(e) {
            const image = new Image();
            const vm = this;
            // eslint-disable-next-line
            image.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;
                canvas.getContext('2d').drawImage(this, 0, 0);
                vm.$emit('change', {
                    image: canvas.toDataURL('image/png'),
                    file: e.target.files[0],
                });
                vm.value = '';
            };
            image.src = URL.createObjectURL(e.target.files[0]);
        },
        onTriggerFileUpload() {
            this.$refs.fileInput.click();
        },
    },
};
</script>
