<template>
    <div class="vh-100 d-flex w-100 justify-content-center align-items-center">
        <loader :listen="[]" :force-loading="true" loading-class="m-auto" style="height: inherit;" />
    </div>
</template>

<script>
/* eslint-disable import/no-unresolved */

import { mapActions } from 'vuex';
import { logUserOut } from '@/utils/auth0';

export default {
    name: 'Logout',
    created() {
        this.logout();
        logUserOut();
    },
    methods: {
        ...mapActions({
            logout: 'profile/logout',
        }),
    },
};
</script>
