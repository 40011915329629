import * as api from '@/store/api/stripe.api';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        products: [],
        prices: [],
    },
    getters: {

    },
    mutations: {
        setProducts(state, products) {
            Vue.set(state, 'products', products);
        },
        setPrices(state, prices) {
            Vue.set(state, 'prices', prices);
        },
    },
    actions: {
        async createCheckoutSession({ commit }, priceId) {
            commit('activateLoading', 'stripe/createCheckoutSession', { root: true });
            try {
                return await api.createCheckoutSession(priceId);
            } finally {
                commit('deactivateLoading', 'stripe/createCheckoutSession', { root: true });
            }
        },
        async createCustomerPortal({ commit }) {
            commit('activateLoading', 'stripe/createCustomerPortal', { root: true });
            try {
                return await api.createCustomerPortal();
            } finally {
                commit('deactivateLoading', 'stripe/createCustomerPortal', { root: true });
            }
        },
        async getAllActiveProducts({ commit }) {
            commit('activateLoading', 'stripe/getAllActiveProducts', { root: true });
            try {
                const { data } = await api.getAllActiveProducts();
                commit('setProducts', data);
                return data;
            } finally {
                commit('deactivateLoading', 'stripe/getAllActiveProducts', { root: true });
            }
        },
        async getAllActivePrices({ commit }) {
            commit('activateLoading', 'stripe/getAllActivePrices', { root: true });
            try {
                const { data } = await api.getAllActivePrices();
                commit('setPrices', data);
                return data;
            } finally {
                commit('deactivateLoading', 'stripe/getAllActivePrices', { root: true });
            }
        },
        async upgradeSubscription({ commit }, priceId) {
            commit('activateLoading', 'stripe/upgradeSubscription', { root: true });
            try {
                const { data } = await api.upgradeSubscription(priceId);
                return data;
            } finally {
                commit('deactivateLoading', 'stripe/upgradeSubscription', { root: true });
            }
        },
        async setDatasetRunLimit({ commit }, { usageLimit, noLimit }) {
            commit('activateLoading', 'stripe/setDatasetRunLimit', { root: true });
            try {
                await api.setDatasetRunLimit({ usageLimit, noLimit });
                commit('profile/setDatasetRunLimit', { usageLimit, noLimit }, { root: true });
            } finally {
                commit('deactivateLoading', 'stripe/setDatasetRunLimit', { root: true });
            }
        },
        async setBillingName({ commit }, billingName) {
            commit('activateLoading', 'stripe/setBillingName', { root: true });
            try {
                await api.setBillingName(billingName);
                commit('profile/setBillingName', billingName, { root: true });
            } finally {
                commit('deactivateLoading', 'stripe/setBillingName', { root: true });
            }
        },
    },
};
