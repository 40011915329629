<template>
    <div>
        <b-form-checkbox
            v-model="status"
            :name="$props.name"
            @input="e => $emit('input', status)"
        />
    </div>
</template>

<script>
export default {
    name: 'BooleanField',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            status: true,
        };
    },
    watch: {
        value(val) {
            if (typeof val === 'boolean' || this.attrs.parameter === 'IS_INCREMENTAL') {
                this.status = val;
            } else {
                if (val === 'true') this.status = true;
                if (val === 'false') this.status = false;
            }
        },
    },
    created() {
        this.status = this.value !== undefined ? this.value : this.attrs.default_value;
    },
};
</script>
