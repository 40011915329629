/* eslint class-methods-use-this: 0 */

import Qs from 'qs';

export default class Manager {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async get(key, defaultValue, store = 'default') {
        const value = await this.adapter.get(store, key);

        if (value === undefined || value === null) {
            return defaultValue;
        }
        return value;
    }

    async set(key, value, store = 'default') {
        await this.adapter.set(store, key, value);
    }

    async remove(key, store = 'default') {
        await this.adapter.remove(store, key);
    }

    async removeAll(store = 'default') {
        await this.adapter.removeAll(store);
    }

    async getPermanent(key, defaultValue) {
        return this.get(key, defaultValue, 'permanent');
    }

    async setPermanent(key, value) {
        return this.set(key, value, 'permanent');
    }

    async removePermanent(key) {
        return this.remove(key, 'permanent');
    }

    async removeAllPermanent() {
        return this.removeAll('permanent');
    }

    paramsToStr(params) {
        const sort = (a, b) => a.localeCompare(b);
        const query = Qs.stringify(params, { arrayFormat: 'brackets', sort });

        return query.length ? `?${query}` : query;
    }

    async getResponse({ url, baseURL, cache, params = {} }) {
        const path = cache.key || this.uriToKey(cache, baseURL, url, params);
        const query = this.paramsToStr(params);

        return this.get(path + query);
    }

    async saveResponse({ url, baseURL, cache, params }, value) {
        const path = cache.key || this.uriToKey(cache, baseURL, url, params);
        const query = this.paramsToStr(params);

        await this.set(path + query, value);
        await this.adapter.addKey(path, cache.permanent ? 'permanent' : 'default');
    }

    uriToKey({ url }, baseURL = '/avora', uri) {
        const path = url || uri;

        if (_.startsWith(path, baseURL)) {
            return path;
        }
        return baseURL.concat('/', path);
    }
}
