<template>
    <div>
        <div class="text-header-info p-2" style="max-width: 500px;">We will create a job for each selected resource. You will configure each job in the configuration step.</div>
        <div class="mt-3 p-2">
            <div class="d-flex flex-row no-wrap justify-content-start">
                <button class="btn pl-0 btn-link" :disabled="allSelected" @click="toggleAll(true)">Select all</button>
                <button class="btn btn-link" :disabled="noneSelected" @click="toggleAll(false)">Deselect all</button>
                <loading-btn :listen="['dataSets/fetchAvailableBulkJobs']" class="btn btn-link ml-auto" @click="onRefresh">Refresh</loading-btn>
            </div>
        </div>
        <div class="mt-2 p-2 " style="overflow-x: auto;">
            <div v-for="(option, $index) in allowedOptions" :key="$index" class="clickable group-item d-flex flex-row no-wrap py-2" @click.prevent.stop="onSelect(option)">
                <div class="custom-control custom-checkbox custom-checkbox-md text-left text-select-none">
                    <input :id="$index" :checked="isSelected(option)" type="checkbox" class="custom-control-input">
                    <label class="custom-control-label text-left user-select-none m-auto" :for="$index">{{ option.label }}</label>
                </div>
                <!-- <div class="text-left">
                    <span class="align-middle" style="overflow: hidden; white-space: nowrap;">
                        {{ option.label }}
                    </span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SourceTableSelection',
    props: {
        allowedOptions: {
            type: Array,
            default: () => {},
        },
        selected: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        noneSelected() {
            return _.isEmpty(this.selected);
        },
        isSelected() {
            return (option) => {
                return _.includes(this.selected, option);
            };
        },
        indeterminate() {
            return this.selected.length > 0
                && this.selected.length < this.allowedOptions.length;
        },
        allSelected() {
            return this.selected.length > 0
                && this.selected.length === this.allowedOptions.length;
        },
    },
    watch: {
        allowedOptions: {
            deep: true,
            handler() {
                this.toggleAll(false);
            },
        },
    },
    methods: {
        toggleAll(selected) {
            if (selected) {
                this.$emit('change', this.allowedOptions);
            } else {
                this.$emit('change', []);
            }
        },
        onSelect(option) {
            if (_.includes(this.selected, option)) {
                this.$emit('change', _.without(this.selected, option));
            } else {
                this.$emit('change', _.concat(this.selected, option));
            }
        },
        onRefresh() {
            this.$emit('refresh');
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: rotate 2s infinite cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-direction: alternate;
}

</style>
