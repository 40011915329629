import Vue from 'vue';
import * as api from '@/store/api/connection.api';

import { DataSourceConnection } from '@/store/models/dataConnection.model';
import { DataSourceConfiguration } from '@/store/models/dataConfiguration.model';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        availableDataSources: {},
        myDataSources: {},
        dataSourcesConfigurations: {},
        sourcesExtraConfig: {},
    },
    getters: {
        availableDataSources(state) {
            return state.availableDataSources;
        },
        myDataSources(state) {
            return state.myDataSources;
        },
        dataSourcesConfigurations(state) {
            return state.dataSourcesConfigurations;
        },
        sourcesExtraConfig(state) {
            return state.sourcesExtraConfig;
        },
    },
    mutations: {
        setAvailableDataSources(state, sources) {
            _.each(sources, (source) => {
                Vue.set(state.availableDataSources, source.code, source);
            });
        },
        setMyDataSources(state, connections) {
            _.each(connections, (connection) => {
                Vue.set(state.myDataSources, connection.id, connection);
            });
        },
        setDataSourceConnection(state, connection) {
            Vue.set(state.myDataSources, connection.id, connection);
        },
        deleteAllDataSourceConnections(state) {
            Vue.set(state, 'myDataSources', {});
        },
        deleteDataSourceConnection(state, connectionId) {
            Vue.delete(state.myDataSources, connectionId);
        },
        setDataSourceConfiguration(state, configuration) {
            Vue.set(state.dataSourcesConfigurations, configuration.code, configuration);
        },
        setSourcesExtraConfig(state, configurations) {
            _.each(configurations, (config) => {
                Vue.set(state.sourcesExtraConfig, _.get(config, 'code'), config);
            });
        },
    },
    actions: {
        async fetchAvailableDataSources({ commit }, { refresh = false, external = false }) {
            commit('activateLoading', 'dataSources/fetchAvailableDataSources', { root: true });
            try {
                const sources = await api.fetchAvailableDataSources(refresh, external);
                // const sources = _.map(dataSources, (source) => {
                //     return new DataSource(source);
                // });
                commit('setAvailableDataSources', sources);
                return sources;
            } finally {
                commit('deactivateLoading', 'dataSources/fetchAvailableDataSources', { root: true });
            }
        },
        async fetchMyDataSources({ commit }, refresh = false) {
            commit('activateLoading', 'dataSources/fetchMyDataSources', { root: true });
            try {
                const { data } = await api.fetchMyDataSources({}, refresh);
                const connections = _.map(data, (item) => {
                    return new DataSourceConnection(item);
                });
                if (refresh) {
                    commit('deleteAllDataSourceConnections');
                }
                commit('setMyDataSources', connections);
                return connections;
            } finally {
                commit('deactivateLoading', 'dataSources/fetchMyDataSources', { root: true });
            }
        },
        async fetchDataSourceConfiguration({ commit }, { dataSourceCode, external = false }, refresh = false) {
            commit('activateLoading', 'dataSources/fetchDataSourceConfiguration', { root: true });
            try {
                const dataSourceConfig = await api.fetchDataSourceConfiguration(dataSourceCode, refresh, external);
                const configuration = new DataSourceConfiguration(_.first(dataSourceConfig));
                commit('setDataSourceConfiguration', configuration);
                return configuration;
            } finally {
                commit('deactivateLoading', 'dataSources/fetchDataSourceConfiguration', { root: true });
            }
        },
        async saveDataSourceConnection({ commit }, { payload, external }) {
            commit('activateLoading', 'dataSources/saveDataSourceConnection', { root: true });
            try {
                const { data } = await api.saveDataSourceConnection(payload, external);
                const connection = new DataSourceConnection(_.first(data));
                commit('setDataSourceConnection', connection);
                return connection;
            } finally {
                commit('deactivateLoading', 'dataSources/saveDataSourceConnection', { root: true });
            }
        },
        async updateSourceConnection({ commit }, dataSourceConfig) {
            commit('activateLoading', 'dataSources/updateSourceConnection', { root: true });
            try {
                const { data } = await api.updateSourceConnection(dataSourceConfig);
                const connection = new DataSourceConnection(_.first(data));
                commit('setDataSourceConnection', connection);
                return connection;
            } finally {
                commit('deactivateLoading', 'dataSources/updateSourceConnection', { root: true });
            }
        },
        async testDataSourceConnection({ commit }, dataSourceConnection) {
            commit('activateLoading', 'dataSources/testDataSourceConnection', { root: true });
            try {
                const result = await api.testDataSourceConnection(dataSourceConnection);
                switch (result.status) {
                    case 'FINISHED_OK':
                        return {
                            type: 'success',
                            text: 'Success',
                        };
                    case 'ERROR':
                        return {
                            type: 'error',
                            text: result.errMessage,
                        };
                    default:
                        return {};
                }
            } finally {
                commit('deactivateLoading', 'dataSources/testDataSourceConnection', { root: true });
            }
        },
        async publishDataSourceConnection({ commit }, dataSourceConnection) {
            commit('activateLoading', 'dataSources/publishDataSourceConnection', { root: true });
            try {
                const { data } = await api.publishDataSourceConnection(dataSourceConnection);
                const connection = new DataSourceConnection(_.first(data));
                commit('setDataSourceConnection', connection);
                return connection;
            } finally {
                commit('deactivateLoading', 'dataSources/publishDataSourceConnection', { root: true });
            }
        },
        async deleteDataSourceConnection({ commit, dispatch }, dataSourceConnection) {
            commit('activateLoading', 'dataSources/deleteDataSourceConnection', { root: true });
            try {
                const { data } = await api.deleteDataSourceConnection(dataSourceConnection);
                commit('deleteDataSourceConnection', dataSourceConnection.id);
                dispatch('dataSets/fetchMyDataSets', true, { root: true });
                return _.first(data);
            } finally {
                commit('deactivateLoading', 'dataSources/deleteDataSourceConnection', { root: true });
            }
        },
        async fetchSourcesExtraConfiguration({ commit }, external) {
            commit('activateLoading', 'dataSources/fetchSourcesExtraConfiguration', { root: true });
            try {
                const data = await api.fetchConfiguration('source', {}, false, external);
                commit('setSourcesExtraConfig', data);
            } finally {
                commit('deactivateLoading', 'dataSources/fetchSourcesExtraConfiguration', { root: true });
            }
        },
    },
};
