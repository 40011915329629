<template>
    <div :id="controlId">
        <label class="label">{{ attrs.display_name }}</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
        <div class="card border-0">
            <input
                v-model.trim="controlValue"
                class="form-control input__text"
                type="password"
                @input="onChange"
            >
        </div>
    </div>

</template>

<script>
import * as uuid from 'uuid';

export default {
    name: 'FieldPassword',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();

        return {
            controlValue: this.value,
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) this.controlValue = newVal;
            },
        },
    },
    methods: {
        onChange(e) {
            this.controlValue = e.target.value;
            this.$emit('input', e.target.value);
        },
    },
};
</script>
