import moment from 'moment-timezone';
import Authorize from '../../utils/authorize';
import { ROLES } from '../../constants';
import Model from './model';
import Organisation from './organisation.model';
import Cache from '../../utils/cache/legacy';

const defaultPhoto = require('../../assets/img/profile-avatar.png');

export default class UserModel extends Model {
    get organisation() {
        return this.getters['profile/organisation'] || new Organisation({});
    }

    get email() {
        return this.attributes.email || this.attributes.username;
    }

    get fullName() {
        return this.getFullName();
    }

    get locale() {
        return this.attributes.locale || 'en';
    }

    get username() {
        return this.attributes.username || null;
    }

    get photo() {
        if (!this.attributes.photo || this.attributes.photo === 'styles/images/profile-avatar.jpg') {
            return defaultPhoto;
        }
        return this.attributes.photo;
    }

    get hasPhoto() {
        return this.photo !== defaultPhoto;
    }

    get timezone() {
        return this.attributes.timezone || 'UTC';
    }

    set timezone(zone) {
        this.attributes.timezone = zone;
    }

    get role() {
        return _.find(ROLES, { id: this.attributes.role }) || {};
    }

    get groups() {
        return _.filter(this.state.groups.collection, ({ users }) => users.includes(this.id));
    }

    get mfaToken() {
        return !!this.attributes.mfaToken;
    }

    get invitedBy() {
        return this.attributes.user;
    }

    get userService() {
        return !!this.attributes.userService;
    }

    get acceptedInvite() {
        return !!this.attributes.acceptedInvite;
    }

    get techAbility() {
        return this.attributes.techAbility || null;
    }

    set techAbility(techAbility) {
        this.attributes.techAbility = techAbility;
    }

    get showSignupModal() {
        return this.isOrganisationOwner
            && !this.isProfileCompleted;
    }

    get isProfileCompleted() {
        return !!this.lastName
            && !!this.firstName
            && !!this.organisation.name
            && !!this.userRole;
    }

    get isOrganisationOwner() {
        return this.id === _.get(this.organisation, 'ownerId');
    }

    get accountExpired() {
        return !!this.attributes.accountExpired;
    }

    get expirationDate() {
        return this.attributes.expirationDate;
    }

    get lastLoginDateFormatted() {
        const loggedIn = this.getters['profile/user'];

        return moment.utc(this.attributes.lastLoginDate).tz(loggedIn.timezone).format(loggedIn.getDateTimeFormat());
    }

    get lastLoginDate() {
        return this.attributes.lastLoginDate || '';
    }

    get currentLoginDateFormatted() {
        const loggedIn = this.getters['profile/user'];

        return moment.utc(this.attributes.currentLoginDate).tz(loggedIn.timezone).format(loggedIn.getDateTimeFormat());
    }

    get currentLoginDate() {
        return this.attributes.currentLoginDate || '';
    }

    get hasRefreshToken() {
        return !!this.attributes.hasRefreshToken;
    }

    get accountLocked() {
        return !!this.attributes.accountLocked;
    }

    get enabled() {
        return this.attributes.enabled;
    }

    get isAvoraAccount() {
        return false;
    }

    /**
     * Return full user name with some return options
     *
     * @param capitalize        Capitalize first letters of first and last name
     * @param firstNameInitial  Return first name as initial (John becomes J.)
     * @returns {string}
     */
    getFullName(capitalize = false, firstNameInitial = false) {
        if (!this.firstName && !this.lastName) return '';

        let firstName = String(this.firstName || '');
        let lastName = String(this.lastName || '');

        if (capitalize) {
            firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
            lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
        }

        if (firstNameInitial) firstName = `${firstName.charAt(0)}.`;

        return (`${firstName} ${lastName}`).trim();
    }

    getDateFormat() {
        return this.dateFormat || this.organisation.getDateFormat();
    }

    getDateTimeFormat() {
        return `${this.getDateFormat()} HH:mm:ss`;
    }

    get isAdmin() {
        return Authorize.isAdmin(this.role.id);
    }

    get isExternal() {
        // '_export' emails are used for pdf and ppt exports
        return false;
    }

    get isStaff() {
        return false;
    }

    get isInvite() {
        return !this.acceptedInvite;
    }

    get isMember() {
        return true;
    }

    get isDeveloper() {
        return false;
    }

    isDemo() {
        return false;
    }

    get isRestricted() {
        return false;
    }

    get hasExpired() {
        return this.accountExpired || moment(this.expirationDate).diff(moment()) <= 0;
    }

    get expirationString() {
        if (this.passwordExpired) {
            return 'Account deactivated due to password expiration';
        }

        if (this.expirationDate) {
            const expirationDate = moment.utc(this.expirationDate).tz(this.getters['profile/user'].timezone);

            if (this.hasExpired) {
                return `Account expired at ${expirationDate.format(this.getDateTimeFormat())}`;
            }

            return `Has access until ${expirationDate.format(this.getDateTimeFormat())}`;
        }

        return '';
    }

    get isDeactivated() {
        return this.accountExpired && this.accountLocked && !this.enabled;
    }

    timezoneString() {
        if (this.timezone === 'UTC') return this.timezone;

        return `${this.timezone} UTC${moment().tz(this.timezone).format('Z')}`;
    }

    hasDomainAccess() {
        // If email domain is not set everybody will have access
        if (!this.organisation.domain) return false;

        return this.email.endsWith(`@${this.organisation.domain}`);
    }

    canAccess(level) {
        let hasAccess = false;

        if (level === 'domain') {
            hasAccess = this.isAdmin || this.hasDomainAccess();
        } else {
            hasAccess = Authorize.check(level, this.role.id);
        }

        return hasAccess || this.isDeveloper;
    }

    hasPermission(permission) {
        return permission.split(' ').every(item => this.permissions.includes(item));
    }

    hasAnyPermission(permission) {
        return permission.split(' ').some(item => this.permissions.includes(item));
    }

    toJSON() {
        return {
            ...super.toJSON(),
            role: this.role.id,
            // These props are not always available in the API so we have to add them here to become reactive
            techAbility: this.techAbility,
            departmentInsideOrg: this.departmentInsideOrg,
            reasonUsingAvora: this.reasonUsingAvora,
            levelInsideOrg: this.levelInsideOrg,
            userRole: this.userRole,
        };
    }
}
