export function formatNumber(number, symbol, decimals = 2) {
    if (!number) return number;
    if (symbol === '\\u00a3') {
        // eslint-disable-next-line
        symbol = '£';
    }
    return `${symbol || ''}${_.round(number, decimals).toLocaleString()}`;
}

export function formatPercent(number, decimals = 2) {
    if (!number) {
        return number;
    }
    return `${_.round(number, decimals)}%`;
}

export const formatPercentage = formatPercent;

export function summarizeNum(val, symbol = '') {
    let num = parseFloat(_.replace(val, /,/g, '').replace(symbol, ''));

    if (!num) return 0;
    const negative = num < 0;

    let suffix = '';

    num = Math.abs(num);

    if (num > 1000) {
        suffix = 'K';
        num /= 1000;
    }
    if (num > 1000) {
        suffix = 'M';
        num /= 1000;
    }
    if (num > 1000) {
        suffix = 'B';
        num /= 1000;
    }

    if (num > 1000) {
        suffix = 'T';
        num /= 1000;
    }

    if (symbol === '123' || symbol === 'time') {
        symbol = '';
    }

    if (symbol === '%') {
        symbol = '';
        suffix += symbol;
    }

    if (symbol === '\\u00a3') {
        // eslint-disable-next-line
        symbol = '£';
    }
    if (negative) {
        return `-${symbol || ''}${_.round(num, 2)}${suffix}`.trim();
    }

    return `${symbol || ''}${_.round(num, 2)}${suffix}`.trim();
}

export function abs(val) {
    return Math.abs(val);
}
