/* eslint-disable class-methods-use-this */

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import router from '@/router';
import Config from '@/utils/config';
import { _ } from 'core-js';

class SentryService {
    constructor() {
        if (!Config.isLocal) {
            Sentry.init({
                Vue,
                dsn: Config.sentry.appUrl,
                integrations: [
                    new BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        tracingOrigins: Config.sentry.tracingOrigins,
                    }),
                ],
                tracesSampleRate: Config.sentry.tracesSampleRate,
                release: Config.version,
                environment: Config.env,
                beforeSend(event, _hint) {
                    // Modify the event here
                    return event;
                },
            });
        }

        Vue.logger = this;
        Vue.prototype.$logger = this;
    }

    async init(user) {
        return new Promise((resolve, _reject) => {
            if (!Config.isLocal) {
                Sentry.setUser({
                    id: _.get(user, 'id'),
                    email: _.get(user, 'email'),
                    username: _.get(user, 'fullName'),
                    ip_address: '{{auto}}',
                    organisationId: _.get(user.organisation, 'id'),
                    organisationName: _.get(user.organisation, 'name'),
                });
                resolve('loaded');
            } else {
                resolve('skipped');
            }
        });
    }

    notify(err, event) {
        if (Config.isLocal) console.warn(err, event);
        else {
            Sentry.withScope((scope) => {
                scope.setTags({
                    context: _.get(event, 'context'),
                    ..._.get(event, 'metadata', {}),
                });
                scope.setLevel(_.get(event, 'severity', 'info'));
                Sentry.captureException(err);
            });
        }
    }
}

export default new SentryService();
