import store from '../store';

function checkIfCanAccess(el, { value, modifiers }, vnode) {
    let keep = false;
    const user = store.getters['profile/user'];

    if (modifiers.admin) {
        keep = user.canAccess('admin');
    }

    if (modifiers.permission && user.hasAnyPermission(value)) {
        keep = true;
    }

    if (modifiers.level && user.canAccess(value)) {
        keep = true;
    }

    if (!keep) {
        if (vnode.elm.parentElement) {
            vnode.elm.parentElement.removeChild(vnode.elm);
        } else {
            vnode.elm.remove();
        }
        // replace HTMLElement with comment node
        // const comment = document.createComment(' ');
        //
        // Object.defineProperty(comment, 'setAttribute', {
        //     value: () => undefined,
        // });
        //
        // /* eslint-disable no-param-reassign */
        // vnode.elm = comment;
        // vnode.text = ' ';
        // vnode.isComment = true;
        // vnode.context = undefined;
        // vnode.tag = undefined;
        // vnode.data.directives = undefined;
        //
        // if (vnode.componentInstance) {
        //     vnode.componentInstance.$el = comment;
        // }
        //
        // if (el.parentNode) {
        //     el.parentNode.replaceChild(comment, el);
        // }
    }
}

export default {
    bind: checkIfCanAccess,
    inserted: checkIfCanAccess,
    componentUpdated: checkIfCanAccess,
};
