/* eslint no-unused-vars: 0 */
/* eslint class-methods-use-this: 0 */

class NullAdapter {
    get(store, key) {
    
    }

    set(key, value) {
    
    }

    remove(key) {
    
    }

    removeAll() {
    
    }

    keys(store) {
    
    }

    addKey(store) {
    
    }

    removeKey(store) {
    
    }
}

export default new NullAdapter();
