<template>
    <div class="router-view">
        <portal to="header">
            <div class="d-flex flex-column justify-content-start flex-wrap header-content w-100">
                <div style="margin-top: -25px;" class="pb-2">
                    <router-link :to="{ name: 'syncs' }" class="navigation-link exact-link text-dark">
                        Syncs overview
                    </router-link>
                    <span>/</span>
                    <router-link :to="{ name: 'sync-overview', params: { syncId: sync.id } }" class="navigation-link exact-link text-dark">
                        {{ sync.name }}
                    </router-link>
                </div>
                <div class="d-flex flex-row justify-content-start w-100">
                    <h1 class="text-header-md text-dark">
                        {{ sync.name }}
                    </h1>
                    <confirm-n-delete-btn
                        v-can-access.level="'user'"
                        v-b-tooltip.hover.top type="button"
                        class="btn text-danger btn-link ml-auto my-auto"
                        :delete-confirmation="sync.name"
                        message="You are about to delete this sync and all it’s associated jobs. Once you have deleted it, there is no way to recover it."
                        delete-title="Delete forever"
                        @delete="onDeleteSync">
                        <i class="svg-icon icon-trash icon-lg" />
                    </confirm-n-delete-btn>
                    <loading-btn v-can-access.level="'user'" v-b-tooltip.hover="isSyncPaused ? 'Unpause' : 'Pause'" class="my-auto mr-0 btn btn-lg p-0" :listen="[`syncs/updateSyncStatus/${sync.id}`]" hide-when-loading @click.stop>
                        <div class="custom-control custom-switch custom-switch-md">
                            <input :id="`${sync.id}_pause`" :checked="!isSyncPaused" type="checkbox" class="custom-control-input p-0" @input="onTogglePaused(sync.id)">
                            <label :for="`${sync.id}_pause`" class="custom-control-label user-select-none clickable" />
                        </div>
                    </loading-btn>
                </div>
                <div class="d-flex flex-row justify-content-between no-wrap mt-2">
                    <div class="header-card card p-0 border-0 radius-2 w-100">
                        <div class="card-body bg-transparent d-flex justify-content-between p-3">
                            <div class="w-auto d-flex flex-row justify-content-start">
                                <div v-b-tooltip.hover="sync.sourcename" class="card-icon bg-white px-4 py-2">
                                    <img :src="`${assetsStorage}/${sync.sourcecode}.png`" height="40" width="40" class="my-auto mx-2">
                                </div>
                                <div class="my-auto d-flex flex-column mx-3 text-center">
                                    <div>{{ syncSchedule }}</div>
                                    <div class="my-auto d-inline-block">
                                        <i class="svg-icon icon-arrow m-auto" />
                                    </div>
                                    <div class="text-muted">
                                        {{ isSyncPaused ? 'Paused' : customSchedules }}
                                    </div>
                                </div>
                                <div v-b-tooltip.hover="sync.targetname" class="card-icon bg-white px-4 py-2">
                                    <img :src="`${assetsStorage}/${sync.targetcode}.png`" height="40" width="40" class="my-auto mx-2">
                                </div>
                            </div>
                            <button
                                v-can-access.level="'user'"
                                class="btn btn-primary ml-auto main-button"
                                @click="showJobSelection = true">
                                <span class="text-nowrap px-0">
                                    + Add new job(s)
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </portal>

        <loader :listen="['dataSets/fetchMyDataSets']" class="pb-3">
            <div class="job-card-grid px-3">
                <div class="my-auto text-muted job-title">
                    Job name
                </div>
                <div class="text-muted my-auto">
                    Schedule
                </div>
                <div class="text-muted my-auto status-added">
                    Added
                </div>
                <div class="text-muted my-auto status-changed">
                    Changed
                </div>
                <div class="text-muted my-auto status-staged">
                    Staged
                </div>
                <div class="text-muted my-auto status-duration">
                    Duration
                </div>
                <div class="text-muted my-auto">
                    Run History
                </div>
                <div class="text-muted my-auto" />
            </div>

            <router-link v-for="(job, $index) in filteredJobs" :key="$index" :to="{ name: 'job-overview', params: { syncId, jobId: job.id } }" class="job-card card my-3 border-0 clickable" :class="isJobPaused(job.id) ? 'disabled' : 'enabled'">
                <div class="card-body job-card-grid clickable p-3">
                    <div class="my-auto text-left job-title">
                        {{ job.name }}
                    </div>
                    <div class="my-auto">
                        <div class="mh-100">
                            {{ jobSchedule(job) }}
                        </div>
                    </div>
                    <div class="my-auto status-added">
                        <span v-if="lastLogEntry(job.id) && lastLogEntry(job.id).status == 'FINISHED_OK'" class="status-item status-item__default">
                            {{ lastLogEntry(job.id).new_records_count || '0' }}
                        </span>
                        <span v-else-if="!isJobRunning(lastLogEntry(job.id))" class="status-item status-item__default">
                            -
                        </span>
                        <div v-else class="text-bold pl-3 my-2">
                            <div class="dot-pulse" />
                        </div>
                    </div>
                    <div class="my-auto status-changed">
                        <span v-if="lastLogEntry(job.id) && lastLogEntry(job.id).status == 'FINISHED_OK'" class="status-item status-item__default">
                            {{ lastLogEntry(job.id).updated_records_count || '0' }}
                        </span>
                        <span v-else-if="!isJobRunning(lastLogEntry(job.id))" class="status-item status-item__default">
                            -
                        </span>
                        <div v-else class="text-bold pl-3 my-2">
                            <div class="dot-pulse" />
                        </div>
                    </div>
                    <div class="my-auto status-staged">
                        <span v-if="lastLogEntry(job.id) && lastLogEntry(job.id).status == 'FINISHED_OK'" class="status-item status-item__default">
                            {{ lastLogEntry(job.id).staged_records_count || '0' }}
                        </span>
                        <span v-else-if="!isJobRunning(lastLogEntry(job.id))" class="status-item status-item__default">
                            -
                        </span>
                        <div v-else class="text-bold pl-3 my-2">
                            <div class="dot-pulse" />
                        </div>
                    </div>
                    <div class="my-auto status-duration">
                        <template v-if="!isJobRunning(lastLogEntry(job.id))">
                            <div class="p-0 m-0">
                                {{ formatDuration(lastLogEntry(job.id)) }}
                            </div>
                            <div class="text-muted p-0 m-0">
                                {{ formatStartDate(lastLogEntry(job.id)) }}
                            </div>
                        </template>
                        <div v-else class="text-bold pl-3 my-2">
                            <div class="dot-pulse" />
                        </div>
                    </div>
                    <div class="my-auto">
                        <div class="d-flex flex-row justify-content-start no-wrap my-auto">
                            <div v-for="(logEntry, $logIndex) in runLogs(job)" :key="logEntry.run_id" v-b-tooltip.hover.html="detailsTooltip(logEntry)" class="run-line-container" @click.prevent.stop="onShowLogTrace(logEntry, job.id)">
                                <div class="run-line" :class="{ 'success': logEntry.status === 'FINISHED_OK', 'error': logEntry.status === 'ERROR', 'primary': !logEntry.status }" :style="{ height: runLineHeight(runLogs(job), $logIndex) }" />
                            </div>
                        </div>
                    </div>
                    <div class="my-auto">
                        <div class="d-flex flex-row justify-content-center no-wrap">
                            <router-link
                                v-b-tooltip.hover="'View logs'"
                                class="btn btn-link p-0 m-2"
                                :title="'View logs'"
                                :to="{ name: 'logs', query: { name: job.name } }">
                                <i class="svg-icon icon-logs" />
                            </router-link>
                            <loading-btn
                                v-b-tooltip.hover="'Run now'"
                                v-can-access.level="'user'"
                                class="btn btn-link p-0"
                                hide-when-loading
                                :disabled="isJobPaused(job.id) || isJobRunning(lastLogEntry(job.id))"
                                :listen="`dataSets/executeDataSet/${job.id}`"
                                @click.stop>
                                <i class="svg-icon icon-play icon-sm" @click.stop.prevent="onRunJob(job)" />
                            </loading-btn>
                            <loading-btn v-b-tooltip.hover="isJobPaused(job.id) ? 'Unpause' : 'Pause'" :disabled="disableJobToggle(job)" class="my-auto ml-auto  btn btn-sm p-0 mr-2" :listen="[`dataSets/updateJobStatus/${job.id}`]" hide-when-loading @click.stop>
                                <div v-can-access.level="'user'" class="custom-control custom-switch">
                                    <input :id="`${job.id}_pause`" :checked="!isJobPaused(job.id)" type="checkbox" class="custom-control-input p-0" @input="onToggleJobPaused(job)">
                                    <label :for="`${job.id}_pause`" class="custom-control-label user-select-none clickable" />
                                </div>
                            </loading-btn>
                        </div>
                    </div>
                </div>
            </router-link>
        </loader>

        <execution-info
            v-if="showLogTrace"
            key="execution-info"
            :show="showLogTrace"
            :job="traceJob"
            :log="traceLog"
            @close="showLogTrace = false;" />

        <portal to="modals">
            <div v-if="showJobSelection" key="job-selection" v-can-access.level="'user'" class="modals-route">
                <button type="button" class="close-modal-btn btn-light" @click="showJobSelection = false">
                    <i class="svg-icon icon-close" />
                </button>
                <div class="modal-container">
                    <job-selection :sync-id="syncId" @next="onNext" @close="showJobSelection = false" />
                </div>
            </div>
            <div v-if="showJobCreation" key="job-creation" v-can-access.level="'user'" class="modals-route">
                <button type="button" class="close-modal-btn btn-light" @click="showJobCreation = false; jobSettings = null;">
                    <i class="svg-icon icon-close" />
                </button>
                <div class="modal-container">
                    <job-creation :settings="jobSettings" @close="showJobCreation = false; jobSettings = null;" @save="onSaveJobs" />
                </div>
            </div>
        </portal>
    </div>
</template>

<script>
import cronstrue from 'cronstrue';

import { mapActions, mapGetters } from 'vuex';
import { LABELS } from '@/constants';
import executionInfo from '@/modules/datasetHistory/views/execution-info';
import JobSelection from '@/modules/jobs/views/jobSelection';
import JobCreation from '@/modules/jobs/views/jobCreation';
import Config from '@/utils/config';

export default {
    components: { executionInfo, JobSelection, JobCreation },
    props: {
        syncId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            assetsStorage: Config.assetsStorage,
            showLogTrace: false,
            traceLog: null,
            traceJob: null,
            sortBy: 'name',
            sortOrder: 'asc',
            searchTerm: '',
            showJobSelection: false,
            showJobCreation: false,
            jobSettings: null,
        };
    },
    computed: {
        ...mapGetters({
            myJobs: 'dataSets/myDataSets',
            mySources: 'dataSources/myDataSources',
            myDestinations: 'dataTargets/myDataTargets',
            multipleJobsRunHistory: 'runHistory/multipleJobsRunHistory',
            lastLogEntry: 'runHistory/lastDatasetRun',
            mySyncs: 'syncs/mySyncs',
            user: 'profile/user',
        }),
        sync() {
            return _.get(this.mySyncs, this.syncId, {});
        },
        syncJobs() {
            return _.filter(this.myJobs, { syncId: this.syncId });
        },
        filteredJobs() {
            return _(this.syncJobs)
                .filter(({ name }) => {
                    return _.includes(_.toLower(name), _.toLower(this.searchTerm));
                })
                .orderBy(this.sortBy, this.sortOrder)
                .value();
        },
        runLogs() {
            return (job) => {
                return _.takeRight(this.multipleJobsRunHistory([job.id]), 9);
            };
        },
        isJobRunning() {
            return (logEntry) => {
                return logEntry && (_.get(logEntry, 'status', 'RUNNING') === 'RUNNING');
            };
        },
        isSyncPaused() {
            return _.get(this.sync, 'schedule_status', 'enabled') === 'disabled';
        },
        isJobPaused() {
            return (jobId) => {
                return _.get(_.find(this.syncJobs, { id: jobId }), 'schedule_status', 'enabled') !== 'enabled';
            };
        },
        disableJobToggle() {
            return (job) => {
                const currentJobStatus = _.get(job, 'schedule_status', 'enabled');
                return currentJobStatus !== 'enabled' && currentJobStatus !== 'disabled';
            };
        },
        syncSchedule() {
            if (!this.sync.run_frequency) return '';
            return cronstrue.toString(this.sync.run_frequency);
        },
        customSchedules() {
            const jobs = _(this.myJobs)
                .filter(({ syncId }) => {
                    return syncId === this.syncId;
                })
                .value();
            const jobsSchedules = _.map(jobs, ({ parameters }) => {
                return _.get(parameters, 'SCHEDULE_INTERVAL_ANCHOR');
            });
            let customCount;

            if (this.sync.run_frequency) {
                customCount = _.size(_.without(jobsSchedules, this.sync.run_frequency));
            } else {
                customCount = _.size(jobsSchedules);
            }
            return customCount ? `${customCount} custom ${customCount > 1 ? 'schedules' : 'schedule'}` : '';
        },
        jobSchedule() {
            return ({ parameters }) => {
                if (!_.get(parameters, 'SCHEDULE_INTERVAL_ANCHOR')) return '-';
                return cronstrue.toString(_.get(parameters, 'SCHEDULE_INTERVAL_ANCHOR'));
            };
        },
    },
    created() {
        this.$eventHub.$on('search', this.onSearchChanged);
        this.$eventHub.$emit('clearSearch');
        if (_.isEmpty(this.sync)) this.$router.replace({ name: 'syncs' });
    },
    beforeDestroy() {
        this.$eventHub.$off('search', this.onSearchChanged);
    },
    methods: {
        ...mapActions({
            executeDataSet: 'dataSets/executeDataSet',
            deleteSync: 'syncs/deleteSync',
            saveJobs: 'dataSets/saveJobs',
            updateSyncStatus: 'syncs/updateSyncStatus',
            updateJobStatus: 'dataSets/updateJobStatus',
        }),
        onDeleteSync() {
            this.deleteSync(this.syncId)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: LABELS.syncDeleteSuccess,
                    });
                    this.$router.replace({ name: 'syncs' });
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
        async onRunJob(job) {
            try {
                await this.executeDataSet(job);
                this.$notify({
                    type: 'success',
                    text: LABELS.jobExecutionSuccess,
                });
            } catch (e) {
                this.$notify({
                    type: 'error',
                    text: e.message || LABELS.jobExecutionError,
                });
            }
        },
        onSearchChanged(value) {
            this.$set(this, 'searchTerm', value);
        },
        clearSearch() {
            this.searchTerm = '';
        },
        runLineHeight(logs, index) {
            const maxDuration = this.maxDuration(logs);

            const runLogDuration = this.runDuration(logs[index]).asSeconds().toFixed(2);
            const height = _.clamp(runLogDuration * 50 / maxDuration, 5, 50);
            return `${height}px`;
        },
        runDuration(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            const endDatetime = _.get(logEntry, 'end_datetime', Date.now());

            const startDate = this.$moment(startDatetime);
            const endDate = this.$moment(endDatetime);

            return this.$moment.duration(endDate.diff(startDate));
        },
        maxDuration(runLogs) {
            const maxLog = _.maxBy(runLogs, (logEntry) => {
                return this.runDuration(logEntry).asSeconds();
            });
            return this.runDuration(maxLog).asSeconds().toFixed(2);
        },
        detailsTooltip(logEntry) {
            return `
                <div>
                    <div><span>Status: </span><span class="strong ${this.statusClass(logEntry)}">${this.statusText(logEntry)}</span></div>
                    <div><span>Started: </span><span class="strong">${this.formatStartDate(logEntry)}</span></div>
                    <div><span>Duration: </span><span class="strong">${this.formatDuration(logEntry)}</span></div>
                    <div><span>Run Id: </span><span class="strong">${_.get(logEntry, 'run_id')}</span></div>
                </div>
                `;
        },
        statusClass(logEntry) {
            if (logEntry.status === 'FINISHED_OK') return 'text-success';
            if (logEntry.status === 'ERROR') return 'text-danger';
            return 'text-primary';
        },
        statusText(logEntry) {
            if (logEntry.status === 'FINISHED_OK') return 'Success';
            if (logEntry.status === 'ERROR') return 'Error';
            return 'Running';
        },
        formatDuration(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            const endDatetime = _.get(logEntry, 'end_datetime');
            const startDate = this.$moment(startDatetime);
            const endDate = this.$moment(endDatetime);
            const duration = endDate.preciseDiff(startDate);

            return duration || '-';
        },
        formatStartDate(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            if (!startDatetime) return '-';

            return this.$moment.tz(this.$moment(startDatetime), this.user.timezone).calendar(null, {
                sameDay: '[Today at] LTS',
                lastDay: '[Yesterday at] LTS',
                lastWeek: 'ddd [at] LTS',
                sameElse: `${this.user.dateFormat} [at] LTS`,
            });
        },
        onShowLogTrace(logEntry, jobId) {
            this.showLogTrace = true;
            this.traceLog = logEntry;
            this.traceJob = _.get(this.myJobs, jobId, null);
        },
        onNext(settings) {
            this.jobSettings = settings;
            this.showJobCreation = true;
        },
        onSaveJobs(payload) {
            this.saveJobs(payload)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: LABELS.jobCreateSuccess,
                    });
                    this.showJobSelection = false;
                    this.showJobCreation = false;
                    this.jobSettings = null;
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
        onTogglePaused() {
            this.updateSyncStatus({
                id: this.syncId,
                schedule_status: _.get(this.sync, 'schedule_status', 'enabled') === 'enabled'
                    ? 'disabled'
                    : 'enabled',
            })
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: 'Success',
                    });
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
        onToggleJobPaused(job) {
            const currentJobStatus = _.get(job, 'schedule_status', 'enabled');
            if (currentJobStatus === 'enabled' || currentJobStatus === 'disabled') {
                this.updateJobStatus({
                    id: _.get(job, 'id'),
                    name: _.get(job, 'name'),
                    sourcecode: _.get(job, 'sourcecode'),
                    sourcename: _.get(job, 'sourceconnectionname'),
                    targetcode: _.get(job, 'targetcode'),
                    targetname: _.get(job, 'targetconnectionname'),
                    schedule_status: currentJobStatus === 'enabled'
                        ? 'disabled'
                        : 'enabled',
                }).then(() => {
                    this.$notify({
                        type: 'success',
                        text: 'Success',
                    });
                }).catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header-card {
    color: var(--theme-text-color);
    background: #e6e3e0;
}

.card-icon {
    border-radius: $border-radius;
}

.sync-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

.job-card {
    color: var(--theme-text-color);
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    border-radius: $border-radius;
    min-height: 80px;
    width: 100%;

    &:hover {
        text-decoration: none;
        transform: scale(1.02);
    }

    &.disabled {
        opacity: 0.6;
    }
}

.job-card-grid {
    display: grid;
    flex: 0;
    justify-content: space-between;
    gap: 10px;

    @include respond-to('small') {
        grid-template-columns: [name] 1fr [schedule] 200px [history] 100px [buttons] 100px;

        .status-added,
        .status-changed,
        .status-staged,
        .status-duration {
            display: none;
        }
    }

    @include respond-to('medium') {
        grid-template-columns: [name] 1fr [schedule] 200px [duration] 200px [history] 100px [buttons] 100px;

        .status-added,
        .status-changed,
        .status-staged {
            display: none;
        }

        .status-duration {
            display: inline-block;
        }
    }

    @include respond-to('large') {
        grid-template-columns: [name] 1fr [schedule] 200px [added] 80px [changed] 80px [staged] 80px [duration] 200px [history] 100px [buttons] 100px;

        .status-added,
        .status-changed,
        .status-staged,
        .status-duration {
            display: inline-block;
        }
    }
}

.job-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    min-width: 100%;
}

.run-line-container {
    width: 5px;
    max-height: 50px;
    margin: 0 2px;
    height: 45px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    &:hover {
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
        transform: scaleX(1.2);
    }

    .run-line {
        min-height: 5px;
        max-height: 50px;
        margin-bottom: 0;
        margin-top: auto;
        border-radius: 5px;

        &.success {
            background-color: $success-100;
        }

        &.error {
            background-color: $error-50;
        }

        &.primary {
            background-color: $primary-300;
        }
    }
}

.navigation-link {
    font-weight: 700;

    &.router-link-exact-active.exact-link,
    &:not(.exact-link).router-link-active {
        background-color: $primary-50;
        text-decoration: none;
        font-weight: 400;
    }
}
</style>
