var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":("Execution Trace - Job " + (_vm.job ? ("" + (_vm.job.name)) : 'Execution Trace')),"visible":_vm.show,"size":"lg","header-bg-variant":"white","header-text-variant":"default"},on:{"hide":_vm.close},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"text-sub-header font-weight-bold text-break"},[_vm._v("\n            Execution Trace "+_vm._s(_vm.job ? ("- " + (_vm.job.name)) : '')+"\n        ")])]},proxy:true}])},[_c('div',{staticClass:"overflow-auto",staticStyle:{"max-height":"700px"}},[_c('div',{staticClass:"small mt-2"},[_c('div',{staticClass:"mb-2"},[_vm._v("Overall status:\n                "),_c('span',{staticClass:"status-item",class:{
                        'status-item__error': _vm.log.status === 'ERROR',
                        'status-item__success': _vm.log.status === 'FINISHED_OK',
                        'status-item__info': !_vm.log.status }},[_vm._v("\n                    "+_vm._s(_vm.statusLabel(_vm.log.status))+"\n                ")])]),(_vm.job && _vm.lastRunLogUpdate && _vm.log.status)?_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(" Last step, executed on "+_vm._s(_vm.lastRunLogUpdate.startTime)+":")]),_c('span',{staticClass:"text-code",class:{
                          'status-item__error': _vm.log.status === 'ERROR',
                          'status-item__success': _vm.log.status === 'FINISHED_OK',
                          'status-item__info': !_vm.log.status}},[_vm._v(_vm._s(_vm.lastRunLogUpdate.executionLabel))])]):_vm._e()]),(_vm.lastRunLogUpdate && !_vm.log.status)?_c('div',{staticClass:"d-flex small"},[_c('span',[_vm._v("Step currently executing:\n                "),_c('span',{staticClass:"text-code",class:{
                    'status-item__error': _vm.log.status === 'ERROR',
                    'status-item__success': _vm.log.status === 'FINISHED_OK',
                    'status-item__info': !_vm.log.status}},[_vm._v("\n                    "+_vm._s(_vm.lastRunLogUpdate.executionLabel)+"\n                ")]),_vm._v(", started on "),_c('b',[_vm._v(_vm._s(_vm.lastRunLogUpdate.startTime))])]),_c('div',{staticClass:"dot-flashing ml-3 mt-1"})]):_vm._e(),_c('hr'),_c('loader',{attrs:{"listen":("runHistory/fetchExecutionHierarchy/" + _vm.runLogId),"first-time-only":true,"force-loading":_vm.isLoading}},[(_vm.executionTrace)?_c('json-viewer',{attrs:{"json":_vm.executionTrace}}):_c('div',[_vm._v("\n                Execution trace not available\n            ")])],1)],1),_c('template',{slot:"modal-footer"},[_c('div',{staticClass:"d-flex flex-row justify-content-around w-50 mx-auto"},[_c('button',{staticClass:"btn btn-link",on:{"click":_vm.close}},[_vm._v("\n                Close\n            ")]),(_vm.executionTrace)?_c('copy-btn',{staticClass:"btn btn-primary",attrs:{"content":JSON.stringify(_vm.executionTrace)}},[_vm._v("\n                Copy execution\n            ")]):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }