
import Connections from '@/modules/connections/views';
import NewConnectionPage from '@/modules/connections/views/newConnectionPage/index';
import AuthorisationPage from '@/modules/connections/views/authorisationPortal';

export default [
    {
        path: '/connections',
        name: 'connections',
        component: Connections,
        meta: {
            name: 'Connections',
            permissions: [],
            auth: 'view',
        },
    },
    {
        path: '/authorization/:code',
        name: 'authorization',
        component: AuthorisationPage,
        meta: { auth: 'view', hideSearch: true, externalAccess: true, showOnlyPage: true, worksWithLogin: true },
    },
    {
        path: '/connection/add-external',
        name: 'add-connection-external',
        component: NewConnectionPage,
        meta: {
            name: 'Add Connection External',
            permissions: [],
            showOnlyPage: true,
            externalAccess: true,
        },
    },
];
