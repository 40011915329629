function highlight(text, term, highlightClass) {
    const start = text.toLowerCase().indexOf(term.toLowerCase());
    if (start > -1) {
        const stop = start + term.length;

        return [
            text.slice(0, start),
            `<span class="${highlightClass}">${text.slice(start, stop)}</span>`,
            text.slice(stop, text.length),
        ].join('');
    }

    return text;
}

export default {
    bind(el, { value }) {
        el.innerHTML = el.innerText;
        if (value) {
            el.innerHTML = highlight(el.innerText, value, el.attributes['higlight-class'].value || 'highlight');
        }
    },
    componentUpdated(el, { value }) {
        el.innerHTML = el.innerText;
        if (value) {
            el.innerHTML = highlight(el.innerText, value, el.attributes['higlight-class'].value || 'highlight');
        }
    },
};
