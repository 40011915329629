import client from './client';

export async function getNotifications(page = 1, limit = 50) {
    const response = await client.get(`userNotification/all?pageNumber=${page}&pageSize=${limit}`);
    return client.parseResponse(response);
}

export async function getIdentitiesWithAccess(notificationId) {
    const response = await client.get(`userNotification/getIdentitiesWithAccess?notificationId=${notificationId}`);
    return client.parseResponse(response);
}

export async function shareNotification(payload) {
    const response = await client.post('userNotification/shareNotification', payload);
    return client.parseResponse(response);
}

export async function setReactForNotification(payload) {
    const response = await client.post('userNotification/react', payload);
    return client.parseResponse(response);
}

export async function getTotalUnreadNotificationsByUserId(userId) {
    const response = await client.get(`userNotification/totalUnread/${userId}`);
    return client.parseResponse(response);
}

export async function setNotificationsReadStatus(payload) {
    const response = await client.post('userNotification/updateReadStatus', payload);
    return client.parseResponse(response);
}

export async function getAllNotificationSubscriptions() {
    const response = await client.get('userNotification/manageSubscriptions');
    return client.parseResponse(response);
}

export async function editNotificationSubscriptions(payload) {
    const response = await client.post('userNotification/manageSubscriptions', payload);
    return client.parseResponse(response);
}

export async function saveNotificationFilters(payload) {
    const response = await client.post('userNotification/updateFilters', payload);
    return client.parseResponse(response);
}

export async function getNotificationFilters() {
    const response = await client.get('userNotification/filters');
    return client.parseResponse(response);
}

export async function getAllMetrics(payload) {
    const response = await client.post('metric/query', payload);
    return client.parseResponse(response);
}
