<template>
    <div class="vh-100 d-flex w-100 justify-content-center align-items-center">
        <loader :listen="[]" :force-loading="true" loading-class="m-auto" style="height: inherit;" />
    </div>
</template>
<script>
/* eslint-disable import/no-unresolved */

import { mapActions } from 'vuex';
import { webAuth } from '@/utils/auth0';
import Cache from '@/utils/cache/legacy';

export default {
    name: 'Callback',
    props: {
        idToken: {
            type: String,
        },
        accessToken: {
            type: String,
        },
    },
    async created() {
        await this.authentificate().then(() => {
            const redirectUrl = Cache.getRegular('redirectUrl');
            if (redirectUrl) {
                Cache.removeItem('redirectUrl');
                this.$router.replace(redirectUrl);
            } else {
                this.$router.replace({ name: 'home' });
            }
        }).catch(() => {
            this.$router.replace({ name: 'logout' });
        });
    },
    methods: {
        ...mapActions({
            login: 'profile/login',
        }),
        async authentificate() {
            return new Promise((resolve, reject) => {
                webAuth.parseHash((error, authResult) => {
                    if (this.$route.hash.includes('#access_token=')) {
                        sessionStorage.clear();
                        localStorage.removeItem('external_access');
                    }

                    if (this.$route.meta.externalAccess && this.$route.meta.worksWithLogin && !!localStorage.getItem('external_access')) {
                        return resolve();
                    }

                    const token = {};
                    if (authResult && authResult.accessToken && authResult.idToken) {
                        token.accessToken = authResult.accessToken;
                        token.idToken = authResult.idToken;
                        return this.login(token).then(() => {
                            return resolve();
                        }).catch((e) => {
                            console.error(e);
                            return reject(e);
                        });
                    }
                    console.error(error);
                    return reject(error);
                });
            });
        },
    },
};
</script>
