<style src="./switch-btn.scss" lang="scss"></style>

<template>
    <div class="switch-btn" :class="{'disabled' : disabled}">
        <span class="mr-1" :class="[value ? 'inactive' : 'active']">
            <slot name="left" />
        </span>
        <div class="switch" :class="[value ? 'switch-on' : 'switch-off']"
             @click="toggle">
            <span class="slider" />
        </div>
        <span class="ml-1" :class="[value ? 'active' : 'inactive']">
            <slot name="right" />
        </span>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        toggle() {
            if (!this.disabled) {
                this.$emit('input', !this.value);
            }
        },
    },
};
</script>
