<template>
    <div :id="controlId">
        <label class="label">{{ attrs.display_name }}</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
        <div class="card border-0">
            <b-form-file
                v-model="file"
                v-validate="{
                    required: attrs.required,
                }"
                :state="state"
                class="text-left text-truncate custom-control input__file"
                :name="attrs.display_name"
                :placeholder="fileKey ? 'Change file' : 'Choose a file or drop it here...'"
                drop-placeholder="Drop file here..."
                :disabled="attrs.disabled || loading"
                @input="onChange"
            />
        </div>
        <form-control-error :name="attrs.display_name" />
    </div>
</template>

<script>
import { uploadKeyFile } from '@/store/api/connection.api';
import * as uuid from 'uuid';

export default {
    name: 'FieldFileUpload',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();

        return {
            file: [],
            fileKey: this.value,
            error: false,
            loading: false,
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    computed: {
        state() {
            if (this.error) return false;
            if (!this.error && this.fileKey) return true;
            return null;
        },
    },
    methods: {
        async onChange(file) {
            try {
                this.error = false;
                this.loading = true;
                if (file) {
                    const { fileId } = await uploadKeyFile(this.file);
                    this.fileKey = fileId;
                    this.$emit('input', fileId);
                } else {
                    this.fileKey = null;
                    this.error = true;
                    this.file = [];
                }
            } catch (e) {
                this.error = e.message || true;
                this.fileKey = null;
                this.$notify({
                    type: 'error',
                    text: e.message || 'An error has occured. Please try again',
                });
                this.$emit('input', null);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
