<template>
    <div :id="controlId" class="field-boolean mt-4 pt-3">
        <div class="custom-control custom-checkbox custom-checkbox-md">
            <input :id="controlId + '-input'" ref="inputField" v-model="controlValue" :true-value="true" :false-value="false" type="checkbox" class="custom-control-input" @change="onChange">
            <label class="custom-control-label text-center m-auto" :for="controlId + '-input'" />
        </div>
        <label class="label pt-1">{{ attrs.display_name }}</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
    </div>

</template>

<script>
import * as uuid from 'uuid';

export default {
    name: 'FieldBoolean',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();
        return {
            controlValue: this.value,
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) this.controlValue = !!newVal;
            },
        },
    },
    computed: {
        isSelected() {
            return !!this.value;
        },
    },
    methods: {
        onChange() {
            this.$emit('input', this.controlValue);
        },
    },
};
</script>
