<template>
    <div :id="controlId">
        <label class="label">{{ attrs.display_name }}</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
        <multi-select
            v-model="controlValue"
            :options="options"
            :name="attrs.display_name"
            label-field="label"
            value-field="id"
            :disabled="isLoading"
            :searchable="true"
            :show-ids="attrs.details"
            :required="attrs.required"
            :loading="isLoading"
            :show-refresh="attrs.dynamic"
            @refresh="refreshDynamicValues(true)"
            @input="onChange"
        />
    </div>
</template>

<script>
import * as uuid from 'uuid';

export default {
    name: 'FieldOneSelect',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();

        return {
            controlValue: this.value,
            options: [],
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    computed: {
        isLoading() {
            return this.attrs.loading;
        },
    },
    watch: {
        'attrs.accepted_values': {
            deep: true,
            handler() {
                this.formatOptions();
            },
        },
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) this.controlValue = newVal;
            },
        },
    },
    async created() {
        await this.formatOptions();
        if (this.attrs.accepted_values) {
            this.controlValue = this.value;
        }
    },
    methods: {
        onChange(value) {
            this.controlValue = value || '';
            if (this.value !== value) this.$emit('input', value);
        },
        async formatOptions() {
            if (_.isString(this.attrs.accepted_values)) {
                this.options = _(this.attrs.accepted_values)
                    .split(',')
                    .map((value) => {
                        return {
                            id: value,
                            label: value,
                        };
                    })
                    .value();
            } else {
                this.options = _(this.attrs.accepted_values)
                    .map((value) => {
                        if (_.isString(value)) {
                            return {
                                id: value,
                                label: value,
                            };
                        }
                        return value;
                    })
                    .value();
            }
        },
        refreshDynamicValues(refreshValues) {
            this.attrs.refreshAcceptedValues(refreshValues);
        },
    },
};
</script>
