<template>
    <textarea :name="$props.name" class="form-control" :value="value" @input="e => $emit('input', e.target.value)" />
</template>

<script>
export default {
    name: 'TextField',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
