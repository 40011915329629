/* eslint-disable camelcase */
import Vue from 'vue';
import * as api from '@/store/api/runHistory.api';
import _ from 'lodash';
import RunHistory from '../models/runHistory.model';

export default {
    namespaced: true,
    state: {
        runHistory: {},
        runHistoryCount: 0,
        groupedDatasetRunHistory: {},
        runLogUpdate: {},
    },
    getters: {
        lastDatasetRun({ groupedDatasetRunHistory }) {
            return (datasetId) => {
                const datasetHistory = groupedDatasetRunHistory[datasetId];
                return datasetHistory ? _.last(datasetHistory.run_logs) : undefined;
            };
        },
        datasetRunHistory({ runHistory }) {
            return (datasetId) => {
                const datasetHistory = runHistory[datasetId];
                return datasetHistory ? datasetHistory.all().value() : [];
            };
        },
        jobRunHistory({ groupedDatasetRunHistory }) {
            return (jobId) => {
                const jobHistory = _.get(groupedDatasetRunHistory, jobId, {});
                return _.get(jobHistory, 'run_logs', []);
            };
        },
        multipleJobsRunHistory({ groupedDatasetRunHistory }) {
            return (jobIds) => {
                const jobsLogs = _(jobIds)
                    .reduce((logs, jobId) => {
                        const jobHistory = _.get(groupedDatasetRunHistory, jobId, {});
                        const runLogs = _.map(_.get(jobHistory, 'run_logs', []), (runLog) => {
                            return {
                                jobName: _.get(jobHistory, 'name', ''),
                                ...runLog,
                            };
                        });
                        return runLogs ? _.concat(logs, runLogs) : logs;
                    }, []);
                return _.orderBy(jobsLogs, 'start_datetime', 'asc');
            };
        },
        fullRunHistory({ runHistory }) {
            return _(runHistory)
                .flatMap((datasetHistory) => {
                    return datasetHistory.all().value();
                })
                .orderBy('start_datetime', 'desc')
                .value();
        },
        runHistoryCount({ runHistoryCount }) {
            return runHistoryCount;
        },
        groupedDatasetRunHistory({ groupedDatasetRunHistory }) {
            const { completed = [], issues = [], pending = [] } = _.groupBy(groupedDatasetRunHistory, (datasetRunHistory) => {
                const status = _.get(_.last(datasetRunHistory.run_logs), 'status', '');
                if (status === 'ERROR') return 'issues';
                if (status === 'FINISHED_OK') return 'completed';
                return 'pending';
            });

            return { completed, issues, pending };
        },
        runLogUpdate({ runLogUpdate }) {
            return runLogUpdate;
        },
    },
    mutations: {
        addLogEntry({ runHistory }, {
            name, sourcecode, sourceconnectionname, feedrunlog, organisationid, datasetId, targetcode, targetconnectionname, source_icon, target_icon,
        }) {
            const id = datasetId || `${sourcecode}_${name}_${sourceconnectionname || targetconnectionname}`;
            const newRunHistory = _.get(runHistory, id, new RunHistory());
            newRunHistory.add({
                name,
                sourcecode,
                sourceconnectionname,
                source_icon,
                targetcode,
                targetconnectionname,
                target_icon,
                organisationid,
                datasetId: id,
                ...feedrunlog,
                status: feedrunlog.status || 'RUNNING',
            });
            Vue.set(runHistory, id, new RunHistory(newRunHistory.entries));
        },
        addLogEntryToGroupedHistory({ groupedDatasetRunHistory }, { name, sourcecode, sourceconnectionname, feedrunlog, targetcode, targetconnectionname, source_icon, target_icon }) {
            const id = `${sourcecode}_${name}_${sourceconnectionname || targetconnectionname}`;
            const datasetRunHistory = _.get(groupedDatasetRunHistory, id, {
                name,
                id,
                run_logs: [],
                source: {
                    code: sourcecode,
                    name: sourceconnectionname,
                    icon: source_icon,
                },
                target: {
                    code: targetcode,
                    name: targetconnectionname,
                    icon: target_icon,
                },
            });

            let prevRunLog;
            if (feedrunlog.status && feedrunlog.status !== 'RUNNING') prevRunLog = _.filter(datasetRunHistory.run_logs, 'status');
            else prevRunLog = datasetRunHistory.run_logs;

            datasetRunHistory.run_logs = _.takeRight([...prevRunLog, feedrunlog], 20);
            Vue.set(groupedDatasetRunHistory, id, datasetRunHistory);
        },
        deleteRunHistory(state) {
            Vue.set(state, 'runHistory', {});
        },
        runHistoryCount(state, count) {
            Vue.set(state, 'runHistoryCount', _.parseInt(count));
        },
        addLogRunUpdate(state, update) {
            Vue.set(state.runLogUpdate, update.runId, update);
        },
        setGroupedDatasetRunLog(state, groupedRunLog) {
            const reversedRunLog = _(groupedRunLog)
                .map((datasetRunLog) => {
                    const runLogs = _.reverse(datasetRunLog.run_logs);
                    return {
                        ...datasetRunLog,
                        run_logs: runLogs,
                        id: `${datasetRunLog.source.code}_${datasetRunLog.name}_${datasetRunLog.source.name || datasetRunLog.target.name}`,
                    };
                })
                .reduce((result, details) => {
                    return {
                        ...result,
                        [details.id]: details,
                    };
                }, {});
            Vue.set(state, 'groupedDatasetRunHistory', reversedRunLog);
        },
    },
    actions: {
        async fetchGroupedDatasetRunLogs({ commit }, refresh) {
            commit('activateLoading', 'runHistory/fetchGroupedDatasetRunLogs', { root: true });
            try {
                const groupedRunLog = await api.fetchGroupedDatasetRunLogs(refresh);
                commit('setGroupedDatasetRunLog', groupedRunLog);
                return groupedRunLog;
            } finally {
                commit('deactivateLoading', 'runHistory/fetchGroupedDatasetRunLogs', { root: true });
            }
        },
        async fetchExecutionHierarchy({ commit }, runId) {
            commit('activateLoading', `runHistory/fetchExecutionHierarchy/${runId}`, { root: true });
            try {
                const jsonExecution = await api.fetchExecutionHierarchy(runId);
                return jsonExecution;
            } finally {
                commit('deactivateLoading', `runHistory/fetchExecutionHierarchy/${runId}`, { root: true });
            }
        },
        async fetchDatasetsRunHistory({ commit }, { ...filters }, refresh) {
            commit('activateLoading', 'runHistory/fetchDatasetsRunHistory', { root: true });
            try {
                const result = await api.fetchDatasetRunHistory(filters, refresh);
                const runLogs = _.get(result, 'run_logs', []);
                const totalRunCount = _.get(result, 'total_run_count', 0);

                commit('deleteRunHistory');
                commit('runHistoryCount', totalRunCount);
                _.each(runLogs, (logEntry) => {
                    commit('addLogEntry', {
                        name: logEntry.datasetname,
                        sourcecode: logEntry.sourcecode,
                        sourceconnectionname: logEntry.sourceconnectionname,
                        source_icon: logEntry.source_icon,
                        targetcode: logEntry.targetcode,
                        targetconnectionname: logEntry.targetconnectionname,
                        target_icon: logEntry.target_icon,
                        organisationid: logEntry.organisationid,
                        datasetId: `${logEntry.sourcecode}_${logEntry.datasetname}_${logEntry.sourceconnectionname || logEntry.targetconnectionname}`,
                        feedrunlog: {
                            status: logEntry.status,
                            start_datetime: logEntry.start_datetime,
                            end_datetime: logEntry.end_datetime,
                            error_message: logEntry.error_message,
                            run_id: logEntry.run_id,
                            staged_records_count: logEntry.staged_records_count,
                            updated_records_count: logEntry.updated_records_count,
                            new_records_count: logEntry.new_records_count,
                        },
                    });
                });
            } finally {
                commit('deactivateLoading', 'runHistory/fetchDatasetsRunHistory', { root: true });
            }
        },
    },
};
