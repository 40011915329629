<template>
    <div>
        <slot v-if="$slots.loading && loading" name="loading" />
        <div v-if="!$slots.loading && loading" class="app-loader d-flex flex-fill flex-column m-auto" :class="loaderClasses" style="height: inherit;">
            <div class="ring1 m-auto">
                <div class="ring2">
                    <div class="ring3" />
                </div>
            </div>
            <div v-if="showName" class="mt-2">
                {{ listen }} - {{ time }} sec
            </div>
        </div>
        <slot v-if="!loading" />
    </div>
</template>

<script>

import Config from '@/utils/config';

export default {
    name: 'Loader',
    props: {
        listen: {
            type: [Array, String],
            required: true,
        },
        loaderClass: {
            type: String,
            default: '',
        },
        firstTimeOnly: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        medium: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        forceLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            alreadyRan: false,
            time: 0,
            timer: undefined,
        };
    },
    computed: {
        loading() {
            let loading = false;

            if (typeof this.listen === 'string') {
                loading = this.$store.state.loading[this.listen];
            } else if (this.listen instanceof Array) {
                loading = this.listen.some(listenTo => this.$store.state.loading[listenTo]);
            }
            return this.forceLoading || (loading && !(this.alreadyRan && this.firstTimeOnly));
        },
        loaderClasses() {
            let classes = 'align-items-center justify-content-center';

            if (this.left) {
                classes = 'align-items-start justify-content-start';
            }

            if (this.right) {
                classes = 'align-items-end justify-content-end';
            }

            if (this.medium) {
                classes += ' loader-medium';
            }

            if (this.small) {
                classes += ' loader-small';
            }

            return `${classes} ${this.loaderClass}`;
        },
        showName() {
            return false && Config.isLocal;
        },
    },
    watch: {
        loading: {
            immediate: true,
            handler() {
                if (this.loading) {
                    this.alreadyRan = true;
                    this.startTimer();
                } else {
                    this.endTimer();
                }
            },
        },
    },
    methods: {
        startTimer() {
            this.time = 0;
            this.timer = setInterval(() => {
                this.time += 1;
            }, 1000);
        },
        endTimer() {
            clearInterval(this.timer);
        },
    },
};
</script>
