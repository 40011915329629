export default {
    data() {
        return {
            // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false,
        };
    },
    created() {
        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });
        document.addEventListener('swOffline', this.offlineApp);
        // Prevent multiple refreshes
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                console.log('controllerchange');
                if (this.refreshing) return;
                this.refreshing = true;
                // Here the actual reload of the page occurs
                window.location.reload();
            });
        } else {
            this.$logger.notify(new Error('Service worker missing'), {
                severity: 'info',
                context: 'service-worker',
            });
        }
    },
    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            console.log('updateAvailable');
            this.registration = event.detail;
            this.updateExists = true;
            this.$notify({
                closeButton: false,
                positionClass: 'toast-top-center',
                timeOut: 0,
                extendedTimeOut: 0,
                preventDuplicates: true,
                onclick: () => { this.refreshApp(); },
                type: 'success',
                text: 'New update available. Click here to refresh.',
            });
        },

        // Called when the user accepts the update
        refreshApp() {
            console.log('refreshingApp', this.updateExists, 'registration', this.registration);
            this.updateExists = false;
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return;
            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
        offlineApp() {
            this.$notify({
                closeButton: false,
                positionClass: 'toast-top-center',
                timeOut: 0,
                extendedTimeOut: 0,
                preventDuplicates: true,
                type: 'error',
                text: 'Internet connection lost. Please connect to internet to continue your work.',
            });
        },
    },
};
