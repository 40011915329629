import client from './client';

export async function fetchDatasetRunHistory(query = {}, skip) {
    const response = await client.get('selfService/datasetRunHistory', query, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function fetchGroupedDatasetRunLogs(skip) {
    const response = await client.get('selfService/groupedDatasetRunLogs', {}, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function fetchExecutionHierarchy(runid, skip) {
    const response = await client.get('selfService/executionHierarchy', { runid }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}
