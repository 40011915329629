<template>
    <form novalidate role="form" @submit.prevent="">
        <button type="button" class="btn btn-lg btn-primary" @click="showInviteModal = true">
            Add Members
        </button>
        <user-role-changer
            class="input-group-append"
            variant="outline-secondary"
            :hide-caret="true"
            :show-modal="showInviteModal"
            :hide-role-dropdown="true"
            @click.native="validate"
            @hide-modal="showInviteModal = false" />
    </form>
</template>

<script>

import { mapGetters } from 'vuex';
import UserRoleChanger from './user-role-changer';

export default {
    components: { UserRoleChanger },
    data() {
        return {
            showInviteModal: false,
        };
    },
    computed: {
        ...mapGetters({
            organisation: 'profile/organisation',
        }),
    },
    methods: {
        validate() {
            this.$v.$touch();
        },
    },
};
</script>
