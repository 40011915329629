import client from './client';

export async function getEnrollments() {
    const response = await client.get('user/mfaEnrollments');
    return client.parseResponse(response);
}

export async function deleteEnrollment(enrollmentId) {
    const response = await client.get('user/deleteMfaEnrollment', { enrollmentId }, false);
    return client.parseResponse(response);
}

export async function deleteMember(email, owner) {
    const response = await client.post('user/delete', { email, owner });
    return client.parseResponse(response);
}

export async function getAvailableTables(skip) {
    const response = await client.get('table/tablesByOrganisation', {}, { cache: { skip } });
    return client.parseResponse(response);
}

export async function requestRegistration(payload) {
    const response = await client.post('organisation/requestRegistration', payload);
    return client.parseResponse(response);
}

export async function registerOrgAndAssociations(payload, file = null) {
    let response = null;
    if (payload.sourceType === 'BIGQUERY') {
        response = await client.upload('organisation/registerOrgAndAssociations', payload, file);
    } else {
        response = await client.post('organisation/registerOrgAndAssociations', payload);
    }
    return client.parseResponse(response);
}

export async function testConnection(params, file) {
    let response = null;
    if (params.sourceType === 'BIGQUERY') {
        response = await client.upload('organisation/testConnection', params, file);
    } else {
        response = await client.post('organisation/testConnection', params);
    }
    return client.parseResponse(response);
}

export async function checkCode(code) {
    const payload = {
        code,
    };
    const response = await client.post('organisation/checkCode', payload);
    return client.parseResponse(response);
}

export async function getConfigValue(key) {
    const response = await client.get(`system/getConfigValue?key=${key}`);
    return client.parseResponse(response);
}

export async function removeLogo() {
    const response = await client.get('organisation/removeLogo');
    return client.parseResponse(response);
}

export async function uploadLogo(file) {
    const response = await client.upload('organisation/addLogo', { }, file);
    return client.parseResponse(response);
}

export async function uploadIcon(file) {
    const response = await client.upload('organisation/addIcon', {}, file);
    return client.parseResponse(response);
}

export async function removeIcon() {
    const response = await client.get('organisation/removeIcon');
    return client.parseResponse(response);
}

export async function saveOrganisation(org) {
    const params = _.pick(org, [
        'id', 'name', 'location', 'domain', 'email', 'language', 'timezone', 'dateFormat', 'startDayOfWeek', 'numberOfDecimals',
        'showTrailingZeroes', 'showWeekEndDate',
    ]);

    const response = await client.post('organisation/update', params, { cache: { method: 'GET', v2: true, url: '/organisation/info' } });
    return client.parseResponse(response);
}

export async function getFilters(entityId, type) {
    const response = await client.get('userFilter/list', { entityId, type });
    return client.parseResponse(response);
}

export async function saveFilter(entityId, filterType, filter) {
    const response = await client.post('userFilter/save', { entityId, filterType, filter });
    return client.parseResponse(response);
}

export async function removeFilter(id) {
    const response = await client.get(`userFilter/delete/${id}`);
    return client.parseResponse(response);
}

export async function getActiveFeatures() {
    const response = await client.get('permission/activeFeatures');
    return client.parseResponse(response);
}

export async function getAvailablePermissions(skip) {
    const response = await client.get('permission/list', {}, { cache: { v2: true, skip } });
    return client.parseResponse(response);
}

export async function getEntitlements(id, identityType, objectTypes) {
    const payload = {
        identityType,
        objectTypes,
    };
    const response = await client.post(`permission/entitlements/${id}`, payload);
    return client.parseResponse(response);
}

export async function saveEntitlements(id, identityType, payloadSent) {
    const payload = {
        ...payloadSent,
        identityType,
    };
    const response = await client.post(`permission/saveEntitlements/${id}`, payload);
    return client.parseResponse(response);
}

export async function getGroupPermissions(id) {
    const response = await client.get(`permission/getForGroup/${id}`);
    return client.parseResponse(response);
}

export async function saveGroupPermissions(id, permissions) {
    const response = await client.post(`permission/saveForGroup/${id}`, permissions);
    return client.parseResponse(response);
}

export async function updateTheme(themeSettings) {
    const response = await client.post('organisation/saveTheme', { themeSettings }, {
        cache: { v2: true, method: 'GET', url: '/organisation/info' },
    });
    return client.parseResponse(response);
}

export async function getOrganisation(externalAccess = false) {
    const response = await client.get('organisation/info', {}, { cache: { skip: true, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function updateProfileImage(email, file) {
    const response = await client.upload('user/uploadPhoto', email, file);
    return client.parseResponse(response);
}

export async function setMfa(status) {
    const response = await client.get('user/setMfa', { status });
    return client.parseResponse(response);
}

export async function getIntegrations() {
    const response = await client.get('integration/getAll');
    return client.parseResponse(response);
}

export async function getApiToken(skip) {
    const response = await client.get('user/getUserAPIKey', {}, { cache: { skip } });
    return client.parseResponse(response);
}

export async function generateApiToken(skip) {
    const response = await client.get('user/generateUserAPIKey', {}, { cache: { skip } });
    return client.parseResponse(response);
}

export async function changePassword(passwords) {
    const response = await client.post('user/resetPassword', passwords);
    return client.parseResponse(response);
}

export async function getByEmail(email, skip) {
    const response = await client.get('user/retrieve', { email }, { cache: { skip } });
    return client.parseResponse(response);
}

export async function getProfile(skip, external) {
    const response = await client.get('user/info', {}, { cache: { skip, permanent: true, key: 'user' } }, external);
    return client.parseResponse(response);
}

export async function login(token, externalAccess = false) {
    return client.post('user/login', token,
        {
            cache: {
                permanent: true,
                key: 'user',
            },
        }, externalAccess)
        .then(({ data }) => {
            if (data.id && data.organisation) {
                return data;
            }
            return Promise.reject(_.isString(data) ? { message: data } : data);
        });
}

export async function updateUser(profile) {
    const params = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        reasonUsingAvora: profile.reasonUsingAvora,
        departmentInsideOrg: profile.departmentInsideOrg,
        levelInsideOrg: profile.levelInsideOrg,
        techAbility: profile.techAbility,
        username: profile.username,
        locale: profile.locale,
        timezone: profile.timezone,
        dateFormat: profile.dateFormat,
        shouldReceiveFailureNotificationEmail: profile.shouldReceiveFailureNotificationEmail || false,
    };

    const response = await client.post('user/update', params);
    return client.parseResponse(response);
}

export async function createIntegration(payload) {
    const response = await client.post('integration/create', payload);
    return client.parseResponse(response);
}

export async function editIntegration({ id, payload }) {
    const response = await client.post(`integration/update/${id}`, { ...payload });
    return client.parseResponse(response);
}

export async function deleteIntegration(id) {
    const response = await client.delete(`integration/delete/${id}`);
    return client.parseResponse(response);
}

export async function integrationCreate(data) {
    const response = await client.post('integration/create', data);
    return client.parseResponse(response);
}

export async function getAllGroups() {
    const response = await client.get('group/list');
    return client.parseResponse(response);
}

export async function saveGroup(group) {
    const response = await client.post('group/save', group);
    return client.parseResponse(response);
}

export async function deleteGroup(id) {
    const response = await client.get(`group/delete/${id}`);
    return client.parseResponse(response);
}

export async function inviteUser(params) {
    const response = await client.post('user/inviteUser', params);
    return client.parseResponse(response);
}

// TODO: Find the duplicates between profile.api and users.api
export async function cancelInvite(email) {
    const response = await client.post('user/cancelInvitationByExistingUser', { email });
    return client.parseResponse(response);
}

export async function resendInvite(email) {
    const response = await client.post('user/resendInvite', { email });
    return client.parseResponse(response);
}

export async function getAllNotificationIntegrations() {
    const response = await client.get('user/integrations');
    return client.parseResponse(response);
}

export async function addNotificationIntegration(payload) {
    const response = await client.post('user/addIntegration', payload);
    return client.parseResponse(response);
}

export async function removeNotificationIntegration(integrationType) {
    const response = await client.delete('user/removeIntegration', {}, { data: { integrationType } });
    return client.parseResponse(response);
}

export async function manageJobScheduling(payload) {
    const response = await client.post('organisation/manageJobScheduling', payload);
    return client.parseResponse(response);
}

export async function logNotification(payload) {
    const response = await client.post('utm/logNotification', payload);
    return client.parseResponse(response);
}

export async function saveExtraDetails(payload) {
    const response = await client.post('user/saveExtraDetails', payload);
    return client.parseResponse(response);
}
