/* eslint-disable import/no-cycle */
import Vue from 'vue';
import router from '@/router';
import { webAuth } from '@/utils/auth0';

export default {
    namespaced: true,
    state: {
        params: {},
        loggedIn: false,
    },
    getters: {
    },
    mutations: {
        setData(state, { loggedIn }) {
            if (_.isBoolean(loggedIn)) Vue.set(state, 'loggedIn', loggedIn);
        },
        setParams(state, params) {
            Vue.set(state, 'params', params);
        },
    },
    actions: {
        handleCallback() {
            return new Promise((resolve, reject) => {
                webAuth.parseHash((error, authResult) => {
                    const token = {};
                    if (authResult && authResult.accessToken && authResult.idToken) {
                        token.accessToken = authResult.accessToken;
                        token.idToken = authResult.idToken;
                        return resolve({ token });
                    }
                    console.log(error);
                    return reject(error);
                });
            });
        },
        async auth0HandleAuthentication({ dispatch }) {
            const result = await dispatch('handleCallback');
            await dispatch('profile/login', result.token, { root: true });
            router.replace('/');
        },
    },
};
