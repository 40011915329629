<template>
    <multi-select :value="selected" :options="dateFormats" value-field="format" @input="update">
        <template slot="singleLabel" slot-scope="{option}">
            {{ option.name }}
            <small class="float-right">{{ option.label }}</small>
        </template>
        <template slot="option" slot-scope="{option}">
            {{ option.name }}
            <small class="float-right">{{ option.label }}</small>
        </template>
    </multi-select>
</template>

<script>
import moment from 'moment';
import Config from '../../../../utils/config';

export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        inheritable: {
            type: Boolean,
            default: false,
        },
        inheritFormat: {
            type: String,
            default: Config.dateFormats.regular,
        },
        inheritLabel: {
            type: String,
            default: 'Inherit',
        },
    },
    data() {
        return {
            selected: '',
            dateFormats: Config.dateFormats.options.map(item => ({
                format: item.format,
                name: item.format,
                label: moment().format(item.format),
            })),
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.selectValue(value);
            },
        },
    },
    created() {
        if (this.inheritable) {
            this.dateFormats.unshift({
                format: null,
                name: this.inheritLabel,
                label: moment().format(this.inheritFormat),
            });

            this.selectValue(this.value);
        }
    },
    methods: {
        update(format) {
            this.$emit('input', format);
        },
        selectValue(value) {
            this.selected = (this.dateFormats.find(item => item.format === value) || {}).format;
        },
    },
};
</script>
