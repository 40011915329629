import Vue from 'vue';

import Cache from '@/utils/cache/legacy';
import Config from '@/utils/config';
import * as api from '@/store/api/profile.api';
import { IntercomService } from '@/utils/services';
import client from '@/store/api/client';
import Auth from '@/utils/auth';
import Organisation from '@/store/models/organisation.model';
import User from '../models/user.model';

export default {
    namespaced: true,
    state: {
        user: {},
        tempUser: {},
        organisation: {},
        apiKey: { token: undefined },
        enrollments: [],
        permissions: [],
        notificationIntegrations: {},
        jobScheduling: {},
        configValue: {},
        loggedIn: false,
    },
    getters: {
        accessToken() {
            return window.localStorage.getItem('access_token') || undefined;
        },
        profileIsLoaded({ user, organisation }) {
            return !!(user.id && organisation.id);
        },
        user({ user }, getters, rootState, rootGetters) {
            return new User(user, rootState, rootGetters);
        },
        tempUser(state) {
            return state.tempUser;
        },
        organisation({ organisation }, getters, rootState, rootGetters) {
            return new Organisation(organisation, rootState, rootGetters);
        },
        notificationIntegrations(state) {
            return state.notificationIntegrations;
        },
        configValue(state) {
            return state.configValue;
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setTempUserDetails(state, user) {
            state.tempUser = user;
        },
        setProfilePhoto(state, photo) {
            state.user.photo = photo;
        },
        setApiToken(state, token) {
            state.apiKey.token = token;
        },
        setUseMfa(state, enabled) {
            state.user.mfaToken = enabled;
        },
        setOrganisation(state, organisation) {
            state.organisation = organisation;
        },
        setOrganisationLogo(state, logo) {
            state.organisation.logo = logo;
        },
        setOrganisationIcon(state, icon) {
            state.organisation.icon = icon;
        },
        setUserPermissions(state, permissions) {
            state.permissions = permissions;
        },
        setEnrollments(state, enrollments) {
            state.enrollments = enrollments;
        },
        addIntegration(state, integration) {
            state.organisation.integrations.push(integration);
        },
        editIntegration(state, integration) {
            state.organisation.integrations = _.map(state.organisation.integrations, (item) => {
                return item.id === integration.id ? integration : item;
            });
        },
        removeIntegration(state, id) {
            const index = _.findIndex(state.organisation.integrations, { id });
            if (index > -1) {
                state.organisation.integrations.splice(index, 1);
            }
        },
        setNotificationIntegrations(state, notificationIntegrations) {
            state.notificationIntegrations = notificationIntegrations;
        },
        deleteNotificationIntegration(state, integrationType) {
            state.notificationIntegrations[integrationType].configured = false;
        },
        setJobScheduling(state, jobScheduling) {
            state.jobScheduling = jobScheduling;
        },
        setConfigValue(state, configValue) {
            state.configValue = configValue;
        },
        setLoggedIn(state, value) {
            Vue.set(state, 'loggedIn', value);
        },
        updateSubscription(state, subscriptionData) {
            Vue.set(state.organisation, 'accountStripeDetails', subscriptionData);
        },
        setDatasetRunLimit(state, { usageLimit, noLimit }) {
            Vue.set(state.organisation.accountStripeDetails, 'usageLimit', usageLimit);
            Vue.set(state.organisation.accountStripeDetails, 'noLimit', noLimit);
        },
        setBillingName(state, billingName) {
            Vue.set(state.organisation.accountStripeDetails, 'billingName', billingName);
        },
        setExtraDetails(state, extraDetails) {
            Vue.set(state.organisation.accountStripeDetails, 'billingName', _.get(extraDetails, 'organisationName'));
            Vue.set(state.organisation, 'name', _.get(extraDetails, 'organisationName'));
            Vue.set(state.user, 'firstName', _.get(extraDetails, 'firstName'));
            Vue.set(state.user, 'lastName', _.get(extraDetails, 'lastName'));
            Vue.set(state.user, 'userRole', _.get(extraDetails, 'userRole'));
        },
    },
    actions: {
        async updateProfile({ commit, state }, user) {
            commit('activateLoading', 'profile/updateProfile', { root: true });
            try {
                await api.updateUser(user);
                user.photo = state.user.photo;
                commit('setUser', user);
                Cache.saveResponse({ cache: { permanent: true, key: 'user', compress: true } }, JSON.stringify(state.user));
            } finally {
                commit('deactivateLoading', 'profile/updateProfile', { root: true });
            }
        },
        async login({ commit, dispatch }, params) {
            let loggedInSuccess = false;
            commit('activateLoading', 'auth/authentificate', { root: true });
            const token = {
                token: params.accessToken,
                jwt: params.idToken,
            };

            try {
                const response = await client.withoutToken().post('user/login', token, {
                    cache: {
                        permanent: true,
                        skip: true,
                        key: 'user',
                    },
                });
                const user = await client.parseResponse(response);
                if (user.tokenValue && user.jwtToken) {
                    Auth.setTokens({ accessToken: user.tokenValue, idToken: user.jwtToken });
                }
                loggedInSuccess = true;

                dispatch('getOrganisation');
                commit('setUser', user);
            } finally {
                commit('setLoggedIn', loggedInSuccess);
                commit('deactivateLoading', 'auth/authentificate', { root: true });
            }
        },
        async logout({ commit }) {
            commit('setOrganisation', {});
            commit('setUser', {});
            Auth.clearTokens();
            IntercomService.reset();
        },
        async getUser({ commit }, external) {
            commit('activateLoading', 'profile/getUser', { root: true });
            try {
                const user = await api.getProfile(true, external);
                commit('setUser', user);
                if (!external) commit('setLoggedIn', true);
            } finally {
                commit('deactivateLoading', 'profile/getUser', { root: true });
            }
        },
        async getConfigValue({ commit }, key) {
            const configValue = await api.getConfigValue(key);
            commit('setConfigValue', configValue);
        },
        async getOrganisation({ commit }, external = false) {
            commit('activateLoading', 'profile/getOrganisation', { root: true });
            try {
                const org = await api.getOrganisation(external);
                commit('setOrganisation', org);
            } finally {
                commit('deactivateLoading', 'profile/getOrganisation', { root: true });
            }
        },
        async checkCode({ commit }, code) {
            commit('activateLoading', 'profile/checkCode', { root: true });
            try {
                const response = await api.checkCode(code);
                commit('setTempUserDetails', response);
            } finally {
                commit('deactivateLoading', 'profile/checkCode', { root: true });
            }
        },
        async registerOrgAndAssociations({ commit }, { params, file }) {
            commit('activateLoading', 'profile/registerOrgAndAssociations', { root: true });
            try {
                await api.registerOrgAndAssociations(params, file);
            } finally {
                commit('deactivateLoading', 'profile/registerOrgAndAssociations', { root: true });
            }
        },
        async requestRegistration({ commit }, userData) {
            commit('activateLoading', 'profile/requestRegistration', { root: true });
            try {
                await api.requestRegistration(userData);
            } finally {
                commit('deactivateLoading', 'profile/requestRegistration', { root: true });
            }
        },
        async testConnection({ commit }, { params, file }) {
            commit('activateLoading', 'profile/testConnection', { root: true });
            try {
                await api.testConnection(params, file);
            } finally {
                commit('deactivateLoading', 'profile/testConnection', { root: true });
            }
        },
        async getEnrollments({ commit }) {
            commit('activateLoading', 'profile/getEnrollments', { root: true });
            try {
                const { enrollments } = await api.getEnrollments();
                commit('setEnrollments', enrollments);
            } finally {
                commit('deactivateLoading', 'profile/getEnrollments', { root: true });
            }
        },
        async deleteEnrollment({ dispatch }, id) {
            await api.deleteEnrollment(id);
            dispatch('getEnrollments');
        },
        async uploadLogo({ commit, state }, file) {
            commit('activateLoading', 'profile/updateLogo', { root: true });
            try {
                const { filePath } = await api.uploadLogo(file);
                commit('setOrganisationLogo', filePath);
                const config = { baseURL: Config.baseURL, cache: { method: 'GET', compress: true, url: '/organisation/info' } };
                Cache.saveResponse(config, JSON.stringify(state.organisation));
            } finally {
                commit('deactivateLoading', 'profile/updateLogo', { root: true });
            }
        },
        async removeLogo({ commit, state }) {
            commit('activateLoading', 'profile/updateLogo', { root: true });
            try {
                await api.removeLogo();
                commit('setOrganisationLogo', null);
                const config = { baseURL: Config.baseURL, cache: { method: 'GET', compress: true, url: '/organisation/info' } };
                Cache.saveResponse(config, JSON.stringify(state.organisation));
            } finally {
                commit('deactivateLoading', 'profile/updateLogo', { root: true });
            }
        },
        async uploadIcon({ commit, state }, file) {
            commit('activateLoading', 'profile/updateIcon', { root: true });
            try {
                const { filePath } = await api.uploadIcon(file);
                commit('setOrganisationIcon', filePath);
                const config = { baseURL: Config.baseURL, cache: { method: 'GET', compress: true, url: '/organisation/info' } };
                Cache.saveResponse(config, JSON.stringify(state.organisation));
            } finally {
                commit('deactivateLoading', 'profile/updateIcon', { root: true });
            }
        },
        async removeIcon({ commit, state }) {
            commit('activateLoading', 'profile/updateIcon', { root: true });
            try {
                await api.removeIcon();
                commit('setOrganisationIcon', null);
                const config = { baseURL: Config.baseURL, cache: { method: 'GET', compress: true, url: '/organisation/info' } };
                Cache.saveResponse(config, JSON.stringify(state.organisation));
            } finally {
                commit('deactivateLoading', 'profile/updateIcon', { root: true });
            }
        },
        async updateOrganisation({ commit }, data) {
            commit('activateLoading', 'profile/updateOrganisation', { root: true });
            try {
                const org = await api.saveOrganisation(data);
                commit('setOrganisation', org);
            } finally {
                commit('deactivateLoading', 'profile/updateOrganisation', { root: true });
            }
        },
        async createIntegration({ commit, state }, payload) {
            commit('activateLoading', 'profile/addIntegration', { root: true });
            try {
                const integration = await api.createIntegration(payload);
                commit('addIntegration', integration);
                const config = { baseURL: Config.baseURL, cache: { method: 'GET', compress: true, url: '/organisation/info' } };
                Cache.saveResponse(config, JSON.stringify(state.organisation));
            } finally {
                commit('deactivateLoading', 'profile/addIntegration', { root: true });
            }
        },
        async editIntegration({ commit, state }, payload) {
            commit('activateLoading', `profile/editIntegration/${payload.id}`, { root: true });
            try {
                const integration = await api.editIntegration(payload);
                commit('editIntegration', integration);
                const config = { baseURL: Config.baseURL, cache: { method: 'GET', compress: true, url: '/organisation/info' } };
                Cache.saveResponse(config, JSON.stringify(state.organisation));
            } finally {
                commit('deactivateLoading', `profile/editIntegration/${payload.id}`, { root: true });
            }
        },
        async deleteIntegration({ commit, state }, integrationId) {
            commit('activateLoading', 'profile/removeIntegration', { root: true });
            try {
                commit('removeIntegration', integrationId);
                await api.deleteIntegration(integrationId);
                const config = { baseURL: Config.baseURL, cache: { method: 'GET', compress: true, url: '/organisation/info' } };
                Cache.saveResponse(config, JSON.stringify(state.organisation));
            } finally {
                commit('deactivateLoading', 'profile/removeIntegration', { root: true });
            }
        },
        async updateTheme({ commit }, theme) {
            commit('activateLoading', 'profile/updateTheme', { root: true });
            try {
                const org = await api.updateTheme(theme);
                commit('setOrganisation', org);
            } finally {
                commit('deactivateLoading', 'profile/updateTheme', { root: true });
            }
        },
        async getApiToken({ commit }) {
            try {
                const { tokenValue } = await api.getApiToken();
                commit('setApiToken', tokenValue);
            } catch (e) {
                commit('setApiToken', '');
            }
        },
        async generateApiToken({ commit }) {
            try {
                const { tokenValue } = await api.generateApiToken();
                commit('setApiToken', tokenValue);
            } catch (e) {
                commit('setApiToken', '');
            }
        },
        async requestPasswordChange({ commit }) {
            commit('activateLoading', 'profile/requestPasswordChange', { root: true });
            try {
                return await api.changePassword();
            } finally {
                commit('deactivateLoading', 'profile/requestPasswordChange', { root: true });
            }
        },
        async toggleMfa({ commit, state }) {
            commit('activateLoading', 'profile/toggleMfa', { root: true });
            try {
                await api.setMfa(!state.user.mfaToken);
                commit('setUseMfa', !state.user.mfaToken);
            } finally {
                commit('deactivateLoading', 'profile/toggleMfa', { root: true });
            }
        },
        async updatePhoto({ commit, state }, photo) {
            commit('activateLoading', 'profile/updatePhoto', { root: true });
            try {
                const { filePath } = await api.updateProfileImage(state.user.email, photo);
                commit('setProfilePhoto', filePath);
                Cache.saveResponse({ cache: { permanent: true, key: 'user', compress: true } }, state.user);
            } finally {
                commit('deactivateLoading', 'profile/updatePhoto', { root: true });
            }
        },
        async getAllNotificationIntegrations({ commit }) {
            commit('activateLoading', 'profile/getNotificationIntegrations', { root: true });
            try {
                const notificationIntegrations = await api.getAllNotificationIntegrations();
                commit('setNotificationIntegrations', notificationIntegrations);
            } catch (err) {
                throw err;
            } finally {
                commit('deactivateLoading', 'profile/getNotificationIntegrations', { root: true });
            }
        },
        async addNotificationIntegration({ commit, dispatch }, payload) {
            commit('activateLoading', 'profile/addNotificationIntegration', { root: true });
            try {
                await api.addNotificationIntegration(payload);
                dispatch('getAllNotificationIntegrations');
            } catch (err) {
                throw err;
            } finally {
                commit('deactivateLoading', 'profile/addNotificationIntegration', { root: true });
            }
        },
        async deleteNotificationIntegration({ commit }, integrationType) {
            commit('activateLoading', 'profile/deleteNotificationIntegration', { root: true });
            try {
                await api.removeNotificationIntegration(_.toUpper(integrationType));
                commit('deleteNotificationIntegration', integrationType);
            } catch (err) {
                throw err;
            } finally {
                commit('deactivateLoading', 'profile/deleteNotificationIntegration', { root: true });
            }
        },
        async manageJobScheduling({ commit }, payload) {
            commit('activateLoading', 'organisation/manageJobScheduling', { root: true });
            try {
                const jobScheduling = await api.manageJobScheduling(payload);
                commit('setJobScheduling', jobScheduling);
            } catch (err) {
                throw err;
            } finally {
                commit('deactivateLoading', 'organisation/manageJobScheduling', { root: true });
            }
        },
        async logNotification({ commit }, payload) {
            commit('activateLoading', 'profile/logNotification', { root: true });
            try {
                await api.logNotification(payload);
            } catch (err) {
                throw err;
            } finally {
                commit('deactivateLoading', 'profile/logNotification', { root: true });
            }
        },
        async saveExtraDetails({ commit }, payload) {
            commit('activateLoading', 'profile/saveExtraDetails', { root: true });
            try {
                await api.saveExtraDetails(payload);
                commit('setExtraDetails', payload);
            } finally {
                commit('deactivateLoading', 'profile/saveExtraDetails', { root: true });
            }
        },
    },
};
