<template>
    <div :id="controlId" class="field-copy-readonly">
        <label v-if="attrs.display_name" class="label">{{ attrs.display_name }}</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
        <div class="input-group border-0">
            <input v-model.trim="controlValue" type="text" class="form-control input__text" readonly>
            <div class="input-group-append">
                <copy-btn :content="controlValue" class="btn btn-primary">
                    Copy
                </copy-btn>
            </div>
        </div>
    </div>

</template>

<script>
import * as uuid from 'uuid';

export default {
    name: 'FieldCopyReadonly',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();

        return {
            controlValue: this.value,
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) this.controlValue = newVal;
            },
        },
    },
    methods: {
        isValid() {
            return true;
        },
    },
};
</script>
