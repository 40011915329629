<template>
    <div>
        <draggable-component :list="list" class="list-group" :options="options" group="folder">
            <div v-for="item in list" :key="item.id" class="list-group-item">
                {{ item.title }}

                <div class="card">
                    <draggable-component :list="item.dashboards" class="list-group" :options="options" group="dash">
                        <div v-for="dash in item.dashboards" :key="dash.id" class="list-group-item">
                            {{ dash.name }}
                        </div>
                    </draggable-component>
                </div>
            </div>
        </draggable-component>
    </div>
</template>

<script>

import DraggableComponent from 'vuedraggable';

export default {
    name: '',
    components: { DraggableComponent },
    data() {
        return {
            list: [],
            options: {
                swapThreshold: 0.5,
            },
        };
    },
    watch: {
        '$store.state.folders.collection': {
            immediate: true,
            deep: true,
            handler(folders) {
                this.list = _.values(folders);
            },
        },
    },
};
</script>
