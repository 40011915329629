<template>
    <loader :listen="['stripe/getAllActiveProducts', 'stripe/getAllActivePrices', 'profile/organisation']" class="router-view">
        <div>
            <div id="pricing-wrapper">
                <div v-if="isSubscriptionActive" class="card border-0">
                    <div class="card-header bg-transparent border-0 d-flex flex-row justify-content-between align-items-center px-5 pt-5">
                        <h4 class="text-header-md">
                            Current Plan
                        </h4>
                    </div>
                    <div class="card-body bg-transparent pt-0 px-5 pb-5">
                        <div class="row w-100 m-auto">
                            <div class="col-auto price-plan__background d-flex flex-row align-items-center">
                                <i class="fa fa-fw fa-tag price-plan__icon" />
                                <div class="price-plan__text-container">
                                    <div class="price-plan__label">
                                        Plan:
                                    </div>
                                    <div class="font-weight-bold price-plan__value">
                                        <!-- {{ accountStripeDetails.planName || '' }} -->
                                        {{ currentPlanName }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto m-auto price-plan__background d-flex flex-row align-items-center">
                                <i class="fa fa-fw fa-check price-plan__icon" />
                                <div class="price-plan__text-container">
                                    <div class="price-plan__label">
                                        Status:
                                    </div>
                                    <div class="font-weight-bold price-plan__value">
                                        Active
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto m-auto price-plan__background d-flex flex-row align-items-center">
                                <i class="fa fa-fw fa-calendar price-plan__icon" />
                                <div class="price-plan__text-container">
                                    <div class="price-plan__label">
                                        Renewal:
                                    </div>
                                    <div class="font-weight-bold price-plan__value">
                                        {{ endDate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto m-auto price-plan__background d-flex flex-row align-items-center">
                                <i class="fa fa-fw fa-warehouse-alt price-plan__icon" />
                                <div class="price-plan__text-container">
                                    <div class="price-plan__label">
                                        Plan Limit:
                                    </div>
                                    <div class="font-weight-bold price-plan__value">
                                        <!-- {{ accountStripeDetails.noLimit ? 'Unlimited' : `${accountStripeDetails.usageLimit} GB` }} -->
                                        {{ accountStripeDetails.monthlyAlottedDatasetRuns }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto m-auto price-plan__background d-flex flex-row align-items-center">
                                <i class="fa fa-fw fa-battery-half price-plan__icon" />
                                <div class="price-plan__text-container">
                                    <div class="price-plan__label">
                                        Plan Used:
                                    </div>
                                    <div class="font-weight-bold price-plan__value">
                                        <!-- {{ `${accountStripeDetails.used || 0} GB` }} -->
                                        {{ accountStripeDetails.currentDatasetRunCount }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0" v-can-access.level="'admin'">
                    <div class="card-header bg-transparent border-0 d-flex flex-row justify-content-between px-5 pt-5">
                        <h4 class="text-header-md">
                            Billing Details
                        </h4>
                        <loading-btn
                            v-can-access.level="'admin'"
                            class="btn btn-link"
                            listen="stripe/createCustomerPortal"
                            @click="onManageAccount">
                            <span class="pill-font text-primary">
                                Manage Cards
                                <i class="far fa-fw fa-edit ml-1" />
                            </span>
                        </loading-btn>
                    </div>
                    <div class="card-body bg-transparent border-0 px-5 pt-0 pb-5">
                        <div>
                            <div class="d-flex flex-row justify-content-start">
                                <div class="form-input form-group" style="width: 500px; box-shadow: none !important;">
                                    <label class="label">Billing name:</label>
                                    <input id="billing-name" type="text" v-model="billingName" class="form-control input__text" aria-describedby="input-append-save">
                                </div>
                                <loading-btn class="btn btn-link my-auto ml-2" :listen="['stripe/setBillingName']" :disabled="disableBillingNameButton" @click="onSetBillingName">
                                    <span class="no-wrap">Update name</span>
                                </loading-btn>
                            </div>
                            <!-- <div class="form-group mt-2">
                                <div class="form-check">
                                    <input id="limited-radio" v-model="limited" class="form-check-input" type="radio" name="Limit Runs" :value="true" @input="runLimit = accountStripeDetails.usageLimit">
                                    <label class="form-check-label label" for="limited-radio">
                                        Limit Spending
                                    </label>
                                    <div class="form-label__info">
                                        <span>
                                            Set up a data limit on a monthly basis
                                        </span>
                                    </div>
                                </div>
                                <div v-if="limited" class="d-flex flex-row justify-content-start mt-2">
                                    <div class="form-input input-group" style="max-width: 500px; box-shadow: none !important;">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="input-prepend-unit">GB</span>
                                        </div>
                                        <input v-model="runLimit" name="runLimit" type="number" min="0" class="form-control input__text" aria-describedby="input-prepend-unit">
                                    </div>
                                    <loading-btn class="btn btn-link my-auto ml-2" :listen="['stripe/setDatasetRunLimit']" :disabled="disableUpdateLimitButton" @click="onSetRunLimit">
                                        <span class="no-wrap">Update limit</span>
                                    </loading-btn>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="d-flex flex-row justify-content-start">
                                    <div class="form-check">
                                        <input id="unlimited-radio" v-model="limited" class="form-check-input" type="radio" name="Unlimited Runs" :value="false" @input="runLimit = accountStripeDetails.usageLimit">
                                        <label class="form-check-label label" for="unlimited-radio">
                                            Unlimited Spending
                                        </label>
                                        <div class="form-label__info">
                                            <span>
                                                Pay as much as needed to keep your data moving
                                            </span>
                                        </div>
                                        <div v-if="!limited" class="form-label__warning">
                                            <i class="fal fa-sm fa-fw fa-exclamation-triangle" />
                                            <span>
                                                Important: you will be paying all the costs through the usage of jobs.
                                            </span>
                                        </div>
                                    </div>
                                    <loading-btn v-if="!limited && !accountStripeDetails.noLimit" class="btn btn-link my-auto ml-4" :listen="['stripe/setDatasetRunLimit']" @click="onSetRunLimit">
                                        Update to unlimited
                                    </loading-btn>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="period-toggle d-flex flex-column justify-content-center">
                            <button
                                class="btn btn-primary m-2"
                                :class="{ 'active': selectedPeriod == 'month' }" @click="selectedPeriod = 'month'">
                                Monthly
                            </button>
                            <button
                                class="btn btn-primary m-2 d-flex flex-row no-wrap text-center"
                                :class="{ 'active': selectedPeriod == 'year' }" @click="selectedPeriod = 'year'">
                                Annually
                                <b-badge variant="warning" class="ml-1">
                                    10% Off
                                </b-badge>
                            </button>
                        </div> -->
                    </div>
                </div>
                <div class="card border-0">
                    <div class="card-header bg-transparent border-0 px-5 pt-5">
                        <h4 class="text-header-md">
                            Available Plans
                        </h4>
                    </div>
                    <div class="plans card-body bg-transparent border-0 px-5 pt-0 pb-5">
                        <div v-for="(product, $index) in sortedProducts" :key="$index" class="price-card">
                            <div
                                class="card user-select-none card text-left"
                                :class="{ 'active border-primary': activeProductId === product.id }">
                                <div class="pt-4 border-white price-card-container">
                                    <div class="mr-auto" style="width: 100px;">
                                        <b-badge
                                            :style="{ 'visibility': activeProductId === product.id ? 'visible' : 'hidden' }"
                                            class="d-flex justify-content-center mb-2 mx-4"
                                            variant="primary">
                                            Active
                                        </b-badge>
                                    </div>
                                    <div class="text-center">
                                        <span class="text-sub-header font-weight-bold">{{ product.name }}</span>
                                    </div>
                                </div>

                                <div class="py-4 px-3 border-white d-flex flex-column justify-content-center text-center align-items-center">
                                    <p class="plan__text text-secondary mb-2">
                                        <span class="font-weight-bold">
                                            <del v-if="selectedPeriod === 'year' && productPriceWithoutPromotion(product.id) !== productPrice(product.id, selectedPeriod)" class="text-strike m-1">
                                                {{ productPriceWithoutPromotion(product.id) }}
                                            </del>
                                            {{ productCurrency(product.id, selectedPeriod) }} {{ productPrice(product.id, selectedPeriod) }}
                                        </span>
                                        <!-- / {{ product.unit_label }} -->
                                        / {{ selectedPeriod }}
                                    </p>
                                    <p class="plan__text text-secondary pb-4">
                                        <!-- <span v-if="product.description">{{ product.description }}</span>
                                        <span v-else-if="productTrialPeriod(product.id, selectedPeriod)">
                                            <span class="font-weight-bold">
                                                {{ productTrialPeriod(product.id, selectedPeriod) }}
                                            </span> days trial period
                                        </span>
                                        <span v-else>Unlimited</span> -->
                                        <span class="font-weight-bold">
                                            {{ product.metadata.runsPerMonth }}
                                        </span> runs / month
                                    </p>
                                    <loading-btn
                                        v-if="!activeProductId"
                                        v-can-access.level="'admin'"
                                        class="btn btn-lg btn-primary"
                                        listen="stripe/createCheckoutSession"
                                        @click="onActivateSubscription(product.id)">
                                        {{ 'Activate' }}
                                    </loading-btn>
                                    <loading-btn
                                        v-else-if="product.id == activeProductId"
                                        v-can-access.level="'admin'"
                                        class="btn btn-lg btn-primary m-auto"
                                        listen="stripe/createCustomerPortal"
                                        @click="onManageAccount">
                                        Manage
                                    </loading-btn>
                                    <loading-btn
                                        v-else
                                        v-can-access.level="'admin'"
                                        class="btn btn-lg btn-primary m-auto"
                                        listen="stripe/upgradeSubscription"
                                        @click="onUpgradeSubscription(product.id)">
                                        Switch
                                    </loading-btn>
                                </div>
                            </div>
                        </div>
                        <div v-if="isCustomActive" class="price-card">
                            <div
                                class="user-select-none card text-left"
                                :class="{ 'active border-primary': isCustomActive }">
                                <div class="pt-4 border-white price-card-container pb-0">
                                    <div class="mr-auto" style="width: 100px;">
                                        <b-badge
                                            :style="{ 'visibility': isCustomActive ? 'visible' : 'hidden' }"
                                            variant="primary">
                                            Active
                                        </b-badge>
                                    </div>
                                    <div class="text-center">
                                        <span class="text-sub-header font-weight-bold">Custom</span>
                                    </div>
                                </div>

                                <div class="py-4 px-3 border-white d-flex flex-column justify-content-center text-center align-items-center">
                                    <p class="plan__text text-secondary mb-2">
                                        <span class="font-weight-bold">
                                            {{ productCurrency(activeProductId, currentPricePeriod) }} {{ currentPriceAmount }}
                                        </span> / {{ currentPricePeriod }}
                                    </p>
                                    <p class="plan__text text-secondary pb-4">
                                        <span class="font-weight-bold">
                                            {{ currentPlanRuns }}
                                        </span> runs / {{ currentPricePeriod }}
                                    </p>
                                    <loading-btn
                                        class="btn btn-lg btn-primary m-auto"
                                        listen="stripe/createCustomerPortal"
                                        @click="onManageAccount">
                                        {{ 'Manage' }}
                                    </loading-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </loader>
</template>

<script>
import { StripeService } from '@/utils/services';
import { mapActions, mapGetters, mapState } from 'vuex';
import Config from '@/utils/config';

export default {
    data() {
        return {
            selectedPeriod: 'month',
            limited: false,
            runLimit: 10,
            billingName: '',
        };
    },
    computed: {
        ...mapState({
            products: state => state.stripe.products,
            prices: state => state.stripe.prices,
        }),
        ...mapGetters({
            organisation: 'profile/organisation',
            user: 'profile/user',
        }),
        sortedProducts() {
            return _.orderBy(_.filter(this.products, ({ metadata }) => { return metadata.isCustom === 'false'; }), ({ metadata }) => {
                return _.toInteger(_.get(metadata, 'runsPerMonth', Number.POSITIVE_INFINITY));
            });
        },
        activeProductId() {
            return _.get(this.organisation.accountStripeDetails, 'lastProductId', _.get(this.organisation.accountStripeDetails, 'productId', null));
            // return _.get(this.organisation.accountStripeDetails, 'productId', null);
        },
        activePriceId() {
            return _.get(this.organisation.accountStripeDetails, 'lastPriceId', _.get(this.organisation.accountStripeDetails, 'priceId', null));
            // return _.get(this.organisation.accountStripeDetails, 'priceId', null);
        },
        currentPlan() {
            return _.find(this.products, { id: this.activeProductId });
        },
        currentPrice() {
            return _.find(this.prices, { id: this.activePriceId });
        },
        currentPlanName() {
            return _.get(this.currentPlan, 'name', '');
        },
        currentPricePeriod() {
            return _.get(this.currentPrice, 'recurring.interval', '');
        },
        currentPriceAmount() {
            return _.get(this.currentPrice, 'unit_amount') / 100;
        },
        currentPlanRuns() {
            return _.get(this.currentPlan, 'metadata.runsPerMonth');
        },
        isCustomActive() {
            return _.get(this.currentPlan, 'metadata.isCustom', 'false') === 'true';
        },
        isSpecialOrganisation() {
            return _.get(this.organisation, 'isSpecialOrganisation', false);
        },
        endDate() {
            return this.$moment(new Date(this.accountStripeDetails.currentPeriodEnd * 1000))
                .format(this.user.getDateFormat());
        },
        accountStripeDetails() {
            return _.get(this.organisation, 'accountStripeDetails', {});
        },
        isSubscriptionActive() {
            const status = _.get(this.accountStripeDetails, 'subscriptionStatus');
            return status === 'active'
                || status === 'trialing';
        },
        disableBillingNameButton() {
            return _.isEmpty(_.trim(this.billingName))
                || this.billingName === (this.accountStripeDetails.billingName || this.organisation.name);
        },
        disableUpdateLimitButton() {
            return _.isEmpty(_.toString(this.runLimit)) || _.includes(_.toString(this.runLimit), '-')
                || (!this.accountStripeDetails.noLimit && _.isEqual(_.toInteger(this.runLimit), this.accountStripeDetails.usageLimit));
        },
    },
    async created() {
        if (this.$route.query.status) {
            const query = Object.assign({}, this.$route.query);
            delete query.status;
            this.$router.replace({ query });
        }

        await Promise.allSettled([
            this.getAllProducts(),
            this.getAllPrices(),
        ]);
        this.$track('view-billing-page');
        this.limited = !this.accountStripeDetails.noLimit;
        this.runLimit = this.accountStripeDetails.usageLimit;
        this.billingName = this.accountStripeDetails.billingName || this.organisation.name;
    },
    methods: {
        ...mapActions({
            createCheckoutSession: 'stripe/createCheckoutSession',
            createCustomerPortal: 'stripe/createCustomerPortal',
            getAllProducts: 'stripe/getAllActiveProducts',
            getAllPrices: 'stripe/getAllActivePrices',
            upgradeSubscription: 'stripe/upgradeSubscription',
            setDatasetRunLimit: 'stripe/setDatasetRunLimit',
            setBillingName: 'stripe/setBillingName',
        }),
        selectedPrice(productId, selectedPeriod) {
            return _.find(this.prices, {
                product: productId,
                recurring: {
                    interval: selectedPeriod,
                },
            });
        },
        productPrice(productId, selectedPeriod) {
            return _.get(this.selectedPrice(productId, selectedPeriod), 'unit_amount') / 100;
        },
        productTrialPeriod(productId, selectedPeriod) {
            return _.get(this.selectedPrice(productId, selectedPeriod), 'recurring.trial_period_days');
        },
        productCurrency(productId, selectedPeriod) {
            switch (_.get(this.selectedPrice(productId, selectedPeriod), 'currency')) {
                case 'usd':
                    return '$';
                case 'gbp':
                    return '£';
                default:
                    return '$';
            }
        },
        productPriceWithoutPromotion(productId) {
            const monthlyPrice = _.find(this.prices, {
                product: productId,
                recurring: {
                    interval: 'month',
                },
            });
            return _.get(monthlyPrice, 'unit_amount') * 12 / 100;
        },
        async onActivateSubscription(productId) {
            const product = _.find(this.sortedProducts, { id: productId });
            const priceId = _.get(this.selectedPrice(productId, this.selectedPeriod), 'id', null);
            if (priceId) {
                try {
                    const { sessionId } = await this.createCheckoutSession(priceId);
                    this.$track('activate-subscription', {
                        product_name: _.get(product, 'name'),
                        product_id: _.get(product, 'id'),
                        price_id: priceId,
                        period: this.selectedPeriod,
                        product_runs: _.get(product, 'metadata.runsPerMonth'),
                    });
                    StripeService.activateSubscription(sessionId);
                } catch (e) {
                    this.$notify({
                        type: 'error',
                        text: e.message || 'There was an error activating supscription. Please try again.',
                    });
                }
            }
        },
        async onManageAccount() {
            try {
                const { url } = await this.createCustomerPortal();
                this.$track('view-manage-stripe-page');
                window.location = url;
            } catch (e) {
                this.$notify({
                    type: 'error',
                    text: e.message || 'An error has occured. Please try again.',
                });
            }
        },
        async onUpgradeSubscription(productId) {
            const product = _.find(this.sortedProducts, { id: productId });
            const priceId = _.get(this.selectedPrice(productId, this.selectedPeriod), 'id', null);
            if (priceId) {
                try {
                    await this.upgradeSubscription(priceId);
                    this.$track('update-subscription', {
                        product_name: _.get(product, 'name'),
                        product_id: _.get(product, 'id'),
                        price_id: priceId,
                        period: this.selectedPeriod,
                        product_runs: _.get(product, 'metadata.runsPerMonth'),
                    });
                    this.$notify({
                        type: 'success',
                        text: 'Subscription upgraded',
                    });
                } catch (e) {
                    this.$notify({
                        type: 'error',
                        text: e.message || 'There was an error upgrading supscription. Please try again.',
                    });
                }
            }
        },
        onContactUs() {
            if (Config.isProd) {
                window.Intercom('show');
            }
        },
        onSetRunLimit() {
            const payload = {
                usageLimit: _.toInteger(this.runLimit),
                noLimit: !this.limited,
            };
            console.log(payload);
            this.setDatasetRunLimit(payload);
        },
        onSetBillingName() {
            this.setBillingName(this.billingName);
        },
    },
};
</script>

<style lang="scss" scoped>

#pricing-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media (max-width: 1560px) {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        grid-template-rows: 1fr;
    }

    .plans {
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 220px);
        grid-template-rows: auto;
        justify-content: space-between;
    }
}

.pill-font {
    color: #424242;

    &:hover {
        color: grey;
    }
}

.manage-billing-btn {
    padding-top: 2px !important;
    display: block;
    width: max-content;
    position: absolute;
    top: 44px;
    left: 198px;
}

.price-plan {
    font-style: normal;
    font-weight: normal;

    &__background {
        border-radius: 6px;
        padding: 4px;
        min-width: 100px;
    }

    &__text-container {
        padding-left: 8px;
    }

    &__label {
        font-size: 12px;
        line-height: 18px;
        color: #b9b9c3;
    }

    &__value {
        font-size: 14px;
        line-height: 23px;
        color: #5e5873;
    }

    &__icon {
        color: var(--theme-badges-background-color);
        width: 34px;
        padding: 8px;
        border-radius: 6px;
        background: rgba(35, 123, 229, 0.12);
    }

    &__status {
        background: rgba(35, 123, 229, 0.12);
        border-radius: $border-radius;
        font-size: 12px;
        color: var(--theme-badges-background-color);
        font-weight: 600;
        padding: 4px 12px;
        max-height: 24px;

        span {
            padding-top: 10px;
        }
    }
}

.form-label {
    &__info {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 70%;
    }

    &__warning {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 70%;
        color: $warning-100;
    }
}

.card-body {
    color: #424242;
}

.period-toggle {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0;
    width: 180px;
    background-color: var(--theme-badges-background-color);
    color: white;
    border-radius: 7px;

    .active {
        border: 1px solid white !important;
    }
}

.plan {
    &__name {
        font-weight: 700;
        font-family: var(--theme-secondary-font);
    }
}

.price-card {
    min-width: 220px;

    .btn {
        max-width: 180px;
        min-width: 180px;
    }

    .card.active {
        background-image: url('../../../../assets/img/plan-bg.svg');
        background-size: cover;
    }
}

.text-p {
    font-size: 14px;
    text-align: center;
    color: #424242;
}
</style>
