import _ from "lodash";

export const OPERATORS = [
    { name: 'Equal', value: 'eq', isDate: true, isRange: true, isRangeNameDependent: true, isNumber: true },
    { name: 'Greater than', value: 'gt', isDate: true, isNumber: true },
    { name: 'Greater than or equal to', value: 'gte', isDate: true, isRangeNameDependent: true, isNumber: true },
    { name: 'In', value: 'in', isNumber: true },
    { name: 'Is NULL', value: 'is', isDate: true, isNumber: true },
    { name: 'Is not NULL', value: 'is not', isDate: true, isNumber: true },
    { name: 'Less than', value: 'lt', isDate: true, isNumber: true },
    { name: 'Less than or equal', value: 'lte', isDate: true, isNumber: true },
    { name: 'Not equal', value: 'ne', isDate: true, isNumber: true },
    { name: 'Not in', value: 'ni', isNumber: true },
    { name: 'Not like', value: 'nl', isNumber: true },
    { name: 'Like', value: 'li', isNumber: true },
    { name: 'Between', value: 'between' },
];

export const OPERATORS_ALLOWED_FOR_MULTIPLE_VALUES = ['in', 'ni'];

export const EMPTY_STRING_PLACEHOLDER = "''";

export const DASHBOARD_FILTER_MODEL_TYPES = [{ label: 'field' }, { label: 'date' }];

export default {
    get range() {
        return OPERATORS.filter(item => item.isRange);
    },

    get rangeNameDependent() {
        return OPERATORS.filter(item => item.isRangeNameDependent);
    },

    get date() {
        return OPERATORS.filter(item => item.isDate);
    },

    get all() {
        return OPERATORS.filter(item => item.isNumber);
    },
};

export const RULE_ENGINE_OPERATORS = {
    // factValue (array) must include any value from ruleValue (array)
    containsAny: (factValue, ruleValue) => {
        return _.some(ruleValue, (value) => {
            return _.includes(factValue, value);
        });
    },
    // factValue (array) must not include any value from ruleValue (array)
    doesNotContainAny: (factValue, ruleValue) => {
        return !_.some(ruleValue, (value) => {
            return _.includes(factValue, value);
        });
    },
    // factValue (array) must include all values from ruleValue (array)
    containsAll: (factValue, ruleValue) => {
        return _.every(ruleValue, (value) => {
            return _.includes(factValue, value);
        });
    },
};
