const hostName = window.location.hostname;
const securedDomains = [
    'app.gravitydata.co',
    'dev.gravitydata.co',
    'local.gravitydata.co',
];
const apiURL = `${process.env.VUE_APP_API_URL || window.location.origin}/gravity`;

let currentServer = process.env.VUE_APP_ENV;
const isSecure = securedDomains.indexOf(hostName) !== -1;
const secure = isSecure ? 's' : '';
if (securedDomains.indexOf(hostName) !== -1) {
    currentServer = [
        'app', 'dev', 'local',
    ][securedDomains.indexOf(hostName)];
}

const port = window.location.port ? `:${window.location.port}` : '';
const appUrl = `http${secure}://${hostName}${port}`;
// TODO Update auth0Credentials based on the env
const auth0Credentials = {
    app: {
        domain: 'auth.gravitydata.co',
        domainURL: `http${secure}://auth.gravitydata.co`,
        responseType: 'id_token token',
        clientId: process.env.VUE_APP_AUTH_CLIENT,
        profileRedirectUrl: `${appUrl}/token`,
        redirectUri: `${appUrl}/callback`,
        scope: 'openid profile email',
        realm: 'mongo-production',
    },
    dev: {
        domain: 'auth.gravitydata.co',
        domainURL: `http${secure}://auth.gravitydata.co`,
        responseType: 'id_token token',
        clientId: process.env.VUE_APP_AUTH_CLIENT,
        profileRedirectUrl: `${appUrl}/token`,
        redirectUri: `${appUrl}/callback`,
        scope: 'openid profile email',
        realm: 'mongo-dev',
    },
    local: {
        domain: 'auth.gravitydata.co',
        domainURL: `http${secure}://auth.gravitydata.co`,
        responseType: 'id_token token',
        clientId: process.env.VUE_APP_AUTH_CLIENT,
        profileRedirectUrl: `${appUrl}/token`,
        redirectUri: `${appUrl}/callback`,
        scope: 'openid profile email',
        realm: 'mongo-dev',
    },
};

const Config = {
    appUrl,
    env: process.env.VUE_APP_ENV,
    version: process.env.VUE_APP_VERSION,
    isDev: !!window.location.host.match(/dev.gravitydata.co$/),
    isProd: !!window.location.host.match(/app.gravitydata.co$/),
    isLocal: !!window.location.host.match(/local.gravitydata.co$/) || !!window.location.host.match(/local.gravitydata.co:1024$/),
    baseURL: apiURL,
    locales: { en: 'English', en_EN: 'English', en_GB: 'English' },
    timezone: 'UTC',
    dateFormats: {
        get regular() {
            return Config.dateFormats.options.find(format => format.default).format;
        },
        get full() {
            return `${Config.dateFormats.regular} HH:mm`;
        },
        grails: 'YYYY-MM-DD HH:mm:ss.S',
        datePicker: format => Config.dateFormats.options.find(item => item.format === format).pickerFormat,
        options: [
            { format: 'DD.MM.YYYY', pickerFormat: 'dd.MM.yyyy', default: true },
            { format: 'MM.DD.YYYY', pickerFormat: 'MM.dd.yyyy' },
            { format: 'YYYY.MM.DD', pickerFormat: 'yyyy.MM.dd' },
            { format: 'DD/MM/YYYY', pickerFormat: 'dd/MM/yyyy' },
            { format: 'MM/DD/YYYY', pickerFormat: 'MM/dd/yyyy' },
            { format: 'YYYY/MM/DD', pickerFormat: 'yyyy/MM/dd' },
            { format: 'DD-MM-YYYY', pickerFormat: 'dd-MM-yyyy' },
            { format: 'MM-DD-YYYY', pickerFormat: 'MM-dd-yyyy' },
            { format: 'YYYY-MM-DD', pickerFormat: 'yyyy-MM-dd' },
        ],
    },
    pusher: {
        debug: !!window.location.host.match(/dev.gravitydata.co$/), // Pusher will log messages to console on DEV env
        appId: process.env.VUE_APP_PUSHER_KEY,
    },
    connectors: {
        oauth: ['facebook'],
    },
    intercom: {
        applicationId: 's397viry',
        disabled: [],
    },
    sentry: {
        appUrl: 'https://760ff3857cf34993afb603ca9c940d45@o1204138.ingest.sentry.io/6365510',
        tracingOrigins: [
            'https://app.gravitydata.co',
            'https://dev.gravitydata.co',
            'https://local.gravitydata.co',
            apiURL,
        ],
        traceSampleRate: 1.0,
    },
    invalidCharacterRegex: '^[-=+,@]*|(?<=,)[-=+,@]*/gm',
    dataFeedsConnector: {
        instagram: {
            baseUrl: 'https://www.facebook.com/v8.0/dialog/oauth?',
            clientId: '684054275528476',
            scope: 'instagram_manage_insights,instagram_basic',
        },
        facebook: {
            baseUrl: 'https://www.facebook.com/v8.0/dialog/oauth?',
            clientId: '684054275528476',
            // eslint-disable-next-line
            scope: 'pages_read_engagement,pages_read_user_content,instagram_manage_insights,instagram_basic,read_insights,business_management,ads_read,ads_management',
        },
        google: {
            baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth?',
            get clientId() {
                let clientId = '';
                if (Config.isDev || Config.isLocal) {
                    clientId = '727999394021-kefp3khl3co1he6ojc568r6h4rprdn0p.apps.googleusercontent.com';
                } else if (Config.isStage) {
                    clientId = '727999394021-2sp8p9hurc6itoitea341o8becmq2hmf.apps.googleusercontent.com';
                } else if (Config.isProd) {
                    clientId = '727999394021-vnsagi7ttkieir4f0fg19g49otmlhbo7.apps.googleusercontent.com';
                }
                return clientId;
            },
            responseType: 'code',
            prompt: 'consent',
            accessType: 'offline',
        },
    },
    auth0: {
        get parameters() {
            return auth0Credentials[currentServer];
        },
    },
    Stripe: {
        options: {
            pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        },
    },
    assetsStorage: 'https://storage.googleapis.com/gravity-assets-prod-public/logos',
};
export { Config as default };
