import Vue from 'vue';
import Toastr from 'toastr';

const compile = (template, { data }) => {
    const el = document.createElement('div');
    const vm = new Vue({
        data() {
            return data;
        },
        template,
    });
    vm.$mount(el);
    return new Promise((resolve) => {
        vm.$nextTick(() => {
            resolve(vm.$el.innerHTML);
        });
    });
};

const notify = async ({ type = 'info', title, text, template, data, ...options }) => {
    let toast;
    if (template) {
        text = await compile(template, { data: { title, text, template, ...data, ...options } });
    }

    const defaultOpts = {
        closeButton: true,
        positionClass: 'toast-bottom-left',
        timeOut: 3000,
        extendedTimeOut: 10000,
        onclick(e) {
            const action = _.get(options.actions, e.target.dataset.action);
            if (action) {
                action(toast);
            }
        },
    };
    toast = Toastr[type](text, title, { ...defaultOpts, ...options });
};

Vue.prototype.$notify = notify;
Vue.notify = notify;
