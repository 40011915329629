<template>
    <div v-if="!$route.meta.showOnlyPage" id="gravity-header">
        <div class="header-buttons">
            <button class="btn btn-link p-0" @click="onShowHelpModal">
                <i class="svg-icon icon-help icon-lg" />
            </button>
            <!-- <button class="btn btn-link p-0">
                <i class="svg-icon icon-notification icon-lg" />
            </button> -->
        </div>

        <portal-target name="header" />

        <div id="end-of-header-anchor" ref="endOfHeaderAnchor" />
    </div>
</template>

<script>
export default {
    name: 'GravityHeader',
    data() {
        return {
            scrollReachedTop: true,
        };
    },
    mounted() {
        // Observe end of header viewport intersection
        const observer = new IntersectionObserver((entries) => {
            this.scrollReachedTop = !_.get(entries[0], 'isIntersecting', true);
        });
        observer.observe(this.$refs.endOfHeaderAnchor);
    },
    methods: {
        onShowHelpModal() {
            this.$eventHub.$emit('showModal', {
                name: 'help-modal',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#end-of-header-anchor {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 15em;
  left: 0;
}
</style>
