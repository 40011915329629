<template>
    <button class="copy-btn" @click="copy">
        <i class="far fa-clone fa-fw" />
        <slot />
    </button>
</template>

<script>

export default {
    props: {
        content: {
            type: String,
            required: true,
        },
    },
    methods: {
        async copy() {
            try {
                await navigator.clipboard.writeText(this.content);

                this.$notify({
                    text: 'Copy successful',
                    type: 'success',
                });
            } catch ($e) {
                this.$notify({
                    text: 'Error when trying to copy on clipboard',
                    type: 'error',
                });
            }
        },
    },
};
</script>
