import Config from '../config';

/* eslint-disable */
class ReditusService {
    async init(user) {
        if (!Config.isProd || !user) return Promise.resolve('skipped');

        this.load();
        if (gr && typeof gr === 'function') {
            gr("track", "pageview");
            gr('track', 'conversion', { email: user.email });
        }

        return Promise.resolve('loaded');
    }

    load() {
        (function (w, d, s, p, t) {
            w.gr = w.gr || function () {
                w.gr.q = w.gr.q || []; w.gr.q.push(arguments);
            };
            p = d.getElementsByTagName(s)[0];
            t = d.createElement(s);
            t.async = true;
            t.src = "https://app.getreditus.com/gr.js?_ce=60";
            p.parentNode.insertBefore(t, p);
        })(window, document, "script");
    }
}

export default new ReditusService();
