<template>
    <div :id="controlId">
        <label class="label">{{ attrs.display_name }}</label>
        <span v-if="attrs.description" v-b-tooltip="tooltipSettings">
            <i class="ml-2 fal fa-info-circle" />
        </span>
        <div class="card border-0">
            <div class="input-group">
                <input
                    v-model="newItem"
                    class="form-control input__text"
                    type="text"
                    data-vv-delay="800"
                    placeholder="Press enter after each item">
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary" :disabled="!newItem.length || duplicateItem" @click="onAddItem(newItem)">
                        Add
                    </button>
                </div>
            </div>
            <div class="form-group mt-2">
                <span v-for="item in controlValue" :key="item" class="badge py-1 mr-2 mb-2 badge-secondary badge-pill">
                    {{ item }}
                    <span class="fal c-pointer fa-fw fa-times" @click="onRemoveItem(item)" />
                </span>
            </div>
        </div>
    </div>

</template>

<script>
import * as uuid from 'uuid';

export default {
    name: 'FieldList',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const id = uuid.v4();

        return {
            controlValue: _.cloneDeep(this.value),
            newItem: '',
            controlId: `${this.attrs.parameter}-${id}`,
            tooltipSettings: {
                container: `#${this.attrs.parameter}-${id}`,
                html: true,
                title: this.attrs.description,
                trigger: 'hover',
                placement: 'top',
            },
        };
    },
    computed: {
        duplicateItem() {
            return _.includes(this.controlValue, this.newItem);
        },
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) this.controlValue = newVal;
            },
        },
    },
    methods: {
        onChange() {
            this.$emit('input', _.cloneDeep(this.controlValue));
        },
        onAddItem(item) {
            if (_.isArray(this.controlValue)) this.controlValue = _.concat(this.controlValue, item);
            else this.controlValue = [item];
            this.newItem = '';
            this.onChange();
        },
        onRemoveItem(item) {
            this.controlValue = _.without(this.controlValue, item);
            this.onChange();
        },
    },
};
</script>
