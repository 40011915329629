/* eslint-disable */
import posthog from 'posthog-js';
import Config from '../config';

class PosthogService {
    #loaded = false;

    async init(user) {
        return new Promise((resolve, _reject) => {
            if (Config.isProd) {
                posthog.init('phc_7rEqx9aufdjQ1veXK46XQRrhsVRYm8WyRq4AIICLtOl', { 
                    api_host: 'https://app.posthog.com',
                    loaded: () => {
                        this.identify(user);
                        this.#loaded = true;
                        resolve('loaded');
                    },
                });
            } else {
                resolve('skipped')
            }
        })
    }

    identify(user) {
        posthog.identify(`${user.id}-${user.username}`, {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            user_id: user.id,
            Created_at: Math.floor(user.dateCreated / 1000),
            organisation_id: _.get(user.organisation, 'id'),
            organisation_name: _.get(user.organisation, 'name')
        });
    }

    pageView() {
        try {
            if (Config.isProd && this.#loaded) posthog.capture('$pageview')
            else console.log('page view', window.location);
        } catch(e) {
            console.error(e);
        }
    }
}

export default new PosthogService();
