<template>
    <div id="connections-container" class="router-view">
        <portal to="header">
            <div v-if="!isFirstConnection" class="d-flex flex-column justify-content-start flex-wrap header-content">
                <div class="d-flex flex-row flex-wrap justify-content-start">
                    <h1 class="mr-5 clickable text-header__tab" :class="{ active: activeTab == 'sources' }" @click="activeTab = 'sources'">
                        Sources
                    </h1>
                    <h1 class="mr-5 clickable text-header__tab" :class="{ active: activeTab == 'destinations' }" @click="activeTab = 'destinations'">
                        Destinations
                    </h1>
                    <h1 class="mr-5 clickable text-header__tab" :class="{ active: activeTab == 'integrations' }" @click="activeTab = 'integrations'">
                        Integrations
                    </h1>
                </div>
                <div class="d-flex flex-row justify-content-between no-wrap mt-3">
                    <p v-if="activeTab === 'sources'" class="header-info">
                        A source is something you pull data from.
                        You connect to a new source by adding the necessary information during the setup.
                        In order to create syncs you first need to setup a source and a destination.
                    </p>
                    <p v-if="activeTab === 'destinations'" class="header-info">
                        A destination is something you push data to.
                        You connect to a new destination by adding the necessary information during the setup.
                        In order to create syncs you first need to setup a source and a destination.
                    </p>
                    <p v-if="activeTab === 'integrations'" class="header-info">
                        We understand that efficiency is key and want to enable you to work faster and smarter.
                        These integration tools will help you do that. Connect to an integration and get notified about job runs.
                    </p>
                    <button v-can-access.level="'user'" class="btn btn-primary ml-auto main-button" @click="onNewConnection">
                        + Add new connection
                    </button>
                </div>
            </div>
        </portal>

        <loader class="connection-list" :listen="['dataSources/fetchMyDataSources', 'dataTargets/fetchMyDataTargets', 'integrations/fetchMyIntegrations', 'integrations/fetchMyPolicies']" loader-class="m-auto">
            <connection-list
                v-if="!isConnectionListEmpty"
                :items="connections"
                @select="onEditConnection"
                @delete="onDeleteConnection" />
            <div v-else-if="isFirstConnection" class="d-flex flex-row justify-content-around">
                <div class="w-50 mx-auto">
                    <div class="text-center m-auto" style="min-height: 300px;">
                        <img src="../../../../public/icons/empty-connection.svg" style="height: 350px;">
                    </div>
                    <h1 class="user-select-none text-header-lg text-center">
                        Establish your first connection!
                    </h1>
                    <div class="text-center my-3 text-lg">
                        You have no connections yet. In order to create a sync you need to connect a source and a destination. We will help you setup your first connections.
                    </div>
                    <div class="text-center">
                        <button v-can-access.level="'user'" class="mx-auto my-5 btn btn-lg btn-primary" @click="onNewConnection">
                            + Add new connection
                        </button>
                    </div>
                </div>
                <div class="card border-0" style="max-width: 400px;">
                    <div class="card-body">
                        <div class="mx-4 mb-5 mt-5">
                            <h3 class="text-header-sm mb-3">
                                Sources
                            </h3>
                            <span class="text-header-info">Sources like facebook, instagram, tiktok all gather data. By connecting these sources to the system, you can start pulling that data into a Destination using the Gravity platform.</span>
                            <p class="my-1">
                                <a :href="URLS.documentation.sources" target="_blank">Learn more</a>
                            </p>
                        </div>
                        <div class="mx-4 my-5">
                            <h3 class="text-header-sm mb-3">
                                Destinations
                            </h3>
                            <span class="text-header-info">Destinations is where the data you gather from your sources will end up being transfered to. These are usually databases of some sort.</span>
                            <p class="my-1">
                                <a :href="URLS.documentation.destinations" target="_blank">Learn more</a>
                            </p>
                        </div>
                        <div class="mx-4 my-5">
                            <h3 class="text-header-sm mb-3">
                                Integrations
                            </h3>
                            <span class="text-header-info">We understand that efficiency is key and want to enable you to work faster and smarter. These integration tools will help you do that.</span>
                            <p class="my-1">
                                <a :href="URLS.documentation.integrations" target="_blank">Learn more</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex flex-column justify-content-center align-items-center">
                <div class="text-center m-auto">
                    <img src="../../../../public/icons/empty-connection.svg" style="height: 400px;">
                </div>
                <h1 class="user-select-none text-md-header text-center pb-3">
                    Create your first connection
                </h1>
                <div v-if="searchTerm" class="text-center">
                    No {{ activeTab }} containing "{{ searchTerm }}" in their name
                </div>
                <button v-can-access.level="'user'" class="btn btn-lg btn-primary mx-auto my-3 main-button" @click="onNewConnection">
                    + Add new connection
                </button>
            </div>
        </loader>
    </div>
</template>

<script>
import ConnectionList from '@/components/connection/connection-list';
import { mapActions, mapGetters } from 'vuex';
import { URLS, LABELS } from '@/constants';

export default {
    components: { ConnectionList },
    data() {
        return {
            URLS,
            activeTab: 'sources',
            searchTerm: '',
        };
    },
    computed: {
        ...mapGetters({
            mySources: 'dataSources/myDataSources',
            myDestinations: 'dataTargets/myDataTargets',
            myIntegrations: 'integrations/myIntegrations',
        }),
        connections() {
            switch (this.activeTab) {
                case 'sources':
                    return _(this.mySources)
                        .filter(({ name }) => _.includes(_.toLower(name), _.toLower(this.searchTerm)))
                        .sortBy(({ code }) => _.toLower(code))
                        .value();
                case 'destinations':
                    return _(this.myDestinations)
                        .filter(({ name }) => _.includes(_.toLower(name), _.toLower(this.searchTerm)))
                        .sortBy(({ code }) => _.toLower(code))
                        .value();
                case 'integrations':
                    return _(this.myIntegrations)
                        .filter(({ name }) => _.includes(_.toLower(name), _.toLower(this.searchTerm)))
                        .sortBy(({ code }) => _.toLower(code))
                        .value();
                default:
                    return [];
            }
        },
        isConnectionListEmpty() {
            return _.isEmpty(this.connections);
        },
        isFirstConnection() {
            return _.isEmpty(this.mySources)
                && _.isEmpty(this.myDestinations)
                && _.isEmpty(this.myIntegrations);
        },
    },
    created() {
        this.$eventHub.$on('search', this.onSearchChanged);
        this.$track('view-sources-page');
        this.clearSearch();
    },
    destroyed() {
        this.$eventHub.$off('search', this.onSearchChanged);
    },
    methods: {
        ...mapActions({
            deleteSourceConnection: 'dataSources/deleteDataSourceConnection',
            deleteDestinationConnection: 'dataTargets/deleteDataTargetConnection',
            deleteIntegration: 'integrations/deleteIntegration',
        }),
        onNewConnection() {
            this.$eventHub.$emit('showModal', {
                name: 'new-connection',
                tab: this.activeTab,
                callback: this.onCreateModalClosed,
            });
        },
        onEditConnection(settings) {
            let type;
            switch (this.activeTab) {
                case 'sources':
                    type = 'source';
                    break;
                case 'destinations':
                    type = 'destination';
                    break;
                case 'integrations':
                    type = 'integration';
                    break;
                default:
                    break;
            }
            this.$eventHub.$emit('showModal', {
                name: 'edit-connection',
                tab: this.activeTab,
                settings: {
                    type,
                    ...settings,
                },
            });
        },
        onDeleteConnection(connection) {
            switch (this.activeTab) {
                case 'sources':
                    this.deleteSourceConnection({
                        name: connection.name,
                        code: connection.code,
                        id: connection.id,
                    }).then(() => {
                        this.$notify({
                            type: 'success',
                            text: LABELS.sourceDeleteSuccess,
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: 'error',
                            text: e.message || LABELS.defaultError,
                        });
                    });
                    break;
                case 'destinations':
                    this.deleteDestinationConnection({
                        name: connection.name,
                        code: connection.code,
                        id: connection.id,
                    }).then(() => {
                        this.$notify({
                            type: 'success',
                            text: LABELS.destinationDeleteSuccess,
                        });
                    }).catch((e) => {
                        this.$notify({
                            type: 'error',
                            text: e.message || LABELS.defaultError,
                        });
                    });
                    break;
                case 'integrations':
                    this.deleteIntegration(connection.id)
                        .then(() => {
                            this.$notify({
                                type: 'success',
                                text: LABELS.integrationDeleteSuccess,
                            });
                        })
                        .catch((e) => {
                            this.$notify({
                                type: 'error',
                                text: e.message || LABELS.defaultError,
                            });
                        });
                    break;
                default:
                    break;
            }
        },
        onSearchChanged(value) {
            this.$set(this, 'searchTerm', value);
        },
        clearSearch() {
            this.searchTerm = '';
            this.$eventHub.$emit('clearSearch');
        },
        onCreateModalClosed(options) {
            if (options && options.type === 'integration' && options.connection) {
                this.$eventHub.$emit('showModal', {
                    name: 'edit-connection',
                    tab: 'integrations',
                    settings: {
                        type: 'integration',
                        name: _.get(options.connection, 'parameters.name'),
                        ...options.connection,
                    },
                });
            }
        },
    },
};
</script>
