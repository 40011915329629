<template>
    <div class="vh-100 d-flex w-100 justify-content-center align-items-center">
        <h4>
            <i class="fad fa-fw fa-spinner-third fa-spin" />
            Loading
        </h4>
    </div>
</template>
<script>
/* eslint-disable import/no-unresolved */

import { signup } from '@/utils/auth0';

export default {
    name: 'Login',
    created() {
        signup();
    },
};
</script>
