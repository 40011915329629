import Vue from 'vue';

import axios from 'axios';
import Cookies from 'js-cookie';
import Qs from 'qs';
import Auth from '../../utils/auth';
import { IndexedDbAdapter, Manager, LegacyCache } from '../../utils/cache';
import Config from '../../utils/config';

class Client {
    #skipAuth = false;

    constructor() {
        this.http = axios.create({
            baseURL: Config.baseURL,
            paramsSerializer(params) {
                return Qs.stringify(params, { arrayFormat: 'brackets' });
            },
        });

        this.manager = new Manager(IndexedDbAdapter);

        this.http.interceptors.request.use(async (request) => {
            if (request.method === 'get' && request.cache && !request.cache.skip) {
                let data;
                if (request.cache.v2) {
                    data = await this.manager.getResponse(request);
                } else {
                    data = LegacyCache.getResponse(request);
                }

                if (data) {
                    request.adapter = () => Promise.resolve({
                        data,
                        status: 304,
                        statusText: 'Not Modified',
                        config: request,
                        request,
                    });
                }
            }
            return request;
        });

        this.http.interceptors.response.use(async (response) => {
            if (response.status === 200 && !this.getError(response) && response.config.cache) {
                if (response.config.cache.v2) {
                    this.manager.saveResponse(response.config, response.data);
                } else {
                    LegacyCache.saveResponse(response.config, JSON.stringify(response.data));
                }
            }
            return response;
        }, (error) => {
            // Handle 401 nd 403 statuses which are returned when there are no tokens in the request or user is unauthorized
            if (error.response) {
                const noTokensInRequest = error.response.request.responseURL.includes('user/info')
                                        && error.response.status === 403;

                const unauthorized = error.response && error.response.status === 401;
                if (noTokensInRequest || unauthorized) {
                    Auth.clearTokens();
                    window.location.href = '/logout';
                    return Promise.resolve();
                }
            }

            // Sometimes BE will return proper error response using 400 status code. This will check if
            // there is an error message and in that case it wont be handled like a failed request
            if (this.getError(error.response)) return error.response;

            // Reject in any other case
            return Promise.reject(error);
        });
    }

    get(path, params, config = {}, externalAccess = false) {
        this.setLocalstorageToken(externalAccess);
        this.setCanaryHeader();
        return this.resetToken(this.http.get(path, { params, ...config }));
    }

    post(path, data, config = {}, externalAccess = false) {
        this.setLocalstorageToken(externalAccess);
        this.setCanaryHeader();
        return this.resetToken(this.http.post(path, data, config));
    }

    upload(path, data, file, config = {}) {
        const form = new FormData();
        _.each(data, (value, key) => {
            form.append(key, value);
        });

        form.append('file', file);
        this.setCanaryHeader();
        return this.post(path, form, {
            ...config,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    put(path, data, config = {}, externalAccess = false) {
        this.setLocalstorageToken(externalAccess);
        this.setCanaryHeader();
        return this.resetToken(this.http.put(path, data, config));
    }

    delete(path, params, config = {}, externalAccess = false) {
        this.setLocalstorageToken(externalAccess);
        this.setCanaryHeader();
        return this.resetToken(this.http.delete(path, { params, ...config }));
    }

    parseResponse(response) {
        const error = this.getError(response);

        if (error) {
            const err = new Error(error);
            Vue.logger.notify(err, {
                metadata: {
                    uuid: _.get(response, 'data.uuid'),
                    api: _.get(response, 'config.url'),
                },
                severity: 'warning',
                context: 'api call',
            });
            throw err;
        }

        return _.get(response, 'data', undefined);
    }

    // eslint-disable-next-line
    getError(response) {
        let error = null;

        if (_.get(response, 'data.error')) {
            error = _.get(response, 'data.error', null);
        }

        // TODO: This should be deprecated as BE wil onnly use "error" as a key from now on
        if (_.get(response, 'data.isError')) {
            error = _.get(response, 'data.isError', null);
        }

        // TODO: This should be deprecated as BE wil onnly use "error" as a key from now on
        if (_.get(response, 'data.success') === false) {
            error = _.get(response, 'data.message', null);
        }

        return error;
    }

    withoutToken() {
        this.#skipAuth = true;
        this.setHeader('Authorization', undefined);

        return this;
    }

    resetToken(response) {
        if (this.#skipAuth) {
            this.setLocalstorageToken();
            this.#skipAuth = false;
        }

        return response;
    }

    setLocalstorageToken(externalAccess) {
        const token = externalAccess ? window.sessionStorage.getItem('external_access_token') : window.localStorage.getItem('access_token');
        this.setHeader('Authorization', token ? `Bearer ${token}` : undefined);
    }

    setCanaryHeader() {
        if (Cookies.get('canary_backend')) this.setHeader('x-canary-be', 'always');
    }

    setHeader(key, value) {
        if (value) {
            this.http.defaults.headers[key] = value;
        } else {
            delete this.http.defaults.headers[key];
        }

        return this;
    }
}

export default new Client();
