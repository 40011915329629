import Vue from 'vue';
import * as api from '../api/profile.api';
import Permission from '../models/permission.model';

export default {
    namespaced: true,
    state: {
        collection: {
            permissions: {},
            activeFeatures: {},
        },
    },
    getters: {
        mappedPermissions({ collection }) {
            return _(collection.permissions)
                .map(item => new Permission(item))
                .sortBy('title')
                .value();
        },
        mappedActiveFeatures({ collection }) {
            return _(collection.activeFeatures)
                .sort()
                .value();
        },
        activePermissions(state, { mappedPermissions }) {
            return _.filter(mappedPermissions, 'active');
        },
    },
    mutations: {
        setPermission(state, { id, ...permission }) {
            if (!state.collection.permissions[id] || !_.isEqual(state.collection.permissions[id], { id, ...permission })) {
                Vue.set(state.collection.permissions, id, { id, ...permission });
            }
        },
        setActiveFeature(state, { id, ...activeFeature }) {
            if (!state.collection.activeFeatures[id] || !_.isEqual(state.collection.activeFeatures[id], { id, ...activeFeature })) {
                Vue.set(state.collection.activeFeatures, id, { id, ...activeFeature });
            }
        },
    },
    actions: {
        async getCollection({ commit }, refresh) {
            commit('activateLoading', 'permissions/getCollection', { root: true });
            const permissions = await api.getAvailablePermissions(refresh);
            _.each(permissions, permission => commit('setPermission', permission));
            commit('deactivateLoading', 'permissions/getCollection', { root: true });
            return permissions;
        },
        async getActiveFeatures({ commit }) {
            const activeFeatures = await api.getActiveFeatures();
            _.each(activeFeatures, feature => commit('setActiveFeature', feature));
            return activeFeatures;
        },
    },
};
