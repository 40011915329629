/* eslint-disable import/no-unresolved */

import Login from '@/modules/auth/views/login';
import Logout from '@/modules/auth/views/logout';
import Signup from '@/modules/auth/views/signup';
import Callback from '@/modules/auth/views/callback';

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { title: 'Login' },
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: { title: 'Signup' },
    },
    {
        path: '/callback',
        name: 'callback',
        component: Callback,
        props: true,
        meta: { title: 'Callback',
            showOnlyPage: true,
            externalAccess: true,
            worksWithLogin: true },
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { title: 'Logout' },
    },
];
