<template>
    <div class="router-view d-flex flex-column justify-content-start" style="gap: 30px;">
        <portal to="header">
            <div class="d-flex flex-column justify-content-start flex-wrap header-content">
                <div style="margin-top: -25px;" class="pb-2">
                    <router-link :to="{ name: 'syncs' }" class="navigation-link exact-link font-weight-bold text-dark">
                        Syncs overview
                    </router-link>
                    <span>/</span>
                    <router-link :to="{ name: 'sync-overview', params: { syncId } }" class="navigation-link exact-link font-weight-bold text-dark">
                        {{ sync.name }}
                    </router-link>
                    <span>/</span>
                    <router-link :to="{ name: 'job-overview', params: { syncId, jobId } }" class="navigation-link exact-link text-dark">
                        {{ job.name }}
                    </router-link>
                </div>
                <div class="d-flex flex-row justify-content-start w-100">
                    <h1 class="text-header-md">
                        {{ job.name }}
                    </h1>
                    <confirm-n-delete-btn
                        v-can-access.level="'user'"
                        v-b-tooltip.hover.top="'Delete'" type="button"
                        class="btn btn-link text-danger ml-auto"
                        :delete-confirmation="job.name"
                        message="You are about to delete this job. Once you have deleted it, there is no way to recover it."
                        delete-title="Delete forever"
                        @delete="onDeleteJob">
                        <i class="svg-icon icon-trash icon-lg" />
                    </confirm-n-delete-btn>
                    <button
                        v-can-access.level="'user'"
                        v-b-tooltip.hover.top="'Edit'"
                        class="btn btn-link"
                        @click="showJobEdit = true">
                        <i class="svg-icon icon-settings icon-lg" />
                    </button>
                    <loading-btn
                        v-b-tooltip.hover="'Run now'"
                        v-can-access.level="'user'"
                        class="btn btn-link"
                        hide-when-loading
                        :disabled="isJobRunning || isJobPaused"
                        :listen="`dataSets/executeDataSet/${job.id}`"
                        @click.prevent.stop="onRunJob">
                        <i class="svg-icon icon-play icon-lg" />
                    </loading-btn>
                    <router-link
                        v-b-tooltip.hover="'View logs'"
                        class="btn btn-link pr-0"
                        :title="'View logs'"
                        :to="{ name: 'logs', query: { name: job.name } }">
                        <i class="svg-icon icon-logs icon-lg" />
                    </router-link>
                </div>
            </div>
        </portal>

        <div class="d-flex flex-md-row flex-sm-column justify-content-start no-wrap" style="gap: 30px;">
            <div class="card border-0 radius-2" style="min-width: 550px; height: 300px;">
                <div class="card-header bg-transparent border-0 pt-5">
                    <span class="text-header-sm text-secondary">Details</span>
                </div>
                <div class="card-body bg-transparent d-flex flex-row justify-content-start flex-wrap px-4 pt-0">
                    <div class="details-card-grid">
                        <span class="text-muted my-auto">Sync</span>
                        <span class="my-auto">
                            <div class="card-icon d-inline-block" v-b-tooltip.hover="sync.sourcename">
                                <img :src="`${assetsStorage}/${sync.sourcecode}.png`" height="30" width="30" class="my-auto">
                            </div>
                            <div class="my-auto mx-2 d-inline-block">
                                <i class="fa fa-angle-right m-auto" />
                            </div>
                            <div class="card-icon d-inline-block" v-b-tooltip.hover="sync.targetname">
                                <img :src="`${assetsStorage}/${sync.targetcode}.png`" height="30" width="30" class="my-auto">
                            </div>
                        </span>

                        <span class="text-muted my-auto">Run Id</span>
                        <span class="my-auto"> {{ lastRunLog ? lastRunLog.run_id : '-' }} </span>

                        <span class="text-muted my-auto">Status</span>
                        <span
                            class="status-item my-auto ml-0 mr-auto"
                            :class="{
                                'status-item__error': lastRunLogStatus === 'ERROR',
                                'status-item__success': lastRunLogStatus === 'FINISHED_OK',
                                'status-item__info': !lastRunLogStatus }">
                            <div class="my-auto mx-2">
                                {{ lastRunLogStatus === 'FINISHED_OK' ? 'FINISHED' : (!lastRunLogStatus ? 'RUNNING' : lastRunLogStatus) }}
                            </div>
                        </span>

                        <span class="text-muted my-auto">Added</span>
                        <span class="my-auto">{{ lastRunLog ? (lastRunLog.new_records_count || 0) : '-' }}</span>

                        <span class="text-muted my-auto">Started</span>
                        <span class="my-auto">{{ formatStartDate(lastRunLog) }}</span>

                        <span class="text-muted my-auto">Changed</span>
                        <span class="my-auto">{{ lastRunLog ? (lastRunLog.updated_records_count || 0) : '-' }}</span>

                        <span class="text-muted my-auto">Duration</span>
                        <span class="my-auto">{{ formatDuration(lastRunLog) }}</span>

                        <span class="text-muted my-auto">Staged</span>
                        <span class="my-auto">{{ lastRunLog ? (lastRunLog.staged_records_count || 0) : '-' }}</span>
                    </div>
                </div>
            </div>
            <div class="card border-0 radius-2 flex-grow-1" style="height: 300px;">
                <div class="card-header bg-transparent border-0 pt-5">
                    <span class="text-header-sm text-secondary">Live execution steps</span>
                </div>
                <div class="card-body bg-transparent overflow-auto px-4 pt-0">
                    <loader :listen="`runHistory/fetchExecutionHierarchy/${lastRunLogId}`" :force-loading="forceLoading" :first-time-only="true">
                        <json-viewer v-if="executionTrace" :json="executionTrace" />
                        <div v-else class="text-center m-auto">
                            Execution trace not available
                        </div>
                    </loader>
                </div>
            </div>
        </div>

        <div class="card bg-white border-0 radius-2">
            <div class="card-header border-0 bg-transparent px-4 pb-0 pt-5 d-flex justify-content-between">
                <span class="text-header-sm text-secondary">Run history</span>
            </div>
            <div v-if="runLogs.length" class="bg-transparent card-body p-4">
                <div class="run-card-grid px-4">
                    <div class="my-auto text-muted status-content">
                        Status
                    </div>
                    <div class="text-muted my-auto duration-content">
                        Duration
                    </div>
                    <div class="text-muted my-auto added-content">
                        Added
                    </div>
                    <div class="text-muted my-auto changed-content">
                        Changed
                    </div>
                    <div class="text-muted my-auto staged-content">
                        Staged
                    </div>
                    <div class="text-muted my-auto buttons-content" />
                </div>
                <div v-for="(runLog, $index) in runLogs" :key="$index" class="border-0 p-0 mx-2 my-2 card run-card clickable" @click="onShowLogTrace(runLog)">
                    <div class="card-body run-card-grid">
                        <span
                            class="status-item d-inline-flex status-content"
                            :class="{
                                'status-item__error': runLog.status === 'ERROR',
                                'status-item__success': runLog.status === 'FINISHED_OK',
                                'status-item__info': !runLog.status }">
                            <div class="m-auto">
                                {{ runLog.status === 'FINISHED_OK' ? 'FINISHED' : (!runLog.status ? 'RUNNING' : runLog.status) }}
                            </div>
                        </span>
                        <div class="my-auto duration-content">
                            <div class="text-bold p-0 m-0">
                                {{ formatDuration(runLog) }}
                            </div>
                            <div class="text-muted p-0 m-0">
                                {{ formatStartDate(runLog) }}
                            </div>
                        </div>
                        <div class="my-auto added-content">
                            {{ runLog.new_records_count || 0 }}
                        </div>
                        <div class="my-auto changed-content">
                            {{ runLog.updated_records_count || 0 }}
                        </div>
                        <div class="my-auto staged-content">
                            {{ runLog.staged_records_count || 0 }}
                        </div>
                        <div class="my-auto text-right mr-2 buttons-content">
                            &lt;&#47;&gt;
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="card-body bg-transparent p-4">
                No runs available
            </div>
        </div>

        <div class="p-2">
            <execution-info
                v-if="!!showLogTrace"
                key="execution-info"
                :show="!!showLogTrace"
                :log="showLogTrace"
                :job="job"
                @close="showLogTrace = null;" />
            <portal to="modals">
                <div v-if="showJobEdit" key="sync-creation" v-can-access.level="'user'" class="modals-route">
                    <button type="button" class="close-modal-btn btn-light" @click="showJobEdit = false">
                        <i class="svg-icon icon-close" />
                    </button>
                    <div class="modal-container">
                        <job-edit :job-id="job.id" @close="showJobEdit = false" />
                    </div>
                </div>
            </portal>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import JsonViewer from '@/components/global/controls/json-viewer';
import ExecutionInfo from '@/modules/datasetHistory/views/execution-info';
import JobEdit from '@/modules/jobs/views/jobEdit';
import { LABELS } from '@/constants';
import Config from '@/utils/config';

export default {
    name: 'JobOverview',
    components: { JsonViewer, ExecutionInfo, JobEdit },
    props: {
        syncId: {
            type: [String, Number],
            required: true,
        },
        jobId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            assetsStorage: Config.assetsStorage,
            executionTrace: null,
            showLogTrace: null,
            showJobEdit: false,
            forceLoading: false,
        };
    },
    watch: {
        lastRunLogUpdate: {
            deep: true,
            handler(value) {
                if (!_.isEmpty(value)) {
                    this.fetchExecutionHierarchy(value.runId)
                        .then((executionTrace) => {
                            if (!_.isEmpty(executionTrace)) this.executionTrace = executionTrace;
                            else this.executionTrace = null;
                        });
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            myJobs: 'dataSets/myDataSets',
            mySyncs: 'syncs/mySyncs',
            jobRunHistory: 'runHistory/jobRunHistory',
            lastLogEntry: 'runHistory/lastDatasetRun',
            runLogUpdate: 'runHistory/runLogUpdate',
            user: 'profile/user',
        }),
        lastRunLogUpdate() {
            return _.get(this.runLogUpdate, this.lastRunLogId);
        },
        job() {
            return _.get(this.myJobs, this.jobId, {});
        },
        sync() {
            return _.get(this.mySyncs, this.syncId, {});
        },
        runLogs() {
            return _.orderBy(_.takeRight(this.jobRunHistory(this.jobId), 10), 'start_datetime', 'desc');
        },
        lastRunLog() {
            return this.lastLogEntry(this.jobId);
        },
        isJobPaused() {
            return _.get(this.job, 'schedule_status', 'enabled') !== 'enabled';
        },
        allowToggleStatus() {
            const status = _.get(this.job, 'schedule_status', 'enabled');
            return status === 'enabled'
                || status === 'disabled';
        },
        isJobRunning() {
            return this.lastRunLog && (_.get(this.lastRunLog, 'status', 'RUNNING') === 'RUNNING');
        },
        lastRunLogId() {
            return _.get(this.lastRunLog, 'run_id');
        },
        lastRunLogStatus() {
            return _.get(this.lastRunLog, 'status');
        },
    },
    created() {
        if (this.lastRunLog) {
            this.forceLoading = true;
            this.fetchExecutionHierarchy(this.lastRunLogId).then((executionTrace) => {
                if (!_.isEmpty(executionTrace)) this.executionTrace = executionTrace;
                else this.executionTrace = null;
                this.forceLoading = false;
            });
        }
    },
    methods: {
        ...mapActions({
            fetchExecutionHierarchy: 'runHistory/fetchExecutionHierarchy',
            deleteJob: 'dataSets/deleteDataSet',
            updateJobStatus: 'dataSets/updateJobStatus',
            executeDataSet: 'dataSets/executeDataSet',
        }),
        onDeleteJob() {
            this.deleteJob(this.job)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: LABELS.jobDeleteSuccess,
                    });
                    this.$router.replace({ name: 'sync-overview', params: { syncId: this.syncId } });
                })
                .catch((e) => {
                    this.$notify({
                        type: 'success',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
        onShowLogTrace(log) {
            this.showLogTrace = log;
        },
        statusClass(logEntry) {
            if (!logEntry) return '';
            if (logEntry.status === 'FINISHED_OK') return 'text-success';
            if (logEntry.status === 'ERROR') return 'text-danger';
            return 'text-primary';
        },
        statusText(logEntry) {
            if (!logEntry) return '-';
            if (logEntry.status === 'FINISHED_OK') return 'Success';
            if (logEntry.status === 'ERROR') return 'Error';
            return 'Running';
        },
        formatDuration(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            const endDatetime = _.get(logEntry, 'end_datetime');
            const startDate = this.$moment(startDatetime);
            const endDate = this.$moment(endDatetime);
            const duration = endDate.preciseDiff(startDate);

            return duration || '-';
        },
        formatStartDate(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            if (!startDatetime) return '-';

            return this.$moment.tz(this.$moment(startDatetime), this.user.timezone).calendar(null, {
                sameDay: '[Today at] LTS',
                lastDay: '[Yesterday at] LTS',
                lastWeek: 'ddd [at] LTS',
                sameElse: `${this.user.dateFormat} [at] LTS`,
            });
        },
        onTogglePaused() {
            const payload = {
                id: this.jobId,
                name: this.job.name,
                sourcecode: this.job.sourcecode,
                sourcename: this.job.sourceconnectionname,
                targetcode: this.job.targetcode,
                targetname: this.job.targetconnectionname,
                schedule_status: this.job.schedule_status === 'enabled'
                    ? 'disabled'
                    : 'enabled',
            };

            this.updateJobStatus(payload)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: 'Success',
                    });
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
        async onRunJob() {
            try {
                await this.executeDataSet(this.job);
                this.$notify({
                    type: 'success',
                    text: LABELS.jobExecutionSuccess,
                });
            } catch (e) {
                this.$notify({
                    type: 'error',
                    text: e.message || LABELS.jobExecutionError,
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.run-card {
    border-radius: $border-radius;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    min-height: 80px;

    &:hover {
        text-decoration: none;
        transform: scale(1.02);
    }
}

.run-card-grid {
    display: grid;
    justify-content: space-between;
    gap: 10px;
    column-gap: 50px;
    flex: 0;

    @include respond-to('small') {
        grid-template-columns: [status] 80px [duration] 300px [buttons] 1fr;

        .added-content,
        .changed-content,
        .staged-content {
            display: none;
        }
    }

    @include respond-to('large') {
        grid-template-columns: [status] 80px [duration] 300px [added] 80px [changed] 80px [staged] 80px [buttons] 1fr;

        .added-content,
        .changed-content,
        .staged-content {
            display: block;
        }
    }
}

.details-card-grid {
    display: grid;
    grid-template-columns: [col_1] 1fr [col_2] 200px [col_3] 1fr [col_4] 1fr;
    grid-template-rows: repeat(4, 1fr);
    justify-content: start;
    column-gap: 30px;
    row-gap: 20px;
}

.status-item {
    border-radius: 1rem;
    padding: 2px 8px;
    margin: auto 0;
    font-size: 14px;

    &__error {
        color: #df554d;
        background: rgba(234, 84, 85, 0.12);
    }

    &__success {
        background: rgba(101, 182, 101, 0.12);
        color: #65b665;
    }

    &__info {
        background: rgba(44, 103, 175, 0.12);
        color: #466fe4;
    }

    &__default {
        background: rgba(186, 191, 199, 0.12);
    }
}
</style>
