import Vue from 'vue';
import moment from 'moment';
import Config from '@/utils/config';
import { extendMoment } from 'moment-range';
import 'moment-precise-range-plugin';

const extendedMoment = extendMoment(moment);

extendedMoment.tz.setDefault(Config.timezone);

Vue.prototype.$moment = extendedMoment;
Vue.moment = extendedMoment;
