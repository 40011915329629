// todo: type->id->filters ?

import Vue from 'vue';
import * as api from '../api/profile.api';
import Filter from '../models/filter.model';

export default {
    namespaced: true,
    state: {
        collection: {},
    },
    getters: {
        mappedFilters({ collection }, getters, rootState, rootGetters) {
            return _(collection).map(filter => new Filter(filter, rootState, rootGetters)).value();
        },
    },
    mutations: {
        setFilter(state, filter) {
            Vue.set(state.collection, filter.id, filter);
        },
    },
    actions: {
        async getFilters(context, { id, type }) {
            return api.getFilters(id, type);
        },
        async saveFilter({ commit }, { id, type, filter }) {
            const eventName = filter.id ? `filters/saveFilter/${filter.id}` : 'filters/saveFilter';

            commit('activateLoading', eventName, { root: true });
            try {
                filter.values = _.isArray(filter.values) ? filter.values : [filter.values];
                const data = await api.saveFilter(id, type, filter);
                commit('setFilter', data);
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                commit('deactivateLoading', eventName, { root: true });
            }
        },
        async deleteFilter({ commit }, { filter }) {
            if (filter && filter.id) {
                commit('activateLoading', `filters/deleteFilter/${filter.id}`, { root: true });
                try {
                    await api.removeFilter(filter.id);
                } catch (e) {
                    console.error(e);
                    throw e;
                } finally {
                    commit('deactivateLoading', `filters/deleteFilter/${filter.id}`, { root: true });
                }
            }
        },
    },
};
