import JobOverview from '@/modules/jobs/views/jobOverview';
import AddJobExternal from '@/modules/jobs/views/jobSelection/addNewJobExternal';

export default [
    {
        path: '/syncs/:syncId/:jobId',
        name: 'job-overview',
        component: JobOverview,
        props: true,
        meta: {
            title: 'Job Overview',
            permissions: [],
            auth: 'view',
            hideSearch: false,
        },
    },
    {
        path: '/connection/sync/add-job-external',
        name: 'add-job-external',
        component: AddJobExternal,
        meta: {
            name: 'Add Job External',
            permissions: [],
            showOnlyPage: true,
            externalAccess: true,
        },
    },
];
