import _ from 'lodash';

// #region OBSERVABLE
class Observable {
    constructor() {
        this._observers = [];
    }

    addObserver(observer) {
        this._observers.push(observer);
    }

    removeObserver(observer) {
        const removeIndex = this._observers.findIndex((obs) => { return observer === obs; });

        if (removeIndex !== -1) {
            this._observers = this._observers.slice(removeIndex, 1);
        }
    }

    notify(data) {
        _.each(this._observers, observer => observer.update(data));
    }
}
// #endregion OBSERVABLE

const dynamicParameterDefaults = {
    accepted_values: undefined,
    condition_for_requirement: undefined,
    config_table: '',
    control: '',
    description: '',
    display_name: '',
    order_of_display: 1,
    parameter: '',
    required: false,
    user_defined: false,
    type: '',
    default_value: '',
    connection_parameter: false,
    section: null,
    grouped: false,
    details: false,
    bulk_create: false,
    bulk_applied: false,
    placeholder: '',
};

export default class DynamicParameter extends Observable {
    // #region PRIVATE ATRIBUTES
    _value; // used only for Vue reactivity, you should use the 'value' one

    _dependenciesValues = {} // store params values affecting accepted_values and contition_for_requirement

    _dependenciesToWatch = []; // store params keys affecting accepted_values and condition_for_requirements

    _config = {}; // store parameter configuration

    _fetchAcceptedValues;
    // #endregion PRIVATE ATRIBUTES

    // #region CONSTRUCTOR
    // receives initial value, configuration, and a callback to watch for value changes
    constructor(value = null, config = {}, fetchAcceptedValues = () => {}) {
        super();
        this._fetchAcceptedValues = fetchAcceptedValues;
        const _config = Object.assign({}, _.cloneDeep(dynamicParameterDefaults), _.cloneDeep(config));
        this._config = _config;
        const defaultValue = this.processDefaultValue();
        this.default_value = defaultValue;
        if (_config.control === 'boolean') {
            const val = value === false || value === 'false' ? false : (value || defaultValue);
            this._value = _.isBoolean(val) ? val : val === 'true';
        } else {
            this._value = value || defaultValue;
        }
        this.visible = _config.user_defined && !_config.condition_for_requirement;
        this.required = _config.required;
        this.dynamic = _.get(_config.accepted_values, 'dynamic', false);
        this.loading = false;
        this.accepted_values = [];

        const groups = _.isString(_config.default_value) && _config.default_value !== '<all>' && !_.isEmpty(_config.default_value) ? _config.default_value.match(/<(?:\w+)>/g) : [];
        let dependeciesToWatch = _.union([], _.map(groups, group => _.trim(group, '<>')));

        this.grouped = _config.grouped; // flag used to group options in dropdown fields
        this.details = _config.details; // flag used to show option id in dropdown fields

        this.section = _config.grouping; // used to group parameters into section on create/edit page
        this.disabled = _config.disabled;
        this.parameter = _config.parameter;
        this.control = _config.control;
        this.display_name = _config.display_name;
        this.description = _config.description;
        this.order_of_display = _config.order_of_display;
        this.bulk_create = _config.bulk_create || false;
        this.bulk_applied = _config.bulk_applied || false;
        this.bulk_hidden = _config.bulk_hidden || false;
        this.bulk_inline = _config.bulk_inline || false;
        this.placeholder = _config.placeholder;

        // add deps from accepted_values
        if (_config.accepted_values && _config.accepted_values.filtering_parameter) {
            dependeciesToWatch = _.union(dependeciesToWatch,
                _.isArray(_config.accepted_values.filtering_parameter)
                    ? _config.accepted_values.filtering_parameter
                    : [_config.accepted_values.filtering_parameter]);
        }

        this._dependenciesToWatch = dependeciesToWatch;
    }
    // #endregion CONSTRUCTOR

    // #region PUBLIC ATTRIBUTES
    get value() {
        return this._value;
    }

    set value(newVal) {
        if (!_.isEqual(this._value, newVal)) {
            this._value = newVal;
            this.notify({
                param: this._config.parameter,
                value: this._value,
            });
        }
    }

    get isValid() {
        return _.every(this.validations, (rule) => { return rule.validate(this.value); });
    }

    get isLoading() {
        return this.loading;
    }

    get validations() {
        const rules = Object.assign({}, this._config.validations);

        switch (this.control) {
            case 'multi-select':
            case 'list':
                rules.notEmpty = {
                    name: 'notEmpty',
                    message: `${this.display_name} cannot be empty`,
                    validate: () => {
                        return !!(!this.required
                        || (this.value && this.value.length > 0));
                    },
                };
                break;
            case 'cron':
                rules.validCron = {
                    name: 'validCron',
                    message: `${this.display_name} is invalid`,
                    validate: () => {
                        const length = _.words(this.value, /[^ ]+/g).length;
                        return !!(length >= 6 && length <= 7);
                    },
                };
                break;
            case 'boolean':
                rules.hasValue = {
                    name: 'hasValue',
                    message: `${this.display_name} is required`,
                    validate: () => {
                        return _.isBoolean(this.value) || this.value === 'true' || this.value === 'false';
                    },
                };
                break;
            case 'numeric':
                rules.hasValue = {
                    name: 'hasValue',
                    message: `${this.display_name} is required`,
                    validate: () => {
                        return _.isNumber(this.value) || !_.isNaN(_.parseInt(this.value));
                    },
                };
                break;
            case 'copy-readonly':
                // no validation for read-only
                rules.hasValue = {
                    name: 'hasValue',
                    message: '',
                    validate: () => {
                        return true;
                    },
                };
                break;
            default:
                rules.hasValue = {
                    name: 'hasValue',
                    message: `${this.display_name} is required`,
                    validate: () => {
                        if (!this.required) return true;
                        return !!this.value;
                    },
                };
        }
        return rules;
    }
    // #endregion PUBLIC ATTRIBUTES

    // #region PUBLIC METHODS
    refreshAcceptedValues(invalidateCache = false) {
        this.loading = true;
        return this._parameterAcceptedValues(invalidateCache).then((acceptedValues) => {
            this.accepted_values = acceptedValues;
            return this.accepted_values;
        }).catch(() => {
            this.accepted_values = [];
            return this.accepted_values;
        }).finally(() => {
            this.loading = false;
        });
    }

    observeDependencies(dependencies = {}) {
        _.each(this._dependenciesToWatch, (depKey) => {
            this._dependenciesValues[depKey] = _.get(dependencies[depKey], 'value');
            const dependency = _.get(dependencies, depKey, null);
            this._watchDependency(dependency);
        });
        this.update({});
    }

    get conditionForRequirementRule() {
        let conditions = null;
        if (this._config.condition_for_requirement) {
            if (!this._config.condition_for_requirement.operands) {
                conditions = this._config.condition_for_requirement;
            } else {
                switch (this._config.condition_for_requirement.operator) {
                    case 'AND':
                        conditions = {
                            all: _.map(this._config.condition_for_requirement.operands, (value, fact) => {
                                if (value === '<any>') return { fact, value: null, operator: 'notEqual' };
                                return { fact, value: _.isArray(value) ? value : [value], operator: 'in' };
                            }),
                        };
                        break;
                    default:
                        conditions = {
                            any: _.map(this._config.condition_for_requirement.operands, (value, fact) => {
                                if (value === '<any>') return { fact, value: null, operator: 'notEqual' };
                                return { fact, value: _.isArray(value) ? value : [value], operator: 'in' };
                            }),
                        };
                }
            }
        }

        return {
            conditions,
            event: { type: this.parameter },
            onSuccess: (event, almanac) => {
                // visiblity should change
                if (!this.visible) {
                    // console.log(`${event.type} visible`, almanac);
                    this.visible = true;
                    this.update({});
                }
            },
            onFailure: (event, almanac) => {
                // visiblity should change
                if (this.visible) {
                    // console.log(`${event.type} hidden`, almanac);
                    this.visible = false;
                    this.value = null;
                    this.update({});
                }
            },
        };
    }
    // #endregion PUBLIC METHODS */

    // #region PRIVATE_METHODS
    _watchDependency(dependecy) {
        if (dependecy) {
            if (dependecy instanceof DynamicParameter) {
                dependecy.addObserver(this);
            }
        }
    }

    update({ param, value }) {
        if (param) this._dependenciesValues[param] = value;
        if (this.visible) {
            const that = this;
            this.accepted_values_promise = this.refreshAcceptedValues().then(() => {
                if (_.size(that.accepted_values) === 1) {
                    if (that.control === 'multi-select' || that.control === 'list') that.value = _.map(that.accepted_values, 'id');
                    if (that.control === 'one-select') that.value = _.get(_.first(that.accepted_values), 'id', _.first(that.accepted_values));
                }

                const processedDefaultValue = that.processDefaultValue();
                if ((that.control === 'boolean' && (_.isNull(that.value) || _.isEqual(that.value, that.default_value)))
                    || (that.control !== 'boolean' && (_.isEmpty(that.value) || _.isEqual(that.value, that.default_value)) && (!_.isEmpty(processedDefaultValue)))) {
                    that.default_value = processedDefaultValue;
                    that.value = processedDefaultValue;
                }
            });
        }
    }

    processDefaultValue() {
        if (_.isString(this._config.default_value)) {
            if (this._config.default_value === '<all>') {
                return _.map(this.accepted_values, 'id');
            }

            const groups = this._config.default_value.match(/<(?:\w+)>/g);
            let processedDefaultValue = this._config.default_value;
            if (groups) {
                _.forEach(groups, (group) => {
                    const paramValue = _.get(this._dependenciesValues, _.trim(group, '<>'), '');
                    processedDefaultValue = _.replace(processedDefaultValue, new RegExp(group, 'g'), _.isEmpty(paramValue) ? '' : paramValue);
                });
                processedDefaultValue = _.trim(_.replace(processedDefaultValue, /([^a-z_1-9])+/gi, '_'), '_');
            }
            return processedDefaultValue;
        }

        return this._config.default_value;
    }

    _parameterAcceptedValues(invalidateCache = false) {
        if (!this._config.accepted_values) return Promise.resolve(this._config.accepted_values);
        if (_.isArray(this._config.accepted_values)) return Promise.resolve(this._config.accepted_values);
        if (_.isObject(this._config.accepted_values)) {
            const { filtering_parameter, results, dynamic } = this._config.accepted_values;
            if (!dynamic) {
                const defaultFilteredResults = _.get(_.find(results, { filtering_value: '<any>' }), 'filtered_results');
                return Promise.resolve(_.get(_.find(results, { filtering_value: this._dependenciesValues[filtering_parameter] }), 'filtered_results', defaultFilteredResults));
            }

            const parametersWithValues = _.isArray(filtering_parameter) ? filtering_parameter : [filtering_parameter];
            const prerequisiteValues = _.reduce(parametersWithValues, (result, param) => {
                return {
                    ...result,
                    [param]: _.get(this._dependenciesValues, param, ''),
                };
            }, {});

            return this._fetchAcceptedValues(this._config.parameter, prerequisiteValues, invalidateCache);
        }

        return Promise.resolve(this._config.accepted_values);
    }
    // #endregion PRIVATE_METHODS
}
