<script>

export default {
    inheritAttrs: false,
    props: {
        listen: {
            type: [Array, String],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideWhenLoading: {
            type: Boolean,
            default: false,
        },
        onlyWhenLoading: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: 'button',
        },
        loadingClass: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isLoading() {
            if (this.loading) return true;

            if (this.listen instanceof Array) {
                return this.listen.some(listenTo => this.$store.state.loading[listenTo]);
            }

            return this.$store.state.loading[this.listen];
        },
    },
    methods: {
        clicked(e) {
            this.$root.$emit('bv::hide::tooltip', this.$attrs.id);
            this.$emit('click', e);
        },
    },
    render(h) {
        const children = [];
        const vm = this;

        if (this.onlyWhenLoading && !this.isLoading) {
            return null;
        }

        if (this.isLoading) {
            if (this.$slots.loader) {
                children.push(this.$slots.loader);
            } else {
                // TODO: If default slot starts with <i> tag its only going to be duplicated by this - no loader will display
                // TODO: Its probably due to this restriction: https://vuejs.org/v2/guide/render-function.html#Constraints
                children.push([
                    h('span', {
                        attrs: {
                            class: 'fad fa-fw fa-spinner-third fa-spin',
                        },
                    }),
                ]);
            }
        }

        if ((!this.hideWhenLoading || !this.isLoading) || (this.onlyWhenLoading && this.isLoading)) {
            children.push(this.$slots.default);
        }
        return h(this.tag, {
            attrs: {
                ...this.$attrs,
                class: `loading-btn ${this.$vnode.data.staticClass} `
                    .concat(this.isLoading ? this.loadingClass : '')
                    .concat(this.isLoading || this.disabled ? ' disabled' : ''),
                disabled: this.isLoading || this.disabled,
            },
            on: {
                click(e) {
                    if (!vm.disabled) {
                        vm.clicked(e);
                    }
                },
            },
        },
        children);
    },
};
</script>
