import _ from 'lodash';
import { RULE_ENGINE_OPERATORS } from '@/utils/operators';
import { Engine } from 'json-rules-engine';
import DynamicParameter from './dynamicParameter.model';

const jobModelDefaults = {
    id: '',
    name: '',
    release_status: '',
    sourceconnectionname: '',
    sourcecode: '',
    source_icon: '',
    targetconnectionname: '',
    targetcode: '',
    target_icon: '',
    parameters: {},
    syncId: null,
    sections: [],
    schedule_status: 'enabled',
};

export default class JobModel {
    #data;

    #configuration;

    #requestQueue = [];

    constructor(data = {}, jobConfiguration = {}, fetchParameterData = () => {}) {
        const values = _.assignIn({}, _.cloneDeep(jobModelDefaults), _.cloneDeep(data));
        this.#data = values;
        this.#configuration = _.cloneDeep(jobConfiguration);
        this.selected = false;

        _.each(this.#data, (value, key) => {
            _.set(this, key, value);
        });

        this.sourcecode = this.#data.sourcecode || this.#configuration.sourcecode;
        this.sourceconnectionname = this.#data.sourceconnectionname || this.#configuration.sourceconnectionname;
        this.targetcode = this.#data.targetcode || this.#configuration.targetcode;
        this.targetconnectionname = this.#data.targetconnectionname || this.#configuration.targetconnectionname;

        this.id = values.id || `${values.sourcecode}_${values.name}_${values.sourceconnectionname || values.targetconnectionname}`;

        // Extract parameter groups. Default is 'General'
        this.sections = _.uniqBy(_.reduce(this.#configuration.parameters, (result, { group = 'General' }) => {
            return [...result, { display_name: group, id: group, expanded: false }];
        }, []), 'id');

        this.parameters = _.reduce(values.parameters, (res, value, parameter) => {
            return {
                ...res,
                [parameter]: {
                    value,
                    parameter,
                },
            };
        }, {});

        const ruleEngine = new Engine([], { allowUndefinedFacts: true });
        _.each(RULE_ENGINE_OPERATORS, (ruleFunction, ruleName) => {
            ruleEngine.addOperator(ruleName, ruleFunction);
        });
        this.ruleEngine = ruleEngine;
        this.facts = {};

        const parameters = _.orderBy(this.#configuration.parameters, 'order_of_display', 'asc');
        _.each(parameters, (paramConfig) => {
            const paramValue = _.get(values.parameters, paramConfig.parameter, null);
            const paramName = paramConfig.parameter;
            const parameter = new DynamicParameter(paramValue, paramConfig, (parameterName, prerequisiteValues, invalidateCache = false) => {
                return new Promise((resolve, reject) => {
                    const req = fetchParameterData({
                        invalidateCache,
                        query: {
                            parameter: parameterName,
                            prerequisite_values: prerequisiteValues,
                            sourcecode: this.sourcecode,
                            sourceconnectionname: this.sourceconnectionname,
                        },
                        external: !!sessionStorage.getItem('external_access_token'),
                    })
                        .then(resp => resolve(resp))
                        .catch(err => reject(err))
                        .finally(() => {
                            this.#requestQueue = _.without(this.#requestQueue, req);
                            console.log(this.name, 'removed', parameterName, 'from queue', this.#requestQueue.length);
                        });
                    this.#requestQueue = _.concat(this.#requestQueue, req);
                    console.log(this.name, 'added', parameterName, 'to queue:', this.#requestQueue.length);
                });
            });

            _.set(this.parameters, paramName, parameter);
        });

        _.each(this.parameters, (parameter) => {
            _.set(this.facts, parameter.parameter, parameter.value);
            if (parameter instanceof DynamicParameter) {
                if (parameter._config.condition_for_requirement) {
                    this.ruleEngine.addRule(parameter.conditionForRequirementRule);
                }

                parameter.observeDependencies(this.parameters);
                parameter.addObserver(this);
            }
        });

        this.initialLoading = this.ruleEngine.run(this.facts).then(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    console.log('queue', this.#requestQueue.length);
                    return Promise.allSettled(this.#requestQueue).then(resolve);
                }, 1000);
            });
        });

        // console.log('job:', this);
    }

    update({ param, value }) {
        this.facts[param] = value;
        this.ruleEngine.run(this.facts).then(() => {
            // console.log(`Runed because ${param} changed`);
        });
    }

    get payload() {
        const parameters = _(this.parameters)
            .filter((param) => {
                return param.visible || !(param instanceof DynamicParameter);
            })
            .value();

        return {
            name: this.name,
            syncId: this.syncId,
            sourcecode: this.sourcecode,
            sourceconnectionname: this.sourceconnectionname,
            targetcode: this.targetcode,
            targetconnectionname: this.targetconnectionname,
            release_status: this.isValid ? 'published' : 'draft',
            parameters: _.reduce(parameters, (result, { parameter, value }) => {
                return {
                    ...result,
                    [parameter]: value,
                };
            }, {}),
        };
    }

    get isValid() {
        const parameters = _(this.parameters)
            .filter((param) => {
                return param.visible && (param instanceof DynamicParameter);
            })
            .value();

        return _.every(parameters, {
            isValid: true,
            loading: false,
        });
    }

    get visibleParameters() {
        return _(this.parameters)
            .filter((param) => {
                return param.visible && (param instanceof DynamicParameter);
            })
            .sortBy('order_of_display')
            .value();
    }

    get groupedVisibleParameters() {
        return _(this.visibleParameters)
            .groupBy('section')
            .map((parameters, section) => {
                const name = (section !== 'null' && section !== 'undefined' && section !== '')
                    ? section
                    : undefined;
                return {
                    parameters,
                    name,
                    order: _.get(_.first(parameters), 'order_of_display'),
                    expanded: false,
                };
            })
            .sortBy('order')
            .value();
    }

    get isLoading() {
        return _.some(this.visibleParameters, 'isLoading');
    }

    get requestQueue() {
        return this.#requestQueue;
    }
}
