/* eslint-disable */

import Vue from 'vue';

function options(fiscalYearStart) {
    return [
        {
            group: 'Other',
            options: [
                {
                    label: 'All Time',
                    value: 'allTime',
                    range: {
                        start: Vue.moment().startOf('year').subtract(25, 'years'),
                        end: Vue.moment().endOf('year').add(20, 'years'),
                    },
                },
                {
                    label: 'Between',
                    value: 'custom',
                    control: 'rangePicker',
                },
                // {
                //     label: 'Week',
                //     value: 'customWeek',
                //     control: 'weekPicker',
                // },
            ],
        },
        {
            group: 'Current',
            options: [
                {
                    label: 'Today',
                    value: 'today',
                    range: {
                        start: Vue.moment().startOf('day'),
                        end: Vue.moment().endOf('day'),
                    },
                },
                {
                    label: 'This Week',
                    value: 'week',
                    range: {
                        start: Vue.moment().startOf('week'),
                        end: Vue.moment().endOf('week'),
                    },
                },
                {
                    label: 'This Month',
                    value: 'month',
                    range: {
                        start: Vue.moment().startOf('month'),
                        end: Vue.moment().endOf('month'),
                    },
                },
                {
                    label: 'This Quarter',
                    value: 'quarter',
                    range: {
                        start: Vue.moment().startOf('quarter'),
                        end: Vue.moment().endOf('quarter'),
                    },
                },
                {
                    label: 'This Year',
                    value: 'year',
                    range: {
                        start: Vue.moment().startOf('year'),
                        end: Vue.moment().endOf('year'),
                    },
                },
            ],
        },
        {
            group: 'Previous',
            options: [
                {
                    label: 'Yesterday',
                    value: 'yesterday',
                    range: {
                        start: Vue.moment().startOf('day').subtract(1, 'day'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                },
                {
                    label: 'Previous Week',
                    value: 'prevWeek',
                    range: {
                        start: Vue.moment().startOf('week').subtract(1, 'week'),
                        end: Vue.moment().endOf('week').subtract(1, 'week'),
                    },
                },
                {
                    label: 'Previous Month',
                    value: 'prevMonth',
                    range: {
                        start: Vue.moment().startOf('month').subtract(1, 'month'),
                        end: Vue.moment().endOf('month').subtract(1, 'month'),
                    },
                },
                {
                    label: 'Previous Quarter',
                    value: 'prevQuarter',
                    range: {
                        start: Vue.moment().startOf('quarter').subtract(1, 'quarter'),
                        end: Vue.moment().endOf('quarter').subtract(1, 'quarter'),
                    },
                },
                {
                    label: 'Previous 6 Months',
                    value: 'prev6Months',
                    range: {
                        start: Vue.moment().startOf('month').subtract(6, 'month'),
                        end: Vue.moment().endOf('month').subtract(1, 'month'),
                    },
                },
                {
                    label: 'Previous Year',
                    value: 'lastYear',
                    range: {
                        start: Vue.moment().startOf('year').subtract(1, 'year'),
                        end: Vue.moment().endOf('year').subtract(1, 'year'),
                    },
                },
                {
                    label: 'This Quarter, Previous Year',
                    value: 'quarterLastYear',
                    range: {
                        start: Vue.moment().startOf('quarter').subtract(1, 'year'),
                        end: Vue.moment().endOf('quarter').subtract(1, 'year'),
                    },
                },
            ],
        },
        {
            group: 'Next',
            options: [
                {
                    label: 'Tomorrow',
                    value: 'tomorrow',
                    range: {
                        start: Vue.moment().startOf('day').add(1, 'day'),
                        end: Vue.moment().endOf('day').add(1, 'day'),
                    },
                },
                {
                    label: 'Next Week',
                    value: 'nextWeek',
                    range: {
                        start: Vue.moment().startOf('week').add(1, 'week'),
                        end: Vue.moment().endOf('week').add(1, 'week'),
                    },
                },
                {
                    label: 'Next Month',
                    value: 'nextMonth',
                    range: {
                        start: Vue.moment().startOf('month').add(1, 'month'),
                        end: Vue.moment().endOf('month').add(1, 'month'),
                    },
                },
                {
                    label: 'Next Quarter',
                    value: 'nextQuarter',
                    range: {
                        start: Vue.moment().startOf('quarter').add(1, 'quarter'),
                        end: Vue.moment().endOf('quarter').add(1, 'quarter'),
                    },
                },
                {
                    label: 'Next Year',
                    value: 'nextYear',
                    range: {
                        start: Vue.moment().startOf('year').add(1, 'year'),
                        end: Vue.moment().endOf('year').add(1, 'year'),
                    },
                },
            ],
        },
        {
            group: 'Rolling',
            options: [
                {
                    label: 'Last 7 Days',
                    value: 'last7Days',
                    range: {
                        start: Vue.moment().startOf('day').subtract(7, 'days'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                },
                {
                    label: 'Last 30 Days',
                    value: 'last30Days',
                    range: {
                        start: Vue.moment().startOf('day').subtract(30, 'days'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                },
                {
                    label: 'Last 4 Weeks',
                    value: 'last4Weeks',
                    range: {
                        start: Vue.moment().startOf('day').subtract(4, 'weeks'),
                        end: Vue.moment().endOf('day').subtract(4, 'weeks'),
                    },
                },
                {
                    label: 'Last 3 Months',
                    value: 'last3Months',
                    range: {
                        start: Vue.moment().startOf('day').subtract(3, 'months'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                },
                {
                    label: 'Last 6 Months',
                    value: 'last6Months',
                    range: {
                        start: Vue.moment().startOf('day').subtract(6, 'months'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                },
                {
                    label: 'Last 12 Months',
                    value: 'last12Months',
                    range: {
                        start: Vue.moment().startOf('day').subtract(12, 'months'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                },
            ],
        },
        {
            group: 'To Full Month',
            options: [
                {
                    label: 'This Year To Full Month',
                    value: 'yearToFullMonth',
                    range: {
                        start() {
                            const date = Vue.moment().startOf('year');
                            if (date.isSame(Vue.moment().startOf('month'))) {
                                return date.subtract(1, 'year');
                            }
                            return date;
                        },
                        end: Vue.moment().subtract(1, 'month').endOf('month'),
                    },
                    help: 'From start of the year until end of last month',

                },
            ],
        },
        {
            group: 'To Date',
            options: [
                {
                    label: 'This Week To Date',
                    value: 'weekTY',
                    range: {
                        start: Vue.moment().startOf('week'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                    help: 'From start of the week until yesterday',
                },
                {
                    label: 'This Month To Date',
                    value: 'monthTY',
                    range: {
                        start() {
                            const date = Vue.moment().startOf('month');
                            if (date.isSame(Vue.moment().startOf('day'))) {
                                return date.subtract(1, 'month');
                            }
                            return date;
                        },
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                    help: 'From start of the month until yesterday',
                },
                {
                    label: 'This Quarter To Date',
                    value: 'quarterTY',
                    range: {
                        start() {
                            const date = Vue.moment().startOf('quarter');
                            if (date.isSame(Vue.moment().startOf('day'))) {
                                return date.subtract(1, 'quarter');
                            }
                            return date;
                        },
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                    help: 'From start of the quarter until yesterday',

                },
                {
                    label: 'This Year To Date',
                    value: 'yearTY',
                    range: {
                        start() {
                            const date = Vue.moment().startOf('year');
                            if (date.isSame(Vue.moment().startOf('day'))) {
                                return date.subtract(1, 'year');
                            }
                            return date;
                        },
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                    help: 'From start of the year until yesterday',

                },
                {
                    label: 'This Fiscal Year To Date',
                    value: 'fiscalYearTY',
                    range: {
                        start: fiscalYearStart
                            ? Vue.moment(fiscalYearStart)
                            : Vue.moment().startOf('year'),
                        end: Vue.moment().endOf('day').subtract(1, 'day'),
                    },
                    help: 'From start of the fiscal year until yesterday',
                },
            ],
        },
        {
            group: 'Rolling Range',
            options: [
                {
                    label: 'Previous ... To ...',
                    labels: {
                        full: 'From [from] [fromSuffix] ago to [to] [toSuffix] ago',
                        current: 'Current [period]',
                        previous: 'Previous [from] [fromSuffix]',
                        previousWithCurrentMonth: 'Previous [from] [fromSuffix] (including current [period])',
                    },
                    fromToPeriodSuffix: (period, number) => {
                        period = _.clone(period);

                        if (period === 'fiscalYear') {
                            period = 'fiscal year';
                        }

                        const suffix = number === 1 ? '' : 's';

                        return `${period}${suffix}`;
                    },
                    value: 'customDate',
                    control: 'fromToSelector',
                },
            ],
        },
    ];
}

function toDates(name, from, to) {
    return this.createByName(name, startDate, endDate).setNumbers(numbers).setStartingDay(startDayOfWeek).getFromTo();
}

function nameToObject(rangeName, fromDate, toDate, numbers = {}, startDayOfWeek = 1) {
    const ranges = _(options()).map('options').flatten().keyBy('value').value();
    const range = ranges[rangeName];

    if (range) {
        if (!range.range) {
            range.range = {
                start: fromDate,
                end: toDate,
            };
        }

        return range;
    }

    return {
        label: 'Unknown',
        range: {
            start: fromDate,
            end: toDate,
        },
    };
}

export default { options, nameToObject };
