<template>
    <a tabindex="" @click="toggle">
        <slot v-if="value" name="on" />
        <slot v-if="!value" name="off" />
    </a>
</template>

<script>
export default {
    name: '',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        toggle() {
            this.$emit('input', !this.value);
        },
    },
};
</script>
