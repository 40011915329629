<style lang="scss" scoped>
.status-item {
    border-radius: 1rem;
    padding: 1px 8px;
    margin: 2px auto;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;

    &__error {
        color: #df554d;
        background: rgba(234, 84, 85, 0.12);

        &_inside {
            background: transparent;
        }
    }

    &__success {
        background: rgba(101, 182, 101, 0.12);
        color: #65b665;
    }

    &__info {
        background: rgba(44, 103, 175, 0.12);
        color: #466fe4;
    }

    &__default {
        background: rgba(186, 191, 199, 0.12);
    }

    &__warning {
        color: #212529;
        background-color: #ffc107;
    }
}
</style>
<template>
    <b-modal
        :title="`Execution Trace - Job ${job ? `${job.name}` : 'Execution Trace' }`"
        :visible="show"
        size="lg"
        header-bg-variant="white"
        header-text-variant="default"
        @hide="close">

        <template #modal-title>
            <div class="text-sub-header font-weight-bold text-break">
                Execution Trace {{ job ? `- ${job.name}` : '' }}
            </div>
            <!-- <div v-if="job" class="mt-2">
                <b-badge pill variant="light" class="pt-0 pb-1 manage-billing-btn">
                    <loading-btn
                        class="btn btn-sm btn-link"
                        listen="runHistory/fetchExecutionHierarchy"
                        :disabled="!enableUpdateJson"
                        @click="updateJsonAction">
                        <span class="pb-1"> {{ enableUpdateJson
                                                ? 'New execution trace available'
                                                : 'Execution trace is up to date' }}
                            <i :class="`far fa-fw ${enableUpdateJson ? 'fa-sync' : 'fa-check'}`" />
                        </span>
                    </loading-btn>
                </b-badge>
                <b-badge pill variant="light" class="pt-0 pb-1 manage-billing-btn ml-2">
                    <loading-btn
                        v-can-access.level="'user'"
                        class="btn btn-sm btn-link"
                        :listen="`dataSets/executeDataSet/${job.id}`"
                        :disabled="!log.status"
                        @click="onExecuteJob">
                        <span class="pb-1"> Re-run Job
                            <i :class="`far fa-fw fa-play`" />
                        </span>
                    </loading-btn>
                </b-badge>
            </div> -->
        </template>
        <div class="overflow-auto" style="max-height: 700px">
            <div class="small mt-2">
                <div class="mb-2">Overall status:
                    <span
                        class="status-item"
                        :class="{
                            'status-item__error': log.status === 'ERROR',
                            'status-item__success': log.status === 'FINISHED_OK',
                            'status-item__info': !log.status }">
                        {{ statusLabel(log.status) }}
                    </span>
                </div>
                <div v-if="job && lastRunLogUpdate && log.status">
                    <span class="mr-2"> Last step, executed on {{ lastRunLogUpdate.startTime }}:</span>
                    <span class="text-code"
                          :class="{
                              'status-item__error': log.status === 'ERROR',
                              'status-item__success': log.status === 'FINISHED_OK',
                              'status-item__info': !log.status}">{{ lastRunLogUpdate.executionLabel }}</span>
                </div>
            </div>
            <div v-if="lastRunLogUpdate && !log.status" class="d-flex small">
                <span>Step currently executing:
                    <span class="text-code" :class="{
                        'status-item__error': log.status === 'ERROR',
                        'status-item__success': log.status === 'FINISHED_OK',
                        'status-item__info': !log.status}">
                        {{ lastRunLogUpdate.executionLabel }}
                    </span>, started on <b>{{ lastRunLogUpdate.startTime }}</b> </span>
                <div class="dot-flashing ml-3 mt-1" />
            </div>
            <hr>
            <loader :listen="`runHistory/fetchExecutionHierarchy/${runLogId}`" :first-time-only="true" :force-loading="isLoading">
                <json-viewer v-if="executionTrace" :json="executionTrace" />
                <div v-else>
                    Execution trace not available
                </div>
            </loader>
        </div>
        <template slot="modal-footer">
            <div class="d-flex flex-row justify-content-around w-50 mx-auto">
                <button class="btn btn-link" @click="close">
                    Close
                </button>
                <copy-btn v-if="executionTrace" class="btn btn-primary" :content="JSON.stringify(executionTrace)">
                    Copy execution
                </copy-btn>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CopyBtn from '@/components/global/buttons/copy-btn';
import JsonViewer from '@/components/global/controls/json-viewer';
import { LABELS } from '@/constants';

export default {
    components: { JsonViewer, CopyBtn },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        log: {
            type: Object,
            default: null,
        },
        job: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            search: '',
            executionTrace: null,
            enableUpdateJson: false,
            isLoading: false,
        };
    },
    watch: {
        lastRunLogUpdate: {
            handler() {
                this.enableUpdateJson = true;
            },
        },
    },
    computed: {
        ...mapGetters({
            runLogUpdate: 'runHistory/runLogUpdate',
            jobs: 'dataSets/myDataSets',
        }),
        lastRunLogUpdate() {
            return _.get(this.runLogUpdate, this.runLogId);
        },
        runLogId() {
            return _.get(this.log, 'run_id');
        },
    },
    async created() {
        this.isLoading = true;
        this.executionTrace = await this.fetchExecutionHierarchy(this.runLogId).finally(() => {
            this.isLoading = false;
        });
        if (_.isEmpty(this.executionTrace)) this.executionTrace = null;
    },
    methods: {
        ...mapActions({
            fetchExecutionHierarchy: 'runHistory/fetchExecutionHierarchy',
            executeDataSet: 'dataSets/executeDataSet',
        }),
        async copyTrace() {
            const text = JSON.stringify(this.executionTrace);

            try {
                await navigator.clipboard.writeText(text);
            } catch ($e) {
                console.log($e);
            }
        },
        close() {
            this.$emit('close');
        },
        async updateJsonAction() {
            this.isLoading = true;
            this.enableUpdateJson = false;
            this.executionTrace = await this.fetchExecutionHierarchy(this.lastRunLogUpdate.runId).finally(() => {
                this.isLoading = false;
            });
        },
        statusLabel(status) {
            if (status === 'FINISHED_OK') return 'FINISHED';
            return !status ? 'RUNNING' : status;
        },
        async onExecuteJob() {
            try {
                await this.executeDataSet(this.job);
                this.$notify({
                    type: 'success',
                    text: LABELS.jobExecutionSuccess,
                });
            } catch (e) {
                this.$notify({
                    type: 'error',
                    text: e.message || LABELS.jobExecutionError,
                });
            }
        },
    },
};
</script>
