<template>
    <div class="group-content">
        <div class="history-card-grid px-4">
            <div class="my-auto text-muted status-title">
                Job Name
            </div>
            <div class="text-muted my-auto status-sync">
                Sync
            </div>
            <div class="text-muted my-auto status-start">
                Start time
            </div>
            <div class="text-muted my-auto status-end">
                End time
            </div>
            <div class="text-muted my-auto status-duration">
                Duration
            </div>
        </div>

        <div v-for="log in items" :key="log.run_id" class="card history-card border-0 clickable" @click="onShowLogTrace(log)">
            <div class="card-body history-card-grid px-4 py-3">
                <div class="my-auto text-left status-title">
                    <span
                        class="status-item d-inline-flex"
                        :class="{
                            'status-item__error': log.status === 'ERROR',
                            'status-item__success': log.status === 'FINISHED_OK',
                            'status-item__info': log.status === 'RUNNING' }">
                        <div class="m-auto">
                            {{ log.status === 'FINISHED_OK' ? 'FINISHED' : (!log.status ? 'RUNNING' : log.status) }}
                        </div>
                    </span>
                    {{ log.name }}
                </div>
                <div class="d-flex flex-row justify-content-start no-wrap my-auto status-sync">
                    <div class="my-auto">
                        <div class="d-inline-block" v-b-tooltip.hover="log.sourceconnectionname">
                            <img :src="`${assetsStorage}/${log.sourcecode}.png`" height="40" width="40" class="my-auto mx-2">
                        </div>
                        <div class="my-auto d-inline-block">
                            <i class="svg-icon icon-arrow" />
                        </div>
                        <div class="d-inline-block" v-b-tooltip.hover="log.targetconnectionname">
                            <img :src="`${assetsStorage}/${log.targetcode}.png`" height="40" width="40" class="my-auto mx-2">
                        </div>
                    </div>
                </div>
                <div class="my-auto status-start">
                    {{ formatDate(log.start_datetime) }}
                </div>
                <div class="my-auto status-end">
                    {{ formatDate(log.end_datetime) }}
                </div>
                <div class="my-auto status-duration">
                    {{ formatDuration(log.start_datetime, log.end_datetime) }}
                </div>
            </div>
        </div>

        <execution-info
            v-if="!!showLogTrace"
            key="execution-info"
            :show="!!showLogTrace"
            :log="showLogTrace"
            @close="showLogTrace = null;" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/utils/config';
import ExecutionInfo from './execution-info';

export default {
    name: 'HistoryList',
    components: { ExecutionInfo },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            assetsStorage: Config.assetsStorage,
            showLogTrace: null,
        };
    },
    computed: {
        ...mapGetters({
            user: 'profile/user',
        }),
    },
    methods: {
        formatDate(runDate) {
            if (!runDate) return '-';
            const date = this.$moment(runDate);
            return this.$moment.tz(date, this.user.timezone).calendar(null, {
                sameDay: '[Today at] LTS',
                lastDay: '[Yesterday at] LTS',
                lastWeek: 'ddd [at] LTS',
                sameElse: `${this.user.dateFormat} [at] LTS`,
            });
        },
        formatDuration(startDatetime, endDatetime) {
            const startDate = this.$moment(startDatetime);
            const endDate = this.$moment(endDatetime);
            const duration = endDate.preciseDiff(startDate);

            return duration || '-';
        },
        onShowLogTrace(logEntry) {
            this.showLogTrace = logEntry;
        },
    },
};
</script>

<style lang="scss" scoped>
.history-card {
    color: $neutral-900;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    border-radius: $border-radius;
    margin: 10px 0;

    &:hover {
        text-decoration: none;
        transform: scale(1.02);
    }
}

.history-card-grid {
    display: grid;
    justify-content: space-between;
    gap: 10px;

    @include respond-to('small') {
        grid-template-columns: [name] 1fr [sync] 180px [duration] 100px;

        .status-end,
        .status-start {
            display: none;
        }
    }

    @include respond-to('medium') {
        grid-template-columns: [name] 1fr [sync] 180px [start_date] 200px [duration] 100px;

        .status-end {
            display: none;
        }

        .status-start {
            display: inline-block;
        }
    }

    @include respond-to('large') {
        grid-template-columns: [name] 1fr [sync] 180px [start_date] 200px [end_date] 200px [duration] 100px;

        .status-end,
        .status-start {
            display: inline-block;
        }
    }

    .status-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.status-item {
    border-radius: 1rem;
    padding: 2px 8px;
    margin: auto 0;
    font-size: 14px;

    &__error {
        color: #df554d;
        background: rgba(234, 84, 85, 0.12);
    }

    &__success {
        background: rgba(101, 182, 101, 0.12);
        color: #65b665;
    }

    &__info {
        background: rgba(44, 103, 175, 0.12);
        color: #466fe4;
    }

    &__default {
        background: rgba(186, 191, 199, 0.12);
    }
}

.svg-icon {
    width: 32px;
    height: 10px;
}
</style>
