/* eslint-disable camelcase */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import _ from 'lodash';
import Config from '@/utils/config';

const _defaults = {
    configuration: {},
    authorised: false,
};

class DataConnection {
    constructor(json = {}) {
        const values = _.assign({}, _.cloneDeep(_defaults), _.cloneDeep(json));
        this.name = values.name || values.connectionname;
        this.healthStatus = values.health_status;
        this.code = values.code;
        this.parameters = values.parameters;
        this.organisationId = values.organisationid;
        this.id = `${values.name || values.connectionname}_${values.code}`;

        this.authorised = values.authorised;
        this.releaseStatus = values.release_status;
        this.icon = `${Config.assetsStorage}/${values.code}.png`;
        this.setup_guide = values.setup_guide;
    }
}

class DataSourceConnection extends DataConnection {
    constructor(json) {
        super(json);
        this.connectionType = 'source';
    }
}

class DataTargetConnection extends DataConnection {
    constructor(json) {
        super(json);
        this.connectionType = 'target';
    }
}

class DataSet extends DataConnection {
    #json;

    constructor(json = {}) {
        const values = _.assign({}, _.cloneDeep(_defaults), _.cloneDeep(json));
        // TODO: Ask DE for this change
        // if (values.parameters) values.parameters.JOB_NAME = values.name;
        super(json);
        delete this.code;
        delete this.healthStatus;
        delete this.authorised;
        delete this.icon;
        this.#json = values;
        this.sourceconnectionname = values.sourceconnectionname;
        this.sourcecode = values.sourcecode;
        this.source_icon = `${Config.assetsStorage}/${values.sourcecode}.png`;
        this.name = values.name;
        this.id = values.id || `${values.sourcecode}_${values.name}_${values.sourceconnectionname || values.targetconnectionname}`;
        this.syncId = values.syncId;
        this.schedule_status = values.schedule_status || 'enabled';

        if (values.sourceconnectionname && values.sourcecode) {
            this.sourceId = `${values.sourceconnectionname}_${values.sourcecode}`;
        } else {
            this.sourceId = null;
        }

        this.targetconnectionname = values.targetconnectionname;
        this.targetcode = values.targetcode;
        this.target_icon = `${Config.assetsStorage}/${values.targetcode}.png`;
        if (values.targetconnectionname && values.targetcode) {
            this.targetId = `${values.targetconnectionname}_${values.targetcode}`;
        } else {
            this.targetId = null;
        }
        this.parameters = values.parameters || {};
    }
}

export {
    DataConnection,
    DataSourceConnection,
    DataTargetConnection,
    DataSet,
};
