<template>
    <b-modal title="Delete this member" :visible="!!user.id" size="md" @hide="onCancel">
        <div class="alert alert-danger d-flex" role="alert">
            <div class="pr-2">
                <i class="far fa-exclamation-triangle fa-fw " />
            </div>
            <span>
                Are you sure you want to delete <strong>{{ user.fullName }}</strong>?
                This action cannot be undone.
            </span>
        </div>

        <template slot="modal-footer">
            <div class="container-fluid px-0">
                <div class="d-flex flex-row justify-content-around">
                    <button class="btn btn-link" @click="onCancel">
                        Cancel
                    </button>
                    <loading-btn listen="users/deleteMember" class="btn btn-primary" @click="onDelete">
                        Delete
                    </loading-btn>
                </div>
            </div>
        </template>

    </b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    props: {
        user: {
            required: true,
            type: Object,
        },
    },
    computed: {
        ...mapState({
            loggedIn: s => s.profile.user,
        }),
        ...mapGetters({
            users: 'users/organisationInternalUsers',
        }),
    },
    methods: {
        ...mapActions({
            deleteMember: 'users/deleteMember',
        }),
        onDelete() {
            this.deleteMember({
                id: this.user.id,
                email: this.user.username,
                owner: _.get(_.find(this.users, { id: this.loggedIn.id }), 'username'),
            }).then(() => {
                this.$notify({
                    type: 'success',
                    text: 'Member was deleted',
                });
            }).catch((error) => {
                this.$notify({
                    type: 'error',
                    text: error.message || 'This user cannot be deleted. Please contact your administrator for more details.',
                });
            });
            this.$emit('close');
        },
        onCancel() {
            this.$emit('close');
        },
    },
};
</script>
