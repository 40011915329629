<template>
    <loader :listen="['profile/getUser', 'profile/getOrganisation']" class="router-view profile-container">
        <div class="card__wrapper">
            <div class="card card1 radius-3 border-0">
                <div class="card-header bg-transparent border-0 px-5 pt-5 d-flex flex-row">
                    <h1 class="text-header-md">
                        Account settings
                    </h1>
                    <loading-btn
                        listen="profile/updateProfile"
                        type="submit"
                        class="btn btn-lg ml-auto btn-primary"
                        :disabled="$errors.any() || !(dirty || uploadedFile)"
                        @click.prevent.stop="update">
                        Update Profile
                    </loading-btn>
                </div>
                <div class="card-body px-5 pt-0 pb-5">
                    <div class="form-row">
                        <!-- AVATAR -->
                        <div class="col-12 row pt-3 pb-4 ml-0">
                            <img class="profile-photo" :src="uploadedImage || user.photo">
                            <div class="d-flex flex-column justify-content-start ml-3">
                                <photo-upload-btn class="align-self-start py-2 px-3" :value="uploadedImage" @change="onUploadPhoto">
                                    <span class="text-light">Upload</span>
                                </photo-upload-btn>
                                <p class="text-sm text-muted font-italic pt-1 mb-0">
                                    You can upload images up to 256x256. Your avatar <br>shows up in your public profile and your team notifications.
                                </p>
                            </div>
                            <loading-btn
                                v-b-tooltip.hover title="Trigger changing password process"
                                listen="profile/requestPasswordChange"
                                class="btn btn-link text-nowrap my-auto ml-auto"
                                @click.prevent="changePassword">
                                Change Password
                            </loading-btn>
                        </div>
                        <div class="col-12 d-flex flex-row justify-content-between p-0 mt-3">
                            <div class="col-lg-6 form-group">
                                <label class="label">First name:</label>
                                <input
                                    v-model="user.firstName"
                                    v-validate="'required'" name="firstName" type="text" class="form-control input__text">
                                <form-control-error name="firstName" />
                            </div>
                            <div class="col-lg-6 form-group">
                                <label class="label">Last name:</label>
                                <input v-model="user.lastName" name="lastName" type="text" class="form-control input__text">
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-row justify-content-between p-0">
                            <div class="col-lg-6 form-group">
                                <label class="label">Location / Timezone:</label>
                                <timezone-selector v-model="user.timezone" v-validate="'required'" name="timezone" />
                            </div>
                            <div class="col-lg-6 form-group">
                                <label class="label">Date format:</label>
                                <date-format-selector v-model="user.dateFormat" name="dateFormat" />
                            </div>
                        </div>
                    </div>

                    <div class="col-12 p-0 mt-3">
                        <label>Job run notifications</label>
                        <div class="form-row">
                            <div class="custom-control custom-switch custom-switch-md d-flex flex-row clickable">
                                <input id="enableNotifications" v-model="user.shouldReceiveFailureNotificationEmail" type="checkbox" class="custom-control-input clickable">
                                <label class="custom-control-label user-select-none text-sm-disabled clickable" for="enableNotifications">
                                    <p class="text-sm text-muted font-italic ml-auto d-inline">These notifications will be sent to the Email address listed below.</p>
                                </label>
                                
                            </div>
                        </div>
                    </div>
                    <div class="p-0 mt-3">
                        <h4 class="text-header-sm text-secondary">
                            Account details
                        </h4>
                        <div class="d-flex flex-column mt-2">
                            <div class="d-flex flex-row justify-content-between px-0 pt-3 pb-1" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                                <div class="font-weight-bold">
                                    Account email
                                </div>
                                <div class="col-6">
                                    {{ user.username }}
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between px-0 pt-3 pb-1" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                                <div class="font-weight-bold">
                                    Account created
                                </div>
                                <div class="col-6">
                                    {{ formattedCreatedDate }}
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between px-0 pt-3 pb-1" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                                <div class="font-weight-bold">
                                    Jobs Run Count
                                </div>
                                <div class="col-6">
                                    {{ accountStripeDetails.totalDatasetRunCount }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card2 radius-3 p-4 border-0">
                <router-link
                    v-can-access.level="'view'"
                    :to="{ name: 'team' }"
                    class="card-body d-flex flex-row justify-content-between">
                    <div class="my-auto">
                        <h2 class="text-header-sm text-secondary font-weight-bold">
                            Your Organization
                        </h2>
                        <p class="body-text my-auto">
                            {{ user.organisation.name }}
                        </p>
                    </div>
                    <div class="my-auto">
                        <span class="font-weight-bold blue">
                            <i class="fa fa-chevron-right fa-fw" />
                        </span>
                    </div>
                </router-link>
            </div>
            <loader :listen="['stripe/getAllActiveProducts', 'stripe/getAllActivePrices', 'profile/organisation']" class="card card3 radius-3 border-0">
                <div class="card-body pt-5 plans d-flex flex-column justify-content-center align-items-center">
                    <h4 class="text-header-sm title-text blue font-weight-bold pb-2">
                        {{ currentPlanName || 'Subscribe to a plan' }}
                    </h4>
                    <!-- <p v-if="!activeProductId" class="plan__text blue mb-2">
                        and start moving your data easily
                    </p>
                    <p v-else class="plan__text blue mb-2">
                        <span v-if="currentPlan && currentPlan.description">{{ currentPlan.description }}</span>
                    </p>

                    <p v-if="activeProductId" class="text-italic my-5">
                        Your team is currently at
                        <span class="font-weight-bold">{{ `${accountStripeDetails.used || 0} GB` }}</span>
                        <span v-if="!accountStripeDetails.noLimit" class="font-weight-bold">{{ ` / ${accountStripeDetails.usageLimit} GB` }}</span>
                        <br>
                        for this month.
                    </p> -->
                    <p v-if="activeProductId" class="plan__text blue mb-2">
                        <span class="font-weight-bold">{{ currentPlanCurrency }} {{ currentPlanPrice }}</span>
                        / {{ currentPlanPeriod }}
                    </p>
                    <p v-else class="plan__text blue mb-2">
                        and start moving your data easily
                    </p>
                    <p v-if="activeProductId" class="plan__text blue mb-2">
                        <span class="font-weight-bold">{{ accountStripeDetails.monthlyAlottedDatasetRuns }}</span>
                        runs / {{ currentPlanPeriod }}
                    </p>

                    <p v-if="activeProductId" class="text-italic mt-5">
                        Your team is currently at
                        <span class="font-weight-bold">{{ accountStripeDetails.monthlyDatasetRunCount }}</span>
                        / <span class="font-weight-bold">{{ accountStripeDetails.monthlyAlottedDatasetRuns }}</span>
                        <br>
                        runs for this month.
                    </p>
                    <p v-else class="text-italic m-5">
                        Only pay for new or updated rows with unlimited connectors.
                    </p>
                    <router-link
                        v-can-access.level="'admin'"
                        v-b-tooltip.hover.left
                        :to="{ name: 'billing' }"
                        class="justify-self-end btn btn-lg btn-primary">
                        <span class="text-nowrap px-0" style="min-width: 200px;">
                            {{ activeProductId ? 'Upgrade plan' : 'View Pricing' }}
                        </span>
                    </router-link>
                </div>
            </loader>
        </div>
    </loader>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Config from '@/utils/config';

export default {
    name: 'ProfileDetails',
    data() {
        return {
            user: {},
            dirty: false,
            uploadedImage: null,
            uploadedFile: null,
        };
    },
    computed: {
        ...mapState({
            products: state => state.stripe.products,
            prices: state => state.stripe.prices,
        }),
        ...mapGetters({
            profile: 'profile/user',
            organisation: 'profile/organisation',
        }),
        locale() {
            return Config.locales[this.user.locale];
        },
        formattedCreatedDate() {
            const date = this.$moment(this.user.dateCreated);
            return this.$moment.tz(date, this.user.timezone).calendar(null, {
                sameDay: '[Today at] LTS',
                lastDay: '[Yesterday at] LTS',
                lastWeek: 'ddd [at] LTS',
                sameElse: `${this.user.dateFormat} [at] LTS`,
            });
        },
        accountStripeDetails() {
            return _.get(this.organisation, 'accountStripeDetails', {});
        },
        activeProductId() {
            return _.get(this.accountStripeDetails, 'lastProductId', _.get(this.accountStripeDetails, 'productId', null));
            // return _.get(this.accountStripeDetails, 'productId', null);
        },
        activePriceId() {
            return _.get(this.accountStripeDetails, 'lastPriceId', _.get(this.accountStripeDetails, 'priceId', null));
            // return _.get(this.accountStripeDetails, 'priceId', null);
        },
        currentPlan() {
            return _.find(this.products, { id: this.activeProductId });
        },
        currentPrice() {
            return _.find(this.prices, { id: this.activePriceId });
        },
        currentPlanPrice() {
            return _.get(this.currentPrice, 'unit_amount') / 100;
        },
        currentPlanPeriod() {
            return _.get(this.currentPrice, 'recurring.interval', '');
        },
        currentPlanName() {
            return _.get(this.currentPlan, 'name', '');
        },
        currentPlanCurrency() {
            switch (_.get(this.currentPrice, 'currency')) {
                case 'usd':
                    return '$';
                case 'gbp':
                    return '£';
                default:
                    return '$';
            }
        },
    },
    watch: {
        user: {
            deep: true,
            handler(current) {
                if (JSON.stringify(current) === JSON.stringify(this.profile)) {
                    this.dirty = false;
                } else this.dirty = true;
            },
        },
    },
    created() {
        Promise.allSettled([
            this.getAllProducts(),
            this.getAllPrices(),
        ]);
        this.user = _.cloneDeep(this.profile.toJSON());
        this.$store.watch(state => state.profile.user, () => {
            this.user = _.cloneDeep(this.profile.toJSON());
        });

        this.currentScale = this.user.techAbility || null;
        this.$track('view-profile-page');
    },
    methods: {
        ...mapActions({
            updateProfile: 'profile/updateProfile',
            updatePhoto: 'profile/updatePhoto',
            requestPasswordChange: 'profile/requestPasswordChange',
            getAllProducts: 'stripe/getAllActiveProducts',
            getAllPrices: 'stripe/getAllActivePrices',
        }),
        async update() {
            const promises = [];
            await this.$validator.validateAll();
            if (!this.$errors.any()) {
                if (this.dirty) promises.push(this.updateProfile(this.user));
                if (this.uploadedFile) promises.push(this.updatePhoto(this.uploadedFile));
                Promise.all(promises)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            text: 'Your profile has successfully been updated',
                        });
                    })
                    .catch((e) => {
                        this.$notify({
                            type: 'error',
                            text: e.message || 'An error occured',
                        });
                    });
            }
            this.$track('edit-profile');
        },
        async changePassword() {
            try {
                await this.requestPasswordChange();
                this.$notify({
                    text: 'Please check your email',
                    type: 'success',
                });
            } catch (error) {
                this.$notify({
                    text: _.get(error, 'message', 'Password change failed'),
                    type: 'error',
                });
            }
        },
        onUploadPhoto({ image, file }) {
            this.uploadedImage = image;
            this.uploadedFile = file;
        },
    },
};
</script>

<style lang="scss" scoped>
.card__wrapper {
    display: grid;
    grid-template-columns: 1fr 430px;
    grid-template-rows: 145px repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media (max-width: 1550px) {
        grid-template-columns: 1fr 300px;
    }

    @media (max-width: 1380px) {
        grid-template-columns: 1fr 280px;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }
}

.card1 { grid-area: 1 / 1 / 4 / 2; }

.card2 { grid-area: 1 / 2 / 2 / 3; }

.card3 {
    grid-area: 2 / 2 / 4 / 3;
    background-image: url('../../../../assets/img/plan-bg.svg');
    background-size: cover;
    max-height: 420px;
}

</style>
