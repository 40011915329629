import Vue from 'vue';
import * as api from '@/store/api/connection.api';

import { DataTargetConnection } from '@/store/models/dataConnection.model';
import { DataTargetConfiguration } from '@/store/models/dataConfiguration.model';

export default {
    namespaced: true,
    state: {
        availableDataTargets: {},
        myDataTargets: {},
        dataTargetsConfigurations: {},
        targetsExtraConfig: {},
    },
    getters: {
        availableDataTargets(state) {
            return state.availableDataTargets;
        },
        myDataTargets(state) {
            return state.myDataTargets;
        },
        dataTargetsConfigurations(state) {
            return state.dataTargetsConfigurations;
        },
        targetsExtraConfig(state) {
            return state.targetsExtraConfig;
        },
    },
    mutations: {
        setAvailableDataTargets(state, sources) {
            _.each(sources, (target) => {
                Vue.set(state.availableDataTargets, target.code, target);
            });
        },
        setMyDataTargets(state, connections) {
            _.each(connections, (connection) => {
                Vue.set(state.myDataTargets, connection.id, connection);
            });
        },
        setDataTargetConnection(state, connection) {
            Vue.set(state.myDataTargets, connection.id, connection);
        },
        deleteAllDataTargetConnections(state) {
            Vue.set(state, 'myDataTargets', {});
        },
        deleteDataTargetConnection(state, connectionId) {
            Vue.delete(state.myDataTargets, connectionId);
        },
        setDataTargetConfiguration(state, configuration) {
            Vue.set(state.dataTargetsConfigurations, configuration.code, configuration);
        },
        setTargetExtraConfig(state, configurations) {
            _.each(configurations, (config) => {
                Vue.set(state.targetsExtraConfig, _.get(config, 'code'), config);
            });
        },
    },
    actions: {
        async fetchAvailableDataTargets({ commit }, { refresh = false, external = false }) {
            commit('activateLoading', 'dataTargets/fetchAvailableDataTargets', { root: true });
            try {
                const targets = await api.fetchAvailableDataTargets(refresh, external);
                commit('setAvailableDataTargets', targets);
                return targets;
            } finally {
                commit('deactivateLoading', 'dataTargets/fetchAvailableDataTargets', { root: true });
            }
        },
        async fetchMyDataTargets({ commit }, { refresh = false, external = false }) {
            commit('activateLoading', 'dataTargets/fetchMyDataTargets', { root: true });
            try {
                const { data } = await api.fetchMyDataTargets({}, refresh, external);
                const connections = _.map(data, (item) => {
                    return new DataTargetConnection(item);
                });
                if (refresh) {
                    commit('deleteAllDataTargetConnections');
                }
                commit('setMyDataTargets', connections);
                return connections;
            } finally {
                commit('deactivateLoading', 'dataTargets/fetchMyDataTargets', { root: true });
            }
        },
        async fetchDataTargetConfiguration({ commit }, dataTargetCode, refresh = false) {
            commit('activateLoading', 'dataTargets/fetchDataTargetConfiguration', { root: true });
            try {
                const dataTargetConfig = await api.fetchDataTargetConfiguration(dataTargetCode, refresh);
                const configuration = new DataTargetConfiguration(_.first(dataTargetConfig));
                commit('setDataTargetConfiguration', configuration);
                return configuration;
            } finally {
                commit('deactivateLoading', 'dataTargets/fetchDataTargetConfiguration', { root: true });
            }
        },
        async saveDataTargetConnection({ commit }, dataTargetConfig) {
            commit('activateLoading', 'dataTargets/saveDataTargetConnection', { root: true });
            try {
                const { data } = await api.saveDataTargetConnection(dataTargetConfig);
                const connection = new DataTargetConnection(_.first(data));
                commit('setDataTargetConnection', connection);
                return connection;
            } finally {
                commit('deactivateLoading', 'dataTargets/saveDataTargetConnection', { root: true });
            }
        },
        async updateDestinationConnection({ commit }, dataTargetConfig) {
            commit('activateLoading', 'dataTargets/updateDestinationConnection', { root: true });
            try {
                const { data } = await api.updateDestinationConnection(dataTargetConfig);
                const connection = new DataTargetConnection(_.first(data));
                commit('setDataTargetConnection', connection);
                return connection;
            } finally {
                commit('deactivateLoading', 'dataTargets/updateDestinationConnection', { root: true });
            }
        },
        async testDataTargetConnection({ commit }, dataTargetConnection) {
            commit('activateLoading', 'dataTargets/testDataTargetConnection', { root: true });
            try {
                const result = await api.testDataTargetConnection(dataTargetConnection);
                switch (result.status) {
                    case 'FINISHED_OK':
                        return {
                            type: 'success',
                            text: 'Success',
                        };
                    case 'ERROR':
                        return {
                            type: 'error',
                            text: result.errMessage,
                        };
                    default:
                        return {};
                }
            } finally {
                commit('deactivateLoading', 'dataTargets/testDataTargetConnection', { root: true });
            }
        },
        async publishDataTargetConnection({ commit }, dataTargetConnection) {
            commit('activateLoading', 'dataTargets/publishDataTargetConnection', { root: true });
            try {
                const { data } = await api.publishDataTargetConnection(dataTargetConnection);
                const connection = new DataTargetConnection(_.first(data));
                commit('setDataTargetConnection', connection);
                return connection;
            } finally {
                commit('deactivateLoading', 'dataTargets/publishDataTargetConnection', { root: true });
            }
        },
        async deleteDataTargetConnection({ commit, dispatch }, dataTargetConnection) {
            commit('activateLoading', 'dataTargets/deleteDataTargetConnection', { root: true });
            try {
                const { data } = await api.deleteDataTargetConnection(dataTargetConnection);
                commit('deleteDataTargetConnection', dataTargetConnection.id);
                dispatch('dataSets/fetchMyDataSets', true, { root: true });
                return _.first(data);
            } finally {
                commit('deactivateLoading', 'dataTargets/deleteDataTargetConnection', { root: true });
            }
        },
        async fetchTargetsExtraConfiguration({ commit }, external = false) {
            commit('activateLoading', 'dataTargets/fetchTargetsExtraConfiguration', { root: true });
            try {
                const data = await api.fetchConfiguration('target', {}, false, external);
                commit('setTargetExtraConfig', data);
            } finally {
                commit('deactivateLoading', 'dataTargets/fetchTargetsExtraConfiguration', { root: true });
            }
        },
    },
};
