/* eslint-disable */
import client from './client';

/**************** START OF DATA SOURCES ********************/
export async function fetchAvailableDataSources(skip, externalAccess = false) {
    const response = await client.get('selfService/availableSources', {}, { cache: { skip: true, v2: true } }, externalAccess);
    return client.parseResponse(response);
}
export async function fetchMyDataSources(query = {}, skip) {
    const response = await client.get('selfService/sourceConnections', query, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}
export async function fetchDataSourceConfiguration(code, skip, externalAccess = false) {
    const response = await client.get('selfService/sourceConfiguration', { code }, { cache: { skip: true, v2: true } }, externalAccess);
    return client.parseResponse(response);
}
export async function saveDataSourceConnection(dataSourceConfig, externalAccess = false) {
    const response = await client.post('selfService/saveSource', dataSourceConfig, {}, externalAccess);
    return client.parseResponse(response);
}
export async function updateSourceConnection(dataSourceConfig) {
    const response = await client.put('selfService/saveSource', dataSourceConfig);
    return client.parseResponse(response);
}
export async function testDataSourceConnection(dataSourceConnection) {
    const response = await client.post('selfService/testSource', dataSourceConnection);
    return client.parseResponse(response);
}
export async function publishDataSourceConnection(dataSourceConnection) {
    const response = await client.put('selfService/publishSource', dataSourceConnection);
    return client.parseResponse(response);
}
export async function deleteDataSourceConnection({ code, name }) {
    const response = await client.delete('selfService/deleteSource', { code, name });
    return client.parseResponse(response);
}
/**************** END OF DATA SOURCES ********************/

/**************** START OF DATA TARGETS ********************/
export async function fetchAvailableDataTargets(skip, external = false) {
    const response = await client.get('selfService/availableTargets', {}, { cache: { skip: true, v2: true } }, external);
    return client.parseResponse(response);
}
export async function fetchMyDataTargets(query = {}, skip, external = false) {
    const response = await client.get('selfService/targetConnections', query, { cache: { skip: true, v2: true } }, external);
    return client.parseResponse(response);
}
export async function fetchDataTargetConfiguration(code, skip) {
    const response = await client.get('selfService/targetConfiguration', { code }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}
export async function saveDataTargetConnection(dataTargetConfig) {
    const response = await client.post('selfService/saveTarget', dataTargetConfig);
    return client.parseResponse(response);
}
export async function updateDestinationConnection(dataTargetConfig) {
    const response = await client.put('selfService/saveTarget', dataTargetConfig);
    return client.parseResponse(response);
}
export async function testDataTargetConnection(dataTargetConnection) {
    const response = await client.post('selfService/testTarget', dataTargetConnection);
    return client.parseResponse(response);
}
export async function publishDataTargetConnection(dataTargetConnection) {
    const response = await client.put('selfService/publishTarget', dataTargetConnection);
    return client.parseResponse(response);
}

export async function deleteDataTargetConnection({ code, name }) {
    const response = await client.delete('selfService/deleteTarget', { code, name });
    return client.parseResponse(response);
}
/**************** END OF DATA TARGETS ********************/

/**************** START OF DATA SETS ********************/
export async function fetchMyDataSets(query = {}, skip) {
    const response = await client.get('selfService/datasets', query, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function fetchDataSetConfiguration({ ...query }, skip) {
    const response = await client.get('selfService/datasetConfiguration', { ...query }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function saveDataSet(dataSet) {
    const response = await client.post('selfService/saveDataset', dataSet);
    return client.parseResponse(response);
}

export async function deleteDataSet({ sourcecode, sourceconnectionname, name }) {
    const response = await client.delete('selfService/deleteDataset', { sourcecode, sourceconnectionname, name });
    return client.parseResponse(response);
}

export async function executeDataSet({ sourcecode, sourceconnectionname, name }) {
    const response = await client.post('selfService/executeDataset', { sourcecode, sourceconnectionname, name });
    return client.parseResponse(response);
}

export async function fetchDynamicParameterValues(query, externalAccess = false) {
    const response = await client.post('selfService/dynamicParameterValues', query, { cache: { skip: true, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function uploadKeyFile(file, payload = {}) {
    const response = await client.upload('selfService/uploadFile', payload, file);
    return client.parseResponse(response);
}
/**************** END OF DATA SETS ********************/

export async function fetchConfiguration(type, query, refresh, externalAccess = false) {
    const response = await client.get('selfService/jobConfiguration', { ...query, job_display_level: type }, { cache: { skip: refresh, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function fetchAvailableBulkJobs(query, refresh = true, externalAccess = false) {
    const response = await client.post('selfService/availableJobs', { ...query,}, { cache: { skip: refresh, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function saveJobs(payload, externalAccess = false) {
    const response = await client.post('selfService/saveJobs', payload, { cache: { skip: true, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function updateJobsStatus(payload) {
    const response = await client.post('selfService/setJobsStatus', payload, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}
