<template>
    <input class="form-control" type="text" :value="filteredValue" @input="e => $emit('input', e.target.value)">
</template>

<script>
export default {
    name: 'TextField',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        filteredValue() {
            return this.value !== undefined ? this.value : this.attrs.default_value;
        },
    },
};
</script>
