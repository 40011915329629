<style lang="scss">
    .week-picker {
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        z-index: 1000;
    }

    .date-range-dropdown {
        max-height: 350px;
        overflow-y: auto;
        position: absolute;
        z-index: 10;

        .list-group {
            margin-top: 1px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
</style>

<template>
    <div v-on-clickaway.native="close" class="position-relative">
        <div class="form-control c-pointer" :disabled="disabled" @click="toggleDropdown()">
            <div class="d-flex align-items-center">
                <div class="flex-grow-1 text-truncate pr-2">
                    {{ visibleLabel }}
                </div>
                <div class="ml-auto">
                    <i class="fa fa-sort text-muted" />
                </div>
            </div>
        </div>
        <div v-if="showDropdown" ref="select" class="date-range-dropdown border rounded">
            <div v-for="(range, index) in availableRanges" :key="index" class="list-group list-group-flush border-top">
                <a class="list-group-item disabled small text-uppercase py-2 border-0 text-muted m-0">
                    {{ range.group }}
                </a>
                <a v-for="(option, key) in range.options" :key="key" class="list-group-item c-pointer py-2 border-0"
                   :class="{ active: option.value === selected }" @click="select(option)">
                    {{ option.label }}
                </a>
            </div>
        </div>
        <div ref="weekpicker" class="week-picker" />
        <date-range-rolling-picker v-if="showFromToSelector" ref="rolling" :value="dates" @input="setRolling" @close="close" />
        <date-range-calendar v-if="showRangePicker" ref="rangePicker" :filter="filter" :value="dates" @input="setDates" @close="close" />
        <small v-if="!hideDates && selectedFrom" class="form-text text-sm text-muted mt-2">{{ selectedFrom }} - {{ selectedTo }}</small>
    </div>
</template>

<script>
import moment from 'moment';
import Pikaday from 'pikaday';
import Popper from 'popper.js';
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import { Ranges } from '../../../../utils/config';

export default {
    mixins: [clickaway],
    props: {
        value: {
            // type: [Array, String],
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideDates: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        format: {
            type: String,
            required: false,
        },
        exclude: {
            type: Array,
            default: () => [],
        },
        includeOnly: {
            type: Array,
            default: () => [],
        },
        filter: {
            type: Object,
        },
    },
    data() {
        return {
            allowDestroy: true,
            showDropdown: false,
            popper: undefined,
            weekPicker: undefined,
            showRangePicker: false,
            showFromToSelector: false,
            selected: undefined,
            dates: {
                start: undefined,
                end: undefined,
                period: undefined,
            },
            ranges: [],
        };
    },
    computed: {
        ...mapGetters({
            user: 'profile/user',
            organisation: 'profile/organisation',
        }),
        availableRanges() {
            if (this.exclude.length) {
                return _.map(this.ranges, (range) => {
                    // eslint-disable-next-line
                    range.options = range.options.filter(range => {
                        return this.exclude.indexOf(range.value) === -1;
                    });
                    return range;
                });
            }
            return this.ranges;
        },
        selectedFrom() {
            if (this.selected === 'customDate') {
                return this.$moment().subtract(this.dates.start, `${this.dates.period}s`).format(this.getFormat());
            }
            return this.dates.start;
        },
        selectedTo() {
            if (this.selected === 'customDate') {
                return this.$moment().subtract(this.dates.end, `${this.dates.period}s`).format(this.getFormat());
            }
            return this.dates.end;
        },
        visibleLabel() {
            if (!this.selected) return this.placeholder ? this.placeholder : 'Select Dates';
            if (this.selected === 'customDate') return this.customDateLabel;

            return this.selectedRange.label;
        },
        customDateLabel() {
            let label = this.selectedRange.labels.full;

            if (+this.dates.start === 0 && +this.dates.end === 0) {
                label = this.selectedRange.labels.current || '';
            } else if (+this.dates.end === 1) {
                label = this.selectedRange.labels.previous || '';
            } else if (+this.dates.end === 0) {
                label = this.selectedRange.labels.previousWithCurrentMonth || '';
            }

            const replacingMap = {
                '[from]': this.dates.start,
                '[to]': this.dates.end,
                '[fromSuffix]': this.selectedRange.fromToPeriodSuffix(this.dates.period, +this.dates.start),
                '[toSuffix]': this.selectedRange.fromToPeriodSuffix(this.dates.period, +this.dates.end),
                '[period]': this.dates.period,
            };

            _.forEach(replacingMap, (value, key) => {
                label = label.replaceAll(key, value);
            });

            return label;
        },
        selectedRange() {
            return _(this.ranges).map('options').flatten().find({ value: this.selected });
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler({ value, ...range }) {
                this.setRanges();
                this.selected = value;
                if (this.selectedRange) {
                    this.dates = _.startsWith(this.selectedRange.value, 'custom') ? range : this.getDates(this.selectedRange.range);
                }
            },
        },
        showFromToSelector(showing) {
            if (showing) {
                this.$nextTick(() => {
                    this.popper = new Popper(this.$refs.select, this.$refs.rolling.$el, {
                        placement: 'right',
                    });
                });
            } else {
                if (this.selectedRange.range) {
                    this.dates = this.getDates(this.selectedRange.range);
                }

                if (this.popper) {
                    this.popper.destroy();
                    this.popper = undefined;
                }
            }
        },
        'organisation.startDayOfWeek': 'setRanges',
    },
    methods: {
        setRanges() {
            this.ranges = Ranges.options(this.organisation.fiscalYearStart);
        },
        destroy() {
            this.allowDestroy = true;
            this.showFromToSelector = false;
            this.showRangePicker = false;
            if (this.weekPicker) {
                this.weekPicker.destroy();
                this.weekPicker = undefined;
            }
        },
        close() {
            this.destroy();
            this.showDropdown = false;
        },
        toggleDropdown() {
            if (this.disabled) return;

            this.showDropdown = !this.showDropdown;
            if (this.showDropdown) {
                this.destroy();
            }
        },
        select(val) {
            this.destroy();
            if (val) {
                if (val.range) {
                    this.setDates({ value: val.value, ...val.range });
                    this.close();
                }
                if (val.control === 'fromToSelector') {
                    this.showFromToSelector = true;
                }
                if (val.control === 'weekPicker') {
                    this.allowDestroy = false;
                    this.showDropdown = false;
                    this.showWeekPicker();
                }
                if (val.control === 'rangePicker') {
                    this.dates = !this.filter ? this.dates : undefined;
                    this.showRangePicker = true;
                    this.showDropdown = false;
                }
            }
        },
        getFormat() {
            return this.format || this.user.getDateFormat();
        },
        getDates(range) {
            if (range.start && range.end) {
                const start = (_.isFunction(range.start) ? range.start() : range.start).format(this.getFormat());
                const end = (_.isFunction(range.end) ? range.end() : range.end).format(this.getFormat());

                return { start, end };
            }

            return range;
        },
        setDates({ value, ...range }) {
            const { start, end } = this.getDates(range);
            this.$emit('input', { start, end, value });
            this.dates = { start, end };
            this.selected = value;
        },
        showWeekPicker() {
            const vm = this;
            if (!this.weekPicker) {
                this.weekPicker = new Pikaday({
                    field: vm.$refs.weekpicker,
                    container: vm.$refs.weekpicker,
                    position: 'bottom left',
                    format: this.getFormat(),
                    firstDay: this.organisation.startDayOfWeek,
                    showWeekNumber: true,
                    pickWholeWeek: true,
                    showDaysInNextAndPreviousMonths: true,
                    enableSelectionDaysInNextAndPreviousMonths: true,
                    onSelect() {
                        vm.setDates({ value: 'customWeek', start: this.getMoment().startOf('week'), end: this.getMoment().endOf('week') });
                        vm.close();
                    },
                    parse(date) {
                        return moment(date).toDate();
                    },
                    onClose() {
                        if (!vm.allowDestroy) {
                            vm.showWeekPicker();
                        }
                    },

                });
            }
            this.weekPicker.show();
        },
        setRolling({ start, end, period }) {
            const value = 'customDate';
            this.$emit('input', { value, start, end, period });
            this.dates = { start, end, period };
            this.selected = value;
        },
    },
};
</script>
