import Config from '../config';

class NewRelicService {
    constructor() {
        this.enabled = true;
        this.licenseKey = '3b0da22e0d';
        this.applicationID = '354485254'; // Production

        if (Config.isDev) this.applicationID = '354439356'; // Develop
        if (Config.isStage) this.applicationID = '354467674'; // UAT
    }

    async init() {
        if (!this.enabled || Config.isLocal) return Promise.resolve('skipped');

        window.licenseKey = this.licenseKey;
        window.applicationID = this.applicationID;

        return this.load();
    }

    // eslint-disable-next-line class-methods-use-this
    load() {
        const script = document.createElement('script');
        script.setAttribute('src', '/assets/scripts/newRelic.js');
        script.setAttribute('type', 'text/javascript');
        document.getElementsByTagName('head')[0].appendChild(script);
        return Promise.resolve('loaded');
    }
}

export default new NewRelicService();
