<template>
    <div class="overflow-hidden">
        <div class="d-flex flex-row justify-content-between no-wrap p-2">
            <div class="d-flex flex-row justify-content-between no-wrap">
                <h3 class="mr-3 clickable user-select-none text-header-md">
                    Add new connection
                </h3>
            </div>
            <loading-btn class="btn btn-lg my-auto btn-primary" :disabled="disableSave" :listen="['dataSources/saveDataSourceConnection', 'dataTargets/saveDataTargetConnection', 'integrations/createIntegration']" @click="onSave">
                Save
            </loading-btn>
        </div>

        <div class="new-connection-wrapper">
            <div class="card connection-selection bg-white border-0">
                <div class="card-header bg-white p-4 border-bottom-0">
                    <div class="d-flex flex-row justify-content-between no-wrap">
                        <h3 class="m-1 clickable text-header__tab font-weight-bold" :class="{ active: activeTab == 'sources' }" @click="activeTab = 'sources'; searchTerm = '';">
                            Sources
                        </h3>
                        <h3 class="m-1 clickable text-header__tab font-weight-bold" :class="{ active: activeTab == 'destinations' }" @click="activeTab = 'destinations'; searchTerm = '';">
                            Destinations
                        </h3>
                        <h3 class="m-1 clickable text-header__tab font-weight-bold" :class="{ active: activeTab == 'integrations' }" @click="activeTab = 'integrations'; searchTerm = '';">
                            Integrations
                        </h3>
                    </div>
                    <div class="d-flex justify-content-center mt-4 mx-auto">
                        <input
                            v-model="searchTerm"
                            type="text"
                            class="form-control input__text w-100 pl-4"
                            placeholder="Search">
                        <div class="input-group-icon d-flex flex-content-center p-3">
                            <img src="/icons/search.svg" class="mw-100">
                        </div>
                    </div>
                </div>
                <div class="card-body scrollable-area overflow-auto pt-0 px-4">
                    <div v-for="item in filteredItems" :key="item.code" class="app-card my-3" :class="{ 'active': selectedConnection && (selectedConnection.code == item.code) }" @click="onConnectionSelected(item)">
                        <div class="app-card__wrapper">
                            <div :id="item.code || item.id" class="app-icon text-center">
                                <img class="app-logo" :src="`${assetsStorage}/${item.code}.png`">
                            </div>
                            <div class="app-content mt-2">
                                <div class="app-title mb-0">
                                    <span class="app-title-content" :title="item.name">
                                        {{ item.name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="selectedConnection" class="card connection-details flex-grow-1 border-0" style="min-height: 500px;">
                <div class="card-header bg-white p-4 border-bottom-0">
                    <span class="d-block text-sub-header font-weight-bold"> {{ selectedConnection.name }} details </span>
                </div>
                <div class="card-body pt-0 px-3 overflow-auto">
                    <loader :listen="['dataSources/fetchDataSourceConfiguration', 'dataTargets/fetchDataTargetConfiguration', 'integrations/fetchIntegrationConfiguration']" class="w-100 px-2">
                        <template v-if="connection">
                            <form-field v-if="connection.type == 'source' || connection.type == 'destination'"
                                        key="connectionName"
                                        ref="formControl"
                                        v-model="connectionName.value"
                                        class="custom-form-field mb-2 py-3"
                                        :config="connectionName"
                                        @input="onNameChanged"
                            />

                            <form-field v-for="field in visibleParameters"
                                        :key="field.parameter"
                                        ref="formControl"
                                        v-model="field.value"
                                        class="custom-form-field mb-2 py-3"
                                        :config="field"
                            />

                            <div v-if="configuration.authorisation_url" class="py-3 mb-2">
                                <loading-btn
                                    class="btn btn-primary btn-lg text-left"
                                    :class="`custom-btn ${connectionAuthorised || authorisationChanged ? '' : `custom-${SOURCE_BUTTON_STYLE[configuration.code]}-btn`}`"
                                    listen=""
                                    :hide-when-loading="true"
                                    :loading="loading"
                                    @click.prevent.stop="authorize">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <span v-if="!connectionAuthorised
                                            && !authorisationChanged" />
                                        <div v-if="!connectionAuthorised
                                            && !authorisationChanged" :class="`${SOURCE_BUTTON_STYLE[configuration.code]}-img`">
                                            {{ SOURCE_BUTTON_STYLE[configuration.code] ? '' : 'Authorize Account' }}
                                        </div>

                                        <i v-if="SOURCE_BUTTON_STYLE[configuration.code]
                                            && SOURCE_BUTTON_STYLE[configuration.code] !== 'google'
                                            && SOURCE_BUTTON_STYLE[configuration.code] !== 'slack'" class="fab" :class="`fa-${SOURCE_BUTTON_STYLE[configuration.code]}`" />
                                        <span v-if="connectionAuthorised || authorisationChanged" class="ml-2 authorised" />
                                    </div>

                                </loading-btn>
                            </div>
                        </template>
                    </loader>
                </div>
            </div>

            <div v-else class="card getting-started bg-white border-0 pt-2">
                <div class="mx-auto getting-started__info card-body">
                    <div class="mb-5 px-1">
                        <h3 class="text-sm-header font-weight-bold">
                            Sources
                        </h3>
                        <span>Sources like facebook, instagram, tiktok all gather data. By connecting these sources to the system, you can start pulling that data into a Destination using the Gravity platform.</span>
                        <p class="my-2">
                            <a :href="URLS.documentation.sources" target="_blank">Learn more</a>
                        </p>
                    </div>
                    <div class="mb-5 px-1">
                        <h3 class="text-sm-header font-weight-bold">
                            Destinations
                        </h3>
                        <span>Destinations is where the data you gather from your sources will end up being transfered to. These are usually databases of some sort.</span>
                        <p class="my-2">
                            <a :href="URLS.documentation.destinations" target="_blank">Learn more</a>
                        </p>
                    </div>
                    <div class="px-1">
                        <h3 class="text-sm-header font-weight-bold">
                            Integrations
                        </h3>
                        <span>We understand that efficiency is key and want to enable you to work faster and smarter. These integration tools will help you do that.</span>
                        <p class="my-2">
                            <a :href="URLS.documentation.integrations" target="_blank">Learn more</a>
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="selectedConnection && configuration && configuration.setup_guide" class="setup-guide card overflow-auto border-0">
                <div class="card-header bg-white border-0 p-4">
                    <span class="text-sub-header font-weight-bold" v-html="configuration.setup_guide.headerTitle" />
                    <p v-if="configuration.setup_guide.header" class="small mb-4 text-base line" v-html="configuration.setup_guide.header" />
                </div>
                <div class="bg-light card-body connection-desc bg-white px-4 pt-0 overflow-auto">
                    <ul class="list-group mb-3">
                        <li v-for="(item, index) in configuration.setup_guide.guideSteps" :key="index"
                            class="list-group-item bg-transparent border-0 small px-0 py-2 text-base line">
                            <div class="text-base line" v-html="item.content" />
                        </li>
                    </ul>

                    <h6 class="font-weight-bold text-base line" v-html="configuration.setup_guide.footerTitle" />
                    <p class="small text-base line" v-html="configuration.setup_guide.footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormField from '@/components/formField';
import { SOURCE_BUTTON_STYLE, URLS, LABELS } from '@/constants';
import ConnectionModel from '@/store/models/connection.model';
import DynamicParameter from '@/store/models/dynamicParameter.model';
import PopupWindow from '@/mixins/popupWindow';
import Config from '@/utils/config';

export default {
    name: 'NewConnection',
    components: { FormField },
    mixins: [PopupWindow],
    props: {
        tab: {
            type: String,
            required: false,
            default: 'sources',
        },
    },
    data() {
        return {
            SOURCE_BUTTON_STYLE,
            URLS,
            assetsStorage: Config.assetsStorage,
            activeTab: this.tab,
            searchTerm: '',
            selectedConnection: null,
            configuration: null,
            connection: null,
            authorizationPayload: null,
            connectionAuthorised: false,
            authorisationChanged: false,
            connectionName: null,
            loading: false,
            nameSettings: {
                control: 'text',
                parameter: 'name',
                required: true,
                display_name: 'Name',
                validations: {
                    allowedCharacters: {
                        name: 'allowedCharacters',
                        message: 'Invalid characters. Only underscore and aplahumeric characters are allowed',
                        validate(value) {
                            return (/^[a-z0-9_]+$/i).test(value);
                        },
                    },
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            availableSources: 'dataSources/availableDataSources',
            availableDestinations: 'dataTargets/availableDataTargets',
            availableIntegrations: 'integrations/integrationTypes',
            dataSourcesConfigurations: 'dataSources/dataSourcesConfigurations',
            dataTargetsConfigurations: 'dataTargets/dataTargetsConfigurations',
            integrationConfigurations: 'integrations/integrationConfigurations',
        }),
        filteredItems() {
            switch (this.activeTab) {
                case 'sources':
                    return _(this.availableSources)
                        .filter(({ name }) => _.includes(_.toLower(name), _.toLower(this.searchTerm)))
                        .sortBy(({ name }) => _.toLower(name))
                        .value();
                case 'destinations':
                    return _(this.availableDestinations)
                        .filter(({ name }) => _.includes(_.toLower(name), _.toLower(this.searchTerm)))
                        .sortBy(({ name }) => _.toLower(name))
                        .value();
                case 'integrations':
                    return _(this.availableIntegrations)
                        .filter(({ name }) => _.includes(_.toLower(name), _.toLower(this.searchTerm)))
                        .sortBy(({ name }) => _.toLower(name))
                        .value();
                default:
                    return [];
            }
        },
        visibleParameters() {
            return _(_.get(this.connection, 'parameters', {}))
                .filter('visible')
                .orderBy('order_of_display')
                .value();
        },
        disableSave() {
            return !this.connection
                || !this.connection.isValid
                || this.loading
                || ((this.connection.type === 'source' || this.connection.type === 'destination')
                    && (!this.connectionName.isValid || (this.configuration.authorisation_url && !this.connectionAuthorised)));
        },
    },
    methods: {
        ...mapActions({
            fetchDataSourceConfiguration: 'dataSources/fetchDataSourceConfiguration',
            fetchDataTargetConfiguration: 'dataTargets/fetchDataTargetConfiguration',
            fetchIntegrationConfiguration: 'integrations/fetchIntegrationConfiguration',
            saveSourceConnection: 'dataSources/saveDataSourceConnection',
            saveDestinationConnection: 'dataTargets/saveDataTargetConnection',
            saveIntegrationConnection: 'integrations/createIntegration',
            fetchDynamicParameterValues: 'integrations/fetchDynamicParameterValues',
        }),
        async fetchParameterValues({ query, invalidateCache }) {
            return this.fetchDynamicParameterValues({
                query: { ...query, integrationId: this.configuration.id },
                invalidateCache,
            });
        },
        onConnectionSelected(connection) {
            this.configuration = null;
            this.connectionName = null;
            this.connection = null;
            this.connectionAuthorised = false;
            this.authorisationChanged = false;
            this.authorizationPayload = null;
            this.loading = false;
            this.selectedConnection = connection;

            switch (this.activeTab) {
                case 'sources':
                    this.fetchDataSourceConfiguration({ dataSourceCode: connection.code })
                        .then((configuration) => {
                            this.connectionName = new DynamicParameter('', this.nameSettings);
                            this.configuration = configuration;
                            this.connection = new ConnectionModel({ code: configuration.code, type: 'source' }, configuration);
                        });
                    break;
                case 'destinations':
                    this.fetchDataTargetConfiguration(connection.code)
                        .then((configuration) => {
                            this.connectionName = new DynamicParameter('', this.nameSettings);
                            this.configuration = configuration;
                            this.connection = new ConnectionModel({ code: configuration.code, type: 'destination' }, configuration);
                        });
                    break;
                case 'integrations':
                    this.fetchIntegrationConfiguration(connection.code)
                        .then((configuration) => {
                            this.configuration = configuration;
                            this.connection = new ConnectionModel({ code: configuration.code, type: 'integration' }, configuration, this.fetchParameterValues);
                        });
                    break;
                default:
                    break;
            }
        },
        authorize() {
            this.loading = true;
            const authLink = this.formAuthorisationLink();
            this.openSignInWindow(authLink, this.connectionName)
                .then((payload) => {
                    this.authorizationPayload = payload;
                    this.authorisationChanged = true;
                    this.connectionAuthorised = true;
                })
                .catch((e) => {
                    console.log('failed message', e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formAuthorisationLink() {
            const groups = this.configuration.authorisation_url.match(/<(?:\w+)>/g);
            let authURL = this.configuration.authorisation_url;

            _.each(groups, (group) => {
                const parameter = _.get(this.connection.parameters, _.trim(group, '<>'), '');
                const value = _.get(parameter, 'value', '');
                if (parameter && value) authURL = _.replace(authURL, group, value);
            });

            return authURL;
        },
        onSave() {
            const payload = {
                ...this.connection.payload,
                authorization: this.authorizationPayload || undefined,
            };
            this.loading = true;

            switch (this.connection.type) {
                case 'source':
                    this.saveSourceConnection({ payload })
                        .then((connection) => {
                            this.$notify({
                                type: 'success',
                                text: LABELS.sourceCreateSuccess,
                            });
                            this.$emit('close', {
                                type: 'source',
                                connection,
                            });
                        })
                        .catch((e) => {
                            // Force user to reauthenticate because token was already exchanged by DE
                            this.authorizationPayload = undefined;
                            this.authorisationChanged = false;
                            this.connectionAuthorised = false;
                            this.$notify({
                                type: 'error',
                                text: e.message || LABELS.defaultError,
                            });
                        }).finally(() => { this.loading = false; });
                    break;
                case 'destination':
                    this.saveDestinationConnection(payload)
                        .then((connection) => {
                            this.$notify({
                                type: 'success',
                                text: LABELS.destinationCreateSuccess,
                            });
                            this.$emit('close', {
                                type: 'destination',
                                connection,
                            });
                        })
                        .catch((e) => {
                            // Force user to reauthenticate because token was already exchanged by DE
                            this.authorizationPayload = undefined;
                            this.authorisationChanged = false;
                            this.connectionAuthorised = false;
                            this.$notify({
                                type: 'error',
                                text: e.message || LABELS.defaultError,
                            });
                        }).finally(() => { this.loading = false; });
                    break;
                case 'integration':
                    this.saveIntegrationConnection(payload)
                        .then((connection) => {
                            this.$notify({
                                type: 'success',
                                text: LABELS.integrationCreateSuccess,
                            });
                            this.$emit('close', {
                                type: 'integration',
                                connection,
                            });
                        })
                        .catch((e) => {
                            this.$notify({
                                type: 'error',
                                text: e.message || LABELS.defaultError,
                            });
                        }).finally(() => { this.loading = false; });
                    break;
                default:
                    break;
            }
        },
        onNameChanged(value) {
            this.connection.name = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.connection-details {
    width: 100%;
    min-width: 400px;
}

.new-connection-wrapper {
    margin-top: 20px;
    gap: 30px;
    display: grid;
    align-content: stretch;
    align-items: stretch;
    grid-auto-flow: column dense;

    @include respond-to('medium') {
        grid-template-columns: 550px 650px;
        grid-template-rows: minmax(0, 1fr) 700px;
    }

    @include respond-to('large') {
        grid-template-columns: 550px 500px 500px;
        grid-template-rows: 750px;
    }

    .connection-selection {
        justify-self: stretch;
        align-self: stretch;

        @include respond-to('medium') {
            grid-column: 1;
            grid-row: 2;
        }

        @include respond-to('large') {
            grid-column: span 1;
            grid-row: span 1;
        }
    }

    .connection-details {
        justify-self: stretch;
        align-self: stretch;
        min-height: 500px;

        @include respond-to('medium') {
            grid-column: 2;
            grid-row: 2;
        }

        @include respond-to('large') {
            grid-column: 2;
            grid-row: 1;
        }
    }

    .setup-guide {
        @include respond-to('medium') {
            grid-column: span 2;
            grid-row: 1;
        }

        @include respond-to('large') {
            grid-column: span 1;
            grid-row: span 1;
        }
    }

    .getting-started {
        @include respond-to('medium') {
            grid-column: 2;
            grid-row: 2;
        }

        @include respond-to('large') {
            grid-column: span 2;
            grid-row: 1;
        }

        &__info {
            span {
                line-height: 28px;
            }
        }
    }
}
</style>
