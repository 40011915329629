import Vue from 'vue';
import * as api from '@/store/api/syncs.api';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        mySyncs: {},
    },
    getters: {
        mySyncs(state) {
            return state.mySyncs;
        },
    },
    mutations: {
        setSyncs(state, syncs) {
            Vue.set(state, 'mySyncs', {});
            _.each(syncs, (sync) => {
                if (sync) {
                    Vue.set(state.mySyncs, sync.id, {
                        schedule_status: 'enabled', // TODO: remove after BE adds this property
                        ...sync,
                    });
                }
            });
        },
        setSync(state, sync) {
            if (sync) {
                Vue.set(state.mySyncs, sync.id, {
                    schedule_status: 'enabled', // TODO: remove after BE adds this property
                    ...sync,
                });
            }
        },
        deleteSync(state, syncId) {
            if (syncId) Vue.delete(state.mySyncs, syncId);
        },
        setSyncStatus(state, sync) {
            if (sync && sync.syncId) {
                Vue.set(state.mySyncs[sync.syncId], 'schedule_status', sync.schedule_status);
            }
        },
    },
    actions: {
        async fetchSyncs({ commit }, { refresh = false, external = false }) {
            commit('activateLoading', 'syncs/fetchSyncs', { root: true });
            try {
                const syncs = await api.fetchSyncs(refresh, external);
                commit('setSyncs', syncs);
                return syncs;
            } finally {
                commit('deactivateLoading', 'syncs/fetchSyncs', { root: true });
            }
        },
        async saveSync({ commit }, payload) {
            commit('activateLoading', 'syncs/saveSync', { root: true });
            try {
                const external = payload.external;
                delete payload.external;

                const sync = await api.saveSync(payload, external);
                commit('setSync', sync);
                return sync;
            } finally {
                commit('deactivateLoading', 'syncs/saveSync', { root: true });
            }
        },
        async updateSync({ commit }, payload) {
            commit('activateLoading', 'syncs/updateSync', { root: true });
            try {
                const sync = await api.updateSync(payload);
                commit('setSync', sync);
                return sync;
            } finally {
                commit('deactivateLoading', 'syncs/updateSync', { root: true });
            }
        },
        async deleteSync({ commit }, syncId) {
            commit('activateLoading', 'syncs/deleteSync', { root: true });
            try {
                const sync = await api.deleteSync(syncId);
                commit('deleteSync', syncId);
                return sync;
            } finally {
                commit('deactivateLoading', 'syncs/deleteSync', { root: true });
            }
        },
        async updateSyncStatus({ commit }, sync) {
            commit('activateLoading', `syncs/updateSyncStatus/${sync.id}`, { root: true });
            try {
                await api.updateSyncStatus(sync);
                commit('setSyncStatus', {
                    syncId: sync.id,
                    schedule_status: sync.schedule_status,
                });
                commit('dataSets/setSyncJobsStatus', {
                    syncId: sync.id,
                    schedule_status: sync.schedule_status,
                }, { root: true });
                return sync;
            } finally {
                commit('deactivateLoading', `syncs/updateSyncStatus/${sync.id}`, { root: true });
            }
        },
    },
};
