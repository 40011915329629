<template>
    <div class="custom-field-wrapper" :class="`custom-${control}`">
        <component :is="control"
                   ref="formField"
                   v-model="$v.controlValue.$model"
                   :disabled="disabled"
                   :name="config.parameter"
                   :attrs="config"
                   class="form-field"
                   :class="{ 'is-invalid': $v.controlValue.$invalid, 'is-valid': !$v.controlValue.$invalid }"
                   @input="onChange"
        />
        <div class="invalid-feedback">
            <template v-for="rule in config.validations">
                <span v-if="!$v.controlValue[rule.name] && $v.controlValue.$anyDirty" :key="rule.name">
                    {{ rule.message }}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import fieldText from './field-text';
import fieldCopyReadonly from './field-copy-readonly';
import fieldNumeric from './field-numeric';
import fieldPassword from './field-password';
import fieldBoolean from './field-boolean';
import fieldOneSelect from './field-one-select';
import fieldMultiSelect from './field-multi-select';
import fieldDatetime from './field-datetime';
import fieldCron from './field-cron';
import fieldFileUpload from './field-file-upload';
import fieldList from './field-list';
import fieldTable from './field-table';

export default {
    name: 'FormField',
    components: {
        fieldText,
        fieldNumeric,
        fieldPassword,
        fieldBoolean,
        fieldOneSelect,
        fieldMultiSelect,
        fieldDatetime,
        fieldCron,
        fieldFileUpload,
        fieldList,
        fieldCopyReadonly,
        fieldTable,
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
        validateOnCreate: {
            type: Boolean,
            default: false,
        },
        value: {},
        disabled: {
            default: false,
        },
    },
    data() {
        return {
            controlValue: this.value,
        };
    },
    computed: {
        control() {
            return `field-${this.config.control || 'text'}`.toLowerCase();
        },
    },
    watch: {
        value: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.controlValue) {
                    this.controlValue = newVal;
                    this.$v.controlValue.$touch();
                }
            },
        },
    },
    validations() {
        return {
            controlValue: _.reduce(this.config.validations || {}, (rules, { validate, name }) => {
                return {
                    ...rules,
                    [name]: validate,
                };
            }, {}),
        };
    },
    created() {
        if (this.validateOnCreate) {
            this.$v.controlValue.$touch();
        } else {
            this.$v.controlValue.$reset();
        }
    },
    methods: {
        onChange(value) {
            if (this.controlValue !== value) {
                this.controlValue = value;
                this.$v.controlValue.$touch();
            }
            if (_.isArray(this.value)) {
                if (!_.isEqual(_.orderBy(this.value), _.orderBy(value))) {
                    this.$emit('input', value);
                }
            } else if (!_.isEqual(this.value, value)) {
                this.$emit('input', value);
            }
        },
    },
};
</script>
