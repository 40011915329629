import Vue from 'vue';
import * as api from '@/store/api/integrations.api';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        integrationTypes: {},
        integrationConfigurations: {},
        myIntegrations: {},
        policyConfigurations: {},
        myPolicies: {},
    },
    getters: {
        integrationTypes(state) {
            return _.map(state.integrationTypes);
        },
        integrationConfigurations(state) {
            return state.integrationConfigurations;
        },
        myIntegrations(state) {
            return _.map(state.myIntegrations, (settings) => {
                return {
                    ...settings,
                    get name() {
                        return _.get(settings.parameters, 'name');
                    },
                };
            });
        },
        policyConfigurations(state) {
            return state.policyConfigurations;
        },
        myPolicies(state) {
            return state.myPolicies;
        },
        integrationPolicies(state) {
            return (integrationId) => {
                return _.filter(state.myPolicies, { integrationId });
            };
        },
    },
    mutations: {
        setIntegrationTypes(state, integrations) {
            Vue.set(state, 'integrationTypes', {});
            _.each(integrations, (integration) => {
                if (integration) Vue.set(state.integrationTypes, integration.code, integration);
            });
        },
        setIntegrationConfiguration(state, configuration) {
            if (configuration) {
                Vue.set(state.integrationConfigurations, configuration.code, {
                    ...configuration,
                    parameters: _.map(configuration.parameters, (paramConfig) => { return { ...paramConfig, user_defined: true }; }),
                });
            }
        },
        setMyIntegrations(state, integrations) {
            Vue.set(state, 'myIntegrations', {});
            _.each(integrations, (integration) => {
                Vue.set(state.myIntegrations, integration.id, integration);
            });
        },
        setIntegration(state, integration) {
            Vue.set(state.myIntegrations, integration.id, integration);
        },
        deleteIntegration(state, id) {
            Vue.delete(state.myIntegrations, id);
        },
        setPolicyConfiguration(state, config) {
            Vue.set(state.policyConfigurations, config.integrationId, {
                ...config,
                parameters: _.map(config.parameters, (paramConfig) => { return { ...paramConfig, user_defined: true }; }),
            });
        },
        setMyPolicies(state, policies) {
            Vue.set(state, 'myPolicies', {});
            _.each(policies, (policy) => {
                Vue.set(state.myPolicies, policy.id, policy);
            });
        },
        setPolicy(state, policy) {
            Vue.set(state.myPolicies, policy.id, policy);
        },
        deletePolicy(state, id) {
            Vue.delete(state.myPolicies, id);
        },
    },
    actions: {
        fetchIntegrationTypes({ commit }, { refresh = false, external = false }) {
            commit('activateLoading', 'integrations/fetchIntegrationTypes', { root: true });
            return api.fetchIntegrationTypes(refresh, external)
                .then((result) => {
                    commit('setIntegrationTypes', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/fetchIntegrationTypes', { root: true });
                });
        },
        fetchIntegrationConfiguration({ commit }, code) {
            commit('activateLoading', 'integrations/fetchIntegrationConfiguration', { root: true });
            return api.fetchIntegrationConfiguration(code)
                .then((result) => {
                    commit('setIntegrationConfiguration', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/fetchIntegrationConfiguration', { root: true });
                });
        },
        fetchMyIntegrations({ commit }, refresh = false) {
            commit('activateLoading', 'integrations/fetchMyIntegrations', { root: true });
            return api.fetchMyIntegrations(refresh)
                .then((result) => {
                    commit('setMyIntegrations', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/fetchMyIntegrations', { root: true });
                });
        },
        createIntegration({ commit }, payload) {
            commit('activateLoading', 'integrations/createIntegration', { root: true });
            return api.createIntegration(payload)
                .then((result) => {
                    commit('setIntegration', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/createIntegration', { root: true });
                });
        },
        updateIntegration({ commit }, payload) {
            commit('activateLoading', 'integrations/updateIntegration', { root: true });
            return api.updateIntegration(payload)
                .then((result) => {
                    commit('setIntegration', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/updateIntegration', { root: true });
                });
        },
        deleteIntegration({ commit }, integrationId) {
            commit('activateLoading', 'integrations/deleteIntegration', { root: true });
            return api.deleteIntegration(integrationId)
                .then((result) => {
                    commit('deleteIntegration', integrationId);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/deleteIntegration', { root: true });
                });
        },
        fetchPolicyConfiguration({ commit }, integrationId) {
            commit('activateLoading', 'integrations/fetchPolicyConfiguration', { root: true });
            return api.fetchPolicyConfiguration(integrationId)
                .then((parameters) => {
                    const config = { integrationId, parameters };
                    commit('setPolicyConfiguration', config);
                    return config;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/fetchPolicyConfiguration', { root: true });
                });
        },
        fetchMyPolicies({ commit }) {
            commit('activateLoading', 'integrations/fetchMyPolicies', { root: true });
            return api.fetchMyPolicies()
                .then((result) => {
                    commit('setMyPolicies', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/fetchMyPolicies', { root: true });
                });
        },
        createPolicy({ commit }, policy) {
            commit('activateLoading', 'integrations/createPolicy', { root: true });
            return api.createPolicy(policy)
                .then((result) => {
                    commit('setPolicy', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', 'integrations/createPolicy', { root: true });
                });
        },
        updatePolicy({ commit }, policy) {
            commit('activateLoading', `integrations/updatePolicy/${policy.id}`, { root: true });
            return api.updatePolicy(policy)
                .then((result) => {
                    commit('setPolicy', result);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', `integrations/updatePolicy/${policy.id}`, { root: true });
                });
        },
        deletePolicy({ commit }, policyId) {
            commit('activateLoading', `integrations/deletePolicy/${policyId}`, { root: true });
            return api.deletePolicy(policyId)
                .then((result) => {
                    commit('deletePolicy', policyId);
                    return result;
                })
                .finally(() => {
                    commit('deactivateLoading', `integrations/deletePolicy/${policyId}`, { root: true });
                });
        },
        async fetchDynamicParameterValues({ commit }, { query, invalidateCache = false }) {
            commit('activateLoading', `integrations/fetchDynamicParameterValues/${query.parameter}`, { root: true });
            try {
                if (invalidateCache) memoizedFetchDynamicParameterValues.cache.delete(hashFunction(query));
                return await memoizedFetchDynamicParameterValues(query);
            } catch (e) {
                memoizedFetchDynamicParameterValues.cache.delete(hashFunction(query));
                return [];
            } finally {
                commit('deactivateLoading', `dataSets/fetchDynamicParameterValues/${query.parameter}`, { root: true });
            }
        },
    },
};

function hashFunction(args) {
    const json = JSON.stringify({
        ...args,
        prerequisite_values: args.prerequisite_values ? _.reduce(args.prerequisite_values, (res, val, key) => {
            return {
                ...res,
                [key]: _.sortBy(val),
            };
        }, {}) : undefined,
    });
    return json;
}

const memoizedFetchDynamicParameterValues = _.memoize(api.fetchDynamicParameterValues, hashFunction);
