import { ROLES } from '../constants';

const LEVELS = {
    all: [
        ROLES.ROLE_EXTERNAL_USER.id,
        ROLES.ROLE_USER_VIEW.id,
        ROLES.ROLE_USER.id,
        ROLES.ROLE_ADMIN.id,
    ],
    view: [
        ROLES.ROLE_USER_VIEW.id,
        ROLES.ROLE_USER.id,
        ROLES.ROLE_ADMIN.id,
    ],
    user: [
        ROLES.ROLE_USER.id,
        ROLES.ROLE_ADMIN.id,
    ],
    admin: [
        ROLES.ROLE_ADMIN.id,
    ],
};

export default class Authorize {
    static check(level, role = null) {
        // Public level can always be accessible
        if (!role && level === 'public') return true;

        return !!(role && LEVELS[level] && LEVELS[level].includes(role));
    }

    static isExternal(roleId) {
        return ROLES.ROLE_EXTERNAL_USER.id === roleId;
    }

    static isBasic(roleId) {
        return ROLES.ROLE_USER.id === roleId;
    }

    static isReadOnly(roleId) {
        return ROLES.ROLE_USER_VIEW.id === roleId;
    }

    static isAdmin(roleId) {
        return ROLES.ROLE_ADMIN.id === roleId;
    }
}
