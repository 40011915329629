/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import _ from 'lodash';
import Config from '@/utils/config';

const _defaults = {
    setup_guide: null,
};

class DataConfiguration {
    #json;

    constructor(json = {}) {
        this.#json = _.assign({}, _.cloneDeep(_defaults), _.cloneDeep(json));

        this.name = this.#json.name;
        this.code = this.#json.code;
        this.type = this.#json.typename;
        this.setup_guide = this.#json.setup_guide ? JSON.parse(this.#json.setup_guide) : null;
        this.authorisation_url = this.#json.authorisation_url;
        this.parameters = _(json.parameters)
            .orderBy('order_of_display')
            .map((parameter) => {
                return new ParameterConfiguration(parameter);
            })
            .value();

        this.icon = `${Config.assetsStorage}/${this.#json.code}.png`;
    }
}

class DataSourceConfiguration extends DataConfiguration {

}

class DataTargetConfiguration extends DataConfiguration {

}

class DataSetConfiguration extends DataConfiguration {
    constructor(json = {}) {
        super(json);
        delete this.configurationParameters;
        delete this.connectionParameters;
        delete this.authorisation_url;
        this.sourcecode = json.sourcecode;
        this.sourceconnectionname = json.sourceconnectionname;
        this.targetcode = json.targetcode;
        this.targetconnectionname = json.targetconnectionname;
        this.name = json.name;
        this.id = `${json.sourcecode}_${json.sourceconnectionname}_${json.targetcode}_${json.targetconnectionname}`;

        // TODO: change for endpoint results
        this.bulk_create = _.some(json.parameters, { bulk_create: true });
    }
}

export {
    DataConfiguration,
    DataSourceConfiguration,
    DataTargetConfiguration,
    DataSetConfiguration,
};

class ParameterConfiguration {
    constructor(json) {
        this.accepted_values = json.accepted_values;
        this.condition_for_requirement = json.condition_for_requirement;
        this.config_table = json.config_table;
        this.description = json.description;
        this.control = json.control;
        this.display_name = json.display_name;
        this.parameter = json.parameter;
        this.required = json.required;
        this.type = json.type;
        this.default_value = json.default_value;
        this.user_defined = json.user_defined;
        this.connection_parameter = json.connection_parameter;
        this.grouped = json.grouped || undefined;
        this.grouping = json.grouping || null;
        this.details = json.details || undefined;
        this.order_of_display = json.order_of_display || 1;
        this.bulk_applied = json.bulk_applied || false;
        this.bulk_create = json.bulk_create || false;
        this.bulk_hidden = json.bulk_hidden || false;
    }
}
