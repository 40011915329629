<script>
export default {
    props: {
        withCaret: {
            type: Boolean,
            default: false,
        },
        split: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        dropup: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'outline-secondary',
        },
        size: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            showDropdown: false,
        };
    },
    methods: {
        show() {
            this.showDropdown = true;
        },
        hide() {
            this.showDropdown = false;
        },
    },
    render(h) {
        const { default: menu, ...slots } = this.$slots;
        const children = [
            ..._.map(slots, (elements, slot) => h('template', { slot }, elements)),
        ];

        if (!slots['button-content']) {
            children.push(h('template', { slot: 'button-content' }, [h('i', { class: 'far fa-bars' })]));
        }
        return h('b-dropdown',
            {
                attrs: this.$attrs,
                class: this.$attrs.class,
                props: { ...this.$props, noCaret: !this.withCaret, lazy: true },
                on: {
                    show: this.show,
                    hide: this.hide,
                    click: e => this.$emit('click', e),
                },
            },
            [
                children,
                this.showDropdown ? menu : [],
            ]);
    },
};
</script>
