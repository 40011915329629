<template>
    <div>
        <b-form-select
            v-model="selectedValue"
            :options="options"
            :required="required"
            multiple
            @input="e => $emit('input', selectedValue.toString())"
        />
    </div>
</template>

<script>
export default {
    name: 'OneSelectField',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
        required: {
            type: Boolean,
        },
    },
    data() {
        return {
            options: [],
            selectedValue: [],
        };
    },
    created() {
        if (this.attrs.values_list) {
            this.options = this.attrs.values_list.split(',').map((value) => {
                return { value, text: value };
            });
        } else {
            this.options = this.attrs.accepted_values.split(',').map((value) => {
                return { value, text: value };
            });
        }
        if (this.value !== undefined) {
            this.selectedValue = this.value.split(',');
        }
    },
};
</script>
