<style src="./members.scss" lang="scss">
</style>
<style lang="scss">
    .photo-medium {
        width: 60px !important;
        height: 60px !important;
    }
</style>

<template>
    <loader :listen="['users/getAvailableUsers']" class="router-view">
        <div class="card p-4">
            <div class="card-body">
                <h1 class="mb-3 text-sm-header font-weight-bold">
                    Team
                </h1>
                <div class="sub-header row py-1 align-items-center bg-white">
                    <div v-if="users.length" class="col-xl-4 col-lg-4 col-md-6 py-1">
                        <input
                            v-model="searchTerm" type="text" class="form-control input__text input__text--sm"
                            placeholder="Search member">
                    </div>

                    <button class="btn btn-sm btn-link p-0" :disabled="users.length <= 1" @click="onSortByName">
                        <span class="mr-1">Sort</span>
                        <i class="far fa-fw" :class="sortNameAsc ?
                            'fa-sort-alpha-down-alt active' : 'fa-sort-alpha-up'" />
                    </button>
                    <button class="btn btn-sm btn-link ml-3" :disabled="users.length <= 1" @click="onSortByLoginDate">
                        <span class="mr-1">Last Login</span>
                        <i v-if="sortLoginAsc === undefined" class="far fa-fw fa-sort-alt" />
                        <i
                            v-if="sortLoginAsc !== undefined" class="far fa-fw active"
                            :class="sortLoginAsc ? 'fa-sort-amount-up' : 'fa-sort-amount-down'" />
                    </button>

                    <div class="col-auto ml-auto">
                        <div v-can-access.level="'admin'">
                            <invite-members-form />
                        </div>
                    </div>
                </div>

                <div v-if="!users.length" class="row">
                    <div class="col">
                        <div class="alert alert-secondary" role="alert">
                            <i class="far fa-info-circle fa-fw" /> There are no members in your team
                        </div>
                    </div>
                </div>
                <div v-if="users.length" class="row mt-2 teams">
                    <div class="col">
                        <div class="mb-3 d-none">
                            <p class="mb-2 mt-1">
                                <span class="font-weight-bold d-block">
                                    <i class="fa fa-lock-alt fa-fw" /> Your team is administered by:
                                </span>
                            </p>
                            <div class="d-flex flex-wrap">
                                <div
                                    v-for="(admin) in admins" :key="admin.id"
                                    class="d-flex flex-nowrap align-items-center border mr-1 mb-1 rounded-pill small bg-light pr-2">
                                    <div class="user-image-wrapper mr-2">
                                        <img
                                            v-if="admin.hasPhoto" :src="admin.photo" width="17" height="17"
                                            class="rounded-circle align-self-center">
                                    </div>
                                    <div class="text-nowrap">
                                        {{ admin.fullName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="user in filteredUsers" :key="user.id"
                            class="card bg-white d-flex flex-row justify-content-between align-items-center mx-0 mt-2"
                            :class="{
                                'bg-light': !user.acceptedInvite,
                                'deactivated-user progress-bar-striped': user.isDeactivated}">
                            <div class="col">
                                <div class="col-7 px-0">
                                    <div class="d-flex align-items-center">
                                        <img
                                            :src="user.photo" width="40" class="profile-photo rounded-circle border photo-medium mr-2 ml-1">
                                        <div v-if="user.acceptedInvite" class="d-flex flex-wrap flex-column">
                                            <div class="d-flex flex-wrap align-items-center">
                                                <span class="font-weight-bold mr-2">{{ user.fullName || user.username }}</span>
                                                <span v-if="user.role.id" class="p-0 mr-2 text-primary">
                                                    <span v-can-access.level="'admin'" class="small">
                                                        <i :class="['fa-fw', user.isAdmin ? 'fa fa-lock-alt': 'far fa-user']" />
                                                        {{ user.role.title }}
                                                    </span>
                                                </span>
                                            </div>
                                            <div>
                                                <a class="text-muted btn-link small" :href="`mailto:${user.username}`">
                                                    {{ user.username }}
                                                </a>
                                            </div>
                                            <div v-if="user.currentLoginDate">
                                                <span class="text-muted small">
                                                    Last Login Date: {{ user.currentLoginDateFormatted }}
                                                </span>
                                            </div>
                                            <div>
                                                <small
                                                    :class="{
                                                        'text-danger': user.accountExpired,
                                                        'text-muted': !user.accountExpired
                                                    }">
                                                    {{ user.expirationString }}
                                                </small>
                                            </div>
                                        </div>
                                        <div v-if="!user.acceptedInvite" class="d-flex align-items-center">
                                            <a class="font-weight-bold" :href="`mailto:${user.username }`">{{ user.username }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-auto member-btn-group">
                                <div class="d-flex flex-nowrap align-items-center justify-content-end">
                                    <!-- <button v-if="organisation.isPasswordPolicyApplied && loggedIn.id !== user.id && user.acceptedInvite"
                                            v-can-access.level="'admin'"
                                            v-b-tooltip.hover.top
                                            class="btn btn-sm"
                                            :title="`${user.isDeactivated ? 'Activate': 'Deactivate'}`"
                                            @click="changeUserStatus(user.id, user.isDeactivated)">
                                        <i class="far fa-fw" :class="user.isDeactivated ? 'fa-toggle-off': 'fa-toggle-on active'" />
                                    </button> -->
                                    <div v-if="loggedIn.id !== user.id" class="d-flex flex-nowrap">
                                        <div v-can-access.level="'admin'">
                                            <button v-if="!user.isAccountOwner" v-b-tooltip.hover.top class="btn btn-link btn-sm ml-2" title="Edit user"
                                                    @click="onEditUser(user)">
                                                    <i class="far fa-user-edit" />
                                            </button>
                                            <button v-if="!user.acceptedInvite" class="btn btn-link btn-sm text-nowrap"
                                                    @click="resendInviteToEmail(user.email)">
                                                <span>Resend Invite</span>
                                            </button>
                                            <button v-if="!user.acceptedInvite" class="btn btn-link btn-sm text-nowrap"
                                                    @click="onCancelInvite(user.email, user.id)">
                                                <span>Cancel Invite</span>
                                            </button>
                                            <button v-if="user.acceptedInvite && !user.isAccountOwner" v-b-tooltip.hover.top class="btn btn-link text-danger btn-sm ml-2" title="Delete user"
                                                    @click="selectOwnerFor(user)">
                                                <i class="svg-icon icon-trash" />
                                            </button>
                                        </div>
                                    </div>
                                    <div v-else class="d-flex">
                                        <span class="text-muted text-nowrap small text-uppercase pr-1">
                                            <i class="far fa-fw fa-user" /> your account
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!filteredUsers.length">
                            <div class="preview-area">
                                <i class="fal fa-users fa-4x py-2 animate__animated animate__bounceIn" />
                                <h5 class="mb-0">
                                    There are no users that satisfy your criteria
                                </h5>
                                <p class="text-muted">
                                    Please refine your search and try again
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transfer-ownership-modal v-if="showTransferModal" :user="userToDelete" @close="userToDelete = null;" />

        <edit-user-role
            v-if="showEditModal"
            class="input-group-append"
            variant="outline-secondary"
            :show-modal="showEditModal"
            :user="userToEdit"
            @click.native="validate"
            @hide-modal="onHideModal" />
    </loader>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import InviteMembersForm from './invite-members-form';
import TransferOwnershipModal from './transfer-ownership-modal';
import EditUserRole from './edit-user-role';

export default {
    components: {
        InviteMembersForm, TransferOwnershipModal, EditUserRole
    },
    filters: {
        formatDate(value) {
            return moment(value).format(`${this.loggedIn.getDateFormat()} HH:mm:ss`);
        },
    },
    data() {
        return {
            searchTerm: '',
            showMembers: true,
            showStaff: false,
            showExternal: false,
            sortNameAsc: false,
            sortLoginAsc: undefined,
            showInvites: true,
            sortedByName: true,
            sortedByLoginDate: false,
            showDeactivatedUsers: true,
            userToDelete: null,
            userToEdit: null,
        };
    },
    computed: {
        ...mapGetters({
            organisation: 'profile/organisation',
            loggedIn: 'profile/user',
            users: 'users/mappedUsers',
            members: 'users/organisationMemberUsers',
            external: 'users/organisationExternalUsers',
            staff: 'users/organisationAvoraStaff',
            invites: 'users/organisationPendingInvites',
            deactivatedUsers: 'users/organisationDeactivatedUsers',
        }),
        admins() {
            return _(this.users).filter(user => user.isAdmin).orderBy('fullName', 'asc').value();
        },
        domain() {
            return window.location.host;
        },
        filteredUsers() {
            const users = _(this.users)
                .filter((user) => {
                    let found = false;

                    if (this.showMembers && user.isMember) found = true;
                    if (this.showInvites && user.isInvite) found = true;
                    if (this.showDeactivatedUsers && user.isDeactivated) found = true;

                    if (found && this.searchTerm) {
                        found = user.fullName.concat(' ', user.username).toLowerCase().includes(this.searchTerm.toLowerCase());
                    }

                    return found;
                });
            if (this.sortedByLoginDate) {
                return users.orderBy([user => user.id === this.loggedIn.id, user => user.currentLoginDate],
                    ['desc', this.sortLoginAsc ? 'desc' : 'asc']).value();
            }

            return users.orderBy([user => user.id === this.loggedIn.id, user => user.fullName.toLowerCase()],
                ['desc', this.sortNameAsc ? 'desc' : 'asc']).value();
        },
        showTransferModal() {
            return !!this.userToDelete;
        },
        showEditModal() {
            return !!this.userToEdit;
        },
    },
    async created() {
        if (this.loggedIn.isRestricted) {
            return this.$router.push({ name: 'home' });
        }

        this.getUsers();

        return true;
    },
    mounted() {
        this.$track('view-members-page');
    },
    methods: {
        ...mapActions({
            getUsers: 'users/getAvailableUsers',
            activateUser: 'users/activateUser',
            deactivateUser: 'users/deactivateUser',
            cancelInvite: 'users/cancelInvite',
            resendInvite: 'users/resendInvite',
        }),
        selectOwnerFor(user) {
            this.userToDelete = user;
        },
        onEditUser(user) {
            this.userToEdit = user;
        },
        onSortByName() {
            this.sortedByName = true;
            this.sortedByLoginDate = false;
            this.sortNameAsc = !this.sortNameAsc;
            this.sortLoginAsc = undefined;
        },
        onSortByLoginDate() {
            this.sortedByName = false;
            this.sortedByLoginDate = true;
            this.sortNameAsc = false;
            this.sortLoginAsc = !this.sortLoginAsc;
        },
        async onCancelInvite(email, id) {
            try {
                await this.cancelInvite({ email, id });
                this.$notify({
                    type: 'success',
                    text: `Invitation to ${email} has successfully been canceled`,
                });
            } catch (error) {
                this.$notify({
                    type: 'error',
                    text: `Something went wrong when trying to cancel the invitation for ${email}. Please try again.`,
                });
            }
        },
        resendInviteToEmail(email) {
            this.resendInvite(email)
                .then(({ success }) => {
                    this.$notify({
                        type: 'success',
                        text: success,
                    });
                }, ({ error }) => {
                    this.$notify({
                        type: 'error',
                        text: error,
                    });
                });
        },
        async changeUserStatus(id, status) {
            if (status) {
                try {
                    await this.activateUser(id);
                    this.$notify({
                        type: 'success',
                        text: 'User has been successfully actived.',
                    });
                } catch (error) {
                    this.$notify({
                        type: 'error',
                        text: 'There was a problem activating this user. Please try again.',
                    });
                }
            } else {
                try {
                    await this.deactivateUser(id);
                    this.$notify({
                        type: 'success',
                        text: 'User has been successfully deactivated.',
                    });
                } catch (error) {
                    this.$notify({
                        type: 'error',
                        text: 'There was a problem deactivating this user. Please try again.',
                    });
                }
            }
        },
        onHideModal() {
            this.userToEdit = null;
            this.getUsers();
        }
    },
};
</script>

<style lang="scss">
.teams {
    .card {
        border-radius: $border-radius;
        padding: 6px 0;
    }
}
</style>
