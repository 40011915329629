import auth0 from 'auth0-js';
import Config from '@/utils/config';

const webAuth = new auth0.WebAuth({
    domain: Config.auth0.parameters.domain,
    clientID: Config.auth0.parameters.clientId,
    redirectUri: Config.auth0.parameters.redirectUri,
    responseType: Config.auth0.parameters.responseType,
    scope: Config.auth0.parameters.scope,
});

let token = {};

const handleAuth = (cb) => {
    webAuth.parseHash((error, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
            token.accessToken = authResult.accessToken;
            token.idToken = authResult.idToken;
            token.expiry = new Date().getTime() + authResult.expiresIn * 1000;
            cb();
            // return token
        } else {
            console.log(error);
        }
    });
};

const isLogged = () => {
    return token.accessToken && new Date().getTime() < token.expiry;
};

const login = () => {
    webAuth.authorize({ realm: Config.auth0.parameters.realm });
};

const signup = () => {
    webAuth.authorize({ action: 'signup', realm: Config.auth0.parameters.realm });
};

const logUserOut = () => {
    token = {};
    webAuth.logout({
        returnTo: `https://${window.location.hostname}/login`,
    });
};

export { login, signup, handleAuth, isLogged, logUserOut, webAuth };
