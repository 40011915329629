<template>
    <div v-if="!jobCreation" class="w-100 router-view">
        <div class="d-flex flex-row justify-content-between no-wrap p-2 mt-3">
            <div class="d-flex flex-row justify-content-between no-wrap">
                <h3 class="user-select-none text-header-md font-weight-bold">
                    Add new job(s)
                </h3>
            </div>
            <div class="logo">
                <img src="/logos/logo-low-res.png" width="133" height="32">
            </div>
            <loading-btn
                class="btn btn-lg my-auto btn-primary"
                :listen="['dataSets/saveDataSet']"
                :disabled="disableNextButton"
                @click="onNext">
                <span>
                    Next step
                </span>
            </loading-btn>
        </div>

        <loader :listen="['dataSources/fetchSourcesExtraConfiguration', 'dataTargets/fetchTargetsExtraConfiguration']" class="d-flex flex-row no-wrap justify-content-start">
            <div class="d-flex flex-column mr-4 w-50">
                <div class="custom-form-field">
                    <label class="label">Sync name</label>
                    <multiselect v-model="sync" :options="syncs" class="w-100" label="name" searchable :show-labels="false" placeholder="">
                        <template slot="singleLabel" slot-scope="{option}">
                            <div class="d-flex flex-row justify-content-start" style="max-height: 40px;">
                                <div style="min-width: 0; max-width: 35px;" class="option__image">
                                    <img :src="`${assetsStorage}/${option.sourcecode}.png`" height="30" width="35" style="object-fit: scale-down;">
                                </div>
                                <i class="svg-icon icon-arrow my-auto mx-2" />
                                <div style="min-width: 0; max-width: 35px;" class="option__image">
                                    <img :src="`${assetsStorage}/${option.targetcode}.png`" height="30" width="35" style="object-fit: scale-down;">
                                </div>
                                <div class="option__desc my-auto mx-2" style="min-width: 0;">
                                    {{ option.name }}
                                </div>
                            </div>
                        </template>

                        <template slot="option" slot-scope="{option}">
                            <div class="d-flex flex-row justify-content-start" style="max-height: 40px;">
                                <div style="min-width: 0; max-width: 35px;" class="option__image">
                                    <img :src="`${assetsStorage}/${option.sourcecode}.png`" height="30" width="35" style="object-fit: scale-down;">
                                </div>
                                <i class="svg-icon icon-arrow my-auto mx-2" />
                                <div style="min-width: 0; max-width: 35px;" class="option__image">
                                    <img :src="`${assetsStorage}/${option.targetcode}.png`" height="30" width="35" style="object-fit: scale-down;">
                                </div>
                                <div class="option__desc my-auto mx-2" style="min-width: 0;">
                                    {{ option.name }}
                                </div>
                            </div>
                        </template>

                        <template slot="placeholder">
                            <div>Select sync</div>
                        </template>
                    </multiselect>
                </div>

                <div class="d-flex flex-column mt-3 w-100 justify-content-between">
                    <div v-if="sourceExtraConfiguration" class="d-flex flex-column w-100">
                        <form-field v-for="param in sourceExtraConfiguration.visibleParameters"
                                    :key="param.parameter"
                                    ref="formControl"
                                    v-model="param.value"
                                    class="custom-form-field my-2"
                                    :config="param"
                        />
                    </div>

                    <div v-if="targetExtraConfiguration" class="d-flex flex-column w-100">
                        <form-field v-for="param in targetExtraConfiguration.visibleParameters"
                                    :key="param.parameter"
                                    ref="formControl"
                                    v-model="param.value"
                                    class="custom-form-field my-2"
                                    :config="param"
                        />
                    </div>
                </div>
            </div>

            <div v-if="isBulkFlow && sourceExtraConfiguration && sourceExtraConfiguration.isValid && targetExtraConfiguration && targetExtraConfiguration.isValid" class="ml-4 w-50 h-100">
                <label class="label">Select source table(s)</label>
                <div class="card overflow-auto" style="max-height: 80%;">
                    <div class="card-body bg-transparent">
                        <loader :listen="['dataSets/fetchAvailableBulkJobs']" loader-class="m-auto" class="d-flex flex-column h-100">
                            <template slot="loading">
                                <div class="d-flex flex-column justify-content-center h-100">
                                    <div class="mx-auto my-4 animate__animated animate__pulse animate__slow animate__infinite">
                                        <i class="fal fa-fw fa-5x fa-layer-group rotate text-primary" />
                                    </div>
                                    <div class="mx-auto">
                                        <h3>Data is being loaded</h3>
                                    </div>
                                </div>
                            </template>
                            <template v-if="availableSourceTables.length">
                                <source-table-selection :allowed-options="availableSourceTables" :selected="selectedSourceTables" @change="onSelected" @refresh="onRefresh(true)" />
                            </template>
                            <template v-else>
                                <div class="d-flex flex-column justify-content-center h-100">
                                    <div class="mx-auto my-4">
                                        <i class="fal fa-fw fa-layer-group fa-5x text-primary" />
                                    </div>
                                    <div class="mx-auto">
                                        <h3>No data available</h3>
                                    </div>
                                </div>
                            </template>
                        </loader>
                    </div>
                </div>
            </div>
        </loader>
    </div>
    <div v-else>
        <job-creation-external :settings="jobSettings" @close="jobCreation = false; jobSettings = null;" @save="onSaveJobs" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Config from '@/utils/config';
import FormField from '@/components/formField';
import Multiselect from 'vue-multiselect';
import ExtraConfiguration from '@/store/models/extraConfiguration.model';
import { LABELS } from '@/constants';
import JobCreationExternal from '@/modules/jobs/views/jobCreation/JobCreationExternal';
import SourceTableSelection from './sourceTableSelection';

export default {
    name: 'JobSelectionExternal',
    components: { FormField, Multiselect, SourceTableSelection, JobCreationExternal },
    data() {
        return {
            assetsStorage: Config.assetsStorage,
            selectedSync: null,
            sourceExtraConfiguration: null,
            targetExtraConfiguration: null,
            availableSourceTables: [],
            selectedSourceTables: [],
            jobCreation: false,
            jobSettings: null,
            syncId: null,
        };
    },
    computed: {
        ...mapGetters({
            mySyncs: 'syncs/mySyncs',
            sourceTypeParameters: 'dataSources/sourcesExtraConfig',
            targetTypeParameters: 'dataTargets/targetsExtraConfig',
            organisation: 'profile/organisation',
        }),
        syncs() {
            return _.map(this.mySyncs);
        },
        sync: {
            get() { return this.selectedSync; },
            set(newValue) {
                if (newValue) {
                    this.selectedSync = newValue;
                    this.availableSourceTables = [];
                    this.selectedSourceTables = [];
                    const sourcecode = _.get(newValue, 'sourcecode');
                    const sourceconnectionname = _.get(newValue, 'sourcename', null);
                    const sourceConfig = _.get(this.sourceTypeParameters, sourcecode);
                    this.sourceExtraConfiguration = new ExtraConfiguration({ code: sourcecode, name: sourceconnectionname }, sourceConfig, ({ invalidateCache, query }) => {
                        return this.fetchDynamicParameterValues({
                            invalidateCache,
                            query: { ...query, sourcecode, sourceconnectionname },
                            external: !!sessionStorage.getItem('external_access_token'),
                        });
                    }, this.onRefresh);

                    const targetcode = _.get(newValue, 'targetcode');
                    const targetconnectionname = _.get(newValue, 'targetname', null);
                    const targetConfig = _.get(this.targetTypeParameters, targetcode);
                    this.targetExtraConfiguration = new ExtraConfiguration({ code: targetcode, name: targetconnectionname }, targetConfig, ({ invalidateCache, query }) => {
                        return this.fetchDynamicParameterValues({
                            invalidateCache,
                            query: { ...query, targetcode, targetconnectionname },
                            external: !!sessionStorage.getItem('external_access_token'),
                        });
                    }, this.onRefresh);
                } else {
                    this.sourceExtraConfiguration = null;
                    this.targetExtraConfiguration = null;
                    this.selectedSync = null;
                    this.availableSourceTables = [];
                    this.selectedSourceTables = [];
                }
            },
        },
        isBulkFlow() {
            return _.get(this.sourceExtraConfiguration, 'bulkFlow', false);
        },
        disableNextButton() {
            return !this.sourceExtraConfiguration
                || !this.sourceExtraConfiguration.isValid
                || !this.targetExtraConfiguration
                || !this.targetExtraConfiguration.isValid
                || (this.isBulkFlow && _.isEmpty(this.selectedSourceTables));
        },
    },
    async created() {
        this.syncId = this.$route.query.syncId;

        Promise.allSettled([
            this.fetchSourcesExtraConfiguration(!!sessionStorage.getItem('external_access_token')),
            this.fetchTargetsExtraConfiguration(!!sessionStorage.getItem('external_access_token')),
        ]).then(() => {
            this.sync = _.get(this.mySyncs, this.syncId, null);
        }).finally(() => {
            console.log('sync', this.sync);
            console.log('sourceExtraConfiguration', this.sourceExtraConfiguration);
            console.log('targetExtraConfiguration', this.targetExtraConfiguration);
        });
    },
    methods: {
        ...mapActions({
            fetchSourcesExtraConfiguration: 'dataSources/fetchSourcesExtraConfiguration',
            fetchTargetsExtraConfiguration: 'dataTargets/fetchTargetsExtraConfiguration',
            fetchDynamicParameterValues: 'dataSets/fetchDynamicParameterValues',
            fetchSourceTables: 'dataSets/fetchAvailableBulkJobs',
            saveJobs: 'dataSets/saveJobs',
        }),
        onSelected(newVal) {
            this.selectedSourceTables = newVal;
        },
        onRefresh(invalidateCache = false) {
            if (this.isBulkFlow
                && _.get(this.sourceExtraConfiguration, 'isValid')
                && _.get(this.targetExtraConfiguration, 'isValid')) {
                const sourcePayload = _.get(this.sourceExtraConfiguration, 'payload');
                const targetPayload = _.get(this.targetExtraConfiguration, 'payload');
                const query = {
                    organisationid: _.get(this.organisation, 'id'),
                    sourcecode: _.get(sourcePayload, 'code'),
                    sourceconnectionname: _.get(sourcePayload, 'name'),
                    targetcode: _.get(targetPayload, 'code'),
                    targetconnectionname: _.get(targetPayload, 'name'),
                    parameters: {
                        ..._.get(sourcePayload, 'parameters', {}),
                        ..._.get(targetPayload, 'parameters', {}),
                    },
                };

                this.fetchSourceTables({ query, invalidateCache, external: !!sessionStorage.getItem('external_access_token') })
                    .then((resp) => {
                        if (resp) this.availableSourceTables = _.get(resp, 'data.availableJobs', []);
                    })
                    .catch(() => {
                        this.availableSourceTables = [];
                    });
            }
        },
        onNext() {
            this.jobSettings = {
                syncId: this.sync.id,
                isBulkFlow: this.isBulkFlow,
                sourceTables: this.selectedSourceTables,
            };

            this.jobCreation = true;
        },
        onSaveJobs(payload) {
            this.saveJobs({ ...payload, external: !!sessionStorage.getItem('external_access_token') })
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: LABELS.jobCreateSuccess,
                    });
                    this.jobCreation = false;
                    this.jobSettings = null;

                    const redirectTo = sessionStorage.getItem('external_redirect_to');

                    if (redirectTo && redirectTo !== 'undefined' && redirectTo !== 'null') {
                        localStorage.removeItem('external_access');
                        setTimeout(() => { window.location.href = redirectTo; }, 2000);
                    } else {
                        this.$notify({
                            type: 'error',
                            text: 'Job saved succesfully but there is no redirect url. Please go back manually.',
                        });

                        localStorage.removeItem('external_access');
                    }
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-form-field {
    max-width: 500px;
    min-width: 500px;
}
</style>
