import _ from 'lodash';
import { RULE_ENGINE_OPERATORS } from '@/utils/operators';
import { Engine } from 'json-rules-engine';
import DynamicParameter from './dynamicParameter.model';

const connectionDefaults = {
    id: '',
    code: '',
    name: '',
    release_status: 'draft',
    parameters: {},
};

export default class ConnectionModel {
    #data;

    #configuration;

    constructor(data = {}, configuration = {}, fetchParameterData = () => {}) {
        const values = _.assignIn({}, _.cloneDeep(connectionDefaults), _.cloneDeep(data));

        this.#data = values;
        this.#configuration = _.cloneDeep(configuration);

        _.each(this.#data, (value, key) => {
            _.set(this, key, value);
        });

        this.id = values.id || `${values.code}_${values.name}`;

        this.parameters = _.reduce(values.parameters, (res, value, parameter) => {
            return {
                ...res,
                [parameter]: {
                    value,
                    parameter,
                },
            };
        }, {});

        const ruleEngine = new Engine([], { allowUndefinedFacts: true });
        _.each(RULE_ENGINE_OPERATORS, (ruleFunction, ruleName) => {
            ruleEngine.addOperator(ruleName, ruleFunction);
        });
        this.ruleEngine = ruleEngine;
        this.facts = {};

        _.each(this.#configuration.parameters, (paramConfig) => {
            const paramValue = _.get(values.parameters, paramConfig.parameter, null);
            const paramName = paramConfig.parameter;
            const parameter = new DynamicParameter(paramValue, paramConfig, (parameterName, prerequisiteValues, invalidateCache = false) => {
                return fetchParameterData({
                    invalidateCache,
                    query: {
                        parameter: parameterName,
                        prerequisite_values: prerequisiteValues,
                        code: this.code,
                        name: this.name,
                    },
                });
            });

            _.set(this.parameters, paramName, parameter);
        });

        _.each(this.parameters, (parameter) => {
            _.set(this.facts, parameter.parameter, parameter.value);

            if (parameter instanceof DynamicParameter) {
                if (parameter._config.condition_for_requirement) {
                    this.ruleEngine.addRule(parameter.conditionForRequirementRule);
                }

                parameter.observeDependencies(this.parameters);
                parameter.addObserver(this);
            }
        });

        console.log('connection:', this);
        this.ruleEngine.run(this.payload.parameters);
    }

    update({ param, value }) {
        this.facts[param] = value;
        this.ruleEngine.run(this.facts);
    }

    get payload() {
        const parameters = _(this.parameters)
            .filter((param) => {
                return param.visible || !(param instanceof DynamicParameter);
            })
            .value();

        return {
            code: this.code,
            name: this.name,
            parameters: _.reduce(parameters, (result, { parameter, value }) => {
                return {
                    ...result,
                    [parameter]: value,
                };
            }, {}),
        };
    }

    get isValid() {
        return _.every(_.filter(this.parameters, param => param.visible && param instanceof DynamicParameter), {
            isValid: true,
            loading: false,
        });
    }
}
