// Todo: actions try-catch

import Vue from 'vue';
import * as api from '../api/profile.api';
import Group from '../models/group.model';

export default {
    namespaced: true,
    state: {
        collection: {},
        selected: {
            id: undefined,
            permissions: [],
            metricPermissions: {},
            dashboardPermissions: {},
        },

    },
    getters: {
        mappedGroups({ collection }, getters, rootState, rootGetters) {
            return _(collection)
                .map(group => new Group(group, rootState, rootGetters))
                .sortBy('name')
                .value();
        },
        selectedGroup({ collection, selected: { id, permissions, metricPermissions,
            dashboardPermissions } }, getters, rootState, rootGetters) {
            const group = _.get(collection, id, { id });
            const model = new Group(group);
            model.permissions = permissions;
            model.metricPermissions = metricPermissions;
            model.dashboardPermissions = dashboardPermissions;
            model.state = rootState;
            model.getters = rootGetters;
            return model;
        },
    },
    mutations: {
        setSelectedGroupMetricPermissions(state, permissions) {
            state.selected.metricPermissions = permissions;
        },
        setSelectedGroupDashboardPermissions(state, permissions) {
            state.selected.dashboardPermissions = permissions;
        },
        setSelectedGroup(state, { id }) {
            state.selected = { id, permissions: [], metricPermissions: {}, dashboardPermissions: {} };
        },
        setSelectedGroupPermissions(state, permissions) {
            state.selected.permissions = permissions;
        },
        setGroups(state, groups) {
            _.each(groups, (group) => {
                if (!state.collection[group.id] || !_.isEqual(state.collection[group.id], group)) {
                    Vue.set(state.collection, group.id, group);
                }
            });
        },
        setGroup(state, group) {
            Vue.set(state.collection, group.id, group);
        },
        deleteGroup(state, id) {
            Vue.delete(state.collection, id);
        },
    },
    actions: {
        async saveGroupMetricPermissions({ commit, dispatch, state, getters }, perms) {
            commit('activateLoading', 'groups/saveGroupMetricPermissions', { root: true });
            await api.saveEntitlements(state.selected.id, 'group', perms);
            commit('setSelectedGroupMetricPermissions', perms);
            // If current user belongs to updated group we need to also update users permissions
            if (getters.selectedGroup.isCurrentUsersGroup) {
                dispatch('profile/getUser', {}, { root: true });
            }
            commit('deactivateLoading', 'groups/saveGroupMetricPermissions', { root: true });
        },
        async saveGroupDashboardPermissions({ commit, dispatch, state, getters }, perms) {
            commit('activateLoading', 'groups/saveGroupDashboardPermissions', { root: true });
            await api.saveEntitlements(state.selected.id, 'group', perms);
            commit('setSelectedGroupDashboardPermissions', perms);
            // If current user belongs to updated group we need to also update users permissions
            if (getters.selectedGroup.isCurrentUsersGroup) {
                dispatch('profile/getProfile', {}, { root: true });
            }
            commit('deactivateLoading', 'groups/saveGroupDashboardPermissions', { root: true });
        },
        async getGroups({ commit }) {
            commit('activateLoading', 'groups/getGroups', { root: true });
            const groups = await api.getAllGroups();
            commit('setGroups', groups);
            commit('deactivateLoading', 'groups/getGroups', { root: true });
        },
        async getGroupPermissions({ commit, state }) {
            commit('activateLoading', 'groups/getGroupPermissions', { root: true });
            const permissions = await api.getGroupPermissions(state.selected.id);
            commit('setSelectedGroupPermissions', permissions);
            commit('deactivateLoading', 'groups/getGroupPermissions', { root: true });
        },
        async getGroupMetricPermissions({ commit, state }) {
            commit('activateLoading', 'groups/getGroupMetricPermissions', { root: true });
            const permissions = await api.getEntitlements(state.selected.id, 'group', ['DATASOURCE', 'METRIC', 'TABLE']);
            commit('setSelectedGroupMetricPermissions', permissions);
            commit('deactivateLoading', 'groups/getGroupMetricPermissions', { root: true });
        },
        async getGroupDashboardPermissions({ commit, state }) {
            commit('activateLoading', 'groups/getGroupDashboardPermissions', { root: true });
            const permissions = await api.getEntitlements(state.selected.id, 'group', ['WORKSPACE', 'DASHBOARD']);
            commit('setSelectedGroupDashboardPermissions', permissions);
            commit('deactivateLoading', 'groups/getGroupDashboardPermissions', { root: true });
        },
        async saveGroupPermissions({ commit, dispatch, state, getters }, perms) {
            await api.saveGroupPermissions(state.selected.id, perms);
            commit('setSelectedGroupPermissions', perms);
            // If current user belongs to updated group we need to also update users permissions
            if (getters.selectedGroup.isCurrentUsersGroup) {
                dispatch('profile/getUser', {}, { root: true });
            }
        },
        async saveGroup({ commit }, data) {
            commit('activateLoading', 'groups/saveGroup', { root: true });
            try {
                const group = await api.saveGroup(data).finally(() => commit('deactivateLoading', 'groups/saveGroup', { root: true }));
                commit('setGroup', group);
                if (!data.id) {
                    commit('deleteGroup', data.id);
                }
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async deleteGroup({ commit }, id) {
            await api.deleteGroup(id);
            commit('deleteGroup', id);
        },
    },
};
