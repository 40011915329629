import Syncs from '@/modules/syncs/views';
import SyncOverview from '@/modules/syncs/views/syncOverview';

export default [{
    path: '/',
    redirect: '/syncs',
    name: 'home',
}, {
    path: '/syncs',
    name: 'syncs',
    component: Syncs,
    meta: {
        name: 'Syncs',
        permissions: [],
        auth: 'view',
    },
}, {
    path: '/syncs/:syncId',
    name: 'sync-overview',
    component: SyncOverview,
    props: true,
    meta: {
        name: 'SyncOverview',
        permissions: [],
        auth: 'view',
    },
}];
