import Vue from 'vue';

import Manager from './manager';
import LocalStorageAdapter from './localstorage-adapter';
import IndexedDbAdapter from './indexeddb-adapter';
import NullAdapter from './null-adapter';
import LegacyCache from './legacy';

const localStorage = new Manager(LocalStorageAdapter);
Vue.prototype.$localStorage = localStorage;
Vue.localStorage = localStorage;

const cache = new Manager(IndexedDbAdapter);
Vue.prototype.$cache = cache;
Vue.cache = cache;

export { Manager, LocalStorageAdapter, IndexedDbAdapter, NullAdapter, LegacyCache };
