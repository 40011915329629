import Vue from 'vue';
import * as api from '../api/users.api';
import UserModel from '../models/user.model';

export default {
    namespaced: true,
    state: {
        collection: {},
    },
    getters: {
        list({ collection }, getters, rootState, rootGetters) {
            return _.mapValues(collection, user => new UserModel(user, rootState, rootGetters));
        },
        mappedUsers(state, { list }) {
            return _.values(list);
        },
        usersByUsername(state, { list }) {
            return _.keyBy(list, 'username');
        },
        organisationMemberUsers(state, { list }) {
            return _.filter(list, 'isMember');
        },
        organisationInternalUsers(state, { list }) {
            return _.reject(list, 'isExternal');
        },
        organisationExternalUsers(state, { list }) {
            return _.filter(list, 'isExternal');
        },
        organisationPendingInvites(state, { list }) {
            return _.filter(list, 'isInvite');
        },
        organisationAvoraStaff(state, { list }) {
            return _.filter(list, 'isStaff');
        },
        organisationDeactivatedUsers(state, { list }) {
            return _.filter(list, 'isDeactivated');
        },
    },
    mutations: {
        setUser(state, user) {
            Vue.set(state.collection, user.id, user);
        },
        setUsers(state, users) {
            state.collection = { ...state.collection, ..._.keyBy(users, 'id') };
        },
        updateUser(state, { id, ...user }) {
            Vue.set(state.collection, id, { ...state.collection[id], ...user });
        },
        deleteUser(state, userId) {
            Vue.delete(state.collection, userId);
        },
    },
    actions: {
        loadUsersIfEmpty({ dispatch, state }) {
            if (!_.keys(state.collection).length) {
                dispatch('getAvailableUsers');
            }
        },
        async getAvailableUsers({ commit }) {
            commit('activateLoading', 'users/getAvailableUsers', { root: true });
            try {
                const users = await api.getAvailableUsers();
                commit('setUsers', users);
            } finally {
                commit('deactivateLoading', 'users/getAvailableUsers', { root: true });
            }
        },
        async deleteMember({ commit }, { id, email, owner }) {
            commit('activateLoading', 'users/deleteMember', { root: true });
            try {
                await api.deleteMember(email, owner);
                commit('deleteUser', id);
            } finally {
                commit('deactivateLoading', 'users/deleteMember', { root: true });
            }
        },
        async inviteUser({ commit }, params) {
            commit('activateLoading', 'users/inviteUser', { root: true });
            try {
                const user = await api.inviteUser(params);
                commit('setUsers', { [user.id]: user });
                Vue.track('invited-user', {
                    email: params.email,
                    limit: params.limit,
                    forever: params.forever,
                    message: params.message,
                    authority: params.authority,
                    groups: _.join(params.groups, ', '),
                });
            } finally {
                commit('deactivateLoading', 'users/inviteUser', { root: true });
            }
        },
        async updateOrganisationUserRole({ commit }, params) {
            commit('activateLoading', 'users/updateOrganisationUserRole', { root: true });
            try {
                const user = await api.updateOrganisationUserRole(params);
            } finally {
                commit('deactivateLoading', 'users/updateOrganisationUserRole', { root: true });
            }
        },
        async cancelInvite({ commit }, { email, id }) {
            commit('activateLoading', 'users/cancelInvite', { root: true });
            try {
                await api.cancelInvite(email);
                commit('deleteUser', id);
            } finally {
                commit('deactivateLoading', 'users/cancelInvite', { root: true });
            }
        },
        async resendInvite({ commit }, email) {
            commit('activateLoading', 'users/resendInvite', { root: true });
            try {
                return await api.resendInvite(email);
            } finally {
                commit('deactivateLoading', 'users/resendInvite', { root: true });
            }
        },
        async activateUser({ commit }, id) {
            commit('activateLoading', 'users/activateUser', { root: true });
            try {
                const user = await api.activateUser(id);
                commit('updateUser', user);
            } finally {
                commit('deactivateLoading', 'users/activateUser', { root: true });
            }
        },
        async deactivateUser({ commit }, id) {
            commit('activateLoading', 'users/deactivateUser', { root: true });
            try {
                const user = await api.deactivateUser(id);
                commit('updateUser', user);
            } finally {
                commit('deactivateLoading', 'users/deactivateUser', { root: true });
            }
        },
    },
};
