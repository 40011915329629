<template>
    <div>
        <div class="d-flex flex-row justify-content-between no-wrap">
            <div class="d-flex flex-row justify-content-between no-wrap">
                <h3 class="user-select-none text-header-md">
                    Edit job
                </h3>
            </div>
            <loading-btn
                class="btn btn-lg my-auto btn-primary"
                :listen="['dataSets/saveDataSet']"
                :disabled="disableSave"
                @click="onSave">
                <span>
                    Save
                </span>
            </loading-btn>
        </div>
        <div class="w-100">
            <div v-if="jobModel" class="card mt-3 bg-transparent border-0 shadow-none animate__animated animate__fadeIn animate__fast">
                <div class="card-header bg-white border-0 d-flex flex-row justify-content-between">
                    <div class="d-flex flex-row">
                        <span class="font-weight-bold my-auto">
                            {{ jobModel.name }}
                        </span>
                        <div v-if="jobIssues" class="my-auto ml-2 py-1 px-2 issues-text text-sm user-select-none d-inline">
                            {{ jobIssues }} {{ jobIssues > 1 ? 'ISSUES' : 'ISSUE' }}
                        </div>
                    </div>
                </div>

                <div class="card-body bg-transparent px-0">
                    <div v-for="group in groupedVisibleParameters" :key="group.name" class="">
                        <div v-if="group.name" class="my-4 text-center card group-card clickable" @click="onToggleGroupExpanded(group.name)">
                            <div class="card-body bg-transparent p-2 d-flex flex-row justify-content-center">
                                <div class="my-auto p-2">
                                    {{ group.name }}
                                </div>
                                <div v-if="groupIssues(group.name)" class="my-auto mx-2 py-1 px-2 issues-text text-sm user-select-none clickable d-inline">
                                    {{ groupIssues(group.name) }} {{ groupIssues(group.name) > 1 ? 'ISSUES' : 'ISSUE' }}
                                </div>
                                <div class="my-auto text-muted">
                                    <i class="far fa-fw fa m-auto" :class="groupExpanded(group.name) ? 'fa-angle-up' : 'fa-angle-down'" />
                                </div>
                            </div>
                        </div>
                        <div v-show="!group.name || groupExpanded(group.name)" class="parameters-container">
                            <form-field
                                v-for="field in group.parameters"
                                :key="`${group.name}_${field.parameter}`"
                                ref="formControl"
                                v-model="field.value"
                                class="custom-form-field"
                                :config="field"
                                @input="onChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JobModel from '@/store/models/job.model';
import FormField from '@/components/formField';
import { mapGetters, mapActions } from 'vuex';
import { LABELS } from '@/constants';

export default {
    name: 'EditJob',
    components: { FormField },
    props: {
        jobId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            jobModel: null,
            changed: false,
            expandedGroups: [],
        };
    },
    computed: {
        ...mapGetters({
            myJobs: 'dataSets/myDataSets',
        }),
        job() {
            return _.get(this.myJobs, this.jobId, {});
        },
        disableSave() {
            return !this.changed
                || !_.get(this.jobModel, 'isValid');
        },
        groupedVisibleParameters() {
            return _.get(this.jobModel, 'groupedVisibleParameters', {});
        },
        jobIssues() {
            const issues = _.reject(_.get(this.jobModel, 'visibleParameters'), 'isValid');
            return issues.length || '';
        },
        groupExpanded() {
            return (groupId) => {
                return _.includes(this.expandedGroups, groupId);
            };
        },
        groupIssues() {
            return (groupName) => {
                const issues = _.reject(_.filter(_.get(this.jobModel, 'visibleParameters'), { section: groupName }), 'isValid');
                return issues.length || '';
            };
        },
    },
    created() {
        this.fetchJobConfiguration({
            sourcecode: _.get(this.job, 'sourcecode'),
            sourceconnectionname: _.get(this.job, 'sourceconnectionname'),
            targetcode: _.get(this.job, 'targetcode'),
            targetconnectionname: _.get(this.job, 'targetconnectionname'),
        }).then((configuration) => {
            this.jobModel = new JobModel(this.job, configuration, this.fetchDynamicParameterValues);
        });
    },
    methods: {
        ...mapActions({
            fetchJobConfiguration: 'dataSets/fetchDataSetConfiguration',
            fetchDynamicParameterValues: 'dataSets/fetchDynamicParameterValues',
            saveJob: 'dataSets/saveDataSet',
        }),
        onSave() {
            const payload = _.get(this.jobModel, 'payload');
            this.saveJob(payload)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: LABELS.jobUpdateSuccess,
                    });
                    this.$emit('close');
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
        onChange() {
            this.changed = true;
        },
        onToggleGroupExpanded(groupId) {
            if (this.groupExpanded(groupId)) {
                this.expandedGroups = _.without(this.expandedGroups, groupId);
            } else {
                this.expandedGroups = _.concat(this.expandedGroups, groupId);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

.parameters-container {
    display: grid;
    gap: 50px;

    @include respond-to('small') {
        grid-template-columns: repeat(auto-fill, 500px);
    }

    @include respond-to('medium') {
        grid-template-columns: repeat(auto-fill, 500px);
    }

    @include respond-to('large') {
        grid-template-columns: repeat(3, 1fr);
    }

    .custom-form-field {
        min-width: 200px;
        max-height: 300px;
    }
}

.card {
    .card-header {
        min-height: 80px;
        border-radius: $border-radius;
    }
}

.group-card {
    background: #e6e3e0;
    border-radius: $border-radius;
}

.issues-text {
    color:$warning-100;
    border-radius: 5px;
    background-color: $warning-50;
}
</style>
