<style lang="scss">
    @import '~pikaday/css/pikaday.css';

    .pika-single {
        border-color: $primary-500;
        border-radius: 4px;
        box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
        color: var(--theme-text-color);
        font-family: var(--theme-global-font);

        .pika-lendar {
            width: 270px;
            padding: 10px;

            .pika-prev,
            .pika-next {
                background-image: none;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: 'Font Awesome 5 Pro', sans-serif;
                font-weight: 300;
                text-indent: 0;
                font-size: 0;
            }

            .pika-prev {
                &:before {
                    font-size: 20px;
                    content: "\f104";
                }
            }

            .pika-next {
                &:before {
                    font-size: 20px;
                    content: "\f105";
                }
            }

            .pika-table {
                table-layout: fixed;

                abbr {
                    cursor: default;
                    text-decoration: none;
                }

                .pika-row {
                    td {
                        .pika-button {
                            color: var(--theme-text-color);
                            border-radius: 0;
                            text-align: center;
                            box-shadow: none;
                        }

                        .pika-button:hover {
                            background: $neutral-200;
                        }

                        &.is-endrange,
                        &.is-startrange {
                            .pika-button {
                                &.pika-day {
                                    background: var(--theme-link-active-text-color);
                                    color: $shade-50;
                                }
                            }
                        }

                        &.is-selected {
                            .pika-button {
                                background: var(--theme-link-active-text-color);
                                color: $shade-50;
                            }
                        }

                        &.is-today {
                            .pika-button {
                                color: var(--theme-link-active-text-color);
                            }

                            &.is-selected {
                                .pika-button {
                                    color: $shade-50;
                                }
                            }
                        }
                    }

                    &.pick-whole-week {
                        &.is-selected {
                            td {
                                .pika-button {
                                    background: #d5e9f7;
                                }
                            }
                        }

                        &:hover {
                            td {
                                .pika-button {
                                    background: #e4eff7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* temporary styling */
    
    .pika-single .pika-lendar .pika-table .pika-row.pick-whole-week.is-selected td.is-selected .pika-button {
        background: var(--theme-link-active-text-color);
        color: $shade-50;
    }
</style>

<template>
    <input ref="datepicker" type="text" class="form-control input__text" :disabled="disabled">
</template>

<script>
import { mapGetters } from 'vuex';
import Pikaday from 'pikaday';
import Config from '../../../../utils/config';

export default {
    props: {
        value: {
            // type: [Array, String],
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        format: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            picker: undefined,
            serverFormat: Config.dateFormats.grails,
        };
    },
    computed: {
        ...mapGetters({
            user: 'profile/user',
            organisation: 'profile/organisation',
        }),
        selectedFormat() {
            return this.format || this.user.getDateFormat();
        },
    },
    watch: {
        value(date) {
            if (date && this.picker) {
                this.setDate(date);
            }
        },
    },
    mounted() {
        const vm = this;

        this.picker = new Pikaday({
            field: this.$refs.datepicker,
            firstDay: this.organisation.startDayOfWeek,
            showWeekNumber: true,
            pickWholeWeek: false,
            showDaysInNextAndPreviousMonths: true,
            enableSelectionDaysInNextAndPreviousMonths: true,
            format: this.selectedFormat,
            toString(date) {
                const val = vm.$moment(date).local().format(vm.selectedFormat);
                return val;
            },
            onSelect() {
                const date = this.getMoment().local().format(vm.serverFormat);
                vm.$emit('input', date);
            },
        });

        this.setDate(this.value);
    },
    methods: {
        setDate(date) {
            const currentDate = this.picker.getMoment();
            if (currentDate.local().format(this.serverFormat) !== date) {
                this.picker.setMoment(this.$moment(date).utc().subtract(currentDate.utcOffset(), 'm'));
            }
        },
    },
};
</script>
