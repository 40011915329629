import client from './client';

export async function createCheckoutSession(priceId) {
    const response = await client.post('stripe/createCheckoutSession', { priceId });
    return client.parseResponse(response);
}

export async function createCustomerPortal(customerId) {
    const response = await client.post('stripe/createCustomerPortal', { customerId });
    return client.parseResponse(response);
}

export async function getAllActiveProducts() {
    const response = await client.get('stripe/getAllActiveProducts');
    return client.parseResponse(response);
}

export async function getAllActivePrices() {
    const response = await client.get('stripe/getAllActivePrices');
    return client.parseResponse(response);
}

export async function upgradeSubscription(priceId) {
    const response = await client.post('stripe/upgradeSubscription', { priceId });
    return client.parseResponse(response);
}

export async function setDatasetRunLimit(payload) {
    const response = await client.post('stripe/setUsageLimit', payload);
    return client.parseResponse(response);
}

export async function setBillingName(billingName) {
    const response = await client.post('stripe/setBillingName', { billingName });
    return client.parseResponse(response);
}
