/* eslint-disable class-methods-use-this */
import Config from '../config';
import { ROLES } from '../../constants';

class IntercomService {
    async init(user) {
        window.intercomSettings = {
            app_id: Config.intercom.applicationId, // Intercom Avora Application ID
            user_hash: user.intercomHash, // HMAC using SHA-256
            email: user.email, // User Email
            user_id: user.id,
            active: true,
            first_name: user.firstName,
            last_name: user.lastName,
            Role: _.get(_.find(ROLES, { id: user.role }), 'name'),
            Created_at: Math.floor(user.dateCreated / 1000),
            'Organisation ID': _.get(user.organisation, 'id'),
            'Organisation Name': _.get(user.organisation, 'name'),
            'No Communications': user.ignoreEmailsFromSystem,
            hide_default_launcher: false,
            custom_launcher_selector: '#open-intercom-bubble',
        };

        if (!Config.isProd) return Promise.resolve('skipped');
        return this.load();
    }

    isIntercomVisible(user) {
        // Hide intercom bubble for users that don't have an email / intercom hash
        if (!user || !user.email || !user.intercomHash) return false;

        // Hide intercom bubble for organisations that don't want access to our helpdesk
        if (_.includes(Config.intercom.disabled, user.organisation.id)) return false;

        return true;
    }

    reset() {
        delete window.intercomSettings;
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }

    /* eslint-disable */
    async load() {
        let isCompleted;
        const waitFor = new Promise((resolve, _reject )=>{
            isCompleted = resolve;
        });

        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/ggbm081r'
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
            isCompleted('updated');
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${Config.intercom.applicationId}`;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
                isCompleted('loaded');
            };
            if (document.readyState === "complete" || document.readyState === "interactive") {
                l();
            } else {
                if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        }
        return waitFor;
    }

    track(eventName, metadata) {
        if(!Config.isProd) console.log('trackEvent', eventName, metadata);
        else if (window.Intercom) window.Intercom('trackEvent', eventName, metadata);
    }
}

export default new IntercomService();
