import client from './client';

export async function fetchSyncs(refresh = false, externalAccess = false) {
    const response = await client.get('selfService/syncs', {}, { cache: { skip: refresh, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function saveSync(payload, externalAccess = false) {
    const response = await client.post('selfService/syncs', payload, { cache: { skip: true, v2: true } }, externalAccess);
    return client.parseResponse(response);
}

export async function updateSync({ id, ...payload }) {
    const response = await client.put(`selfService/syncs/${id}`, { id, ...payload }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function deleteSync(id) {
    const response = await client.delete(`selfService/syncs/${id}`, {}, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}

export async function updateSyncStatus(sync) {
    const response = await client.post('selfService/setSyncStatus', {
        syncId: sync.id,
        schedule_status: sync.schedule_status,
    }, { cache: { skip: true, v2: true } });
    return client.parseResponse(response);
}
