<template>
    <div>
        <div v-for="(opt, index) in options" :key="index" class="custom-control custom-radio custom-control-inline"
             @click="$emit('input', opt)">
            <input :id="`radio-${index}`" type="radio" :name="$props.name" class="custom-control-input" :value="opt"
                   :checked="opt === value">
            <label class="custom-control-label" :for="`radio-${index}`">{{ opt }}</label>
        </div>
        <div v-if="!attrs.accepted_values" class="alert alert-secondary">
            No values defined
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownField',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        options() {
            return _.filter((this.attrs.accepted_values || '').split(','), val => val.length);
        },
    },
};
</script>
