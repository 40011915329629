<style lang="scss">
    .date-range-rolling-picker {
        height: 350px;
        z-index: 100;
        margin: 0 -2px 0 -2px;

        .rolling-picker-footer {
            height: 62px;
        }
    }
</style>

<template>
    <div class="card date-range-rolling-picker">
        <div class="card-header bg-transparent border-0 small text-uppercase text-muted pt-3 pb-0">
            Rolling Range
        </div>

        <div class="card-body pt-2">
            <div class="d-flex align-items-center">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-transparent pr-0 border-right-0">From:</span>
                    </div>
                    <input v-model="start" v-validate="{ min_value:0, max_value: 1000 }" name="from" type="number"
                           class="form-control no-spinner rounded-0 border-left-0">
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text rounded-0 border-left-0 border-right-0 bg-transparent pr-0">To:</span>
                    </div>
                    <input v-model="end" v-validate="{ min_value:0, max_value: start }" name="to" type="number"
                           class="form-control no-spinner border-left-0">
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="form-text text-muted small">
                    {{ selectedRange.label }} ago
                </div>
                <div class="text-right">
                    <form-control-error name="from" />
                    <form-control-error name="to" />
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="col-12 small text-uppercase text-muted mb-2">
                    Period
                </div>
                <div class="col">
                    <label v-for="range in ranges" :key="range.value" class="custom-checkbox-wrapper mr-2 mt-1 mb-2"
                           @click="setRange(range)">
                        <i class="fa-fw"
                           :class="{'far fa-dot-circle': period !== range.value, 'fas fa-dot-circle active': period === range.value}" />
                        {{ range.label }}
                    </label>
                </div>
            </div>
        </div>

        <div class="d-flex p-3 rolling-picker-footer border-top">
            <div class="w-50">
                <button class="btn btn-link btn-block" @click="close">
                    Cancel
                </button>
            </div>
            <div class="w-50">
                <button class="btn btn-primary btn-block" :disabled="!!$errors.any()" @click="apply">
                    Apply
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            start: 0,
            end: 0,
            period: 'day',
            ranges: [
                {
                    label: 'Days',
                    value: 'day',
                },
                {
                    label: 'Weeks',
                    value: 'week',
                },
                {
                    label: 'Months',
                    value: 'month',
                },
                {
                    label: 'Quarters',
                    value: 'quarter',
                },
                {
                    label: 'Years',
                    value: 'year',
                },
                {
                    label: 'Fiscal Years',
                    value: 'fiscalYear',
                },
            ],
        };
    },
    computed: {
        selectedRange() {
            return _(this.ranges).find({ value: this.period });
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                const start = Number(_.get(value, 'start'));
                const end = Number(_.get(value, 'end'));

                this.start = Number.isInteger(start) ? start : 1;
                this.end = Number.isInteger(end) ? end : 1;
                this.period = _.get(value, 'period', 'day');
            },
        },
    },
    methods: {
        setRange(range) {
            this.period = range.value;
        },
        async apply() {
            await this.$validator.validateAll();
            if (!this.$errors.any()) {
                this.$emit('input', {
                    period: this.period,
                    start: this.start,
                    end: this.end,
                });
                this.close();
            }
        },
        close() {
            this.$emit('close');
        },

    },
};
</script>
