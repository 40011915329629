<template>
    <div id="sidebar-wrapper">
        <div class="flex-column no-wrap justify-content-start h-100 sidebar-content">
            <div class="sidebar-header-wrapper">
                <slot v-bind="settings" name="header" />
            </div>

            <div class="sidebar-main-wrapper">
                <slot v-bind="settings" name="main" />
            </div>

            <div class="sidebar-footer-wrapper">
                <slot v-bind="settings" name="footer" />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            status: 'expanded',
        };
    },
    computed: {
        settings() {
            return {
                tooltip: {
                    trigger: 'hover',
                    placement: 'right',
                    disabled: this.status === 'expanded',
                    boundary: 'window',
                },
            };
        },
    },
    async created() {
        await this.$localStorage.get('sidenavStatus').then((status) => {
            this.status = status || 'expanded';
            setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
        });
    },
    methods: {
        setSidenav(status) {
            this.status = status;
            this.$localStorage.set('sidenavStatus', status);
            setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
        },
    },
};
</script>
