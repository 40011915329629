<template>
    <div class="connection-list">
        <div
            v-for="item in mappedItems"
            :id="item.code || item.id"
            :key="item.id"
            class="app-card card card-body p-0 clickable">
            <slot v-bind="item">
                <div class="d-flex flex-row justify-content-between clickable" @click="onItemSelected(item)">
                    <div class="app-card__wrapper">
                        <div :id="item.code || item.id" class="app-icon text-center">
                            <img class="app-logo" :src="item.icon">
                        </div>
                        <div class="app-content mt-2">
                            <div class="app-title mb-0">
                                <span class="app-title-content" :title="item.name">
                                    {{ item.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <confirm-n-delete-btn
                        v-can-access.level="'user'"
                        type="button"
                        class="btn btn-md text-danger btn-link delete-button" title="Delete"
                        :delete-confirmation="item.name"
                        message="You are about to delete this connection and all it’s associated syncs and jobs. Once you have deleted it, there is no way to recover it."
                        delete-title="Delete forever"
                        @delete="onDeleteItem(item)">
                        <i class="svg-icon icon-trash icon-lg" />
                    </confirm-n-delete-btn>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConnectionList',
    props: {
        items: {
            type: Array,
            required: true,
        },
        sortBy: {
            type: String,
        },
        sortOrder: {
            type: String,
        },
    },
    computed: {
        mappedItems() {
            return _.orderBy(this.items, this.sortBy, this.sortOrder);
        },
    },
    methods: {
        onItemSelected(item) {
            this.$emit('select', item);
        },
        onDeleteItem(item) {
            this.$emit('delete', item);
        },
    },
};
</script>

<style lang="scss" scoped>
.connection-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 430px);
    justify-content: center;
    gap: 15px 30px;

    .delete-button {
        visibility: hidden;
        opacity: 0;
    }

    .app-card:hover {
        .delete-button {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s linear;
        }
    }
}
</style>
