import { PusherService, PosthogService } from '@/utils/services';
import Config from '@/utils/config';

export default {
    data() {
        return {
            windowObjectReference: null,
            state: null,
            previousUrl: null,
            messageListener: null,
            windowClosedWatcher: null,
            resolvePromise: () => {},
            rejectPromise: () => {},
        };
    },
    created() {
        this.subscribeToMessages();
    },
    beforeDestroy() {
        this.unsubscribeFromMessages();
    },
    methods: {
        openSignInWindow(url, name) {
            const authURL = new URL(url);
            this.state = (new URLSearchParams(authURL.search)).get('state');

            // window features
            const strWindowFeatures = '_parent'; // 'popup';// 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
            this.clearWindowCloseWatcher();

            if (this.windowObjectReference === null || this.windowObjectReference.closed) {
                /* if the pointer to the window object in memory does not exist
                or if such pointer exists but the window was closed */
                this.windowObjectReference = window.open(url, name, strWindowFeatures);
            } else if (this.previousUrl !== url) {
                /* if the resource to load is different,
                then we load it in the already opened secondary window and then
                we bring such window back on top/in front of its parent window. */
                this.windowObjectReference = window.open(url, name, strWindowFeatures);
                this.windowObjectReference.focus();
            } else {
                /* else the window reference must exist and the window
                is not closed; therefore, we can bring it back on top of any other
                window with the focus() method. There would be no need to re-create
                the window or to reload the referenced resource. */
                this.windowObjectReference.focus();
            }

            this.setupWindowClosedWatcher();

            // assign the previous URL
            this.previousUrl = url;

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },
        receiveMessage(event) {
            // Do we trust the sender of this message?
            if (event.origin !== Config.appUrl) {
                return;
            }

            const { data } = event;
            // if we trust the sender
            if (_.get(data, 'payload.state', '') === this.state
                || _.get(data, 'source', '') === _.get(this.connection, 'code')) {
                this.state = null;
                this.resolvePromise(_.get(data, 'payload', null));
                this.clearWindowCloseWatcher();
            }
        },
        onWindowClosed() {
            this.clearWindowCloseWatcher();

            // give some time to receive the message
            setTimeout(() => {
                this.rejectPromise();
            }, 5000);
        },
        subscribeToMessages() {
            // subscribe to messages
            if ('serviceWorker' in navigator && !this.messageListener) {
                this.messageListener = navigator.serviceWorker.addEventListener('message', (event) => {
                    if (event.data && event.data.type === 'AUTHORIZATION_MESSAGE') {
                        this.receiveMessage(event.data);
                    }
                });
            }

            PusherService.user.listenFor('client-authorise-connection', this.receiveMessage);
        },
        unsubscribeFromMessages() {
            if (this.messageListener && 'serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener(this.messageListener);
            }

            PusherService.user.removeListener('client-authorise-connection', this.receiveMessage);
        },
        setupWindowClosedWatcher() {
            if (this.windowObjectReference) {
                this.windowClosedWatcher = setInterval(() => {
                    try {
                        if (this.windowObjectReference && this.windowObjectReference.closed) this.onWindowClosed();
                    } catch (e) {
                        console.error(e);
                        this.clearWindowCloseWatcher();
                    }
                }, 60000);
            }
        },
        clearWindowCloseWatcher() {
            if (this.windowClosedWatcher) clearInterval(this.windowClosedWatcher);
            PosthogService.pageView();
        },
    },
};
