<template>
    <div class="pb-2 h-100">
        <div class="d-flex flex-row justify-content-between no-wrap p-2">
            <div class="d-flex flex-row justify-content-between no-wrap">
                <h3 class="mr-3 clickable user-select-none text-header-md" :class="{ 'active': activeTab == 'connection', 'text-header__tab': settings.type == 'integration' }" @click="activeTab = 'connection'; searchTerm = '';">
                    Edit {{ settings.type }}
                </h3>
                <h3 v-if="settings.type === 'integration'" class="ml-5 clickable user-select-none text-header-md text-header__tab" :class="{ active: activeTab == 'policies' }" @click="activeTab = 'policies'; searchTerm = '';">
                    Policies
                </h3>
            </div>
            <loading-btn v-if="activeTab == 'connection'" class="btn btn-lg my-auto btn-primary" :disabled="disableSave" :listen="['dataSources/saveDataSourceConnection', 'dataTargets/saveDataTargetConnection', 'integrations/createIntegration']" @click="onSave">
                Save
            </loading-btn>
        </div>

        <div class="d-flex flex-row justify-content-start no-wrap py-4" style="height: 90%; gap: 30px;">
            <div v-if="activeTab == 'connection'" class="card overflow-auto border-0" style="max-width: 42.5%; min-width: 545px;">
                <div class="card-header px-4 pt-4 pb-0 bg-white border-0">
                    <div v-if="connection" class="text-sub-header font-weight-bold">
                        {{ connection.name }} details
                    </div>
                </div>
                <div class="card-body pt-4 px-4">
                    <loader :listen="['dataSources/fetchDataSourceConfiguration', 'dataTargets/fetchDataTargetConfiguration', 'integrations/fetchIntegrationConfiguration']" loading-class="m-auto h-100">
                        <div v-if="connection" class="pb-4 pt-0">
                            <form-field v-if="connection.type == 'source' || connection.type == 'destination'"
                                        key="connectionName"
                                        ref="formControl"
                                        v-model="connectionName.value"
                                        class="custom-form-field my-2 py-2"
                                        disabled
                                        :config="connectionName"
                            />

                            <form-field v-for="field in visibleParameters"
                                        :key="field.parameter"
                                        ref="formControl"
                                        v-model="field.value"
                                        class="custom-form-field my-2 py-2"
                                        :config="field"
                                        @input="onChange"
                            />
                            <div v-if="configuration.authorisation_url" class="mb-2 py-3">
                                <loading-btn
                                    class="btn btn-outline-primary btn-lg text-left"
                                    listen=""
                                    :hide-when-loading="true"
                                    :loading="loading"
                                    @click.prevent.stop="authorize">
                                    <div class="d-flex justify-content-center align-items-center" :class="`custom-btn custom-${SOURCE_BUTTON_STYLE[configuration.code]}-btn`">
                                        <span v-if="!connectionAuthorised
                                            && !authorisationChanged" />
                                        <div v-if="!connectionAuthorised
                                            && !authorisationChanged" :class="`${SOURCE_BUTTON_STYLE[configuration.code]}-img`">
                                            {{ SOURCE_BUTTON_STYLE[configuration.code] ? '' : 'Authorize Account' }}
                                        </div>
                                        <i v-if="SOURCE_BUTTON_STYLE[configuration.code]
                                            && SOURCE_BUTTON_STYLE[configuration.code] !== 'google'
                                            && SOURCE_BUTTON_STYLE[configuration.code] !== 'slack'" class="fab" :class="`fa-${SOURCE_BUTTON_STYLE[configuration.code]}`" />
                                        <span v-if="connectionAuthorised || authorisationChanged" class="ml-2 authorised" />
                                    </div>
                                </loading-btn>
                            </div>
                        </div>
                    </loader>
                </div>
            </div>

            <policies-list v-if="connection && connection.type == 'integration' && activeTab == 'policies'" :integration-id="connection.id" />

            <div v-if="configuration && configuration.setup_guide && activeTab == 'connection'" class="card overflow-auto border-0">
                <div class="card-header bg-white border-0 pt-4 px-4 pb-0">
                    <span class="text-sub-header font-weight-bold" v-html="configuration.setup_guide.headerTitle" />
                    <p class="small text-base line" v-html="configuration.setup_guide.header" />
                </div>
                <div class="bg-light card-body connection-desc bg-white px-4 pt-4">
                    <ul class="list-group mb-3">
                        <li v-for="(item, index) in configuration.setup_guide.guideSteps" :key="index"
                            class="list-group-item bg-transparent border-0 small px-0 py-2 text-base line">
                            <div class="text-base line" v-html="item.content" />
                        </li>
                    </ul>

                    <h6 class="font-weight-bold text-base line" v-html="configuration.setup_guide.footerTitle" />
                    <p class="small text-base line" v-html="configuration.setup_guide.footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConnectionModel from '@/store/models/connection.model';
import DynamicParameter from '@/store/models/dynamicParameter.model';
import { SOURCE_BUTTON_STYLE, LABELS } from '@/constants';
import FormField from '@/components/formField';
import PopupWindow from '@/mixins/popupWindow';
import PoliciesList from './policiesList';

export default {
    name: 'EditConnection',
    components: { FormField, PoliciesList },
    mixins: [PopupWindow],
    props: {
        settings: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            connectionName: null,
            loading: false,
            connection: null,
            configuration: null,
            authorizationPayload: null,
            connectionAuthorised: false,
            authorisationChanged: false,
            SOURCE_BUTTON_STYLE,
            changed: false,
            activeTab: this.settings.type === 'integration' ? 'policies' : 'connection',
        };
    },
    computed: {
        ...mapGetters({
            availableSources: 'dataSources/availableDataSources',
            availableDestinations: 'dataTargets/availableDataTargets',
            availableIntegrations: 'integrations/integrationTypes',
            dataSourcesConfigurations: 'dataSources/dataSourcesConfigurations',
            dataTargetsConfigurations: 'dataTargets/dataTargetsConfigurations',
            policyConfigurations: 'integrations/policyConfigurations',
            integrationPolicies: 'integrations/integrationPolicies',
        }),
        visibleParameters() {
            return _(_.get(this.connection, 'parameters', {}))
                .filter('visible')
                .orderBy('order_of_display')
                .value();
        },
        disableSave() {
            return !this.changed
                    || !this.connection
                    || !this.connection.isValid
                    || this.loading
                    || ((this.connection.type === 'source' || this.connection.type === 'destination')
                        && !this.connectionName.isValid);
        },
    },
    created() {
        switch (this.settings.type) {
            case 'source':
                this.fetchDataSourceConfiguration({ dataSourceCode: this.settings.code })
                    .then((configuration) => {
                        this.connectionName = new DynamicParameter(this.settings.name, { control: 'text', required: true, display_name: 'Name' });
                        this.configuration = configuration;
                        this.connection = new ConnectionModel(this.settings, configuration);
                        console.log('edit configuration', this.configuration);
                        console.log('edit connection', this.connection);
                        this.connectionAuthorised = this.connection.authorised;
                    });
                break;
            case 'destination':
                this.fetchDataTargetConfiguration(this.settings.code)
                    .then((configuration) => {
                        this.connectionName = new DynamicParameter(this.settings.name, { control: 'text', required: true, display_name: 'Name' });
                        this.configuration = configuration;
                        this.connection = new ConnectionModel(this.settings, configuration);
                        console.log('edit configuration', this.configuration);
                        console.log('edit connection', this.connection);
                        this.connectionAuthorised = this.connection.authorised;
                    });
                break;
            case 'integration':
                this.fetchIntegrationConfiguration(this.settings.code)
                    .then((configuration) => {
                        console.log(configuration);
                        this.configuration = configuration;
                        this.connection = new ConnectionModel(this.settings, configuration, this.fetchDynamicParameterValues);
                        this.connectionAuthorised = true;
                    });
                break;
            default:
                break;
        }
    },
    methods: {
        ...mapActions({
            fetchDataSourceConfiguration: 'dataSources/fetchDataSourceConfiguration',
            fetchDataTargetConfiguration: 'dataTargets/fetchDataTargetConfiguration',
            fetchIntegrationConfiguration: 'integrations/fetchIntegrationConfiguration',
            fetchDynamicParameterValues: 'integrations/fetchDynamicParameterValues',
            updateSourceConnection: 'dataSources/updateSourceConnection',
            updateDestinationConnection: 'dataTargets/updateDestinationConnection',
            updateIntegrationConnection: 'integrations/updateIntegration',
        }),
        authorize() {
            this.loading = true;
            const authLink = this.formAuthorisationLink();
            this.openSignInWindow(authLink, this.connectionName)
                .then((payload) => {
                    this.authorizationPayload = payload;
                    this.authorisationChanged = true;
                    this.connectionAuthorised = true;
                    this.changed = true;
                })
                .catch((e) => {
                    console.log('faild message', e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formAuthorisationLink() {
            const groups = this.configuration.authorisation_url.match(/<(?:\w+)>/g);
            let authURL = this.configuration.authorisation_url;
            _.each(groups, (group) => {
                const parameter = _.get(this.connection.parameters, _.trim(group, '<>'), '');
                const value = _.get(parameter, 'value', '');
                if (parameter && value) authURL = _.replace(authURL, group, value);
            });
            return authURL;
        },
        onSave() {
            const payload = {
                id: this.connection.id,
                ...this.connection.payload,
                authorization: this.authorizationPayload || undefined,
            };

            this.loading = true;

            switch (this.connection.type) {
                case 'source':
                    this.updateSourceConnection(payload)
                        .then(() => {
                            this.$notify({
                                type: 'success',
                                text: LABELS.sourceUpdateSuccess,
                            });
                            this.$emit('close');
                        })
                        .catch((e) => {
                            this.$notify({
                                type: 'error',
                                text: e.message || LABELS.defaultError,
                            });
                        }).finally(() => { this.loading = false; });
                    break;
                case 'destination':
                    this.updateDestinationConnection(payload)
                        .then(() => {
                            this.$notify({
                                type: 'success',
                                text: LABELS.destinationUpdateSuccess,
                            });
                            this.$emit('close');
                        })
                        .catch((e) => {
                            this.$notify({
                                type: 'error',
                                text: e.message || LABELS.defaultError,
                            });
                        }).finally(() => { this.loading = false; });
                    break;
                case 'integration':
                    this.updateIntegrationConnection(payload)
                        .then(() => {
                            this.$notify({
                                type: 'success',
                                text: LABELS.integrationUpdateSuccess,
                            });
                            this.$emit('close');
                        })
                        .catch((e) => {
                            this.$notify({
                                type: 'error',
                                text: e.message || LABELS.defaultError,
                            });
                        }).finally(() => { this.loading = false; });
                    break;
                default:
                    break;
            }
        },
        onChange() {
            this.changed = true;
        },
    },
};
</script>
