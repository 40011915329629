<template>
    <div>
        <b-form-select
            v-model="selectedValue"
            :options="options"
            value-field="text"
            :required="required"
            @input="e => $emit('input', selectedValue)"
        />
    </div>
</template>

<script>
export default {
    name: 'OneSelectField',
    props: {
        value: {},
        attrs: {
            type: Object,
            default: () => ({}),
        },
        required: {
            type: Boolean,
        },
    },
    data() {
        return {
            options: [],
            selectedValue: '',
        };
    },
    watch: {
        value(val) {
            this.selectedValue = val;
        },
    },
    created() {
        if (this.attrs.values_list) {
            this.options = this.attrs.values_list.split(',').map((value, index) => {
                return { value: index, text: decodeURIComponent(value) };
            });
        } else {
            this.options = this.attrs.accepted_values.split(',').map((value, index) => {
                return { value: index, text: value };
            });
        }
       
        this.selectedValue = this.value !== undefined ? this.value : this.attrs.default_value;
    },
};
</script>
