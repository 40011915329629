<template>
    <b-modal
        v-if="showModal || showRoleChangeModal"
        :id="`edit-user-modal_${user.id}`"
        :key="`edit-user-modal_${user.id}`"
        class="user-permissions-modal"
        size="lg"
        header-bg-variant="white"
        header-text-variant="default"
        visible
        @hide="dismiss"
    >
        <div slot="modal-header">
            <h5 class="modal-title">
                <span>Edit User</span>
            </h5>
        </div>
        <div class="form-group">
            <label>Email Address:</label>
            <div class="input-group">
                {{ this.user.username }}
            </div>
        </div>
        <div class="form-group mt-4">
            <label>Select a role:</label>
            <multi-select v-model="selectedRole" :options="roles" label-field="title" value-field="id" :allow-clear="true" />
        </div>

        <template slot="modal-footer">
            <div class="d-flex flex-row justify-content-around w-50 mx-auto">
                <button class="btn btn-link" @click="dismiss()">
                    Cancel
                </button>
                <loading-btn
                    class="btn my-auto btn-primary"
                    :disabled="!canSave"
                    :listen="['users/updateOrganisationUserRole']"
                    @click="save()"
                >
                    Save
                </loading-btn>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ROLES } from "../../../../constants";

export default {
    props: {
        user: {
            type: Object,
            default: () => ({
                role: {}
            })
        },
        disabled: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: "link"
        },
        showModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: "",
            showRoleChangeModal: false,
            roles: _.filter(ROLES, "assignable")
        };
    },
    computed: {
        ...mapState({
            loggedIn: s => s.profile.user
        }),
        canSave() {
            return this.selectedRole;
        }
    },
    watch: {
        "user.role": {
            immediate: true,
            handler() {
                this.selectedRole = _.get(this.user.role, "id");
            }
        }
    },
    methods: {
        ...mapActions({
            updateOrganisationUserRole: "users/updateOrganisationUserRole",
            getUsers: "users/getAvailableUsers"
        }),
        dismiss() {
            this.selectedRole = undefined;
            this.email = "";
            this.$nextTick(() => {
                this.showRoleChangeModal = false;
                this.$bvModal.hide(`edit-user-modal_${this.user.id}`);
                this.$emit("hide-modal", true);
            });
        },
        async save() {
            const authority = _.find(ROLES, { id: this.selectedRole });
            const payload = {
                id: this.user.id,
                email: this.user.username,
                authority: authority ? authority.name : "",
                currentRole: _.get(this.user.role, "id")
            };
            await this.updateOrganisationUserRole(payload)
                .then(() => {
                    this.$notify({
                        type: "success",
                        title: "Success!",
                        text: `UserRole was updated successfully for ${payload.email}.`
                    });
                    this.dismiss();
                })
                .catch(e =>
                    this.$notify({
                        type: "error",
                        title: "Error!",
                        text: e.message || `There was an error when updating UserRole for ${payload.email}.`
                    })
                );
        }
    }
};
</script>
