<template>
    <bulk-job-creation :sync-id="settings.syncId" :source-tables="sourceTables" @save="onSave" />
</template>

<script>
import { mapGetters } from 'vuex';
import BulkJobCreation from './bulkJobCreation';

export default {
    name: 'JobCreation',
    components: { BulkJobCreation },
    props: {
        settings: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            mySyncs: 'syncs/mySyncs',
        }),
        sync() {
            return _.get(this.mySyncs, this.settings.syncId, {});
        },
        sourceTables() {
            if (this.settings.isBulkFlow) return this.settings.sourceTables;
            const label = `${_.get(this.sync, 'sourcename', '')}_to_${_.get(this.sync, 'targetname', '')}`;
            return [{ label }];
        },
    },
    created() {
        console.log(this.settings);
    },
    methods: {
        onSave(payload) {
            this.$emit('save', { jobs: payload });
        },
    },
};
</script>
