<style lang="scss">
    .color-picker {
        .color-dot {
            width: 32px;
            height: 32px;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            display: flex;
        }

        .color-picker-popup {
            position: absolute;
            top: 35px;
            left: 0;
            z-index: 1;
        }
    }
</style>

<template>
    <div class="color-picker d-flex position-relative">
        <div ref="colorDot" :disabled="disabled" name="btn-toggle-picker" class="color-dot rounded-circle mr-2 mb-2 border"
             triggers="hover, focus, click" :style="{backgroundColor: color.hex}"
             @click="togglePicker()">
            <slot />
        </div>
        <p v-if="!disabled && value" class="small" :target="() => $refs.colorDot">
            <button class="btn btn-sm btn-link px-0" name="btn-reset-color" @click="resetColor">
                <i class="far fa-sync-alt fa-fw" /> Reset
            </button>
            <button v-if="allowDelete" class="btn btn-sm btn-link mr-5" name="btn-delete-color" @click="deleteColor">
                <i class="far fa-trash-alt fa-fw" /> Delete
            </button>
        </p>
        <div v-if="showPicker && !disabled" v-on-clickaway.native="closePicker" class="color-picker-popup bg-light border shadow-sm">
            <picker v-model="color" class="border-0 shadow-none" />
            <div class="form-row p-2">
                <div class="col-6">
                    <button class="btn btn-sm btn-outline-secondary btn-block" name="btn-close-picker" @click="closePicker">
                        Close
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-sm btn-outline-primary btn-block" name="btn-set-color" @click="setColor">
                        Set
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Sketch } from 'vue-color';
import { mixin as clickaway } from 'vue-clickaway';

export default {
    components: {
        picker: Sketch,
    },
    mixins: [clickaway],
    props: {
        value: {
            type: String,
            default: '',
        },
        defaultColor: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowDelete: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showPicker: false,
            color: { hex: '#fff' },
        };
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.color.hex = this.value || this.defaultColor;
            },
        },
    },
    methods: {
        deleteColor() {
            this.$emit('delete');
        },
        setColor() {
            this.showPicker = false;
            this.$emit('input', this.color.hex);
        },
        resetColor() {
            this.showPicker = false;
            this.color.hex = this.defaultColor;
            this.$emit('input', this.defaultColor);
        },
        closePicker() {
            this.showPicker = false;
            this.color.hex = this.value;
        },
        togglePicker() {
            this.showPicker = !this.disabled && !this.showPicker;
        },
    },
};
</script>
