import Vue from 'vue';
import * as date from './date';
import * as lang from './lang';
import * as number from './number';

_.each({
    ...date,
    ...lang,
    ...number,
},
(func, key) => {
    Vue.filter(_.camelCase(key), func);
});
