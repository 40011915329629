/* eslint class-methods-use-this: 0 */
/* eslint consistent-return: 0 */
/* eslint import/no-extraneous-dependencies: 0 */

import Dexie from 'dexie';
import moment from 'moment';
import Vue from 'vue';

class IndexedDbAdapter {
    constructor() {
        this.db = new Dexie('gravity');
        this.db.version(1).stores({
            default: 'key',
            permanent: 'key',
        });

        this.expiry = {
            default: { unit: 'hours', value: 24 },
            permanent: false,
        };
    }

    async get(store, key, defaultValue) {
        try {
            const result = await this.db[store].get(key);

            if (!result || result.expiresAt <= moment().valueOf()) return undefined;

            return result.value;
        } catch (e) {
            Vue.logger.notify(e, {
                severity: 'info',
                context: 'indexeddb-adapter get',
            });
            return defaultValue;
        }
    }

    async set(store, key, value) {
        try {
            const expiry = this.expiry[store];
            const expiresAt = expiry ? moment().add(expiry.value, expiry.unit).valueOf() : undefined;
            return this.db[store].put({ key, value, expiresAt });
        } catch (e) {
            Vue.logger.notify(e, {
                severity: 'info',
                context: 'indexeddb-adapter set',
            });
        }
    }

    async remove(store, key) {
        try {
            return this.db[store].delete(key);
        } catch (e) {
            Vue.logger.notify(e, {
                severity: 'info',
                context: 'indexeddb-adapter remove',
            });
        }
    }

    async removeAll(store) {
        try {
            return this.db[store] && this.db[store].clear();
        } catch (e) {
            Vue.logger.notify(e, {
                severity: 'info',
                context: 'indexeddb-adapter removeAll',
            });
        }
    }

    async keys(store) {
        try {
            return this.db[store].toCollection().primaryKeys();
        } catch (e) {
            Vue.logger.notify(e, {
                severity: 'info',
                context: 'indexeddb-adapter keys',
            });
        }
    }

    addKey() {}

    removeKey() {}
}

export default new IndexedDbAdapter();
