<style lang="scss">
    .pika-label,
    .pika-table th {
        font-weight: 500;
    }

    .data-range-calendar {
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        width: 602px;
        z-index: 1000;

        .pika-single {
            border: 0;
            box-shadow: none;
        }

        .card-footer {
            .btn {
                max-width: 270px;
            }
        }

        .card-body {
            height: 270px;
            width: 100%;
        }
        
        .pika-button {
            text-align: center;
            height: 25px;
        }

        .pika-lendar {
            width: 100%;
            padding-top: 0;
        }

        .is-startrange .pika-button,
        .is-endrange .pika-button {
            background: #d5e9f7;
            color: black;
        }

        .pika-start .is-startrange .pika-button,
        .pika-end .is-endrange .pika-button {
            background: var(--theme-link-active-text-color);
            color: white;
        }
    }
</style>

<template>
    <div class="data-range-calendar card">
        <div class="card-body">
            <div class="d-flex flex-nowrap justify-content-between">
                <div class="flow-element">
                    <div ref="startDate" class="pika-start" />
                </div>
                <div class="flow-element">
                    <div ref="endDate" class="pika-end" />
                </div>
            </div>
        </div>
        <div class="card-footer bg-transparent mt-10">
            <div class="d-flex flex-nowrap justify-content-between">
                <button class="btn btn-link w-50" @click="close">
                    Cancel
                </button>
                <button class="btn btn-success text-white w-50" @click="apply">
                    Apply
                </button>
            </div>
        </div>
    </div>

</template>

<script>

import { mapGetters } from 'vuex';
import Pikaday from './pikaday-plus/pikaday';

export default {
    props: {
        filter: {
            type: Object,
        },
        value: {
            type: Object,
        },
    },
    data() {
        return {
            closing: false,
            dates: {
                start: undefined,
                end: undefined,
            },
            settingEnd: false,
            currentDate: new Date(new Date().setHours(0, 0, 0, 0)),
            showOnce: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'profile/user',
            organisation: 'profile/organisation',
        }),
    },
    beforeDestroy() {
        this.closing = true;
        if (this.startDatePicker || this.endDatePicker) {
            this.startDatePicker.destroy();
            this.endDatePicker.destroy();
            this.startDatePicker = undefined;
            this.endDatePicker = undefined;
        }
    },
    mounted() {
        const vm = this;
        const currentYear = this.$moment().year();
        const dateFormat = vm.user.getDateFormat();
        
        const defaultDateStart = this.value ? this.$moment(this.value.start, dateFormat).toDate() : '';
        const defaultDateEnd = this.value ? this.$moment(this.value.end, dateFormat).toDate() : '';
        
        this.startDatePicker = new Pikaday({
            container: this.$refs.startDate,
            position: 'bottom left',
            showWeekNumber: true,
            firstDay: this.organisation.startDayOfWeek,
            yearRange: [2010, currentYear + 10],
            bound: false,
            defaultDate: defaultDateStart,
            setDefaultDate: true,
            onSelect(date) {
                vm.validateRange();
                vm.endDatePicker.setMinDate(date);
            },
            onOpen() {
                const offset = new Date().getTimezoneOffset();
                
                if (!vm.showOnce && vm.filter && vm.filter.rangeName && vm.filter.toDays && vm.filter.fromDays) {
                    const today = vm.$moment().startOf('day');

                    let offsetToday = vm.$moment(today).utcOffset(-offset * (-1));
                   
                    if (vm.filter.fromDays > 8) {
                        offsetToday = vm.$moment(today).utcOffset(-offset * (-1) - 60);
                    }

                    const toDays = -(vm.filter.toDays);
                    const fromDays = -(vm.filter.fromDays);
                    const startDate = vm.$moment(offsetToday, dateFormat).add(fromDays, 'days').utc(true).toDate();
                    const endDate = vm.$moment(today, dateFormat).add(toDays, 'days').utc(true).toDate();
                    this.setStartRange(startDate);
                    this.setEndRange(endDate);
                } else if (vm.value) {
                    const offsetHoursStart = vm.$moment(vm.value.start, dateFormat).toDate().getHours();
                    const offsetHoursEnd = vm.$moment(vm.value.end, dateFormat).toDate().getHours();
                    /* eslint-disable */
                    const selectedStartDate = vm.$moment(vm.value.start, dateFormat).utcOffset(-(offsetHoursStart * 60))._d;
                    const selectedEndDate = vm.$moment(vm.value.end, dateFormat).utcOffset(-(offsetHoursEnd * 60))._d;
                    /* eslint-enable */
                   
                    this.setStartRange(selectedStartDate);
                    this.setEndRange(selectedEndDate);
                    vm.$nextTick(() => {
                        vm.endDatePicker.setMinDate(selectedStartDate);
                    });
                }
                vm.showOnce = true;
            },
            onClose() {
                if (!vm.closing) {
                    this.show();
                }
            },
        });

        this.endDatePicker = new Pikaday({
            container: this.$refs.endDate,
            position: 'bottom right',
            showWeekNumber: true,
            firstDay: this.organisation.startDayOfWeek,
            yearRange: [2010, currentYear + 10],
            bound: false,
            defaultDate: defaultDateEnd,
            setDefaultDate: true,
            onSelect(date) {
                vm.validateRange();
                vm.startDatePicker.setMaxDate(date);
            },
            onOpen() {
                const offset = new Date().getTimezoneOffset();
                if (vm.filter && vm.filter.rangeName && vm.filter.toDays && vm.filter.fromDays) {
                    const today = vm.$moment().startOf('day');
                   
                    const toDays = -(vm.filter.toDays);
                    const fromDays = -(vm.filter.fromDays);
                    const offsetToday = vm.$moment(today).utcOffset(-offset * (-1));

                    const startDate = vm.$moment(offsetToday, dateFormat).add(fromDays, 'days').utc(true).toDate();
                    const endDate = vm.$moment(offsetToday, dateFormat).add(toDays, 'days').utc(true).toDate();
                    this.setStartRange(startDate);
                    this.setEndRange(endDate);
                } else if (vm.value) {
                    const offsetHoursStart = vm.$moment(vm.value.start, dateFormat).toDate().getHours();
                    const offsetHoursEnd = vm.$moment(vm.value.end, dateFormat).toDate().getHours();
                    
                    /* eslint-disable */
                    const selectedStartDate = vm.$moment(vm.value.start, dateFormat).utcOffset(-(offsetHoursStart * 60))._d;
                    const selectedEndDate = vm.$moment(vm.value.end, dateFormat).utcOffset(-(offsetHoursEnd * 60))._d;
                    /* eslint-enable */
                    
                    this.setStartRange(selectedStartDate);
                    this.setEndRange(selectedEndDate);
                    vm.$nextTick(() => {
                        vm.startDatePicker.setMaxDate(selectedEndDate);
                    });
                }
                vm.showOnce = true;
            },
            onClose() {
                if (!vm.closing) {
                    this.show();
                }
            },
        });
    },
    methods: {
        apply() {
            this.$emit('input', {
                value: 'custom',
                ...this.dates,
            });
            this.close();
        },
        close() {
            this.closing = true;
            this.$emit('close');
        },
        validateRange() {
            // eslint-disable-next-line
            const startDate = this.startDatePicker._d;
            // eslint-disable-next-line
            const endDate = this.endDatePicker._d;
            
            if (endDate && startDate) {
                this.startDatePicker.setStartRange(startDate);
                this.startDatePicker.setEndRange(endDate);
                this.endDatePicker.setStartRange(startDate);
                this.endDatePicker.setEndRange(endDate);

                // offset needs to be added because moment is seen as Utc but it has gmt+3 ??
                const offset = new Date().getTimezoneOffset();
                const currentDay = this.$moment(new Date());
                const startDayPicker = this.$moment(startDate);
                const diff = currentDay.diff(startDayPicker, 'days');
               
                this.dates.start = this.$moment(startDate).utcOffset(offset * (-1));
                this.dates.end = this.$moment(endDate).utcOffset(offset * (-1));
                if (diff > 8) {
                    this.dates.start = this.$moment(startDate).utcOffset(offset * (-1) + 60);
                    this.dates.end = this.$moment(endDate).utcOffset(offset * (-1) + 60);
                }
            } else {
                this.startDatePicker.setStartRange(null);
                this.startDatePicker.setEndRange(null);
                this.endDatePicker.setStartRange(null);
                this.endDatePicker.setEndRange(null);
            }
        },

    },
};
</script>
