<template>
    <div id="syncs-container" class="router-view">
        <portal to="header">
            <div v-if="allSyncs.length" class="d-flex flex-column justify-content-start flex-wrap header-content">
                <h1 class="text-header-md">Syncs overview</h1>
                <div class="d-flex flex-row justify-content-between no-wrap mt-3">
                    <p class="header-info">
                        A sync is a link between a source and a destination.
                        In order to create a sync you first need to setup connections to a source and a destination.
                        Click here to learn more about syncs.
                    </p>
                    <button
                        v-can-access.level="'user'"
                        class="btn btn-primary ml-auto main-button"
                        @click="showCreateModal = true">
                        <span class="text-nowrap">
                            + Add new sync
                        </span>
                    </button>
                </div>
            </div>
        </portal>

        <loader :listen="['dataSources/fetchMyDataSources', 'dataTargets/fetchMyDataTargets', 'dataSets/fetchMyDataSets']" loader-class="m-auto">
            <template v-if="filteredSyncs.length">
                <div class="sync-card-grid">
                    <div class="my-auto text-muted">
                        Sync name</div>
                    <div class="text-muted my-auto">
                        Source & Destination
                    </div>
                    <div class="text-muted my-auto">
                        Jobs
                    </div>
                    <div class="text-muted my-auto">
                        Schedule
                    </div>
                    <div class="text-muted my-auto">
                        Run History
                    </div>
                </div>
                <router-link v-for="(sync, syncId) in filteredSyncs" :key="syncId" :to="{ name: 'sync-overview', params: { syncId: sync.id } }" class="sync-card card border-0 clickable" :class="isSyncPaused(sync.id) ? 'disabled' : 'enabled'">
                    <div class="sync-card-grid card-body">
                        <div class="my-auto text-left sync-title">
                            {{ sync.name }}
                        </div>
                        <div class="d-flex flex-row justify-content-start no-wrap my-auto">
                            <div class="my-auto">
                                <div class="d-inline-block" v-b-tooltip.hover="sync.sourcename">
                                    <img :src="`${assetsStorage}/${sync.sourcecode}.png`" height="40" width="40" class="my-auto mx-2">
                                </div>
                                <div class="my-auto d-inline-block">
                                    <i class="svg-icon icon-arrow" />
                                </div>
                                <div class="d-inline-block" v-b-tooltip.hover="sync.targetname">
                                    <img :src="`${assetsStorage}/${sync.targetcode}.png`" height="40" width="40" class="my-auto mx-2">
                                </div>
                            </div>
                        </div>
                        <div class="my-auto">
                            {{ syncJobs(sync).length }}
                        </div>
                        <div class="my-auto">
                            <div class="mh-100">{{ syncSchedule(sync) }}</div>
                            <div class="text-muted">{{ isSyncPaused(sync.id) ? 'Paused' : customSchedules(sync) }}</div>
                        </div>
                        <div class="my-auto">
                            <div class="d-flex flex-row justify-content-start no-wrap my-auto">
                                <div v-for="(logEntry, $index) in runLogs(sync)" :key="logEntry.run_id" v-b-tooltip.hover.html="detailsTooltip(logEntry)" class="run-line-container" @click.prevent.stop="onShowLogTrace(logEntry)">
                                    <div class="run-line" :class="{ 'success': logEntry.status === 'FINISHED_OK', 'error': logEntry.status === 'ERROR', 'primary': !logEntry.status }" :style="{ height: runLineHeight(runLogs(sync), $index) }" />
                                </div>
                            </div>
                        </div>
                        <loading-btn v-b-tooltip.hover="isSyncPaused(sync.id) ? 'Unpause' : 'Pause'" class="my-auto ml-auto mr-0 btn btn-sm pt-2" :listen="[`syncs/updateSyncStatus/${sync.id}`]" hide-when-loading @click.stop>
                            <div v-can-access.level="'user'" class="custom-control custom-switch">
                                <input :id="`${sync.id}_pause`" :checked="!isSyncPaused(sync.id)" type="checkbox" class="custom-control-input p-0" @input="onTogglePaused(sync)">
                                <label :for="`${sync.id}_pause`" class="custom-control-label user-select-none clickable" />
                            </div>
                        </loading-btn>
                    </div>
                </router-link>
            </template>
            <div v-else-if="allSyncs.length" class="d-flex flex-column justify-content-center pt-5">
                <div class="text-center">
                    <img src="../../../../public/icons/empty-sync.svg"/>
                </div>
                <div class="text-center">
                    No sync containing "{{ searchTerm }}" in their name
                </div>
            </div>
            <div v-else class="d-flex flex-column justify-content-center pt-5">
                <div class="m-auto">
                    <div class="text-center m-auto" style="height: 200px;">
                        <!--TODO - create image and link it here -->
                        <img src="../../../../public/icons/empty-sync.svg" />
                    </div>
                    <h1 class="user-select-none text-header-lg text-center">
                        Creating your first sync
                    </h1>
                    <div class="text-center text-lg my-4 mx-auto" style="max-width: 700px;">
                        A “Sync” is a connection between a source and a destination. You will setup a connection to these while you create your first sync.
                    </div>
                    <div class="d-flex mt-5">
                        <button v-can-access.level="'user'" class="mx-auto my-3 btn btn-lg btn-primary" @click="showCreateModal = true">
                            Create your first sync
                        </button>
                    </div>
                </div>
            </div>
        </loader>
        <execution-info
            v-if="showLogTrace"
            key="execution-info"
            :show="showLogTrace"
            :log="log"
            @close="showLogTrace = false;" />

        <portal to="modals">
            <div v-if="showCreateModal" key="sync-creation" v-can-access.level="'user'" class="modals-route">
                <button type="button" class="close-modal-btn btn-light" @click="showCreateModal = false">
                    <i class="svg-icon icon-close" />
                </button>
                <div class="modal-container">
                    <sync-creation @close="showCreateModal = false" />
                </div>
            </div>
        </portal>
    </div>
</template>

<script>
import cronstrue from 'cronstrue';

import { mapActions, mapGetters } from 'vuex';
import executionInfo from '@/modules/datasetHistory/views/execution-info';
import SyncCreation from '@/modules/syncs/views/syncCreation';
import Config from '@/utils/config';
import { LABELS } from '@/constants';

export default {
    components: { executionInfo, SyncCreation },
    data() {
        return {
            assetsStorage: Config.assetsStorage,
            searchTerm: '',
            showLogTrace: false,
            log: null,
            showCreateModal: false,
        };
    },
    computed: {
        ...mapGetters({
            myJobs: 'dataSets/myDataSets',
            mySources: 'dataSources/myDataSources',
            myDestinations: 'dataTargets/myDataTargets',
            multipleJobsRunHistory: 'runHistory/multipleJobsRunHistory',
            mySyncs: 'syncs/mySyncs',
            user: 'profile/user',
        }),
        allSyncs() {
            return _.orderBy(this.mySyncs, 'name');
        },
        filteredSyncs() {
            return _.filter(this.allSyncs, ({ name }) => {
                return _.includes(_.toLower(name), _.toLower(this.searchTerm));
            });
        },
        syncJobs() {
            return (sync) => {
                return _(this.myJobs)
                    .filter({ syncId: sync.id })
                    .value();
            };
        },
        runLogs() {
            return (sync) => {
                return _(this.syncJobs(sync))
                    .map('id')
                    .thru((ids) => {
                        return this.multipleJobsRunHistory(ids);
                    })
                    .takeRight(9)
                    .value();
            };
        },
        syncSchedule() {
            return (sync) => {
                if (!sync.run_frequency) return '';
                return cronstrue.toString(sync.run_frequency);
            };
        },
        customSchedules() {
            return (sync) => {
                const jobs = this.syncJobs(sync);
                const jobsSchedules = _.map(jobs, ({ parameters }) => {
                    return _.get(parameters, 'SCHEDULE_INTERVAL_ANCHOR');
                });
                let customCount;
                if (sync.run_frequency) {
                    customCount = _.size(_.without(jobsSchedules, sync.run_frequency));
                } else {
                    customCount = _.size(jobsSchedules);
                }
                return customCount ? `${customCount} custom ${customCount > 1 ? 'schedules' : 'schedule'}` : '';
            };
        },
        isSyncPaused() {
            return (syncId) => {
                return _.get(this.mySyncs[syncId], 'schedule_status', 'enabled') === 'disabled';
            };
        },
    },
    created() {
        this.$eventHub.$on('search', this.onSearchChanged);
        this.$eventHub.$emit('clearSearch');
    },
    beforeDestroy() {
        this.$eventHub.$off('search', this.onSearchChanged);
    },
    methods: {
        ...mapActions({
            updateSyncStatus: 'syncs/updateSyncStatus',
        }),
        onSearchChanged(value) {
            this.$set(this, 'searchTerm', value);
        },
        clearSearch() {
            this.searchTerm = '';
        },
        runLineHeight(logs, index) {
            const maxDuration = this.maxDuration(logs);

            const runLogDuration = this.runDuration(logs[index]).asSeconds().toFixed(2);
            const height = _.clamp(runLogDuration * 50 / maxDuration, 5, 50);
            return `${height}px`;
        },
        runDuration(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            const endDatetime = _.get(logEntry, 'end_datetime');
            const startDate = this.$moment(startDatetime);
            const endDate = this.$moment(endDatetime);

            return this.$moment.duration(endDate.diff(startDate));
        },
        maxDuration(runLogs) {
            const maxLog = _.maxBy(runLogs, (logEntry) => {
                return this.runDuration(logEntry).asSeconds();
            });
            return this.runDuration(maxLog).asSeconds().toFixed(2);
        },
        detailsTooltip(logEntry) {
            return `
                <div>
                    <div><span>Status: </span><span class="strong ${this.statusClass(logEntry)}">${this.statusText(logEntry)}</span></div>
                    <div><span>Started: </span><span class="strong">${this.formatStartDate(logEntry)}</span></div>
                    <div><span>Duration: </span><span class="strong">${this.formatDuration(logEntry)}</span></div>
                    <div><span>Run Id: </span><span class="strong">${_.get(logEntry, 'run_id')}</span></div>
                    <div><span>Job Name: </span><span class="strong">${_.get(logEntry, 'jobName', '-')}</span></div>
                </div>
                `;
        },
        statusClass(logEntry) {
            if (logEntry.status === 'FINISHED_OK') return 'text-success';
            if (logEntry.status === 'ERROR') return 'text-danger';
            return 'text-primary';
        },
        statusText(logEntry) {
            if (logEntry.status === 'FINISHED_OK') return 'Success';
            if (logEntry.status === 'ERROR') return 'Error';
            return 'Running';
        },
        formatDuration(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            const endDatetime = _.get(logEntry, 'end_datetime');
            const startDate = this.$moment(startDatetime);
            const endDate = this.$moment(endDatetime);
            const duration = endDate.preciseDiff(startDate);
            return duration || '-';
        },
        formatStartDate(logEntry) {
            const startDatetime = _.get(logEntry, 'start_datetime');
            if (!startDatetime) return '-';

            return this.$moment.tz(this.$moment(startDatetime), this.user.timezone).calendar(null, {
                sameDay: '[Today at] LTS',
                lastDay: '[Yesterday at] LTS',
                lastWeek: 'ddd [at] LTS',
                sameElse: `${this.user.dateFormat} [at] LTS`,
            });
        },
        onShowLogTrace(logEntry) {
            this.showLogTrace = true;
            this.log = logEntry;
        },
        onTogglePaused(sync) {
            this.updateSyncStatus({
                id: sync.id,
                schedule_status: sync.schedule_status === 'enabled'
                    ? 'disabled'
                    : 'enabled',
            })
                .then(() => {
                    this.$notify({
                        type: 'success',
                        text: 'Success',
                    });
                })
                .catch((e) => {
                    this.$notify({
                        type: 'error',
                        text: e.message || LABELS.defaultError,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.sync-card {
    color: var(--theme-text-color);
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    border-radius: $border-radius;
    height: 70px;
    margin-top: 10px;

    &.disabled {
        opacity: 0.6;
    }

    &:hover {
        text-decoration: none;
        transform: scale(1.02);
    }

    .custom-control.custom-switch {
        input:checked + label:before {
            background-color: theme-color("success");
            border-color: theme-color("success");
        }

        input:not(:checked) ~ .custom-control-label {
            &:before {
                background-color: gray("500");
                border-color: gray("500");
                color: white;
            }

            &:after {
                background-color: white;
            }
        }
    }
}

.sync-card-grid {
    display: grid;
    grid-template-columns: [name] 1fr [connections] 180px [jobs] 50px [schedule] 200px [history] 100px [buttons] 50px;
    justify-content: space-between;
    gap: 10px;
    padding: 0 30px;
}

.sync-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-family: $font-family-primary;
    font-weight: 500;
    color: $neutral-900;
}

.svg-icon {
    width: 32px;
    height: 10px;
}

.run-line-container {
    width: 5px;
    max-height: 50px;
    margin: 0 2px;
    height: 45px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    &:hover {
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
        transform: scaleX(1.2);
    }

    .run-line {
        min-height: 5px;
        max-height: 50px;
        margin-bottom: 0;
        margin-top: auto;
        border-radius: 5px;

        &.success {
            background-color: var(--success);
        }

        &.error {
            background-color: var(--danger);
        }

        &.primary {
            background-color: var(--primary);
        }
    }
}
</style>
