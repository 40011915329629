<template>
    <loading-btn :listen="listen" @click.prevent.stop="showPopover">
        <slot />

        <portal to="extra-modals">
            <div v-if="showModal" :key="modalKey" class="extra-modals-route" @hide="onCancel">
                <div class="modal-container">
                    <button type="button" class="close-modal-btn btn-light" @click="onCancel()">
                        <i class="svg-icon icon-close" />
                    </button>

                    <div class="card header-card">
                        <div class="d-flex no-wrap card-body p-3 bg-transparent">
                            <i class="svg-icon icon-trash my-auto mr-3" />
                            <div class="text-header-sm text-secondary my-auto">
                                {{ headerTitle }}
                            </div>
                        </div>
                    </div>

                    <div class="delete-message">
                        {{ message }}
                    </div>

                    <div v-if="deleteConfirmation" class="delete-confirmation">
                        <div class="font-weight-bold text-lg">
                            Confirm by typing
                            <span class="text-danger font-weight-bolder">{{ deleteConfirmation }}</span>
                            below
                        </div>
                        <div class="">
                            <input v-model="confirmValue" class="form-control input__text" type="text">
                        </div>
                    </div>

                    <div class="mx-auto delete-buttons">
                        <button class="btn btn-lg btn-danger mx-auto" :disabled="disableDelete" @click="onDelete">
                            {{ deleteTitle }}
                        </button>
                        <button class="btn btn-lg btn-link text-dark mx-auto" @click="onCancel">
                            {{ cancelTitle }}
                        </button>
                    </div>
                </div>
            </div>
        </portal>
    </loading-btn>
</template>
<script>
import * as uuid from 'uuid';

export default {
    props: {
        message: {
            type: String,
            default: 'Are you sure you want to delete this?',
        },
        headerTitle: {
            type: String,
            default: 'Delete Confirmation',
        },
        deleteTitle: {
            type: String,
            default: 'Delete',
        },
        cancelTitle: {
            type: String,
            default: 'Cancel',
        },
        deleteConfirmation: {
            type: String,
            default: '',
        },
        listen: {
            type: [String, Array],
            default: '',
        },
    },
    data() {
        return {
            showModal: false,
            confirmValue: '',
            modalKey: uuid.v4(),
        };
    },
    computed: {
        disableDelete() {
            return this.deleteConfirmation
                ? this.deleteConfirmation !== this.confirmValue
                : false;
        },
    },
    methods: {
        showPopover() {
            this.confirmValue = '';
            this.$set(this, 'showModal', true);
        },
        onCancel() {
            this.showModal = false;
            this.$emit('cancel');
        },
        onDelete() {
            if (!this.disableDelete) {
                this.showModal = false;
                this.$emit('delete');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 775px;
    max-height: 680px;
    padding: 60px;

    .header-card {
        color: var(--theme-text-color);
        border-radius: $border-radius;
        background: #e6e3e0 !important;
        height: 90px;
    }

    .delete-message {
        margin: 15px auto 50px auto;
    }

    .delete-confirmation {

    }

    .delete-buttons {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        row-gap: 10px;
    }
}
</style>
