<template>
    <div class="form-group">
        <label>{{ config.label }}</label>
        <span v-b-tooltip.hover.right.html="description">
            <i class="ml-2 fal fa-info-circle small" />
        </span>
        <component :is="control"
                   :required="config.required"
                   :value="value"
                   :name="config.parameter"
                   :disabled="disabled"
                   :readonly="readonly"
                   :attrs="config"
                   @input="update"
        />
        <form-control-error :name="config.parameter" />
        <slot name="error" />
        <slot name="additionalInformation" />
    </div>
</template>

<script>
export default {
    name: 'FormField',
    props: {
        config: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        value: {},
        sourcename: {
            type: String,
        },
    },
    data() {
        return {
            description: {},
        };
    },
    computed: {
        control() {
            return `${this.config.control || 'text'}-field`.toLowerCase();
        },
    },
    mounted() {
        if (this.config.parameter === 'SRC_SQL_QUERY') {
            try {
                const configDescriptions = JSON.parse(this.config.description);
                const results = _.find(configDescriptions.results, result => result.filtering_value === this.sourcename);
                this.description = results ? results.filtered_results : this.config.description;
            } catch (err) {
                this.description = this.config.description;
            }
        } else {
            this.description = this.config.description;
        }
    },

    methods: {
        update(value) {
            if (this.config.slug === 'IS_INCREMENTAL' || this.config.slug === 'INCREMENTAL_TYPE') {
                this.$emit('inputDatabase', { value, slug: this.config.slug });
            }
            this.$emit('inputRequired', { value, slug: this.config.slug, config: this.config });
            this.$emit('input', value);
        },
    },

};
</script>
