import Pusher from 'pusher-js';
import Cookies from 'js-cookie';
import Config from '../config';

class PusherService {
    async init(user) {
        const token = window.sessionStorage.getItem('external_access_token') || window.localStorage.getItem('access_token');
        if (user.id && user.organisation && token) {
            Pusher.logToConsole = Config.isLocal || (Config.isDev && Config.pusher.debug);

            const pusherConfig = {
                cluster: 'eu',
                encrypted: true,
                disableStats: true,
                authEndpoint: `${Config.baseURL}/pusher/auth`,
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            };

            if (Cookies.get('canary_backend')) {
                pusherConfig.auth.headers['x-canary-be'] = 'always';
            }

            this.client = new Pusher(Config.pusher.appId, pusherConfig);

            this.userChannel = this.subscribePrivate(`${user.organisation.id}-${user.id}`);
            this.organisationChannel = this.subscribePrivate(`${user.organisation.id}`);

            const waitForSubscription = new Promise((resolve, reject) => {
                this.userChannel.bind('pusher:subscription_succeeded', () => {
                    resolve('subscription_succeeded');
                });
                this.userChannel.bind('pusher:subscription_error', (err) => {
                    reject(err);
                });
            });

            return waitForSubscription;
        }
        return Promise.reject(new Error('Pusher: User not logged in'));
    }

    get user() {
        this.currentChannel = this.userChannel;

        return this;
    }

    get organisation() {
        this.currentChannel = this.organisationChannel;

        return this;
    }

    subscribe(channelName, eventName, callback) {
        const channel = this.client.subscribe(`${channelName}-${Config.env}`);

        if (eventName) channel.bind(eventName, callback);

        return channel;
    }

    subscribePrivate(channelName, eventName, callback) {
        return this.subscribe(`private-${channelName}`, eventName, callback);
    }

    listenFor(eventName, callback) {
        if (!this.client) return;

        if (!this.currentChannel) throw new Error('You should select a channel before listening for events.');

        this.currentChannel.bind(eventName, callback);
        this.currentChannel = null;
    }

    removeListener(eventName, callback) {
        if (!this.client) return;
        if (!this.currentChannel) throw new Error('You should select a channel before listening for events.');

        this.currentChannel.unbind(eventName, callback);
        this.currentChannel = null;
    }

    trigger(eventName, data) {
        if (!this.client) return;

        if (!this.currentChannel) throw new Error('You should select a channel before listening for events.');
        this.currentChannel.trigger(`client-${eventName}`, data);
    }

    reset() {
        if (this.client) this.client.disconnect();
    }

    subscribeToAppUpdates(callback) {
        return this.subscribe('application-updates', 'new-version', callback);
    }
}

export default new PusherService();
