import lzString from 'lz-string';

class LocalStorageAdapter {
    constructor() {
        this.stores = {
            default: 'gravity.defaultStorage',
            permanent: 'gravity.permanentStorage',
        };
    }

    get(store, key) {
        const value = localStorage.getItem(`${this.stores[store]}.data.${key}`);

        return value ? JSON.parse(lzString.decompress(value)) : null;
    }

    set(store, key, value) {
        const compressed = lzString.compress(JSON.stringify(value));

        return localStorage.setItem(`${this.stores[store]}.data.${key}`, compressed);
    }

    remove(store, key) {
        localStorage.removeItem(`${this.stores[store]}.data.${key}`);
        this.removeKey(store, key);
    }

    removeAll(store) {
        _.each(this.keys(store), (key) => {
            this.remove(store, key);
        });
    }

    keys(store) {
        const value = localStorage.getItem(`${this.stores[store]}.keys`);

        return JSON.parse(value) || [];
    }

    addKey(key, store) {
        const keys = this.keys(store);

        if (!keys.includes(key)) {
            keys.push(key);
            localStorage.setItem(`${this.stores[store]}.keys`, JSON.stringify(keys));
        }
    }

    removeKey(key, store) {
        const keys = this.keys(store);

        _.remove(keys, key);
        localStorage.setItem(`${this.stores[store]}.keys`, JSON.stringify(keys));
    }
}

export default new LocalStorageAdapter();
