import Vue from 'vue';
import * as api from '../api/notifications.api';
import Notification from '../models/notification.model';

export default {
    namespaced: true,
    state: {
        totalUnreadNotifications: {
            SMART_ALERTS: 0,
            ALERTS: 0,
            INSIGHTS: 0,
        },
        notifications: [],
        subscriptions: {
            insights: {},
            smartAlerts: {},
            alerts: {},
        },
        filters: {
            isRead: null,
            isGood: null,
            notificationTypes: [],
            metrics: [],
        },
        activeMetrics: [],
        browserNotificationsEnabled: false,
    },
    getters: {
        totalUnreadNotifications: state => state.totalUnreadNotifications,
        notifications: ({ notifications }, getters, rootState, rootGetters) => {
            return _(notifications).map((attributes) => {
                return new Notification(attributes, rootState, rootGetters);
            }).value();
        },
        subscriptions: state => state.subscriptions,
        notificationMaxPages: state => state.notificationNrPages || 0,
        notificationFilters: state => state.filters,
    },
    mutations: {
        addNotifications: (state, payload) => {
            // Add new notifications and order from newest to oldest
            const updatedList = _(state.notifications)
                .unionBy(payload, 'id')
                .orderBy('createdDate', 'desc')
                .value();
            Vue.set(state, 'notifications', updatedList);
        },
        setBrowserNotifications(state, value) {
            Vue.set(state, 'browserNotificationsEnabled', value);
        },
        setNotifications: (state, payload) => {
            // Add new notifications and order from newest to oldest
            const notifications = _(payload)
                .orderBy('createdDate', 'desc')
                .value();
            Vue.set(state, 'notifications', notifications);
        },
        setNotification(state, { id, ...notification }) {
            const index = state.notifications.findIndex(item => item.id === id);

            if (index === -1) {
                // Create new notification
                state.notifications.push({ id, ...notification });
            } else if (!_.isEqual(state.notifications[index], { id, ...notification })) {
                // Update existing notification
                Vue.set(state.notifications, index, { id, ...notification });
            }
        },
        setNotificationsNrPages(state, nrPages) {
            Vue.set(state, 'notificationNrPages', nrPages);
        },
        updateNotification(state, { id, ...notification }) {
            const index = state.notifications.findIndex(item => item.id === id);
            if (!_.isEqual(state.notifications[index], { id, ...notification })) {
                // Update existing notification
                Vue.set(state.notifications, index, { id, ...state.notifications[index], ...notification });
            }
        },
        setNotificationReaction(state, { id, reaction }) {
            const index = state.notifications.findIndex(item => item.id === id);
            const notification = Object.assign({}, state.notifications[index], { reaction });
            Vue.set(state.notifications, index, notification);
        },
        setTotalUnreadNotifications: (state, payload) => {
            state.totalUnreadNotifications = payload;
        },
        decrementTotalUnreadNotifications: (state, notificationType) => {
            state.totalUnreadNotifications[notificationType] -= 1;
        },
        incrementTotalUnreadNotifications: (state, notificationType) => {
            state.totalUnreadNotifications[notificationType] += 1;
        },
        setLatestReceivedNotification: (state, payload) => {
            // Adding latest received notification at the beginning of the Notifications array
            state.notifications = _.concat(payload, state.notifications);
        },
        setNotificationSubscriptions: (state, payload) => {
            state.subscriptions = payload;
        },
        setNotificationFilters: (state, { isGood = null, isRead = null, notificationTypes = [], metrics = [] }) => {
            const selectedTypes = [];
            const ANOMALY = _.includes(notificationTypes, 'SMART_ALERTS')
                || _.includes(notificationTypes, 'INSIGHTS');
            const TRESHOLD = _.includes(notificationTypes, 'ALERTS');
            if (ANOMALY) selectedTypes.push('Anomaly');
            if (TRESHOLD) selectedTypes.push('Threshold');

            Vue.set(state.filters, 'notificationTypes', selectedTypes.length ? selectedTypes : null);

            if (isGood === null) Vue.set(state.filters, 'isGood', null);
            if (isGood === true) Vue.set(state.filters, 'isGood', 'Good');
            if (isGood === false) Vue.set(state.filters, 'isGood', 'Bad');
            
            if (isRead === null) Vue.set(state.filters, 'isRead', null);
            if (isRead === true) Vue.set(state.filters, 'isRead', 'Read');
            if (isRead === false) Vue.set(state.filters, 'isRead', 'Unread');

            Vue.set(state.filters, 'metrics', metrics);
        },
        setActiveMetrics(state, activeMetrics) {
            const tablesWithMetrics = _(activeMetrics)
                .groupBy('table.id')
                .reduce((result, metrics, tableId) => {
                    return [
                        ...result,
                        {
                            id: tableId,
                            tableName: metrics[0].table.name,
                            metrics: _.map(metrics, (metric) => {
                                return { metricName: metric.name, id: metric.id };
                            }),
                        },
                    ];
                }, []);
            Vue.set(state, 'activeMetrics', tablesWithMetrics);
        },
    },
    actions: {
        async getNotifications({ commit }, { page = 1, limit = 50, refresh = false }) {
            commit('activateLoading', 'notifications/getNotifications', { root: true });
            try {
                return await api.getNotifications(page, limit).then((response) => {
                    if (refresh) commit('setNotifications', response.notifications);
                    else commit('addNotifications', response.notifications);
                    commit('setNotificationsNrPages', response.nrPages);
                });
            } finally {
                commit('deactivateLoading', 'notifications/getNotifications', { root: true });
            }
        },
        async setReactForNotification({ commit }, { notificationId, reaction }) {
            commit('activateLoading', 'notifications/setReactForNotification', { root: true });
            try {
                const payload = {
                    id: notificationId,
                    reaction,
                };
                await api.setReactForNotification(payload);
                commit('setNotificationReaction', payload);
            } finally {
                commit('deactivateLoading', 'notifications/setReactForNotification', { root: true });
            }
        },
        async setNotificationsReadStatus({ commit }, { notifications, status }) {
            const updateUnreadNotifications = status ? 'decrementTotalUnreadNotifications' : 'incrementTotalUnreadNotifications';
            // filter out notifications that already have the same status
            const notificationToUpdate = _.reject(notifications, { isRead: status });
            await api.setNotificationsReadStatus({
                notificationIds: _.map(notificationToUpdate, 'id'),
                read: status,
            });

            _.each(notificationToUpdate, (notification) => {
                commit('setNotification', { ...notification, isRead: status });
                commit(updateUnreadNotifications, notification.feature);
            });
        },

        async getTotalUnreadNotificationsByUserId({ commit }, id) {
            try {
                const response = await api.getTotalUnreadNotificationsByUserId(id);
                commit('setTotalUnreadNotifications', response);
            } catch (error) {
                console.log('Error: getTotalUnreadNotificationsByUserId: ', error);
            }
        },

        async getNotificationSubscriptions({ commit }) {
            commit('activateLoading', 'notifications/toggleSubscriptionStatus', { root: true });
            try {
                const { success: { deliveryDetails } } = await api.getAllNotificationSubscriptions();

                commit('setNotificationSubscriptions', convertIntegrations(deliveryDetails));
            } catch (err) {
                throw err;
            } finally {
                commit('deactivateLoading', 'notifications/getNotificationSubscriptions', { root: true });
            }
        },

        async editNotificationSubscriptions({ commit }, payload) {
            commit('activateLoading', 'notifications/toggleSubscriptionStatus', { root: true });
            try {
                const { success: { deliveryDetails } } = await api.editNotificationSubscriptions(payload);
                commit('setNotificationSubscriptions', convertIntegrations(deliveryDetails));
            } catch (err) {
                throw err;
            } finally {
                commit('deactivateLoading', 'notifications/toggleSubscriptionStatus', { root: true });
            }
        },

        async getIdentitiesWithAccess({ commit }, notificationId) {
            commit('activateLoading', 'notifications/getIdentitiesWithAccess', { root: true });
            try {
                return await api.getIdentitiesWithAccess(notificationId);
            } finally {
                commit('deactivateLoading', 'notifications/getIdentitiesWithAccess', { root: true });
            }
        },

        async shareNotification({ commit }, payload) {
            commit('activateLoading', 'notifications/shareNotification', { root: true });
            try {
                const response = await api.shareNotification(payload);
                commit('updateNotification', response);
            } catch (error) {
                console.log('Error: shareNotification: ', error);
            } finally {
                commit('deactivateLoading', 'notifications/shareNotification', { root: true });
            }
        },

        async saveNotificationFilters({ commit }, filters) {
            commit('activateLoading', 'notifications/saveFilters', { root: true });
            try {
                const payload = {
                    isGood: null,
                    isRead: null,
                    notificationTypes: [],
                    metrics: [],
                };

                const notificationTypes = [];
                if (_.includes(filters.notificationTypes, 'Anomaly')) notificationTypes.push('SMART_ALERTS', 'INSIGHTS');
                if (_.includes(filters.notificationTypes, 'Threshold')) notificationTypes.push('ALERTS');

                payload.metrics = filters.metrics;
                payload.notificationTypes = notificationTypes;
                if (filters.isGood === null) payload.isGood = null;
                if (filters.isGood === 'Good') payload.isGood = true;
                if (filters.isGood === 'Bad') payload.isGood = false;
                if (filters.isRead === null) payload.isRead = null;
                if (filters.isRead === 'Read') payload.isRead = true;
                if (filters.isRead === 'Unread') payload.isRead = false;

                const response = await api.saveNotificationFilters(payload);
                commit('setNotificationFilters', response);
            } finally {
                commit('deactivateLoading', 'notifications/saveFilters', { root: true });
            }
        },

        async getNotificationFilters({ commit }) {
            commit('activateLoading', 'notifications/getFilters', { root: true });
            try {
                const response = await api.getNotificationFilters();
                commit('setNotificationFilters', response);
            } finally {
                commit('deactivateLoading', 'notifications/getFilters', { root: true });
            }
        },

        async getAllActiveMetrics({ commit }) {
            commit('activateLoading', 'notifications/getAllActiveMetrics', { root: true });
            try {
                const payload = {
                    active: true,
                    cohort: false,
                    complexity: 'dynamicRelation',
                    mode: 'simple',
                };
                const response = await api.getAllMetrics(payload);
                commit('setActiveMetrics', response);
            } finally {
                commit('deactivateLoading', 'notifications/getAllActiveMetrics', { root: true });
            }
        },
    },
};

function convertIntegrations(integrations) {
    const subscriptions = {};
    _.forEach(integrations, (type) => {
        subscriptions[_.camelCase(type.feature)] = { };

        _.forEach(type.deliveryMethods, (item) => {
            const { deliveryMethod, ...subscription } = item; // Removing deliveryMethod from object
            subscriptions[_.camelCase(type.feature)][_.camelCase(item.deliveryMethod)] = {
                ...subscription,
                // Converting from String to Boolean
                disabled: !(subscription.disabled === undefined || subscription.disabled === 'true'),
            };
        });

        if (Object.keys(type.featureDetails).length) {
            subscriptions[_.camelCase(type.feature)].featureDetails = type.featureDetails;
        }
    });
    return subscriptions;
}
