/* eslint import/no-cycle:0 */

import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.store';
import filters from './modules/filters.store';
import groups from './modules/groups.store';
import profile from './modules/profile.store';
import users from './modules/users.store';
import permissions from './modules/permissions.store';
import notifications from './modules/notifications.store';
import dataSources from './modules/datasources.store';
import dataTargets from './modules/datatargets.store';
import dataSets from './modules/datasets.store';
import stripe from './modules/stripe.store';
import runHistory from './modules/runHistory.store';
import integrations from './modules/integrations.store';
import syncs from './modules/syncs.store';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        auth,
        filters,
        groups,
        profile,
        users,
        permissions,
        notifications,
        dataSources,
        dataTargets,
        dataSets,
        stripe,
        runHistory,
        integrations,
        syncs,
    },
    state: {
        loading: {},
        app: {
            config: {
                styles: {
                    font: '-apple-system, BlinkMacSystemFont, "Segoe UI", Nunito, "Helvetica Neue", Arial, sans-serif, '
                    + '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                },
            },
        },
    },
    mutations: {
        activateLoading(state, key) {
            window.performance.mark(key);
            Vue.set(state.loading, key, true);
        },
        deactivateLoading(state, key) {
            Vue.set(state.loading, key, false);
            window.performance.measure(key);
        },
    },
});
