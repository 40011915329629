export default class Auth {
    static setTokens({ accessToken, idToken, expiresIn = 36000 }) {
        window.localStorage.setItem('access_token', accessToken);
        window.localStorage.setItem('token', idToken);
        window.localStorage.setItem('expires_at', JSON.stringify((expiresIn * 1000) + new Date().getTime()));
    }

    static clearTokens() {
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('expires_at');
        window.localStorage.removeItem('external_access');
    }
}
