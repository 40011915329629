<template>
    <div>
        <multi-select
            :value="selected"
            :disabled="disabled"
            size="1"
            :searchable="true"
            :options="timezones"
            label-field="label"
            required
            track-by="value"
            @input="setTimezone"
        />
        <portal to="extra-modals">
            <div v-if="showModal" key="timezone-modal" class="extra-modals-route" @hide="onCancel">
                <div class="modal-container">
                    <button type="button" class="close-modal-btn btn-light" @click="onCancel()">
                        <i class="svg-icon icon-close" />
                    </button>
                    <div class="input-group mb-3">
                        <input v-model="search" type="text" class="form-control input__text" placeholder="Search Timezones">
                    </div>
                    <div class="card" style="height: 500px;">
                        <ul class="list-group card-body p-4 list-group-flush scrollable-area h-100 overflow-auto">
                            <li v-for="timezone in filteredZones" :key="timezone.label" class="list-group-item px-0 py-0"
                                :class="[timezone.value === selected.value ? 'o-100' : 'o-50']">
                                <label class="custom-checkbox-wrapper d-block mb-0 py-2 clickable" @click="selected = timezone">
                                    <i class="far fa-fw" :class="[timezone.value === selected.value ? 'fa-dot-circle active' : 'fa-circle']" />
                                    {{ timezone.label }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex align-items-center mt-2 border-top pt-3">
                        <i class="far fa-fw fa-globe-europe fa-lg text-muted" />
                        <div class="small text-muted mb-0 ml-1">
                            Currently selected <br>
                            <span class="font-weight-bold">{{ selected.value }}</span>
                        </div>
                        <button class="btn btn-link ml-auto " @click="onApply">
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </portal>
        <!-- <portal to="extra-modals">
            <div v-if="showModal" key="timezone-modal" class="extra-modal-route">
                <div class="modal-container">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-transparent border-right-0">
                                <i class="fal fa-search fa-fw" />
                            </span>
                        </div>
                        <input v-model="search" type="text" class="form-control pl-0 border-left-0" placeholder="Search Timezones">
                    </div>
                    <div class="list-group scrollable-area">
                        <ul class="list-group list-group-flush">
                            <li v-for="timezone in filteredZones" :key="timezone.label" class="list-group-item px-0 py-0"
                                :class="[timezone.value === selected.value ? 'o-100' : 'o-50']">
                                <label class="custom-checkbox-wrapper d-block mb-0 py-2" @click="selected = timezone">
                                    <i class="far fa-fw"
                                    :class="[timezone.value === selected.value ? 'fa-dot-circle active' : 'fa-circle']" />
                                    {{ timezone.label }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex align-items-center mt-2 border-top pt-3">
                        <i class="far fa-fw fa-globe-europe fa-lg text-muted" />
                        <div class="small text-muted mb-0 ml-1">
                            Currently selected <br>
                            <span class="font-weight-bold">{{ selected.value }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </portal> -->
    </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: '',
            selected: '',
            showAll: false,
            showModal: false,
            originalTimezone: '',
        };
    },
    computed: {
        filteredZones() {
            if (this.search) {
                return this.timezones.filter(z => z.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
            }
            return this.timezones;
        },
        timezones() {
            let zones = this.showAll ? moment.tz.names() : this.mainZones;
            zones = zones.map(zone => ({
                label: `${zone} (UTC${moment().tz(zone).format('Z')}) `,
                value: zone,
            }));

            if (!this.showAll) {
                zones.push({
                    label: 'Show More...',
                    value: 'show-all',
                });
            }
            return zones;
        },
        mainZones() {
            const zones = [
                'GMT',
                'Europe/London',
                'Europe/Berlin',
                'Asia/Hong_Kong',
                'America/New_York',
                'Australia/Sydney',
            ];

            if (this.value && zones.indexOf(this.value) === -1) {
                zones.push(this.value);
            }

            return zones.sort();
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.selected = _.find(this.timezones, { value });
            },
        },
    },
    methods: {
        setTimezone(zone) {
            if (zone && zone.value === 'show-all') {
                this.showModal = true;
                this.showAll = true;

                // Remember original timezone so we can revert if user clicks cancel
                this.originalTimezone = _.clone(this.selected);

                return;
            }
            this.selected = zone;
            this.$emit('input', zone.value);
        },
        onApply() {
            this.showModal = false;
            this.$emit('input', this.selected.value);
        },
        onCancel() {
            // Revert to previously selected timezone when someone clicks cancel
            this.selected = this.originalTimezone;
            this.showModal = false;
        },
    },
};
</script>
