import Model from './model';

class Filter extends Model {
    get values() {
        // If we have no value just return it, no need to convert to array
        if (!this.attributes.values) return this.attributes.values;

        return _.isArray(this.attributes.values) ? this.attributes.values : [this.attributes.values];
    }
}

export default Filter;
