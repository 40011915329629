<template>
    <div>
        <loader :force-loading="true" :listen="[]" class="row m-auto">
            Please wait
        </loader>
    </div>
</template>

<script>
/* eslint-disable import/no-unresolved */

import { PusherService } from '@/utils/services';
import Config from '@/utils/config';

export default {
    created() {
        // send them to the opening window
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
                type: 'AUTHORIZATION_MESSAGE',
                origin: Config.appUrl,
                data: {
                    source: this.$route.params.code,
                    payload: this.$route.query,
                },
            });
        } else {
            // Fallback to pusher
            console.log({
                origin: Config.appUrl,
                data: {
                    source: this.$route.params.code,
                    payload: this.$route.query,
                },
            }, 'fallback token');
            PusherService.user.trigger('client-authorise-connection', {
                origin: Config.appUrl,
                data: {
                    source: this.$route.params.code,
                    payload: this.$route.query,
                },
            });
        }

        // close the popup
        setTimeout(() => {
            window.close();
        }, 5000);
    },
};
</script>
