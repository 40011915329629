import _ from 'lodash';
import { RULE_ENGINE_OPERATORS } from '@/utils/operators';
import { Engine } from 'json-rules-engine';
import DynamicParameter from './dynamicParameter.model';

const policyModelDefaults = {
    id: '',
    integrationId: '',
    parameters: {},
};

export default class PolicyModel {
    #data;

    #configuration;

    constructor(data = {}, configuration = {}, fetchParameterData = () => {}) {
        const values = _.assignIn({}, _.cloneDeep(policyModelDefaults), _.cloneDeep(data));
        this.#data = values;
        this.#configuration = _.cloneDeep(configuration);

        _.each(this.#data, (value, key) => {
            _.set(this, key, value);
        });

        this.showEdit = false;

        const ruleEngine = new Engine([], { allowUndefinedFacts: true });
        _.each(RULE_ENGINE_OPERATORS, (ruleFunction, ruleName) => {
            ruleEngine.addOperator(ruleName, ruleFunction);
        });
        this.ruleEngine = ruleEngine;
        this.facts = {};

        _.each(this.#configuration.parameters, (paramConfig) => {
            const paramValue = _.get(values.parameters, paramConfig.parameter, null);
            const paramName = paramConfig.parameter;
            const param = new DynamicParameter(paramValue, paramConfig, (parameter, prerequisiteValues, invalidateCache = false) => {
                return fetchParameterData({
                    invalidateCache,
                    query: {
                        parameter,
                        prerequisiteValues,
                        integrationId: this.integrationId,
                    },
                });
            });

            _.set(this.parameters, paramName, param);
        });

        _.each(this.parameters, (parameter) => {
            _.set(this.facts, parameter.parameter, parameter.value);
            if (parameter instanceof DynamicParameter) {
                if (parameter._config.condition_for_requirement) {
                    this.ruleEngine.addRule(parameter.conditionForRequirementRule);
                }

                parameter.observeDependencies(this.parameters);
                parameter.addObserver(this);
            }
        });

        this.ruleEngine.run(this.facts).then(() => {
            console.log('First run');
        });
    }

    update({ param, value }) {
        this.facts[param] = value;
        this.ruleEngine.run(this.facts).then(() => {
            console.log(`Runed because ${param} changed`);
        });
    }

    get payload() {
        const parameters = _(this.parameters)
            .filter((param) => {
                return param.visible || !(param instanceof DynamicParameter);
            })
            .value();

        return {
            name: this.name,
            id: this.id,
            integrationId: this.integrationId,
            parameters: _.reduce(parameters, (result, { parameter, value }) => {
                return {
                    ...result,
                    [parameter]: value,
                };
            }, {}),
        };
    }

    get isValid() {
        const parameters = _(this.parameters)
            .filter((param) => {
                return (param instanceof DynamicParameter) && param.visible;
            })
            .value();
        
        return _.every(parameters, {
            isValid: true,
            loading: false,
        });
    }

    get visibleParameters() {
        return _(this.parameters)
            .filter('visible')
            .sortBy('order_of_display')
            .value();
    }
}
