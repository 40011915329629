export default class RunHistory {
    entries

    constructor(entries = []) {
        this.entries = new Map(entries);
    }

    add(runLog) {
        this.entries.delete(_.get(runLog, 'run_id'));
        this.entries.set(_.get(runLog, 'run_id'), runLog);
    }

    latest() {
        return this.all().first();
    }

    all() {
        return _(this.entries.values())
            .thru(Array.from)
            .orderBy('start_datetime', 'desc');
    }
}
