import Vue from 'vue';
import path from 'path';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import JsonCSV from 'vue-json-csv';

// The relative path of the components folder,  Whether or not to look in subfolders

const requireComponent = require.context('./global', true);

requireComponent.keys().forEach((fileName) => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(camelCase(path.basename(fileName, '.vue')));

    // Register component globally
    Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.component('DownloadCsv', JsonCSV);
