<template>
    <div>
        <div v-if="!emptyJson" class="d-flex flex-row justify-content-start">
            <button v-if="!expanded" class="btn btn-link px-0 mr-2" @click="setJson(true)">
                <span class="small"> <i class="far fa-expand mr-1" /> Expand all </span>
            </button>
            <button v-if="expanded" class="btn btn-link px-0 mr-2" @click="setJson(false)">
                <span class="small"> <i class="far fa-compress mr-1" /> Collapse all </span>
            </button>
            <button v-if="errorLog"
                    class="btn btn-link px-0 mx-2"
                    @click="setJson(false, true)">
                <span class="small"> <i class="far fa-exclamation-triangle" /> Go to error </span></button>
        </div>
        <div ref="target" class="json-viewer" />
    </div>
</template>

<script>
export default {
    props: {
        json: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            expanded: false,
        };
    },
    computed: {
        errorLog() {
            return this.json.status.toLowerCase().includes('error');
        },
        emptyJson() {
            return _.isEmpty(this.json);
        },
    },
    watch: {
        json: {
            handler() {
                this.setJson(false);
            },
            deep: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.setJson(false, true);
        });
    },
    methods: {
        jsonViewer(json, collapsible = false, goToError = false) {
            const vm = this;
            const TEMPLATES = {
            // eslint-disable-next-line max-len
                item: '<div class="json__item"><div class="json__key">%KEY%</div><div class="json__value json__value--%TYPE% status__%STATUS%">%VALUE%</div></div>',
                // eslint-disable-next-line max-len
                itemCollapsible: '<label class="json__item json__item--collapsible"><input type="checkbox" class="json__toggle"/><div class="json__key">%KEY%</div><div class="json__value json__value--type-%TYPE% status__%STATUS%">%VALUE%</div>%CHILDREN%</label>',
                // eslint-disable-next-line max-len
                itemCollapsibleOpen: '<label class="json__item json__item--collapsible"><input type="checkbox" checked class="json__toggle"/><div class="json__key">%KEY%</div><div class="json__value json__value--type-%TYPE% status__%STATUS%">%VALUE%</div>%CHILDREN%</label>',
            };

            function createItem(key, value, type) {
                let element = TEMPLATES.item.replace('%KEY%', key);

                if (type === 'string') {
                    element = element.replace('%VALUE%', `"${value}"`);
                } else {
                    element = element.replace('%VALUE%', value);
                }

                element = element.replace('%TYPE%', type);

                if (key === 'status') {
                    let status = 'info';
                    if (value.toLowerCase().includes('finished')) {
                        status = 'success';
                    } else if (value.toLowerCase().includes('error')) {
                        status = 'error';
                    }

                    element = element.replace('%STATUS%', status);
                }

                return element;
            }

            function createCollapsibleItem(key, value, type, children) {
                let tpl = 'itemCollapsible';
        
                if (collapsible) {
                    tpl = 'itemCollapsibleOpen';
                }

                if (goToError) {
                    tpl = getExpandStatusForError(value);
                }
        
                let element = TEMPLATES[tpl].replace('%KEY%', key);

                if (type === 'object') {
                    if (value.executionLabel) {
                        element = element.replace('%VALUE%', `${value.executionLabel} ${value.status ? `-- ${value.status}` : ''}`);
                    } else {
                        element = element.replace('%VALUE%', 'stages');

                        let status = 'info';
                        if (vm.json.status.toLowerCase().includes('finished')) {
                            status = 'success';
                        } else if (vm.json.status.toLowerCase().includes('error')) {
                            status = 'error';
                        }

                        element = element.replace('%STATUS%', status);
                    }

                    if (value.status) {
                        let status = 'info';
                        if (value.status.toLowerCase().includes('finished')) {
                            status = 'success';
                        } else if (value.status.toLowerCase().includes('error')) {
                            status = 'error';
                        }

                        element = element.replace('%STATUS%', status);
                    }
                } else {
                    element = element.replace('%VALUE%', type);
                }

                element = element.replace('%TYPE%', type);
                element = element.replace('%CHILDREN%', children);

                return element;
            }

            function getExpandStatusForError(value) {
                let tpl = 'itemCollapsible';
 
                if (value.length) {
                    value.forEach((child) => {
                        if (child.status.toLowerCase().includes('error')) {
                            tpl = 'itemCollapsibleOpen';
                            return;
                        }
                            
                        if (tpl === 'itemCollapsibleOpen') return;

                        if (child.children && child.children.length) {
                            getExpandStatusForError(child.children);
                        }
                    });
                } else if (value.status.toLowerCase().includes('error')) {
                    tpl = 'itemCollapsibleOpen';
                }

                return tpl;
            }

            function handleChildren(key, value, type) {
                let html = '';

                // eslint-disable-next-line guard-for-in
                for (const item in value) {
                    // eslint-disable-next-line no-shadow
                    const key = item;
                    const val = value[item];

                    html += handleItem(key, val);
                }

                return createCollapsibleItem(key, value, type, html);
            }

            function handleItem(key, value) {
                const type = typeof value;

                if (typeof value === 'object') {
                    return handleChildren(key, value, type);
                }

                return createItem(key, value, type);
            }

            function parseObject(obj) {
                let result = '<div class="json">';
                
                // eslint-disable-next-line guard-for-in
                for (const item in obj) {
                    const key = item;
                    const value = obj[item];
                    result += handleItem(key, value);
                }

                result += '</div>';
                return result;
            }
    
            return parseObject(json);
        },
        setJson(expand = false, toError = false) {
            this.expanded = expand;
            this.$refs.target.innerHTML = this.jsonViewer(this.json, expand, toError);
        },
    },
};
</script>
