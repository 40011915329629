import Profile from '@/modules/profile/views/details';
import Billing from '@/modules/profile/views/billing';
import MembersSection from '@/modules/profile/views/members';

export default [
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profile',
            auth: 'view',
        },
    },
    {
        path: '/billing',
        name: 'billing',
        component: Billing,
        meta: {
            title: 'Billing',
            auth: 'view',
        },
    },
    {
        path: '/team',
        name: 'team',
        component: MembersSection,
        meta: {
            title: 'Team',
            auth: 'view',
        },
    },
];
