/* eslint-disable class-methods-use-this */
import { loadStripe } from '@stripe/stripe-js';

import Config from '../config';

class StripeService {
    #stripe

    constructor() {
        this.loaded = false;
        this.#stripe = null;
        this.user = null;
    }

    async init(user) {
        this.user = user;
        this.loaded = true;
        return loadStripe(Config.Stripe.options.pk).then((stripe) => {
            this.#stripe = stripe;
            this.loaded = true;
            return 'loaded';
        }).catch((_e) => {
            return 'skipped';
        });
    }

    async activateSubscription(sessionId) {
        return this.#stripe.redirectToCheckout({ sessionId });
    }
}

export default new StripeService();
